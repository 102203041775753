import React from 'react';
import { Spinner } from '../../components/spinner/Spinner'

const DnnSpinningContainer = (props) => {

    return (
        <>   
            <Spinner />
        </>
    )
}

export default DnnSpinningContainer
