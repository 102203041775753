const CONFIG = {
   POWERBI_ENABLED: true,
   KONTAKTSKJEMA_ENABLED: true,   
   DEBUG_SERVER: 'http://127.0.0.1:5000/',
   SHOW_CHECK_PROFILE: true,
   SHOW_NEW_KREDITORDROPDOWN: true,
   SHOW_OLD_KREDITORDROPDOWN: false,
   WSUSER_TOKENLOGIN: true,
}

export default CONFIG