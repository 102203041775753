import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import styled from 'styled-components'
import colorConfig from '../../../colorConfig';

const StyledCol = styled(Col)`
    border-width: 0 0 0 1px;
    border-color:  rgb(0, 79, 113);
    border-style: solid;
    margin-bottom: 20px;

    position: relative;    
    width: 20%;
    float: left;

    @media print {
      border-width: 0 0 0 1px;
    }
`

const StyledColR = styled(Col)`
    border-width: 0 1px 0 1px;
    border-color:  rgb(0, 79, 113);
    border-style: solid;
    margin-bottom: 20px;

    position: relative;
    width: 20%;
    float: left;

    @media print {
      border-width: 0 1px 0 1px;
    }
`

const StyledBox = styled.div`
    margin-right: 15px;
   
    height: 75px;
    background-color: white;
    margin-bottom: 0px;

    font-size: 20px;
    font-weight: 500;
    color: rgb(0, 79, 113);
`

const StyledBoxHeader = styled.p`
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
    color: ${colorConfig.tabellrad_tekst}; 
    padding: 0px 0px 8px 0px;
    
    word-break:keep-all;
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;

    @media (max-width: 1024px) {
      font-size: 12px;
    }

    @media print {
      font-size: 9px;
    }
`

const StyledBoxNumber = styled.p`
    font-size: 30px;
    line-height: 50px;
    font-weight: 400;
    margin: 0px;
    padding: 0px 0px 8px 0px;
    color: ${colorConfig.header_tekst}; 
    
    word-break:keep-all;
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;

    @media (max-width: 1900px) {
      font-size: 36px;
    }

    @media (max-width: 1600px) {
      font-size: 24px;
    }

    @media print {
      font-size: 16px;
    }    
`

const KeepAll = styled.div`
    margin:0 auto;
    word-break:keep-all;
    padding:0px 5px;
`

const leftBox5 = (caption, value, size) => {

  return (
    <StyledCol>
      <StyledBox>
        
        <StyledBoxNumber>
          {value}
        </StyledBoxNumber>

        <StyledBoxHeader>
          {caption}
        </StyledBoxHeader>
      

      </StyledBox>
    </StyledCol>
  )
}

const rightBox5 = (caption, value, size) => {

  return (
    <StyledColR>
      <StyledBox>
        <StyledBoxNumber>
          {value}
        </StyledBoxNumber>
        <StyledBoxHeader>{caption}</StyledBoxHeader>
      </StyledBox>
    </StyledColR>
  )
}

const OversiktHeaderComponent = (props) => {

  let a1 = props.a1 ? props.a1 : 'props a1'
  let a2 = props.a2 ? props.a2 : 'props a2'
  let b1 = props.b1 ? props.b1 : 'props b1'
  let b2 = props.b2 ? props.b2 : 'props b2'
  let c1 = props.c1 ? props.c1 : 'props c1'
  let c2 = props.c2 ? props.c2 : 'props c2'
  let d1 = props.d1 ? props.d1 : 'props d1'
  let d2 = props.d2 ? props.d2 : 'props d2'
  let e1 = props.e1 ? props.e1 : 'props e1'
  let e2 = props.e2 ? props.e2 : 'props e2'

  return (
    <Container fluid="true">
      <Row>
        {leftBox5 (a1, a2, 3)}
        {leftBox5 (b1, b2, 3)}
        {leftBox5 (c1, c2, 3)}
        {/*leftBox5 (b1, b2, 2) */}
        {rightBox5(d1, d2, 3)}
      </Row>

    </Container>

  )
}

export default OversiktHeaderComponent