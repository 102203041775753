import { check_personalnumber_no, orgNummer } from '../../../util/validering/valideringsRutiner'

const getValidateEmail = (errorMessage) => {

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // const re2 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    // https://www.w3resource.com/javascript/form/email-validation.php
    const validateEmail = (val, name, formState) => {

        //if (formState.debtorType.toLowerCase() !== 'privat')
        //    return ''
        if (val === '') {
            return ''
        } else{
            if (re.test(val)) {
                return ''
            }
        }

        return errorMessage
    }

    return validateEmail
}

const getIsRequired = (errorMessage) => {

    const isRequired = (val, name, formState) => {
        //console.log ('is not blank', val)
        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getMinLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {

        if (!val) {
            return ''
        }
        else if (val.length >= length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}




const getMaxLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {

        if (!val) {
            return ''
        }
        else if (val.length <= length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}



export const getKontaktFormValidators = (messages) => {


    const reqiredName = getIsRequired(messages.requiredName)
    const validEmail = getValidateEmail(messages.notValidEpost)
    const reqiredEmail = getIsRequired(messages.requiredEpost)
    const reqiredEmne = getIsRequired(messages.requiredEmne)
    const reqiredMelding = getIsRequired(messages.requiredMelding)

    const formValidators = {
    
        navn: [
            reqiredName,
        ],
        epost: [
            reqiredEmail,
            validEmail,
        ],
        emne: [
            reqiredEmne,
        ],
        melding: [
            reqiredMelding,
        ]
    }

    return formValidators
}


