import { ArticleArea } from "../../../Styling/Layout/ContentArea"
import { BfButtonBlue } from "../../../Styling/styledForm/styledBlueForm"

export const MfaRemove = (props) => {

    let messages = props.messages
    let handleClick = props.postUnRegisterMfa

    return (
        <ArticleArea>
            <h1>{messages.MfaRemoveHeader}</h1>
            <p>{messages.MfaRemoveParagaph1}</p>            

            <BfButtonBlue onClick={() => handleClick()}>
                {messages.MfaRemoveButton}
            </BfButtonBlue>
        </ArticleArea>
    )
}
