import React from 'react'
import { Tabs, Tab, Container, Col, Alert } from "react-bootstrap";
import LocaleContext from '../../state/contexts/LocaleContext'

import { BlueRow } from '../../Styling/styledForm/styledBlueForm';

import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';
import { useStengelisteContainer } from './useStengelisteContainer';
import StengelisteTabell from './components/StengelisteGridComponent';
import StengelisteTotals from './components/StengelisteTotals';
import BekreftInnsending from './modals/bekreftInnsending/BekreftInnsending';
import BekreftOnHold from './modals/bekreftInnsending/BekreftOnHold';
import BekreftFlytting from './modals/bekreftInnsending/BekreftFlytting';
import { TabWrapper } from '../../components/tabs/TabWrapper';


const StengelisteContainer = (props) => {

    const locale = React.useContext(LocaleContext);

    const { localeMessages, key, setKey, selectedKreditor, toggleRow, toggleOnHoldRow, toggleMovedRow, toggleAllRows, state, headerState, getNumDisabled, getNumOnHold, getNumMoved, getSumDisabled,
        bsFuncs, fbFuncs, stFuncs }
        = useStengelisteContainer(locale)


    const getModalWindows = () => {
        return (
            <>
                {bsFuncs.dialogVisible && <BekreftInnsending bsFuncs={bsFuncs} />}
                {fbFuncs.dialogVisible && <BekreftFlytting fbFuncs={fbFuncs} />}
                {stFuncs.dialogVisible && <BekreftOnHold stFuncs={stFuncs} />}
            </>
        )
    }

    const getClosingList = () => {
        if (!state.filterString) {
            return state.closingList
        }
        else {
            return state.closingList.filter((rec) => rec.searchIndex.includes(state.filterString));
        }
    }

    const getInnsendtMessage = () => {

        let numDisabled = getNumDisabled()

        if (getNumDisabled()) {
            let message = `Du har sendt inn ${getNumDisabled()} til stenging på denne kreditoren.
                Disse vil bli behandlet i sine respektive datasystemer, og vil da forsvinne fra denne listen når den oppfriskes.`
            return (
                <Alert style={{ marginLeft: "-12px", marginRight: "-12px" }} fluid variant='success'>{message}</Alert>
            )
        }

        if (getNumOnHold()) {
            let message = `Du har satt ${getNumOnHold()} på hold.
                Den vil forsvinne fra denne listen når den oppfriskes, og kan bli funnet igjen under "På hold".`
            return (
                <Alert style={{ marginLeft: "-12px", marginRight: "-12px" }} fluid variant='success'>{message}</Alert>
            )
        }

        if (getNumMoved()) {
            let message = `Du har flyttet ${getNumMoved()} til listen.
                De vil forsvinne fra denne listen når den oppfriskes, og kan bli funnet igjen under "På stengeliste".`
            return (
                <Alert style={{ marginLeft: "-12px", marginRight: "-12px" }} fluid variant='success'>{message}</Alert>
            )
        }

        return ''
    }

    if (state.loading) {

        return (
            <>
                <h2>{localeMessages['faSlow']}</h2>
                <OverlaySpinner />
            </>
        )

    } else if (state.error) {

        return (<Alert variant='danger'>{state.error}</Alert>)

    } else if (!selectedKreditor) {

        return (<Alert variant='danger'>{localeMessages['VelgKreditor']}</Alert>)

    } else if (selectedKreditor && !state.hasStengeliste) {

        return (<Alert variant='danger'>{localeMessages['ikkeStengeliste']}</Alert>)

    } 

    else {

        return (
            <>
                {getModalWindows()}

                <Container fluid="true">
                    <StengelisteTotals
                        messages={localeMessages}
                        state={state}
                    />
                </Container>

                {getInnsendtMessage()}

                <Container fluid="true">
                        <Col xs="12">
                            <TabWrapper>
                                <Tabs id="closingListTabs" activeKey={key} onSelect={(k) => setKey(k)} style={{ backgroundColor: "white", fontWeight: "400", "color": "rgb(0, 62, 96)" }} >
                                    <Tab eventKey="statusNyTab" title={localeMessages['onClosingListTab']}>
                                    <StengelisteTabell
                                            localeMessages={localeMessages}
                                            tableData={state.filteredList}
                                            state={state}
                                            headerState={headerState}
                                            selectedKreditor={selectedKreditor}
                                            toggleRow={toggleRow}
                                            toggleOnHoldRow={toggleOnHoldRow}
                                            toggleMovedRow={toggleMovedRow}
                                            toggleAllRows={toggleAllRows}
                                            bsFuncs={bsFuncs}
                                            stFuncs={stFuncs}  
                                            fbFuncs={fbFuncs}                      
                                        />
                                    </Tab>
                                    <Tab eventKey="chosenTab" title={localeMessages['chosenTab']}>
                                        <StengelisteTabell
                                            localeMessages={localeMessages}
                                            tableData={state.filteredList}
                                            state={state}
                                            headerState={headerState}
                                            selectedKreditor={selectedKreditor}
                                            toggleRow={toggleRow}
                                            toggleOnHoldRow={toggleOnHoldRow}
                                            toggleMovedRow={toggleMovedRow}
                                            toggleAllRows={toggleAllRows}
                                            bsFuncs={bsFuncs}
                                            stFuncs={stFuncs}
                                            fbFuncs={fbFuncs}
                                        />
                                    </Tab>
                                    <Tab eventKey="paymentReceivedTab" title={localeMessages['paymentReceivedTab']}>
                                        <StengelisteTabell
                                            localeMessages={localeMessages}
                                            tableData={state.filteredList}
                                            state={state}
                                            headerState={headerState}
                                            selectedKreditor={selectedKreditor}
                                            toggleRow={toggleRow}
                                            toggleOnHoldRow={toggleOnHoldRow}
                                            toggleMovedRow={toggleMovedRow}
                                            toggleAllRows={toggleAllRows}
                                            bsFuncs={bsFuncs}
                                            stFuncs={stFuncs}
                                            fbFuncs={fbFuncs}
                                            
                                        />
                                    </Tab>
                                    <Tab eventKey="onHoldTab" title={localeMessages['onHoldTab']}>
                                        <StengelisteTabell
                                            localeMessages={localeMessages}
                                            tableData={state.filteredList}
                                            state={state}
                                            headerState={headerState}
                                            selectedKreditor={selectedKreditor}
                                            toggleRow={toggleRow}
                                            toggleOnHoldRow={toggleOnHoldRow}
                                            toggleMovedRow={toggleMovedRow}
                                            toggleAllRows={toggleAllRows}
                                            bsFuncs={bsFuncs}
                                            stFuncs={stFuncs}
                                            fbFuncs={fbFuncs}
                                        />
                                    </Tab>
                                </Tabs>
                            </TabWrapper>
                        </Col>
                </Container>
            </>
        )
    }
}

export default StengelisteContainer