export const messages = {
    nb: {
        header: `Kontakt Kreditorforeningen`,
        beskrivelse: `Registrer spørsmål og tilbakemeldinger til kreditorforeningen her.`,
        Send: `Send`,
        Skjemafeil: 'Du har feil i skjemaet som du må rette før du kan sende det inn.',

        notValidEmail: 'Ugyldig epostadresse',

        navnLabel: 'Navn',
        navnPlaceholder: 'Oppgi fullt navn',
        requiredNavn: 'Du må oppgi navn.',

        epostLabel: 'Epostadresse',
        epostPlaceholder: 'Oppgi epostadresse',
        requiredEpost: 'Du må oppgi epostadresse.',
        notValidEpost: 'Du har ikke oppgitt en gyldig epostadresse',

        emneLabel: 'Hva forespørselen gjelder',
        emnePlaceholder: '',
        requiredEmne: 'Du må oppgi hva saken gjelder.',

        meldingLabel: 'Melding',
        meldingPlaceholder: '',
        requiredMelding: 'Du må fylle ut meldingsfeltet.',

        dropDown1: 'Pålogging',
        dropDown2: 'Filopplasting',
        dropDown3: 'Inkassofaglig',
        dropDown4: 'Annet' 
    }
}
