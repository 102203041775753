import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

export const FETCH_PROFIL_BEGIN = 'FETCH_PROFIL_BEGIN'
export const FETCH_PROFIL_SUCCESS = 'FETCH_PROFIL_SUCCESS'
export const FETCH_PROFIL_FAILURE = 'FETCH_PROFIL_FAILURE'

const showToasts = true

export const fetchProfilBegin = () => ({
    type: FETCH_PROFIL_BEGIN
});

export const fetchProfilSuccess = profileData => ({
    type: FETCH_PROFIL_SUCCESS,
    payload: { profileData }
});

export const fetchProfilFailure = error => ({
    type: FETCH_PROFIL_FAILURE,
    payload: { error }
});



export const getProfile = (userName) => {
    return dispatch => {

        console.log("henter profil")
        const errorMessage = 'Henting av profildata feilet'
        const okMessage = 'Profildata er hentet'

        dispatch(fetchProfilBegin())

        axiosTokenInstance
            ({
                method: 'GET',                
                url: `api/UserAdmin/GetUser?email=${userName}`
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage, {autoClose: false}))
                    dispatch(fetchProfilFailure(errorMessage))                   
                } else {

                    let profileData = ''
                    if (_.has(result, 'data')) {
                        profileData = result.data
                    }

                    (showToasts && toast.info(okMessage))
                    dispatch (fetchProfilSuccess(profileData))
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchProfilFailure(lErrMess))
            })
    }
}
