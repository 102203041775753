import { useState } from 'react';
import styled from 'styled-components';

export const HoverBox = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${({ minWidth = 100 }) => minWidth}px;

    :hover {
        width: 100%;
    }
`


export const HoverBoxNoWidthChange = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${({ minWidth = 100 }) => minWidth}px;   
`

export const DisplayTextOnHover = (props) => {

    const [isShown, setIsShown] = useState(false);

    let timeoutId;
    const mouseEnter = () => {
        if (!timeoutId) {
            timeoutId = window.setTimeout(function() {
                timeoutId = null; // EDIT: added this line
                setIsShown(true)      
           }, 200);
        }
    }
    const mouseLeave = () => {
        if (timeoutId) {
            window.clearTimeout(timeoutId);
            timeoutId = null;
        }
        setIsShown(false)
    }

    return (
        <span
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}>
            {props.children}
            {isShown && (<>&nbsp;{props.caption}</>)}

        </span>

    )
}