import React, {useState} from 'react'
import {Pagination, Dropdown } from 'react-bootstrap'
import range from 'js-range'
import styled from 'styled-components'
import { messages } from './usePaginator.i18n'
import LocaleContext from '../../state/contexts/LocaleContext'
import { If } from '../../Utility/utility'
import colorConfig from '../../colorConfig'


export const StyledDropdown = styled(Dropdown)`
    display: inline-block;
    margin: 10px;
    
      
    button {     
      background-color: ${colorConfig.header_bg};
      border-color: ${colorConfig.header_bg};
      border-radius: 0px;
      font-size: 20px;
      border: 0px;    
      margin-top: -3px;
}
    }

    @media print { 
      display: none      
    }
  `

const HideOnPrint = styled.div`
  @media print { 
    display: none      
  }
`

// Merk state variablene må trekkes høyt opp for å beholde paginering mellom rendring
const usePaginator = (arr, numberPrPage, setNumberPrPAge, activePage, setActivePage ) => {

  const locale = React.useContext(LocaleContext);
  const { vis, linjer } = messages[locale]

  //const [numberPrPage, setNumberPrPAge] = useState(prPage)
  //const [activePage, setActivePage] = useState(1);

  const Paginate01 = ({ children }) => {
    const paginationFilter = (_, i) => 
      i >= activePage * numberPrPage - numberPrPage && 
      i < activePage * numberPrPage
    
    return arr
      .filter(paginationFilter)
      .map(children)
  }

  const Paginate = ({ children }) => {
    const paginationFilter = (_, i) =>
      i >= activePage * numberPrPage - numberPrPage &&
      i < activePage * numberPrPage

    return arr
      .filter(paginationFilter)
      .map((row, i) => children(row, i))
  }

  const PaginationNav = () => {
    const numberOfPages = Math.ceil(arr.length / numberPrPage) 
    let start = activePage - 2
    let end = activePage + 2
    end = end < 5 ? 5 : end
    
    return ( 
      numberOfPages > 1 &&
      <HideOnPrint>

      
      <Pagination className='hidden-print'>
        <Pagination.First onClick={() => setActivePage(1)} />
        <Pagination.Prev 
          onClick={() => setActivePage(activePage - 1)} 
          disabled={activePage === 1} 
        />
        <If condition={activePage > 3}>
          <Pagination.Item onClick={resetPagination}>1</Pagination.Item>
          <Pagination.Ellipsis />
        </If>
        {range(numberOfPages)
          .map((_, i) => {
            const count = i + 1
            if(count > end || count < start) return null
            return (
              <Pagination.Item 
                key={i} 
                onClick={() => setActivePage(count)} 
                active={count === activePage}
              >
                {count}
              </Pagination.Item>
            )
        })}
        <If condition={activePage < numberOfPages - 2}>
          <Pagination.Ellipsis />
          <Pagination.Item onClick={() => setActivePage(numberOfPages)}>{numberOfPages}</Pagination.Item>
        </If>        
        <Pagination.Next 
          onClick={() => setActivePage(activePage + 1)} 
          disabled={activePage === numberOfPages} 
        />
        <Pagination.Last onClick={() => setActivePage(numberOfPages)} />
      </Pagination>
      </HideOnPrint>
    )
  }

  const PaginationDropdown = () => { 
    const lines = [5, 10, 20, 40, 100, 250, 500]
    return (
      <span>  
        {vis}
        <StyledDropdown>
          <Dropdown.Toggle size="sm" variant="secondary">
            {numberPrPage}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {lines.map(line => 
              <Dropdown.Item 
                key={line}
                onClick={() => {
                  setNumberPrPAge(line)
                  resetPagination()
                }}
              >
                {line}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </StyledDropdown> 
        {linjer}
      </span>
    )
  }

  const PaginationIndex = () => {
    const start = activePage * numberPrPage - numberPrPage + 1
    const end = activePage * numberPrPage
    const total = arr.length 
    return `Viser ${start} til ${end > total ? total : end} av ${total} linjer`
  }

  const resetPagination = () => setActivePage(1)

  return [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination, numberPrPage, setNumberPrPAge, activePage, setActivePage]
}
 
export default usePaginator
  