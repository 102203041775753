import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';
import { models, Report, Embed, service, Page } from 'powerbi-client';

export const powerBiActions = {
    PBI_REPORT_LOADING: 'PBI_REPORT_LOADING',
    PBI_REPORT_ERROR: 'PBI_REPORT_ERROR',
    PBI_REPORT_DATA_FETCHED: 'PBI_REPORT_DATA_FETCHED',
    PBI_REPORT_CLEAR: 'PBI_REPORT_CLEAR',
}

const fetchBegin = (reportparams) => ({
    type: powerBiActions.PBI_REPORT_LOADING,
    payload: {'reportParams': reportparams}
});

const fetchSuccess = (data, reportparams) => ({
    type: powerBiActions.PBI_REPORT_DATA_FETCHED,
    payload: { data: data, 'reportParams': reportparams }
});

const fetchFailure = error => ({
    type: powerBiActions.PBI_REPORT_ERROR,
    payload: { error }
});

const fetchClear = () => ({
    type: powerBiActions.PBI_REPORT_CLEAR,
    payload: {  }
})

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}

// Tøm PBI for å unngå refreshtoken ved utløp av side
export const clearPowerBi = () => {
    return dispatch => {
        dispatch(fetchClear())
    }
}

export const getEmbeddedBbiUri2 = (reportId, settings, filter, reportparams, showToasts, messages) => {

    return dispatch => {

        const errorMessage = messages['toastGetReportFailed']
        const okMessage = messages['toastGetReportOk']

        dispatch(fetchBegin(reportparams))

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/report/getPBIReportByReportId/${reportId}`
            })
            .then((result) => {

                console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage, {autoClose: false}))
                    dispatch(fetchFailure(errorMessage))
                } else {

                    (showToasts && toast.info(okMessage))

                    const data = result.data
                    console.log(data)

                    let embedPBiConfig = {
                        type: 'report',       // Supported types: report, dashboard, tile, visual and qna
                        id: data.EmbedReport[0].ReportId,
                        embedUrl: data.EmbedReport[0].EmbedUrl,
                        accessToken: data.EmbedToken.Token,
                        tokenType: models.TokenType.Embed,
                        settings: settings,
                        }                                       
                
                    if (filter) {
                         embedPBiConfig.filters = filter
                    }
                
                    dispatch(fetchSuccess(embedPBiConfig, reportparams))
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchFailure(lErrMess))
            })
            // Fordi parsing av errormessage (getErrorMessage) kan feile
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + err
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchFailure(lErrMess))
            })
    }
}

export const getEmbeddedBbiUri = (reportId, settings, filter, reportparams, showToasts, messages) => {

    return dispatch => {

        const errorMessage = messages['toastGetReportFailed']
        const okMessage = messages['toastGetReportOk']

        dispatch(fetchBegin(reportparams))

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/report/getPBIReport/${reportId}`
            })
            .then((result) => {

                console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage, {autoClose: false}))
                    dispatch(fetchFailure(errorMessage))
                } else {

                    (showToasts && toast.info(okMessage))

                    const data = result.data
                    console.log(data)

                    let embedPBiConfig = {
                        type: 'report',       // Supported types: report, dashboard, tile, visual and qna
                        id: data.EmbedReport[0].ReportId,
                        embedUrl: data.EmbedReport[0].EmbedUrl,
                        accessToken: data.EmbedToken.Token,
                        tokenType: models.TokenType.Embed,
                        settings: settings,
                    }                                       
                
                    if (filter) {
                        embedPBiConfig.filters = filter
                    }

                    dispatch(fetchSuccess(embedPBiConfig, reportparams))
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchFailure(lErrMess))
            })
            // Fordi parsing av errormessage (getErrorMessage) kan feile
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + err
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchFailure(lErrMess))
            })
    }
}
