import React from 'react';
import { Container, Col, Form, Alert, Row } from "react-bootstrap";
import { BfYellowRowForm, BfTightYellowRowForm,  YellowRoundedContainer,  BfRowForm, BfTightRowForm, BfButtonBlue, BfFormLabel, BfFormLabelSm, BfFormErrorSm, BfButtonBlueNarrow } from '../../../Styling/styledForm/styledBlueForm'
import LocaleContext from '../../../state/contexts/LocaleContext'
import { messages } from './pbiPlannerForm.i18n'
import { usePbiPlannerForm } from './usePbiPlannerForm';
import { IntlProvider, FormattedMessage } from 'react-intl'
import { SceduledReportsTable } from './SceduledReportsTable';
import { BfColInput } from '../../../Styling/FormComponents/FormInput';
import BekreftSlettRapport from '../reportsTab/BekreftSlettRapport';

export const PbiPlannerForm = (props) => {

    // Localisation
    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]
    const setShowSpinner = props.setShowSpinner

    const {
        handleChange, handleMultiSelectChange, formState,
        errorMessages, debugInfo, getScheduleIntervalRadioBtns,  
        getKreditorsToSelect, getKreditorsToUnSelect, alleIsSelected, initializeForm,
        leggTilCustomer, trekkFraCustomer, reportMenuState, submitForm, deleteReport, gridRowClicked,
        showConfirm, setShowConfirm  } = usePbiPlannerForm(props)

    const hasErrors = props.hasErrors
    const savedOk = props.savedOk

    
    const kreditorSelectorsDisable = () => {return false}

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                //extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
            />
        )
    }


    return (

        <div style={{ padding: "30px" }}>

            {showConfirm && <BekreftSlettRapport 
                messages={localeMessages}
                okHandler={deleteReport}
                cancelHandler={()=>{setShowConfirm(false)}} />}

            <Form autoComplete="chrome-off">

               


                {hasErrors && (
                    <Alert variant='danger'>
                        {localeMessages.youHaveErrors}
                    </Alert>
                )}

                {savedOk && (
                    <Alert variant='success'>
                        {savedOk}
                    </Alert>
                )}
                
                <Row>

                    <Col xs={12} md={12}>
                    {reportMenuState.scheduleItems && reportMenuState.scheduleItems.length > 1 &&                        
                        <SceduledReportsTable 
                            state={reportMenuState}                
                            messages={localeMessages}
                            gridRowClicked={gridRowClicked}                  
                        />                        
                        }
                    </Col>

                    <Col xs={12} md={6}>

                    </Col>
                </Row>

                <YellowRoundedContainer fluid  style={{padding: '15px'}}> 

                <BfTightYellowRowForm>
                    <Col xs={12} md={12}>
                      

                    </Col>
                </BfTightYellowRowForm>

                <BfTightYellowRowForm>
                    <Col xs={12} md={5}>                       
                        {getFormField(12, 'reportName', null, null, false, 80)}                        
                    </Col>

                    <Col xs={12} md={2}>                       
                        
                    </Col>

                    <Col xs={12} md={5}>                       
                    <BfFormLabel htmlFor={'scheduleinterval'}>{localeMessages['scheduleinterval' + "Label"]}</BfFormLabel>
                        {getScheduleIntervalRadioBtns()}
                        <BfFormLabelSm>{localeMessages['scheduleinterval' + "Small"]}</BfFormLabelSm>
                        <BfFormErrorSm>{errorMessages['scheduleInterval']}</BfFormErrorSm>                 
                    </Col>
                </BfTightYellowRowForm>

                    
                <BfTightYellowRowForm>

                    {/* Daglig / ukentlig / måndedlig  */}
                    <Col xs={12} md={5}>
                        
                    </Col>
                    
                </BfTightYellowRowForm>

                <BfYellowRowForm>

                    <Col xs={12} md={5}>

                        <Form.Group controlId="formKreditorListe">
                        <BfFormLabel htmlFor={'datainterval'}>{localeMessages['listactor' + "Label"]}</BfFormLabel>

                            <Form.Control
                                name="listactor"
                                as="select"
                                multiple
                                htmlSize="10"
                                onChange={handleMultiSelectChange}
                                disabled={alleIsSelected}
                            >
                                {getKreditorsToSelect()}
                            </Form.Control>

                            <BfFormLabelSm>{localeMessages['KreditorListeSmall']}</BfFormLabelSm>
                        </Form.Group>


                    </Col>

                    <Col xs={6} md={1}>
                        <BfFormLabel></BfFormLabel><br />
                        <BfButtonBlueNarrow variant="primary" onClick={leggTilCustomer} disabled={alleIsSelected}>
                            {localeMessages['LeggTil']}
                        </BfButtonBlueNarrow>
                    </Col>
                
                    <Col xs={6} md={1}>
                        <BfFormLabel></BfFormLabel><br />
                        <BfButtonBlueNarrow variant="primary" onClick={trekkFraCustomer} disabled={kreditorSelectorsDisable()}>
                        {localeMessages['TrekkFra']}
                        </BfButtonBlueNarrow>
                    </Col>

                    <Col xs={12} md={5}>

                        <Form.Group controlId="formUnSelectedActors">
                            <BfFormLabel htmlFor={'unSelectedActors'}>{localeMessages['unSelectedActors' + "Label"]}</BfFormLabel>                            

                            <Form.Control
                                name="unSelectedActors"
                                as="select"
                                multiple
                                htmlSize="10"
                                onChange={handleMultiSelectChange}                                
                            >
                                {getKreditorsToUnSelect()}
                            </Form.Control>
                            
                            <BfFormLabelSm>{localeMessages['unSelectedActorsSmall']}</BfFormLabelSm>&nbsp;
                            <BfFormErrorSm>{errorMessages['selectedKreditors']}</BfFormErrorSm>
                        </Form.Group>

                    </Col>


                </BfYellowRowForm>


                <BfTightYellowRowForm>
                    <Col xs={12} md={12}>
                        <BfButtonBlue variant="primary" type="button" onClick={submitForm}>
                            Lagre
                        </BfButtonBlue>
                        &nbsp;&nbsp;

                        <BfButtonBlue variant="primary" type="button" onClick={initializeForm}>
                            Ny
                        </BfButtonBlue>
                        &nbsp;&nbsp;

                        <BfButtonBlue variant="primary" type="button" 
                            onClick={()=>{setShowConfirm(true)}}
                            disabled={!formState.reportId}
                            >
                            Slett
                        </BfButtonBlue>
                    </Col>
                </BfTightYellowRowForm>

                <BfTightYellowRowForm></BfTightYellowRowForm>

                </YellowRoundedContainer>
                {debugInfo()}

            </Form>

        </div>



    )
}