import { actions } from "./sakslisteActions";
import { initialState } from "./sakslisteActions";



export default function saklisteReducer (state = initialState, action) {

    switch (action.type) {

        case actions.SAKSLISTE_LOADING:
            return {
                ...state,
                fetched: false,
                loading: true,
                error: null
            };

        case actions.SAKSLISTE_DATA_FETCHED:            
            return {
                ...state,
                
                caseList: [...action.payload.caseList ],
                caseFiltered: [],

                numberOfItems: action.payload.numberOfItems,
                sumOriginalClaim: action.payload.sumOriginalClaim,
                sumInnbetHs: action.payload.sumInnbetHs,
                sumRestHs: action.payload.sumRestHs,
                sumRemainBalance: action.payload.sumRemainBalance,

                filtredNumberOfItems: 0,
                filtredSumOriginalClaim: 0,
                filteredSumInnbetHs: 0,
                filteredSumRestHs: 0,
                filtredSumRemainBalance: 0,

                filtered: false,
                fetched: true,
                loading: false,
                error: null
            };
        
        case actions.SAKSLISTE_DATA_FILTERED:
            return {
                ...state,
                
                caseFiltered: [...action.payload.caseList],
                
                filtredNumberOfItems: action.payload.filtredNumberOfItems,
                filtredSumOriginalClaim: action.payload.filtredSumOriginalClaim,
                filteredSumInnbetHs: action.payload.filteredSumInnbetHs,
                filteredSumRestHs: action.payload.filteredSumRestHs,
                filtredSumRemainBalance: action.payload.filtredSumRemainBalance,
                
                filtered: true,
                fetched: true,
                loading: false,
                error: null
            };
        
        case actions.SAKSLISTE_DATA_UNFILTERED:
            return {
                ...state,
                caseFiltered: [],
                filtered: false,
                fetched: true,
                loading: false,
                error: null
            };

        case actions.SAKSLISTE_ERROR:
            return {
                ...state,
                loading: false,
                fetched: true,
                error: action.payload
            }

        default:
            return state;
    }
}
