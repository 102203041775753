export const messages = {
    nb: {
        header: `Profilinformasjon`,
        beskrivelse: `Dette er informasjon vi har registert om deg. Du kan endre epostadresse og navn, men epostadressen må være unik for systemet.  Dvs du kan ikke ha registrert samme epostadressen på en annen kreditor.
        Dersom du ikke vil endre passordet, lar du passordfeltene være tomme.  Dersom du vil endre passordet, må du oppgi gammelt passord.`,
        Lagre: `Oppdater profilen`,
        youHaveErrors: 'Du har feil i skjemaet, ikke alle felter er fylt ut korrekt.  Du må korrigere dette før du kan endre profilen din.',
        youHavePWErrors: 'Du har feil i skjemaet.  Dersom du vil endre passord, må du oppgi gammelt passord, og du må oppgi nytt passord, og gjenta dette.  Du må korrigere dette før du kan endre profilen din.',

        sjekkprofilen: 'Kontroller at opplysningene vi har lagret på deg er korrekte. Kontroller at telefonnummer er til et norsk mobilnummer.  Dette nummeret brukes for å sende meldinger til, blant annet ved tofaktor autentisering.  Kontroller at epostadressen du benytter, er den du bruker til å motta epost fra.  Denne brukes dersom du er utestengt fra kontoen din.',

        toastSaveError: 'Kunne ikke oppdatere profilinformasjonen pga: ',
        toastSaveOk: 'Profilen din er oppdatert. Logg inn på på nytt for å ta i bruk endringene. ',
  
        IsRequired: `Feltet er påkrevd. `,

        EpostLabel: `Epostadresse`,
        EpostPlaceholder: `Oppgi gyldig epostadresse`,
        EpostSmall: `Merk: Epostadressen må være unik for systemet, den bruker du til pålogging. `,
        EpostRequired: `Feltet er påkrevd.`,


        PhoneNumberLabel: `Mobilnummer (8 siffer)`,
        PhoneNumberPlaceholder: `Oppgi gyldig norsk mobilnummer`,
        PhoneNumberSmall: `Mobilnummer for SMS varsling og tofaktor pålogging.`,
        GyldigMobil: ' Mobilnummer må være på formen 99922333, 4799922333 eller +4799922333.',

        NavnLabel: `Fullt navn`,
        NavnPlaceholder: `Oppgi navn`,
        NavnSmall: `Oppgi fornavn og etternavn.`,
        NavnRequired: `Feltet er påkrevd. `,
        minLength5: ' Minimum 5 tegn. ',

        validatePassword: 'Passord må ha minst 8 tegn, både store og små bokstaver, tall og spesialtegn.',
        checkEqualPasswords: 'Passordene oppgitt er forskjellige.',

        PassordLabel: `Gammelt passord`,
        PassordPlaceholder: `Oppgi gammelt passord`,
        PassordSmall: `Dersom du ikke vil bytte passord, lar du dette feltet stå tomt.`,
        PassordRequired: `Du må oppgi gammelt passord. `,

        Passord1Label: `Nytt Passord`,
        Passord1Placeholder: `Oppgi nytt passord`,
        Passord1Small: `Oppgi passordet du evt vil endre til her. `,
        Passord1Required: `Du må oppgi gammelt passord.`,

        Passord2Label: `Gjenta nytt passord`,
        Passord2Placeholder: `Gjenta nytt passord`,
        Passord2Small: `Gjenta passordet du evt vil endre til her. `,

        
    }
} 