import React, { useState } from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl'
import { StyleInlineFkTable, StyledPropotionalNavLink } from './../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../components/useTable/UseTable'
import usePaginator from '../../../components/useTable/UsePaginator'

import { fixMissingTimeFromDate } from '../../../util/date/dateRoutines';

const StengelisteExpandedRowComponent = (props) => {

    const  localeMessages = props.localeMessages

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('up'); // default
    const [currentField, setCurrentField] = useState('j');    // feltnavn
    const [currentType, setCurrentType] = useState('datetime'); // num

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10000)
    const [activePage, setActivePage] = useState(1);


    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    // Point to data 
    const tableData = props.tableData

    // Setupt paginator
    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        tableData.sort(t1GetSortFunc().fn), // <==== sorting inputdata =========
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const printHeader = () => {
        return (
            <thead>
                <tr>
                    <th onClick={() => t1ChangeSortField('j', 'num')}>{localeMessages['GHS_line']}{t1IndicaterIcon('j')}</th>
                    <th onClick={() => t1ChangeSortField('caseNumber', 'num')}>{localeMessages['GHS_caseNr']}{t1IndicaterIcon('caseNumber')}</th>
                    <th onClick={() => t1ChangeSortField('invoiceDate', 'datetime')}>{localeMessages['GHS_invoice_date']}{t1IndicaterIcon('invoiceDate')}</th>
                    <th onClick={() => t1ChangeSortField('dueDate', 'datetime')}>{localeMessages['GHS_due_date']}{t1IndicaterIcon('dueDate')}</th>
                    <th onClick={() => t1ChangeSortField('invoiceNo', 'num')}>{localeMessages['GHS_invoice_nr']}{t1IndicaterIcon('invoiceNo')}</th>
                    <th onClick={() => t1ChangeSortField('caseStatusText', 'string')}>{localeMessages['GHS_case_status_text']}{t1IndicaterIcon('caseStatusText')}</th>
                    <th style={{ textAlign: "right", paddingRight: 50 }} onClick={() => t1ChangeSortField('princepal', 'num')}>{localeMessages['GHS_princepal']}{t1IndicaterIcon('princepal')}</th>
                    <th style={{ textAlign: "right", paddingRight: 50 }} onClick={() => t1ChangeSortField('remainingPrincepal', 'num')}>{localeMessages['GHS_remaining_princepal']}{t1IndicaterIcon('remainingPrincepal')}</th>
                    <th style={{ textAlign: "right", paddingRight: 50 }} onClick={() => t1ChangeSortField('remainingTotal', 'num')}>{localeMessages['GHS_remaining_total']}{t1IndicaterIcon('remainingTotal')}</th>
                    <th onClick={() => t1ChangeSortField('closingNotificationSent', 'string')}>{localeMessages['GHS_closing_sent']}{t1IndicaterIcon('closingNotificationSent')}</th>

                </tr>
            </thead>
        )
    }

    const printRow = (model) => {

        const asDate = (val) => {
            // Tomt felt om det er noe krøll med val, ellers blir dagens dato satt inn som default
            if (val === null || val === '' || val === undefined) {return (<></>)};
            // Kludge fordi FireFox ikke godtar dato uten tid uten videre
            let valok = fixMissingTimeFromDate(val)
            return (
                <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
            )
        }


        return (
            <>
                <tr key={model.j + 10000} style={{ backgroundColor: 'white' }}>

                    <td>{model.j}</td>
                    <td>
                        <StyledPropotionalNavLink target='_blank' to={'/CaseDetails/' + model.creditorNo + '/' + model.caseNumber}>{model.caseNumber}</StyledPropotionalNavLink>
                    </td>
                    <td>{asDate(model.invoiceDate)}</td>
                    <td>{asDate(model.dueDate)}</td>
                    <td>{model.invoiceNo}</td>

                    <td>{model.caseStatusText}</td>
                    <td align='right' style={{ paddingRight: 50 }}>
                        {<FormattedNumber
                            value={model.princepal}
                            style='decimal'
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />}
                    </td>
                    <td align='right' style={{ paddingRight: 50 }}>
                        {<FormattedNumber
                            value={model.remainingPrincepal}
                            style='decimal'
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />}
                    </td>
                    <td align='right' style={{ paddingRight: 50 }}>
                        {<FormattedNumber
                            value={model.remainingTotal}
                            style='decimal'
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />}
                    </td>
                    <td>{model.closingNotificationSent}</td>

                </tr>

            </>

        )
    }

    return (
        <>

            <StyleInlineFkTable>
                {printHeader()}

                <tbody>
                    <Paginate>
                        {/* paginate forventer en funksjon */}
                        {row => printRow(row)}
                    </Paginate>
                </tbody>
            </StyleInlineFkTable>

        </>
    )
}

export default StengelisteExpandedRowComponent
