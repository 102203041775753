import React, { useState } from 'react';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';
import CaseListTable from './caseListTable/CaseListTable';
import CaseListTotals from './CaseListTotals';
import { Alert, Container } from 'react-bootstrap'
import { useCaselistReduxContainer } from './useCaseListReduxContainer';

import { useTrekkSakModal } from '../caseModals/trekkSak/useTrekkSakModal'
import { useUtsettSakModal } from '../caseModals/utsettSak/useUtsettSakModal'
import { useAvdragsordModal } from '../caseModals/avdragsordning/useAvdragsordModal'
import { useFakturaUploadModal } from '../caseModals/fakturaUpload/useFakturaUploadModal';

import TrekkSak01Modal from '../caseModals/trekkSak/TrekkSakModal01';
import TrekkSak02Modal from '../caseModals/trekkSak/TrekkSakModal02';
import UtsettSakModal from '../caseModals/utsettSak/UtsettSakModal'
import AvdragsordModal from '../caseModals/avdragsordning/AvdragsordModal';
import FakturaUploadModal from '../caseModals/fakturaUpload/FakturaUploadModal';
import { BfRowForm } from '../../Styling/styledForm/styledBlueForm';


export const CaseListReduxContainer = (props) => {
  
  const { isLoading, localeMessages, fetchMessage, state, aktive, PaginationDropdown,
    t1IndicaterIcon, t1ChangeSortField, memoizedCasesForTable,
    Paginate, PaginationIndex, PaginationNav } = useCaselistReduxContainer(props)
  
  const [errorMessage, setErrorMessage] = useState('')
  const [message, setMessage] = useState('')
  const [showSpinner, setShowSpinner] = useState(false)
  
  const tsFuncs = useTrekkSakModal(setErrorMessage, setMessage, setShowSpinner)
  const usFuncs = useUtsettSakModal(setErrorMessage, setMessage, setShowSpinner)
  const aoFuncs = useAvdragsordModal(setErrorMessage, setMessage, setShowSpinner)
  const fuFuncs = useFakturaUploadModal(setErrorMessage, setMessage, setShowSpinner)
 
  const getModalWindows = () => {
    return (
      <>
        {showSpinner && <OverlaySpinner/>}
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
        {message && <Alert variant='success'>{message}</Alert>}
        {tsFuncs.showTrekkSak01 && <TrekkSak01Modal tsFuncs={tsFuncs} />}
        {tsFuncs.showTrekkSak02 && <TrekkSak02Modal tsFuncs={tsFuncs} />}
        {usFuncs.showUtsettSakModal && <UtsettSakModal funcs={usFuncs} />}
        {aoFuncs.modalVisible && <AvdragsordModal funcs={aoFuncs} />}
        {fuFuncs.dialogVisible && <FakturaUploadModal funcs={fuFuncs} />}
      </>
    )
  }

  const getMainContent = () => {

    const datalen = memoizedCasesForTable.length

    if ((datalen === 0) && (isLoading)) {
      return (
        <Alert variant='danger'>{localeMessages['filterNone']}</Alert>
      )
    }
    else {
      return (
        <>

          <CaseListTotals
            state={state}
            aktive={aktive}
            messages={localeMessages}
          />
       
          {getModalWindows()}


          <Container fluid="true">
              <BfRowForm>              
                <div style={{color: "Black", fontSize: "24px"}}>
                  <span><b>{fetchMessage && fetchMessage}</b></span>
                </div>
              </BfRowForm>
            </Container>

          <CaseListTable

            PaginationDropdown={PaginationDropdown}
            t1IndicaterIcon={t1IndicaterIcon}
            t1ChangeSortField={t1ChangeSortField}
            Paginate={Paginate}
            PaginationIndex={PaginationIndex}
            PaginationNav={PaginationNav}
           
            aktive={aktive}

            showTrekkSak={tsFuncs.showTrekkSak}
            showUtsettSak={usFuncs.showUtsettSakDialog}
            showAvrdOrdn={aoFuncs.showDialog}
            showFaktUpload={fuFuncs.showDialogHandler}
          />
        </>

      )
    }
  }

  return (
    <>

      {isLoading &&
        <OverlaySpinner></OverlaySpinner>}

      {!isLoading && getMainContent()}
      
    </>

  )
  
}