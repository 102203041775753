export const messages = {
    nb: {
        IkkeTilgang: 'Du har ikke tilgang til bisnode.',

        Header1: 'Kredittinformasjon',
        Paragraph1: 'Oppslag av kredittinformasjon.',

        toastBisnodeFailed: 'Bisnode pålogging feilet.',
        toastGetBisnodeOk: 'Du blir nå videresendt til bisnode'

    }
}