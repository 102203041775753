export const messages = {
    nb: {
        toastNoUsersError: 'Ingen brukere funnen (en feil har oppstått',
        toastNoUsersFound: 'Ingen brukere tilfredstillet kriteriene',
        toastFoundUsers: 'Lastet ${poster} rader med brukere og kreditorer',    
        
        toastNoUserError: 'Kan ikke laste bruker (en feil har oppstått)',
        toastNoUserFound: 'Finner ikke bruker i database.',
        toastFoundUser: 'Brukerprofile lastet fra server.',  
        
        toastKreditorsError: 'Kan ikke laste kreditorer for valgt forening (en feil har oppstått)',
        toastNoKreditorsFound: 'Ingen kreditorer funnet for valg forening',
        toastFoundKreditors: 'Hentet ${poster} kreditorer for valgt forening.',   

        toastCannotSaveUser: 'Kunne ikke lagre bruker (en feil har oppstått)',
        toastUserSaved: 'Brukeren er lagret i databasen.',
        toastNon200SaveUser: 'Kunne ikke lagre bruer (en feil har oppstått)',
    }
} 