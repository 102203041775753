import axios from 'axios'
import CONFIG from '../config'

//let gBaseUrl = 'https://kundeportal-test.azurewebsites.net'
let gBaseUrl = window.location.protocol + '//' + window.location.hostname + ":" + window.location.port + '/'
if (process.env.NODE_ENV === 'development') {
    //gBaseUrl = 'http://localhost:5000/'
    gBaseUrl = CONFIG.DEBUG_SERVER
}

const authInstance = axios.create({
    baseURL: gBaseUrl,
    headers: { 'Content-Type': 'application/json' }
})

authInstance.defaults.headers = {
    'Cache-Control': 'no-store',
    'Pragma': 'no-cache',
    'Expires': '0',
};

export default authInstance