import React, { useState } from 'react';
import CaseDetailsContext from '../../CaseDetailsContext'

import { StyledKfTextTable } from '../../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../../components/useTable/UseTable'
import usePaginator from '../../../../components/useTable/UsePaginator'
import { FormattedDate, FormattedNumber } from 'react-intl'
import { fixMissingTimeFromDate } from '../../../../util/date/dateRoutines';
import { useSelector } from 'react-redux';

const CaDetMeldingListComponent = (props) => {

    const { localeMessages, sendMelding } = React.useContext(CaseDetailsContext);
    const state = useSelector(state => state.saksdetaljer)

    // Felter
    const f1 = 'messageDate'
    const f2 = 'subject'
    const f3 = 'messageText'
   

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down'); // default
    const [currentField, setCurrentField] = useState(f1);    // feltnavn
    const [currentType, setCurrentType] = useState('datetime'); // num

    let pageSize = 1000
    if (!props.isReport) {
        pageSize = 10
    }

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(pageSize)
    const [activePage, setActivePage] = useState(1);

    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    const gridData = state.data.taskList

    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        gridData.sort(t1GetSortFunc().fn), // <==== fix henting av data
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const getPageHeader = (fld) => localeMessages['notesLst_' + fld]

    const asDate = (val) => {
        // Kludge fordi FireFox ikke godtar dato uten tid uten videre
        let valok = fixMissingTimeFromDate(val)
        return (
            <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
        )
    }

    const asDecimal = (val) => {
        if (val) {

            return (
                <FormattedNumber
                    value={val}
                    style='decimal'
                    minimumFractionDigits={2}
                />
            )
        }
        else {
            return ''
        }
    }

    if (props.isReport && (state.data.taskList.length === 0)) {
        return (
            <></>
        )
    }

    const printSubject = (subject) => {
        if (subject) {
            return (
                <p><b>{subject}</b></p>
            )            
        }
        return null
    }

    const printMeldButton = (row) => {

        if (!props.isReport) {

            if (row.isResponseAvailable) {
                return (
                    <>
                        <br />
                        <br />
                        <div onClick={() => props.showSendMelding(
                            state.data.fetchedData.caseCreditor.customerNo,
                            state.data.fetchedData.caseNo, row.taskId,
                            row.messageText, state.data.fetchedData.caseDebtor.debtorName
                        )}> <span className="fa fa-envelope" />&nbsp;&nbsp;{localeMessages['IC_Besvar']}</div >
                    </>
                )
            }
            else {
                return null
            }
        }
    }


    return (
        <>
            <StyledKfTextTable>                
                <thead>
                    <tr>
                        <th style={{ minWidth: '150px' }} onClick={() => t1ChangeSortField(f1, 'datetime')}>{getPageHeader('f1')}{!props.isReport && t1IndicaterIcon(f1)}</th>                       
                        <th onClick={() => t1ChangeSortField(f3, 'string')}>{getPageHeader('f3')}{!props.isReport && t1IndicaterIcon(f3)}</th>                        
                    </tr>
                </thead>

                <tbody>

                    <Paginate>
                        {row =>
                            <tr key={row.i}>
                                <td>{asDate(row[f1])}</td>
                                <td>{printSubject(row[f2])}{row[f3].trim()}{printMeldButton(row)}</td>
                            </tr>
                        }
                    </Paginate>

                </tbody>

            </StyledKfTextTable>
            <br />
            {(!props.isReport) && <><PaginationNav /><PaginationIndex /></>}
        </>
    )
}

export default CaDetMeldingListComponent
