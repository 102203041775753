import { bankKontoNummer, orgNummer } from '../../util/validering/valideringsRutiner'

const getIsRequired = (errorMessage) => {

    const isRequired = (val, name, formState) => {
        //console.log ('is not blank', val)
        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getValidInteger = (errorMessage) => {
    
    const isValidInteger = (val, name, formState) => {

        if ([...val].every(c => {
            //console.log(c)
            return '0123456789'.includes(c)
        })) {
            return ''
        }

        return errorMessage
    }

    return isValidInteger
}

const getValidPhone = (errorMessage) => {

    //const digit_space_only = string => [...string].every(c => ' 0123456789'.includes(c));

    const isValidphone = (val, name, formState) => {
        //if (digit_space_only(val)) {
        if ([...val].every(c => {
            //console.log(c)
            return ' 0123456789'.includes(c)
        })) {
            return ''
        }

        return errorMessage
    }

    return isValidphone
}

const getMinLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {

        if (val.length >= length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}


const getAbsoluteLenght = (errorMessage, length) => {

    const isLength = (val, name, formState) => {

        if (val.length === length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isLength
}

const getNewPassWordRequired = (errorMessage) => {

    const newPassWordRequired = (val, name, formState) => {

        if ((formState.newUser) && (!val)) {
            return errorMessage
        }

        if ((formState.oldPassword) && (!val)) {
            return errorMessage
        }
        return ''
    }

    return newPassWordRequired
}

const getCheckEqualPasswords = (errorMessage) => {

    const checkequalPasswords = (val, name, formState) => {
        //console.log('Password1', val)
        //console.log('Password2', formState.newPassword1)

        if (val !== formState.passord) {
            return errorMessage
        }
        return ''
    }

    return checkequalPasswords
}

const getValidatePassword = (errorMessage) => {

    const validatePassword = (val, name, formState) => {

        // (?=.*?[#?!@$%^&*-])

        let errors = false;

        // 8 Tegn
        if (val.length < 8) {
            errors = true;
        }

        // minst en bokstav
        if (val.search(/[a-z]/) < 0) {
            errors = true
        }

        // minst en stor bokstav
        if (val.search(/[A-Z]/) < 0) {
            errors = true
        }

        // Minst ett tall
        if (val.search(/[0-9]/) < 0) {
            errors = true
        }

        // Minst ett spesialtegn
        if (val.search(/[#?!@$%^&*-]/) < 0) {
            errors = true
        }

        if (errors) {
            return errorMessage
        }
        return '';
    }

    return validatePassword
}

const getValidateEmail = (errorMessage) => {

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // const re2 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    // https://www.w3resource.com/javascript/form/email-validation.php
    const validateEmail = (val, name, formState) => {
        if (re.test(val)) {
            return ''
        }

        return errorMessage
    }

    return validateEmail
}

const getValidateOrgNr = (errorMessage) => {

    const isValidOrgNr = (val, name, formState) => {
        if (orgNummer(val)) {
            return ''
        }
        return errorMessage
    }
    return isValidOrgNr
}

const getValidateKontoNr = (errorMessage) => {

    const isValidKontoNr = (val, name, formState) => {
        if (bankKontoNummer(val)) {
            return ''
        }
        return errorMessage
    }
    return isValidKontoNr
}


export const onBoardingFormValidators = (messages) => {
    const orgNrIsRequired = getIsRequired(messages['orgNrIsRequired'])
    const isValidOrgNr = getValidateOrgNr(messages['isValidOrgNr'])
    const isValidKontoNr = getValidateKontoNr(messages['isValidKontoNr'])
    const isValidphone = getValidPhone(messages['isValidPhone'])
    const isValidInteger = getValidInteger(messages['isValidInteger'])
    
    const firmanavnIsRequired = getIsRequired(messages['firmanavnIsRequired'])
    const adresseIsRequired = getIsRequired(messages['adresseIsRequired'])
    const postnrIsRequired = getIsRequired(messages['postnrIsRequired'])
    const poststedIsRequired = getIsRequired(messages['poststedIsRequired'])
    const kontonrIsRequired = getIsRequired(messages['kontonrIsRequired'])
    const brukeridIsRequired = getIsRequired(messages['brukeridIsRequired'])
    const IsRequired = getIsRequired(messages['IsRequired'])
    
    const fullNameisRequired = getIsRequired(messages['fullNameisRequired'])
    const checkEqualPasswords = getCheckEqualPasswords(messages['checkEqualPasswords'])
    const passWordRequired = getNewPassWordRequired(messages['passWordRequired'])
    const validatePassword = getValidatePassword(messages['validatePassword'])
    const validateEmail = getValidateEmail(messages['validateEmail'])
    const minLength3 = getMinLenght(messages['minLength3'], 3)
    const minLength5 = getMinLenght(messages['minLength5'], 5)
    const minLength8 = getMinLenght(messages['minLength8'], 8)
    const absoluteLenght = getAbsoluteLenght(messages['minLength11'],11)

    const momsPliktigRequired = getIsRequired(messages['momsPliktigIsRequired'])
    const prodTypeRequired = getIsRequired(messages['prodTypeIsRequired'])
    const foreningRequired = getIsRequired(messages['foreningRequired'])
    const financialSystemRequired = getIsRequired(messages['financialSystemRequired'])

    const formValidators = {
        orgnr: [
            orgNrIsRequired,
            isValidOrgNr
        ],
        firmanavn: [
            firmanavnIsRequired,
            minLength3
        ],
        adresse: [
            //adresseIsRequired,
            //minLength5
        ],
        postnr: [
            postnrIsRequired
        ],
        poststed: [
            poststedIsRequired,
        ],
        firmaepost: [
            validateEmail
        ],
        kontonr: [
            kontonrIsRequired,
            //isValidKontoNr
            isValidInteger,
            absoluteLenght
        ],
        brukernavn: [
            fullNameisRequired,
            minLength5
        ],
        brukerid: [
            brukeridIsRequired,
            validateEmail
        ],
        telefonnr: [
            minLength8,
            isValidphone,
        ],
        passord: [
            passWordRequired,
            validatePassword
        ],
        gjentapassord: [
            checkEqualPasswords,            
        ],
        okonomisystem: [
            financialSystemRequired
        ],
        forening: [
            //foreningRequired
        ],
        momsPliktig: [
            momsPliktigRequired
        ],
        prodType: [
            prodTypeRequired
        ]
        
    }

    return formValidators
}


