import React, { useState, useContext } from 'react';
import { IntlProvider, FormattedDate } from 'react-intl'
import { NavLink } from 'react-router-dom';
import { StyledKfTextTable, StyledNavLink, StyledPropotionalNavLink } from './../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../components/useTable/UseTable'
import usePaginator from '../../../components/useTable/UsePaginator'
import OversiktContext from '../OversiktContext';
import CaseListToolTip from '../../caselist_redux/caseListTable/CaseListTooltip';
import FakturaListe from './FakturaListe';
import LocaleContext from '../../../state/contexts/LocaleContext';
import styled from 'styled-components';
import { fixMissingTimeFromDate } from '../../../util/date/dateRoutines';
import colorConfig from '../../../colorConfig';

const LesButton = styled.button`
    color: black;
    background-color: ${colorConfig.button_bg};
    border-color: #1e6091;
    border-radius: 0.5em;
    border:none;
    font-family: "Fellix", "sans-serif";
    font-size: 18px;
    padding: 2px 10px 2px 10px; 

    :focus {
        border: 1px;
    }

    :hover {
            background-color: ${colorConfig.button_focused};
            color: white;
            -webkit-transition: background-color 1.5s ease-out;
            -moz-transition: background-color 1.5s ease-out;
            -o-transition: background-color 1.5s ease-out;
            transition: background-color 1.5s ease-out;
            transition-delay:0.1s;
        }
`

const SvarButton = styled.button`
    color: black;
    background-color: ${colorConfig.button_bg};
    border-color: #1e6091;
    border-radius: 0.5em;
    border:none;
    font-family: "Fellix", "sans-serif";
    font-size: 18px;
    padding: 2px 10px 2px 10px; 

    :focus {
        border: 1px;
    }

    :hover {
            background-color: ${colorConfig.button_focused};
            color: white;
            -webkit-transition: background-color 1.5s ease-out;
            -moz-transition: background-color 1.5s ease-out;
            -o-transition: background-color 1.5s ease-out;
            transition: background-color 1.5s ease-out;
            transition-delay:0.1s;
        }
`

const MeldingsListe = (props) => {

    const locale = useContext(LocaleContext)

    const { localeMessages, handleLesShow, handleSvarShow } = React.useContext(OversiktContext);

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down'); // default
    const [currentField, setCurrentField] = useState('regDate');    // feltnavn
    const [currentType, setCurrentType] = useState('datetime'); // num

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10)
    const [activePage, setActivePage] = useState(1);


    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    // Point to data 
    const tableData = props.tableData

    // Setupt paginator
    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        tableData.sort(t1GetSortFunc().fn), // <==== sorting inputdata =========
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const printHeader = () => {
        return (
            <thead>
                <tr>
                    <th onClick={() => t1ChangeSortField('regDate', 'datetime')}>{localeMessages['GH_regDate']}{t1IndicaterIcon('regDate')}</th>
                    <th onClick={() => t1ChangeSortField('caseNo', 'string')}>{localeMessages['GH_caseNo']}{t1IndicaterIcon('caseNo')}</th>
                    <th onClick={() => t1ChangeSortField('debtorName', 'string')}>{localeMessages['GH_debtorName']}{t1IndicaterIcon('debtorName')}</th>
                    <th onClick={() => t1ChangeSortField('creditorNo', 'string')}>{localeMessages['GH_creditorNo']}{t1IndicaterIcon('creditorNo')}</th>

                    <th onClick={() => t1ChangeSortField('caseReference', 'string')}>{localeMessages['GH_caseReference']}{t1IndicaterIcon('caseReference')}</th>
                    <th onClick={() => t1ChangeSortField('messageDirection', 'string')}>{localeMessages['GH_messageDirection']}{t1IndicaterIcon('messageDirection')}</th>
                    <th onClick={() => t1ChangeSortField('caseStatus', 'string')}>{localeMessages['GH_caseStatus']}{t1IndicaterIcon('caseStatus')}</th>

                    <th></th>
                    <th></th>
                </tr>
            </thead>
        )
    }

    const PrintRow = (model) => {

        const [expanded, setExpanded] = useState(false)
        const toggleExpand = () => setExpanded(!expanded)

        let caseDetUri = '/CaseDetails/' + model.creditorNo + '/' + model.caseNo

        const asDate = (val) => {

            if (!val)
                return (<></>)

            // Kludge fordi FireFox ikke godtar dato uten tid uten videre
            let valok = fixMissingTimeFromDate(val)
            //let valok = val
            return (
                <>
                    <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
                </>
            )
        }

        //console.log(model)
        const NavLinkStyle = {
            backgroundColor: 'unset',
            fontWeight: "300",
            color: '#007BFF',            
        }

        const invoiceButton = () => {
            if (props.expand) {
                return (
                    <>
                        <span onClick={toggleExpand}>
                            {!expanded ? <span className="fa fa-search-plus" /> : <span className="fa fa-search-minus" />}
                        </span>&nbsp;&nbsp;
                    </>
                )
            }
        }

    
        return (
            <>
                <tr key={model.i}>

                    <td>
                       {invoiceButton()}
                       {asDate(model.regDate)} 
                    </td>
                    <td>                
                        <StyledPropotionalNavLink to={caseDetUri}>
                            {model.caseNo}
                        </StyledPropotionalNavLink>
                    </td>
                    <td>{model.debtorName}</td>
                    <td>{model.creditorNo}</td>

                    <td>{model.caseReference}</td>
                    <td>{model.messageDirection}</td>
                    <td>{model.caseStatus}</td>

                    <td>
                        {model.lastCaseNote &&
                            <CaseListToolTip id={'ctn' + model.i} caption={model.lastCaseNote}>
                                <span className="fa fa-commenting" />
                            </CaseListToolTip>
                        }
                        {!model.lastCaseNote &&                        
                            <span className="fa fa-commenting" style={{ color: "#a4a4a4"}}/>
                        }
                    </td>
                    <td>
                        {model.isHistorical &&
                            <>
                            <LesButton onClick={() => handleLesShow(model)}>
                                Les
                            </LesButton>
                            </>
                        }
                        {!model.isHistorical && model.isReplyable &&
                            <>
                            <SvarButton onClick={() => handleSvarShow(model)}>
                                Svar
                            </SvarButton>
                            </>
                        }

                        {!model.isHistorical && model.isInformation &&
                            <>
                            <LesButton onClick={() => handleLesShow(model)}>
                                Les
                            </LesButton>
                            </>
                        }
                                                                   
                        &nbsp;
                        
                        
                    </td>
                </tr>

                {expanded &&
                    <>
                    <tr key={model.i + 1000} style={{ backgroundColor: 'white', hover: { opacity: 1 }}} >
                        <td></td>
                        <td colspan="8">
                            <FakturaListe tableData={model.invoiceList} />
                        </td>
                    </tr>
                    <tr style={{ backgroundColor: 'white' }}>
                        <td colspan="9"></td>
                    </tr>
                    </>
                    
                }
            </>

        )
    }

    return (
        <IntlProvider locale={locale} messages={localeMessages}>

            {props.tableData.length !== 0 && <PaginationDropdown />}
            <StyledKfTextTable>
                {printHeader()}

                <tbody>
                    <Paginate>
                        {/* paginate forventer en funksjon */}
                        {row => PrintRow(row)}
                    </Paginate>
                </tbody>
            </StyledKfTextTable>
            {props.tableData.length !== 0 &&
                <>
                    <br />
                    <PaginationIndex />
                    <br /><br />
                    <PaginationNav />
                </>
            }
        
        </IntlProvider>        
    )
}

export default MeldingsListe
