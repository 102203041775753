import React from 'react';
import {ArticleArea} from '../../Styling/Layout/ContentArea'

const MFAInformation = (props) => {

    return (
        <div class="alert alert-warning" role="alert">
            <h1>Kjære kunde,</h1>
            <p>
                For å styrke sikkerheten til våre systemer og dermed dine kundedata har vi nå valgt å gå over til tofaktorautentisering.
                Vi oppfordrer alle våre kunder til å aktivere tofaktorautentisering umiddelbart.                                
            </p>            
       
            <h2>
                Slik gjør du det:
            </h2>

            <ol>
                <li>Sjekk at du har registrert riktig mobilnr på din bruker.</li>
                <li>Klikk på <i>Registrer tofaktor pålogging</i> nederst på profilen din (lengre ned på siden)</li>
                <li>Ha mobiltelefonen klar.</li>
                <li>Motta og registrer verifiseringskode.</li>
            </ol>

            <h2>
                Trenger du hjelp?
            </h2>
            <p><a href="https://nettkontor.kred.no/kundeportal/Brukerdokumentasjon%20To-faktor%20p%C3%A5logging.pdf" target="_blank">Her er en detaljert brukerveiledning.</a></p>
            <p>Trenger du fortsatt hjelp? Vennligst kontakt oss på <a href="mailto:kundepost@kred.no">kundepost@kred.no</a>
            <br/>Takk for at du aktiverer tofaktorautentisering og bidrar til en tryggere digital opplevelse i Kundeportalen!</p>

            <h2>
                Oppdater din profil
            </h2>
            <p>
                Sjekk at e-postadresse og telefonnummer stemmer med de du bruker i dag. Du kan også endre passordet ditt her.
            </p>

        </div>
    )
}

export default MFAInformation