import React from "react";
import { Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,

} from "../../../Styling/styledModal/styledModals";


const BekreftSlettRapport = (props) => {

    const messages = props.messages    

    return (

        <StyledModal centered show={true} onHide={props.cancelHandler}>

            <StyledModalBody>
                <h4>{messages.BE_Header}</h4>
                <p style={{whiteSpace: "pre-line"}}>{messages.BE_message}</p>
            </StyledModalBody>
           
            <StyledModalFooter>
                <Button variant="secondary" onClick={() => props.okHandler(props.okHandler)}>
                    {messages.BE_Ja}
                </Button>
                <Button variant="secondary" onClick={props.cancelHandler}>
                    {messages.BE_Nei}
                </Button>
            </StyledModalFooter>

        </StyledModal>
    );
};

export default BekreftSlettRapport;