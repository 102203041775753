import React, { useEffect } from 'react';
//import { IntlProvider, FormattedMessage } from 'react-intl'
import LocaleContext from '../../state/contexts/LocaleContext'
import CONFIG from '../../config';
import { useAuth } from '../../hooks/useAuth';
//import { messages } from './wsuserlogin.i18n'

/*

Logikk:
1. Kosapi produserer en url med token på formen 
   ../wsuserlogin/../../<token goes here>
   Dette endepunktet må ikke kreve at man er pålogget
2. Man gjør et oppslag mot backend der tokenet sendes inn
3. Dersom backend godtar tokenet, logges brukeren i tokenet inn
   Brukeren må være en Webservice bruker, andre godtas ikke
4. Man gjennomfører login i react applikasjonen
5. Man kan fortsette som vanlig

Når dette går i prod, kan vanlig login avvise alle ws brukere

*/

let gBaseUrl = window.location.protocol + '//' + window.location.hostname + ":" + window.location.port + '/'
if (process.env.NODE_ENV === 'development') {
    //gBaseUrl = 'http://localhost:5000/'
    gBaseUrl = CONFIG.DEBUG_SERVER
}


const WsUserLoginContainer = (props) => {

    const locale = React.useContext(LocaleContext);

    const auth = useAuth();

    useEffect(() => {

        console.log('wsuserlogin', props.token)
        
        // Logg av gammel bruker dersom du er pålogget
        auth.logOut()  
        
        let startPage = `/CaseDetails/${props.crNo}/${props.caseId}`

        // Kjør en fetch med eksisterende token mot WsUserAuth
        // Dersom du får token fra denne, kan du kjøre auth.TokenSignIn med 
        // Tokenet du fikk tilbake

        fetch(`${gBaseUrl}api/ApiSecurity/WsUserAuth`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',
                Authorization: `Bearer ${props.token}`
            }
        })
        .then(resp => resp.json())
        .then(
            json => {
                console.log(JSON.stringify(json))
                
                let token = json.token

                if (token) {
                    console.log(token);                    
                    auth.tokenSignIn(token, startPage)
                }
            })
        .catch(e => {
            console.log(e)
        })

    }, [props.token, props.crNo, props.caseId])

    return (
        <>
            <h1>Webservicebruker pålogging avvist.</h1>
        </>
    )
}

export default WsUserLoginContainer
