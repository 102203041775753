import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';
import { mapCaseDetailsToWeb } from './caseMapper';

/*
┌──────────────────────────────────────────────────────────────────────────────┐
│ To use:                                                                      │
│    import { useSelector, useDispatch } from 'react-redux'                    │
│    import { ..exported dispatchers... } from '../../reduxStore/...';         │
│                                                                              │
│    // Angi ønsket redux store du vil hente state fra                         │
│    const state = useSelector(state => state.oversikt)                        │
│                                                                              │
│    // Få tak i redux dispatheren som du evt kan gi eget navn                 │
│    const _dispatch = useDispatch()                                           │
│     ..                                                                       │
│                                                                              │
│    // Kjør kall for å endre state                                            │
│    _dispatch(setSomeShit(shitparam1))                                        │
└──────────────────────────────────────────────────────────────────────────────┘
*/

export const actions = {
    SAKSDETALJER_LOADING: 'SAKSDETALJER_LOADING',
    SAKSDETALJER_ERROR: 'SAKSDETALJER_ERROR',
    SAKSDETALJER_DATA_FETCHED: 'SAKSDETALJER_DATA_FETCHED',
}



const getErrorMessage = (err) => {
    if (_.has(err, 'response.data')) {
        return err.response.data
    }
    else if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else {
        return err.message.toString()
    }
}

const getCaseForWeb = (data, caseNo, creditorNo) => {
    let caseDetail = data.caseDetail
    let webcase = mapCaseDetailsToWeb(caseDetail, caseNo, creditorNo)
    return webcase
}

/*
 ┌───────────────────────────────────────────────────────────────────────────────────────────────────┐
 │  Kalles fra saksheader når man ikke har valgt kreditor, og fra saksdetaljer useeffect             │
 │  Dersom onsuccess er satt skal man skippe feil (silent), fordi man da bare sjekker om saken fins  │
 │  Dvs du har da kalt den fra sakslisteheader                                                       │
 └───────────────────────────────────────────────────────────────────────────────────────────────────┘
 */
export const dispatchGetCaseDetails = (creditorNo, caseNo, showToasts, messages, onSuccess) => {

    return dispatch => {

        dispatch({ type: actions.SAKSDETALJER_LOADING, data: null })

        let outModel = {
        }

        //console.log("dispatchGetCaseDetailsFunc: in:", creditorNo, caseNo)

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/sak/GetCaseDetails/${creditorNo}/${caseNo}`,
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastGetCaseDetailsFailed'], {autoClose: false}))
                    dispatch({ type: actions.SAKSDETALJER_ERROR, data: messages['toastGetCaseDetailsFailed'] })
                } else {

                    let data = JSON.parse(result.data)
                    //onsole.log(data)
                    if (data.errorCode === "000") {
                        (showToasts && toast.info(messages['toastGetCaseDetailsOk']))

                        // Map om                   
                        dispatch({ type: actions.SAKSDETALJER_DATA_FETCHED, payload: getCaseForWeb(data, caseNo, creditorNo) })
                        if (onSuccess) {
                            toast.warning(messages['toastGetCaseDetailsOk'] + `${caseNo}`)
                            onSuccess()
                        }
                    } else {
                        let errorMessage = messages['toastGetCaseDetailsFailed'] + ', ' + result.data.errorMessage
                        dispatch({ type: actions.SAKSDETALJER_ERROR, data: errorMessage })
                        (showToasts && toast.warning(errorMessage))
                    }
                
                }
            })
            .catch((err) => {
                let errormessage = messages['toastGetCaseDetailsFailed'] + ' ' + getErrorMessage(err)
                if (!onSuccess) {
                    toast.error(errormessage, {autoClose: false})
                    dispatch({ type: actions.SAKSDETALJER_ERROR, data: errormessage })
                }
            })

    }
}

/*
┌──────────────────────────────────────────────────────────────────────────────┐
│ Kalles fra saksheader når man ikke har valgt kreditor                        │
│ Dersom onsuccess er satt skal man skippe feil (silent), fordi man da bare    │
│ sjekker om saken finnes                                                      │                                                                       │
└──────────────────────────────────────────────────────────────────────────────┘
*/
export const dispatchGetCaseDetailsAllKreditors = (creditorList, caseNo, showToasts, messages, onSuccess) => {

    return dispatch => {

        dispatch({ type: actions.SAKSDETALJER_LOADING, data: null })

        const formData = new FormData();        
        formData.append('CaseNo', caseNo.toString())
                    
        console.log("dispatchGetCaseDetailsAllKreditors: in:", caseNo)

        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/sak/checkCaseNo/`,
                data: formData
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastGetCaseDetailsFailed'], {autoClose: false}))
                    dispatch({ type: actions.SAKSDETALJER_ERROR, data: messages['toastGetCaseDetailsFailed'] })
                } else {

                    let data = JSON.parse(result.data)
                    //console.log('dispatchGetCaseDetailsAllKreditors', data)
                    if (data.errorCode === "000") {
                        (showToasts && toast.warning(messages['toastGetCaseDetailsOk']))

                        // Map om                   
                        dispatch({ type: actions.SAKSDETALJER_DATA_FETCHED, payload: getCaseForWeb(data, caseNo, data.creditorNo) })
                        if (onSuccess) {
                            toast.warning(messages['toastGetCaseDetailsOk'] + `${caseNo}`)
                            onSuccess(data.creditorNo)
                        }
                    } else {
                        let errorMessage = messages['toastGetCaseDetailsFailed'] + ', ' + result.data.errorMessage
                        dispatch({ type: actions.SAKSDETALJER_ERROR, data: errorMessage })
                        (showToasts && toast.warning(errorMessage))
                    }
                
                }
            })
            .catch((err) => {
                let errormessage = messages['toastGetCaseDetailsFailed'] + ' ' + getErrorMessage(err)
                if (!onSuccess) {
                    toast.error(errormessage, {autoClose: false})
                    dispatch({ type: actions.SAKSDETALJER_ERROR, data: errormessage })
                }
            })

    }
}