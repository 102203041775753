import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

export const mruActions = {
    LOADING: 'LOADING',
    ERROR: 'ERROR',
    DATA_FETCHED: 'DATA_FETCHED',
}

export const fetchMruBegin = () => ({
    type: mruActions.LOADING
});

export const fetchMruSuccess = data => ({
    type: mruActions.DATA_FETCHED,
    payload: { data: data }
});

export const fetchMruFailure = error => ({
    type: mruActions.ERROR,
    payload: { error }
});

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}

export const dispatchGetCasesMRUList = (showToasts, messages) => {

    return dispatch => {

        const errorMessage = messages['toastGetFilesFailed']
        const okMessage = messages['toastGetFilesOk']

        //dispatch({ type: actions.LOADING, data: null })
        dispatch(fetchMruBegin())

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/sak/GetMRU`,
            })
            .then((result) => {

                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage, {autoClose: false}))
                    //dispatch({ type: actions.ERROR, data: errorMessage })
                    dispatch(fetchMruFailure(errorMessage))
                } else {

                    (showToasts && toast.info(okMessage))
                    //console.log(result.data)

                    //dispatch({ type: actions.DATA_FETCHED, data: result.data })
                    dispatch(fetchMruSuccess(result.data))
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                //dispatch({ type: actions.ERROR, data: lErrMess })
                dispatch(fetchMruFailure(lErrMess))
            })

    }    
}