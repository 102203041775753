import React from 'react'
import { Container, Col, Alert } from "react-bootstrap";
import LocaleContext from '../../state/contexts/LocaleContext'

import { BlueRow } from '../../Styling/styledForm/styledBlueForm';

import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';
import { useFakturaAdminContainer } from './useFakturaAdminContainer';
import FakturaAdminTabell from './components/FakturaGridComponent';
import FakturaAdminTotals from './components/FakturaAdminTotals';
import BekreftInnsending from './modals/bekreftInnsending/BekreftInnsending';
import { FakturaMonthSelector } from './components/FakturaMonthsSelector';


const FakturaAdminContainer = (props) => {

    const locale = React.useContext(LocaleContext);
    
    const { localeMessages, selectedKreditor, toggleRow, toggleAllRows, state, headerState, getNumDisabled, getSumDisabled,
        bsFuncs, invMonths, setInvMonths, fakturaMonthSelectorValues }
        = useFakturaAdminContainer(locale)

    //const bsFuncs = useBekreftInnsending(setErrorMessage, setMessage, setShowSpinner)
    
    const getModalWindows = () => {
        return (
            <>
                {bsFuncs.dialogVisible && <BekreftInnsending bsFuncs={bsFuncs} />}
            </>
        )
    }

    const getInvoices = () => {
        if (!state.filterString) {
            return state.invoiceList
        }
        else {
            return state.invoiceList.filter((rec) => rec.searchIndex.includes(state.filterString));
        }
    }

    const getInnsendtMessage = () => {

        let sumDisabled = getSumDisabled()
                   
        if (getNumDisabled()) {
            let message = `Du har ${getNumDisabled()} innsendte fakturaer på totalt     
            ${(Math.round(sumDisabled * 100) / 100).toFixed(2)} på denne kreditoren.
                Disse vil bli behandlet i sine respektive datasystemer, og vil da forsvinne fra denne listen når den oppfriskes.`
            return (
                <Alert style={{marginLeft: "-12px", marginRight: "-12px"}} fluid variant='success'>{message}</Alert>   
                )
        }
        
        return ''
    }

    if (state.loading) {

        return (
            <>
                <h2>{localeMessages['faSlow']}</h2>
                <OverlaySpinner />
            </>
        )

    } else if (state.error) {

        return (<Alert variant='danger'>{state.error}</Alert>)

    } else if (!selectedKreditor) {

        return (<Alert variant='danger'>{localeMessages['VelgKreditor']}</Alert>)

    } else if (selectedKreditor && !state.hasFakturaAdmin) {

        return (<Alert variant='danger'>{localeMessages['IkkeFakturaAdm']}</Alert>)

    } else if (selectedKreditor && state.invoiceList.length === 0) {

        return (
            <>
                {getInnsendtMessage()}
                <Alert variant='info'>{localeMessages['ingenFakturaer']} integrasjon: {state.kisIntegration}.</Alert>
                {localeMessages['faIngenFaktRet']}
                <FakturaMonthSelector 
                        invMonths={invMonths}
                        setInvMonths={setInvMonths}
                        selectorValues={fakturaMonthSelectorValues}
                        messages={localeMessages}
                />
                <p>{localeMessages['faIncInterval']}</p>
            </>
        )

    } else {

        return (
            <>
                {getModalWindows()}

                <Container fluid="true">
                    <FakturaAdminTotals
                        messages={localeMessages}
                        state={state}
                    />
                </Container>

                {getInnsendtMessage()}

                <Container fluid="true">
                    <BlueRow>
                        <Col xs="12">
                            <FakturaAdminTabell
                                localeMessages={localeMessages}
                                //tableData={state.invoiceList}
                                tableData={state.filteredList}
                                state={state}
                                headerState={headerState}
                                selectedKreditor={selectedKreditor}
                                toggleRow={toggleRow}
                                toggleAllRows={toggleAllRows}
                                bsFuncs={bsFuncs}
                                invMonths={invMonths}
                                setInvMonths={setInvMonths}
                                selectorValues={fakturaMonthSelectorValues}
                            />
                                   
                        </Col>
                    </BlueRow>
                </Container>

            </>
        )
    }
}

export default FakturaAdminContainer