import React from 'react';
//import {ReactComponent as ReactLogo} from '../../assets/KfLogo.svg';
//import ReactLogo from '../../assets/kundeportal_2021.gif';
// <img src={ReactLogo} alt='logo' height='48px' />
import ReactLogo from '../../assets/Kreditorforeningen logo.png'

const SvgLogo = () => {
    return (
        <img style={{ marginTop: "-12px", height: "80px"}} src={ReactLogo} alt='logo' height='150px' />
    )
}

export default SvgLogo


/*

import logo from '../assets/loader.gif';

<img src={logo} alt="loading..." />

const SvgLogo = () => {
    return (    
        <ReactLogo />
      )
  }
  


*/