import React,{ useState,  useEffect } from "react";
import LocaleContext from "../../../state/contexts/LocaleContext";
import { messages } from "./LoginForm.i18n";
import { ArticleArea } from "../../../Styling/Layout/ContentArea";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import styled from "styled-components"
import { PasswordWithReveal } from "../../../components/passwordWithReveal/PasswordWithReveal";
import colorConfig from "../../../colorConfig";
import { BfFormLabel, BfYellowRowForm, BfFormControl, YellowRoundedContainer, BfFormLabelSm, BfFormErrorSm, BfTightYellowRowForm, BfButtonBlue } from "../../../Styling/styledForm/styledBlueForm";
import { LoginMfaModal } from "./LoginMfaModal";

// Fordi Jumbotron ikke virket etter oppgradering
const JamboTron = styled.div`
    padding: 40px;
    margin-bottom: 2rem;
    background-color: ${colorConfig.login_jumbo}; 
    border-radius: 1em;
`

const LoginForm = (props) => {
  const locale = React.useContext(LocaleContext);
  const { Header1, Paragraph1, EmailCap, EmailErr, PassCap, PassErr, 
    EmailAsCap, EmailAsErr,
    SubButCap, mfaCodeCap, LoginFailedErr, FormNotValidErr } = messages[locale];

  const handleChange = props.handleChange
  const submitHandler = props.submitHandler
  const hasError = props.hasError
  const authError = props.authError  
  
  const [loginAsUser, setLoginAsUser] = useState(false)
  useEffect(() => {
    setLoginAsUser (props.loginPath.toUpperCase() === "/LOGIN/SOM")
  }, [props.loginPath])


  const [showMfaModal, setShowMfaModal] = useState(false)
  useEffect(() => {
    if (props.mfa)
    {
      setShowMfaModal(true)
    }
  }, [props.mfa])


  //console.log("props.mfa", props.mfa)

  return (
    <>

      <Container style={{ paddingTop: '15px', width: '800px' }}>

        <ArticleArea style={{ padding: '15px', maxWidth: '800px' }}>
          <h1>{Header1}</h1>
          <p>{Paragraph1}</p>
        </ArticleArea>

        <Form autocomplete="chrome-off" onSubmit={submitHandler}>

          <YellowRoundedContainer style={{ padding: '15px', width: '800px' }}>

            {(hasError("email") || hasError("password") || (authError)) && (
              <Alert variant="danger">
                {(authError) ? <span>Kunne ikke logge på med brukerid og passord oppgitt. </span> : ""}
                {FormNotValidErr}
              </Alert>
            )}

            {(showMfaModal) && <LoginMfaModal
              messages={messages[locale]}
              mfaCodeCap={mfaCodeCap}
              handleChange={handleChange}
              fieldValues={props.fieldValues}
              submit={submitHandler}
              cancel={() => {
                props.setFieldValue({ ...props.fieldValues, ['mfaCode']: '' });
                setShowMfaModal(false)
              }}
            />}


            <BfYellowRowForm>
              <Col xs={12} md={12}>
                <Form.Group controlId="formHorizontalEmail">
                  <BfFormLabel>{EmailCap}</BfFormLabel>

                  <BfFormControl
                    className="col-md2"
                    type="email"
                    placeholder=""
                    name="email"
                    value={props.fieldValues['email']}
                    onChange={(event) => handleChange(event)}
                  />

                  {hasError("email") && <BfFormErrorSm>{EmailErr}</BfFormErrorSm>}
                </Form.Group>

              </Col>
            </BfYellowRowForm>

            <BfTightYellowRowForm />


            <BfYellowRowForm>
              <Col xs={12} md={12}>
                <Form.Group controlId="formHorizontalPassword">
                  <BfFormLabel>{PassCap}</BfFormLabel>

                  <PasswordWithReveal
                    type="password"
                    name="password"
                    placeholder=""
                    handleChange={props.handleChange}
                  />
                  {hasError("password") && <BfFormErrorSm>{PassErr}</BfFormErrorSm>}
                </Form.Group>

              </Col>
            </BfYellowRowForm>

            <BfTightYellowRowForm />

            {loginAsUser && <BfYellowRowForm>
              <Col xs={12} md={12}>
                <Form.Group controlId="emailas">
                  <BfFormLabel>{EmailAsCap}</BfFormLabel>

                  <BfFormControl
                    className="col-md2"
                    type="email"
                    placeholder=""
                    name="emailas"
                    value={props.fieldValues['emailas']}
                    onChange={(event) => handleChange(event)}
                  />

                  {hasError("emailas") && <BfFormErrorSm>{EmailAsErr}</BfFormErrorSm>}
                </Form.Group>

              </Col>
            </BfYellowRowForm>}

            

            
            {/*
            {(props.mfa) &&
              <BfYellowRowForm>
                <Col xs={12} md={12}>
                  <Form.Group controlId="formHorizontalMfaCode">
                    <BfFormLabel>{mfaCodeCap}</BfFormLabel>

                    <BfFormControl
                      className="col-md2"
                      type="mfaCode"
                      placeholder=""
                      name="mfaCode"
                      value={props.fieldValues['mfaCode']}
                      onChange={(event) => handleChange(event)}
                    />

                  </Form.Group>
                </Col>
              </BfYellowRowForm>
            }
            */}

            <BfTightYellowRowForm />

            <BfTightYellowRowForm />

            <BfTightYellowRowForm>
              <Form.Group as={Row}>

                <Col>
                  <BfButtonBlue type="submit"> {SubButCap}</BfButtonBlue>&nbsp;
                  <BfButtonBlue onClick={props.SubmitGlemtPassord}> Glemt passord</BfButtonBlue>
                </Col>

              </Form.Group>

            </BfTightYellowRowForm>

            <BfTightYellowRowForm />

          </YellowRoundedContainer>

        </Form>
      </Container>

    </>
  );
};

export default LoginForm;
