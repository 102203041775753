import React from "react";
import { Form, Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
} from "../../../Styling/styledModal/styledModals";
import { BfFormControl, BfFormLabel } from "../../../Styling/styledForm/styledBlueForm";
import axiosTokenInstance from "../../../Instances/axiosTokenInstance";


export const LoginMfaModal = (props) => {

    const messages = props.messages
    const mfaCodeCap = props.mfaCodeCap
    const fieldValues = props.fieldValues
    const handleChange = props.handleChange
    const cancel = props.cancel
    const submit = props.submit

    const sendSms = () => {

        let formData = new FormData()
        formData.append('email', props.fieldValues.email); 


        axiosTokenInstance({
            method: 'POST',
            url: `/api/apisecurity/SendSMSCode`,
            data: formData
        }).then((result) => {
            console.log('token sendt...')
            console.log(result);
        })
            .catch((err) => {
                console.log(err);
                //setIsLoading(false)
                //toast.error('Registrering av tofaktor pålogging feilet.')                                  
            })

    }

    const submitDisabled = () => {
        console.log(props.fieldValues['mfaCode'])
        return (props.fieldValues['mfaCode']?.length != 6)
    }

    return (

        <StyledModal centered show={true} size="m" onHide={cancel}>

            <StyledModalBody>
                <h4>{messages.mfaModalHeader}</h4>
                <p style={{ whiteSpace: "pre-line" }}>{messages.confirmMessage}</p>
                <p style={{ whiteSpace: "pre-line" }}>{messages.confirmMessage2}</p>

                <Form.Group>

                    <Form.Row>
                        <BfFormLabel>{mfaCodeCap}</BfFormLabel>

                        <BfFormControl
                            className="col-md2"
                            type="mfaCode"
                            placeholder=""
                            name="mfaCode"
                            value={fieldValues['mfaCode']}
                            onChange={(event) => handleChange(event)}
                        />
                    </Form.Row>

                </Form.Group>
            </StyledModalBody>

            <StyledModalFooter>
                <Button
                    variant="secondary"
                    onClick={submit}
                    disabled={submitDisabled()}
                >
                    {messages.mfaOk}
                </Button>

                <Button variant="secondary" onClick={sendSms}>
                    {messages.mfaSendSMS}
                </Button>

                <Button variant="secondary" onClick={cancel}>
                    {messages.mfaCancel}
                </Button>

            </StyledModalFooter>

        </StyledModal>
    );
};
