import React, { useEffect, useContext } from 'react';
import { useAuth } from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import { Spinner } from '../../components/spinner/Spinner';
import GlobalStateContext, {} from '../../state/contexts/GlobalStateContext';
import { globalStateActions } from '../../state/reducers/GlobalStateReducer';
import { SpinnerDnnLoginRedirect } from '../dnnSite/dnnFunctions';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';

/*
  Komponent du kommer til fra en link via integrasjon, da må du sette en egen 
  property som indikerer at du ikke er pålogget gammelt nettkontor, og når du
  går videre via en menylink, sjekkes det, og logger deg på.  Dvs at da kommer 
  du ikke til riktig menypunkt, men default bildet, istedenfor login.
*/
export const TokenLoginCaseDet = (props) => {

    const auth = useAuth();
    
    useEffect(() => {
        console.log('Tokenlogin, token in useeffect: ', props.token)
      
        let startPage = `/CaseDetails/${props.crNo}/${props.caseId}`

        if (props.token) {
            auth.tokenSignIn(props.token, startPage)            
        } else {
            console.log('Tokenlogin, BUT NO TOKEN?!')
        }
    }, [props.token])

 
    return (
        <>
            <OverlaySpinner />
            <p>
                Validerer pålogging med direktelogin til kunderportalen med direkte oppslag på sak..
            </p>
        </>
    )
}

//  http://localhost:3000/#/tokenLoginCaseDet/776482/3S5K71/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImdhbUBrcmVkLm5vIiwianRpIjoiMmZmMDE5NWYtMjQ0OS00NWJmLWEyMGItMWM1Yjc0OTNhMzllIiwiRnVsbG5hbWUiOiJBZG1pbiBWZXN0IiwiQ29uY2VudFVzZXJOYW1lIjoiIiwiRXh0cmFJbmZvMSI6IkFkbWluIFZlc3QiLCJFeHRyYUluZm8yIjoiIiwiUm9sbGUiOiJVc2VyQWRtaW4iLCJGb3JlbmluZ3NJZCI6IjMwIiwiRm9yZW5pbmdzTmF2biI6IktyZWRpdG9yRm9yZW5pbmdlbiBWZXN0IiwiQmlzTm9kZUVuYWJsZWQiOiJmYWxzZSIsIkJpc05vZGVVc2VyIjoiIiwiQmlzTm9kZVBhc3N3b3JkIjoiIiwiZXhwIjoxNjE2MTA2Nzk2LCJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdCJ9.JxwSSg5PaGEWGlsLcQmp5PtHA7WS-rlbuh6MslC-eZw