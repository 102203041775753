export const messages = {
    nb: {
             
        US1M_Overskrift: `Utsett sak`,
        US1M_alt1: `Utsett i 7 dager`,
        US1M_alt2: `Utsett i 14 dager`,
        US1M_alt3: `Utsett i 1 måned`,
        US1M_melding: 'Årsak til utsettelse (må fylles ut)',
        US1M_Ok: `Utsett`,
        US1M_Avbryt: `Avbryt`,  

        toastUtsettSakOk: 'Utsetting av sak er registrert OK.',
        toastUtsettSakFailed: 'Utsetting av sak feilet.',    
       
    }
    
} 