export const messages = {
    nb: {
        header: `Profilinformasjon`,
        beskrivelse: `Dette er informasjon vi lagrer om brukeren. Du kan endre feltene, men epostadressen må være unik for systemet.  Dvs du kan ikke ha registrert samme epostadressen på en annen kreditor.
        Dersom du ikke vil endre passordet, lar du passordfeltene være tomme.`,
        Lagre: `Oppdater profilen`,

        EpostLabel: `Epostadresse`,
        EpostPlaceholder: `Oppgi gyldig epostadresse`,
        EpostSmall: `Merk: Epostadressen må være unik for systemet, den bruker du til pålogging.`,

        NavnLabel: `Fullt navn`,
        NavnPlaceholder: `Oppgi navn`,
        NavnSmall: `Oppgi fornavn og etternavn.`,

        PhoneNumberLabel: `Mobilnummer (8 siffer)`,
        PhoneNumberPlaceholder: `Oppgi gyldig mobilnummer`,
        PhoneNumberSmall: `Mobilnummer for SMS varsling og tofaktor pålogging.`,
        GyldigMobil: ' Mobilnummer må være på formen 99922333, 4799922333 eller +4799922333.',

        PassordLabel: `Gammelt passord`,
        PassordPlaceholder: `Oppgi gammelt passord`,
        PassordSmall: `Dersom du ikke vil bytte passord, lar du dette feltet stå tomt.`,

        Passord1Label: `Passord`,
        Passord1Placeholder: `Oppgi passord`,
        Passord1Small: `Oppgi passordet her.`,

        Passord2Label: `Gjenta passord`,
        Passord2Placeholder: `Gjenta passord`,
        Passord2Small: `Gjenta passordet her.`,

        RolleLabel: `Tilgangsnivå`,
        RollePlaceholder: `Velg rolle`,
        RolleSmall: `Velg rolle for å angi tilgansnivå.`,

        ForeningLabel: `Forening`,
        ForeningPlaceholder: `Velg forening`,
        ForeningSmall: `Velg forening (gjelder ikke Administrator).`,

        KreditorListeLabel: `Kreditorer`,
        //KreditorListePlaceholder: `Velg kreditorer`,
        KreditorListeSmall: `Dobbelklikk på en, eller velg flere og klikk på knapp.`,

        ValgteKreditorerLabel: `Valgte kreditorer`,
        //ValgteKreditorerPlaceholder: `Velg kredoiv inn ValgteKreditorer`,
        ValgteKreditorerSmall: `For å fjerne, velg kreditor og klikk på knappen.`,

        disabledLabel: 'Deaktiver bruker',
        disabledSmall: 'Stenger tilgang for brukeren',

        bisnodeBrukerLabel: `Bisnode BrukerId`,
        bisnodeBrukerPlaceholder: `Skriv inn Bisnode Brukerid`,
        bisnodeBrukerSmall: `Skriv inn brukerid, (epost??).`,

        bisnodePassordLabel: `Bisnode Passord`,
        bisnodePassordPlaceholder: `Skriv inn Bisnode Passord`,
        bisnodePassordSmall: `Oppgi passord (regler?).`,

        bisnodeEnabledLabel: `Kredittopplysning`,
        bisnodeEnabledPlaceholder: `Skriv inn BisnodeEnabled`,
        bisnodeEnabledSmall: `Gir tilgang til Bisnode.`,

        webservicebrukerLabel: `Webservicebruker`,
        webservicebrukerPlaceholder: ``,
        webservicebrukerSmall: `Kun Weservice pålogging.`,

        twoFactorEnabledHeaderLabel: 'Tofaktor pålogging (sms/app)',
        twoFactorEnabledLabel: 'Tofaktor er aktivert',
        twoFactorEnabledSmall: 'Fjern krysset for å deaktivere',

        leggtil: "=>",
        trekkfra: "<=",

        Kopier: 'Kopier bruker',
        Tilbake: 'Tilbake',

        VelgRolle: 'Velg rolle for tilgang',
        Systemadministrator: 'Systemadministrator',
        Foreningsadministrator: 'Foreningsadministrator',
        Konsernbruker: 'Konsernbruker',
        Bruker: 'Bruker',

        fullNameisRequired: 'Feltet er påkrevd. ',
        checkEqualPasswords: 'Passordene er ulike. ',
        newPassWordRequired : "Feltet er påkrevd. ",
        validatePassword : "Passord må ha minst 8 tegn, både store og små bokstaver, tall og spesialtegn. ",
        validateEmail : "Epostadressen er ugyldig. ",
        minLength5 : 'Minimum er 5 bokstaver. ',
        bisNodeNameIsRequired : "Feltet er påkrevd. ",
        bisNodePassIsRequired : "Feltet er påkrevd. ",
        rolleIsRequired : "Feltet er påkrevd. ",
        foreningIsRequired: "Feltet er påkrevd. ",
        kreditorsIsRequired: "Du må velge kreditorer. ",
        kreditorsToMany: "Det er kun tillatt med en kreditor. ",

        BE_Header: 'Bekreft sletting av bruker',
        BE_message: 'Trykk "Ja" for å slette bruker. Bruker vil bli permanent slettet, og det kan ikke angres.',
        BE_Ja: 'Ja',
        BE_Nei: 'Avbryt',
        But_Slett: 'Slett',
        Slett: 'Slett'

    }
} 