import {
    FETCH_PROFIL_BEGIN,
    FETCH_PROFIL_FAILURE,
    FETCH_PROFIL_SUCCESS
} from "./profilActions"

const initialState = {
    profile: null,
    fetched: false,
    loading: false,
    error: null
}

const ProfilReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_PROFIL_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        
        case FETCH_PROFIL_SUCCESS:
            
            return {
                ...state,
                loading: false,
                fetched: true,
                profile: action.payload.profileData
            }
        
        case FETCH_PROFIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                items: []
            };
            
        default:
            return state
    }
}

export default ProfilReducer