import React, { useEffect, useState, useRef } from "react"
import { Form, Row, Col } from "react-bootstrap"
import { messages } from './Header2SakslisteSelectorRedux.i18n'
import styled from "styled-components"
import { Button } from "react-bootstrap"
import { BfSelect } from '../../Styling/styledForm/styledBlueForm'
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth'
import { useHistory } from 'react-router-dom';
import { useTimerDelay } from '../../hooks/useTimerDelay'
import { toast } from 'react-toastify';

/*===============================================================================

KF Ønsker følgende funksjonalitet

Dersom man skriver i filter:
- Gå til sakslisten
- Filtrer sakslisten

Dersom man velger kreditor i dropdown:
- Velg kreditor i aktuelt bilde

Dersom man endrer aktive / inaktive,
- Hent aktive inaktive


*******************************************************************************
TODO:
  [ ] - Hent kreditorer ved login, så dropdown blir populert
  [ ] - Ta inn reducer for saksliste, og hent ved endring i aktive/ikke aktive
  [ ] -  -- " -- hent ved klikk på reload knappen
  [ ] Disable på loading event både på saksliste og header statusen
===============================================================================*/

import LocaleContext from "../../state/contexts/LocaleContext"

import { useDispatch, useSelector } from 'react-redux';
import { filterCases, getCaseList } from "../../reduxStore/saksliste/sakslisteActions"
import { getKreditorsForDropdown, setAktiveInactiveSelected, setFilterString, setSelectedKreditor } from "../../reduxStore/sakslisteHeader/sakslisteHeaderActions"
import { dispSetActivePage } from "../../reduxStore/sakslisteGridState/saksListeGridStateActions"
import { dispatchGetCaseDetails, dispatchGetCaseDetailsAllKreditors } from "../../reduxStore/saksdetaljer/saksdetaljerActions"
import colorConfig from "../../colorConfig"
import { SearcableSelect } from "../searcableSelect/SearchableSelect"
import CONFIG from "../../config"
import { setFakturaFilterString } from "../../reduxStore/fakturaAdmin/fakturaAdminActions"
import { setStengelisteFilterString } from "../../reduxStore/stengeliste/stengelisteActions"

const H2SelSpan = styled.div`
  color: white;
  margin-top: -14px;
  padding-top: 0px; 
`

const H2Caption = styled(Form.Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Fellix", "sans-serif";
  color: ${colorConfig.lys_gul};
  margin-bottom: 0px;
`

const OrangeButton = styled(Button)`    
   border-radius: 0.5em;
   border: 0px;
   background-color: ${colorConfig.button_bg};
   height: 44px;
   margin-top: 27px;

   :hover {
            background-color: ${colorConfig.button_focused};
            -webkit-transition: background-color 1.5s ease-out;
            -moz-transition: background-color 1.5s ease-out;
            -o-transition: background-color 1.5s ease-out;
            transition: background-color 1.5s ease-out;
            transition-delay:0.1s;
    }
    :focus {
        background-color: ${colorConfig.button_focused};
    }
    :disabled {
        background-color: gray;
    }
 `

const Header2SelectorRedux = (props) => {

    const showToasts = true

    const auth = useAuth()

    const locale = React.useContext(LocaleContext)
    const localeMessages = messages[locale]

    const location = useLocation();
    const history = useHistory();

    const state = useSelector(state => state.sakslisteHeader)
    const saksListeState = useSelector(state => state.saksliste)
    const saksdetaljerState = useSelector(state => state.saksdetaljer)
    const fakturaAdminState = useSelector(state => state.fakturaAdmin)
    const stengelisteState = useSelector(state => state.stengeliste)
    //const gridState = useSelector(state => state.saksListeGridState)
    const dispatch = useDispatch()

    // Merk at defaultverdien settes i reduceren
    const getStatusOptions = () => {
        return (
            <>
                <option value="ALL">{localeMessages.alleCaption}</option>
                <option value="ACTIVE">{localeMessages.saksListeCaption}</option>
                <option value="CLOSED">{localeMessages.historikkCaption}</option>
            </>
        )
    }

    const handleStatusChange = e => {
        //console.log('handleStatusChange', e)
        dispatch(dispSetActivePage(1))
        dispatch(setAktiveInactiveSelected(e.target.value))
    }

    const handleKreditorChange = e => {
        //console.log('handleKreditorChange', e)
        dispatch(dispSetActivePage(1))
        dispatch(setSelectedKreditor(e.target.value, e.target.options[e.target.selectedIndex].text))
    }

    const handleFilteredSelectKreditorChange = e => {
        //console.log('handleKreditorChange', e)
        dispatch(dispSetActivePage(1))
        if (e) {
            let kreditorId = e
            let kreditor = state.kreditorliste.find(o => o.customerNo === e)
            let kreditorCaption = kreditor.disp
            dispatch(setSelectedKreditor(kreditorId, kreditorCaption))
        } else {
            dispatch(setSelectedKreditor("", ""))
        }


    }

    const reload = () => {
        if (!auth.isSupervisor()) {
            dispatch(dispSetActivePage(1))
            dispatch(getCaseList(state.aktive, showToasts, localeMessages))
        }
    }

    // Når prodklar skal denne alltid vises
    const showHeaderSelector = () => {
        if (auth.creditors.length === 0) {
            return false
        }

        if (!auth.isAuthenticated) {
            return false
        }

        let validLocations = [
            '/Caselist', 'CaseDetails', '/dummy', '/Overview', '/Filbehandling', '/CasesMRU', '/Profil', '/RenteKalkulator', '/HeadlessCMS', '/fakturaadmin', '/stengeliste',
        ]

        const index = validLocations.findIndex(element => {
            if (location.pathname.includes(element)) {
                return true;
            }
        });

        if (index === -1) {
            // array contains substring match
            return false
        }

        if (auth.isSupervisor()) {
            return false
        }
        return true
    }

    // ----------------------------------------------------- 
    // START rutiner for timer for delay på filter
    // ----------------------------------------------------- 

    const [lastFilter, setLastFilter] = useState('')
    // State for filter som settes etter timerdelay
    const setSokVerdi = e => {
        console.log('Setter filterverdien: ', e)
        dispatch(dispSetActivePage(1))
        dispatch(setFilterString(e))
        setLastFilter(e)

        let currentLocation = location.pathname
        if (location.pathname !== '/Caselist') {
            history.push('/Caselist')
        }
        //console.log(location.pathname)
    }

    // lokal state for filterstrengen
    const [filterValue, setFilterValue] = useState()

    // useTimerDelay er en custom hook som kaller funksjonene som sendes inn som parametre
    const { handlefilterValueChange } = useTimerDelay(400, setSokVerdi, setFilterValue)

    // ----------------------------------------------------- 
    // START rutiner for timer for delay på fakturafilter
    // ----------------------------------------------------- 

    // Dersom løs på samme måte som saksfilteret, fungerer det ikke, ukjent hvilken grunn
    const [fakturaFilterValue, setFakturaFilterValue] = useState()
    const [stengelisteFilterValue, setStengelisteFilterValue] = useState()

    // Tøm søkefeltet når det lastes datata
    useEffect(() => {
        setFakturaFilterValue('')
    }, [fakturaAdminState.loading])

    useEffect(() => {
        setStengelisteFilterValue('')
    }, [stengelisteState.loading])

    // Filtrer fakturaer med en liten delay, dersom bruker skriver inn flere bokstaver
    const [fakTimer, setFakTimer] = useState()
    useEffect(() => {
        const resetFilterTimer = (filter) => {
            clearTimeout(fakTimer);
            setFakTimer(setTimeout(() => dispatch(setFakturaFilterString(fakturaFilterValue)), 400))
        }
        resetFilterTimer()
    }, [fakturaFilterValue])

    const [stengelisteTimer, setStengelisteTimer] = useState()
    useEffect(() => {
        const resetFilterTimer = (filter) => {
            clearTimeout(stengelisteTimer);
            setStengelisteTimer(setTimeout(() => dispatch(setStengelisteFilterString(stengelisteFilterValue)), 400))
        }
        resetFilterTimer()
    }, [stengelisteFilterValue])


    // ----------------------------------------------------- 
    // SLUTT rutiner for timer for delay på filter
    // -----------------------------------------------------   


    // ----------------------------------------------------- 
    // START useEffekt rutiner som trigger henting
    // -----------------------------------------------------   

    // Dette skjer når du er pålogget
    useEffect(() => {
        if (!auth.isSupervisor()) {
            dispatch(getKreditorsForDropdown(auth.forening, showToasts, localeMessages))
        }
    }, [auth.forening])

    // Dette skjer når filterstrengen eller valgt kreditor er endret, filtrer om data er hentet
    useEffect(() => {

        const saksPrefix = ['1S', '1T', '2S', '2T', '3S', '4S', '70']

        if (saksListeState.fetched) {

            //console.log('Henter sak og ser om det er ok')
            /* NY FUNKSJONALITET HENT SAK DERSOM FILTER INNEHOLDER SAKSNUMMER
            **********************************************************
            TODO ***** HENT SAK ******
            Sjekk om du har valgt kreditor, da kan du hente med gammel metode
            Dersom kreditor ikke er utfylt må du sende med en liste med alle kreditorene for kunden
            Du må sende med en funksjon som skal kjøre ved success som pusher history til saksdetaljer bildet.
            Sjekk også at ikke denne allerede ligger i state
            */
            //if (state.kreditorId) {
            //   
            //    const onSuccess = () => history.push(`/CaseDetails/${state.kreditorId}/${state.filter}`)
            // TODO Vi vil ikke ha toast på errorm men kan godt ha det på sucess
            //    dispatch(dispatchGetCaseDetails(state.kreditorId, state.filter, false, messages, onSuccess))
            //}
            // *******************************************************************

            //console.log('(Header2SelectorRedux) Nå må filtrere på ...', lastFilter)

            // Merk at vi ikke bruker state filter, for da går det så tregt...
            dispatch(filterCases(state.kreditorId, lastFilter, saksListeState))

            // dersom det er 6 tegn, kan du hente sak
            if (state.filter.length === 6) {


                if (state.kreditorId) {

                    // Sjekk om saken finnes i sakslisteState for valgt kreditor
                    toast.info(`Sjekker om ${state.filter} er et saksnummer i saksliste for valgt kreditor...`)
                    const uperCaseFilter = state.filter.toUpperCase()
                    let saksRad = saksListeState.caseList.find(
                        e => (e.caseNumber === uperCaseFilter) && (e.crNo === state.kreditorId)
                    )
                    if (saksRad) {

                        // OK, fant saken i sakslisten
                        toast.info(`Vidersender deg til detaljer for sak ${state.filter}.`)
                        history.push(`/CaseDetails/${saksRad.crNo}/${state.filter}`)

                    } else {

                        // Sjekker om søkestrengen begynner på et saksnummer prefix
                        if (saksPrefix.includes(lastFilter.substring(0, 2).toUpperCase())) {

                            toast.info(`Kontrollerer om ${state.filter} er saksnummer for en av dine kreditorer.`)

                            // Fant ikke saken i sakslisten, men kreditor er valgt, sjekk om saken kan lastes, gå til saksbildet
                            const onSuccess = () => history.push(`/CaseDetails/${state.kreditorId}/${state.filter}`)
                            dispatch(dispatchGetCaseDetails(state.kreditorId, state.filter, false, localeMessages, onSuccess))

                        }
                    }

                } else {
                    // Du har valgt alle kreditorer
                    // Sjekk om vi finner saken i sakslisten
                    const uperCaseFilter = state.filter.toUpperCase()
                    let saksRader = saksListeState.caseList.filter(e => e.caseNumber === uperCaseFilter)
                    if (saksRader.length === 1) {

                        // OK, fant saken i sakslisten
                        toast.info(`Vidersender deg til detaljer for sak ${state.filter}.`)
                        history.push(`/CaseDetails/${saksRader[0].crNo}/${state.filter}`)

                    } else {

                        // Sjekker om søkestrengen begynner på et saksnummer prefix
                        if (saksPrefix.includes(lastFilter.substring(0, 2).toUpperCase())) {

                            // Vi må gjøre en spørring på saksnr med alle kreditorer
                            toast.info(`Kontrollerer om ${state.filter} er saksnummer for alle dine registrerte keditorer. (ikke implementert) `)

                            // kall /kos/portalService/checkCaseNo
                            let kreditorListe = state.kreditorliste.map(
                                e => e.ind.split(';')[1].toString()
                            )
                            //console.log(kreditorListe)

                            const onSuccess = (kreditor) => {

                                let pushUri = `/CaseDetails/#KREDITORID#/${state.filter}`
                                pushUri = pushUri.replace('#KREDITORID#', kreditor)

                                toast.info(`Vidersender deg til detaljer for sak ${state.filter}.`)

                                history.push(pushUri)
                            }

                            dispatch(dispatchGetCaseDetailsAllKreditors(kreditorListe, state.filter, false, localeMessages, onSuccess))

                        }
                    }

                }
            }
        }

    }, [state.kreditorId, lastFilter])

    // Dette skjer når du veksler mellom aktive og ikke aktive, last data fra kos
    useEffect(() => {
        if ((auth.isAuthenticated && !auth.isSupervisor())) {
            dispatch(getCaseList(state.aktive, showToasts, localeMessages))
        }
    }, [state.aktive])

    // Dette skjer når sakslisten er lastet for å filtrere på nye data
    useEffect(() => {
        // Sjekk om vi har filter, da må vi filtrere også, men ikke før vi er ferdige
        dispatch(filterCases(state.kreditorId, lastFilter, saksListeState))
    }, [saksListeState.caseList])

    // Dette skjer når noe lastes for å disable knapper
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(state.loading || saksListeState.loading)
    }, [state.loading, saksListeState.loading])

    // ----------------------------------------------------- 
    // SLUTT useEffekt rutiner som trigger henting
    // -----------------------------------------------------

    if (!showHeaderSelector()) {
        return ""
    }

    // NB: Enter tast vil i enkelte tilfeller føre til reload av applikasjonen
    const keyPressHandler = (event) => {
        if (event.charCode === 13) {
            event.preventDefault()
        }
    }


    const getKreditorOptions = () => {
        if (!state.kreditorliste)
            return (<option key={-1} value={""}>Ingen kreditorer</option>)

        if (Object.keys(state.kreditorliste).length === 0)
            return (<option key={-1} value={""}>Ingen kreditorer</option>)

        if (state.kreditorliste.length === 1) {
            let val = state.kreditorliste[0].ind.split(';')[1]
            return (<option key={1} value={val}>{state.kreditorliste[0].disp}</option>)
        }

        return (
            <>
                <option key={-1} value={""}>Alle kreditorer</option>
                {state.kreditorliste.map((item, i) => (
                    <option key={i} value={`${item.ind.split(';')[1]}`}>{item.disp}</option>
                ))}
            </>
        )
    }

    if (auth.forceMfaNow()) {
        return (
            <>
            </>
        )
    }

    return (
        <H2SelSpan>

            <Row>

                <Col xs="auto">
                    <Form.Group as={Col} controlId="formGridState">
                        <H2Caption>Status</H2Caption>
                        <BfSelect
                            name="rolle"
                            value={state.aktive}
                            onChange={handleStatusChange}
                            disabled={isLoading}
                            onKeyPress={keyPressHandler}
                        >
                            {getStatusOptions()}
                        </BfSelect>
                    </Form.Group>
                </Col>

                <Col xs="auto">
                    <OrangeButton
                        className="fa fa-redo"
                        onClick={reload}
                        disabled={isLoading}
                    >
                        <span style={{ fontSize: "32px" }} className="fa fa-refresh"></span>
                    </OrangeButton>
                </Col>

                {CONFIG.SHOW_OLD_KREDITORDROPDOWN &&
                    <Col xs="auto">
                        <Form.Group as={Col} controlId="formGridState">
                            <H2Caption>Kreditor</H2Caption>
                            <BfSelect
                                name="kreditor"
                                value={state.kreditorId}
                                onChange={handleKreditorChange}
                                disabled={isLoading}
                                onKeyPress={keyPressHandler}
                            >
                                {getKreditorOptions()}
                            </BfSelect>
                        </Form.Group>
                    </Col>
                }

                {CONFIG.SHOW_NEW_KREDITORDROPDOWN &&
                    <Col xs="auto">
                        <Form.Group as={Col} controlId="kreditor2">
                            <H2Caption>Kreditor</H2Caption>
                            <SearcableSelect
                                name="kreditor"
                                value={state.kreditorId}
                                onChange={handleFilteredSelectKreditorChange}
                                disabled={isLoading}
                                kreditorListe={state.kreditorliste}
                                defaultCaption={"Alle kreditorer"}
                            />
                        </Form.Group>
                    </Col>
                }

                {!location.pathname.toLowerCase().includes('fakturaadmin') && !location.pathname.toLowerCase().includes('stengeliste') &&
                    <Col xs="auto">
                        <Form.Group as={Col} controlId="formGridState">
                            <H2Caption>Søkefelt</H2Caption>
                            <Form.Control as="input"
                                placeholder="søk etter"
                                value={filterValue}
                                onChange={handlefilterValueChange}
                                onBlur={(e) => setFilterValue(e.target.value)}
                                disabled={isLoading}
                                onKeyPress={keyPressHandler}
                                style={{ fontSize: "20px", borderRadius: ".5em", border: "none" }}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                }

                {location.pathname.toLowerCase().includes('fakturaadmin') &&
                    <Col xs="auto">
                        <Form.Group as={Col} controlId="formFaktGridState">
                            <H2Caption>Søkefelt</H2Caption>
                            <Form.Control as="input"
                                placeholder="søk i fakturaer"
                                value={fakturaFilterValue}
                                onChange={(e) => setFakturaFilterValue(e.target.value)}
                                //onBlur={(e) => setFakturaFilterValue(e.target.value)}
                                disabled={fakturaAdminState.loading || fakturaAdminState?.invoiceList?.length === 0}
                                onKeyPress={keyPressHandler}
                                style={{ fontSize: "20px", borderRadius: ".5em", border: "none" }}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                }

                {location.pathname.toLowerCase().includes('stengeliste') &&
                    <Col xs="auto">
                        <Form.Group as={Col} controlId="formFaktGridState">
                            <H2Caption>Søkefelt</H2Caption>
                            <Form.Control as="input"
                                placeholder="søk i stengeliste"
                                value={stengelisteFilterValue}
                                onChange={(e) => setStengelisteFilterValue(e.target.value)}
                                disabled={stengelisteState.loading || stengelisteState?.closingList?.length === 0}
                                onKeyPress={keyPressHandler}
                                style={{ fontSize: "20px", borderRadius: ".5em", border: "none" }}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>
                }

            </Row>

        </H2SelSpan>
    );
};

export default Header2SelectorRedux;