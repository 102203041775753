import { actions } from "./stengelisteActions";

const initialState = {
    kisIntegration: '',
    closingList: [],
    filteredList: [],
    fetchedData: [],
    apiTimeStamp: '',
    filterString: '',
    antall: 0,
    sum_alle: 0.0,
    antall_valgte: 0,
    antall_ikke_valgte: 0,
    sum_valgte: 0.0,
    sum_ikke_valgte: 0.0,
    hasStengeliste: false,
    fetched: false,
    loading: false,
    error: ''
}

let setCounters = (newState) => {

    let count = 0
    let total = 0.0
    let countSelected = 0
    let countNotSelected = 0
    let sumSelected = 0.0
    let sumNotSelected = 0.0

    newState.closingList.forEach(e => {

        if (!e.disabled) {

            count++
            total += e.remainingTotal

            if (e.isSelected) {
                countSelected++
                sumSelected += e.remainingPrincepal
            } else {
                countNotSelected++
                sumNotSelected += e.remainingPrincepal
            }

        }

    })

    newState.antall = count
    newState.sum_alle = total
    newState.sum_valgte = sumSelected
    newState.sum_ikke_valgte = sumNotSelected
    newState.antall_valgte = countSelected
    newState.antall_ikke_valgte = countNotSelected

    return newState

}

export default function stengelisteReducer(state = initialState, action) {

    switch (action.type) {

        case actions.STENGELISTE_LOADING:
            return {
                ...state,
                filteredList: [],
                fetched: false,
                loading: true,
                error: null
            };

        case actions.STENGELISTE_CLEAR_LOADING:
            return {
                ...state,
                fetched: false,
                loading: false,
                error: null
            }

        case actions.STENGELISTE_SET_KISINTEGRATION:
            return {
                ...state,
                kisIntegration: action.payload.kisIntegration
            }

        case actions.STENGELISTE_SET_SOKEVERDI:
            let _filteredList = []
            if (!action.payload.value) {
                _filteredList = [...state.closingList]
            }
            else {
                _filteredList = [...state.closingList.filter((rec) => rec.searchIndex.includes(action.payload.value.toUpperCase()))]
            }
            return {
                ...state,
                filterString: action.payload.value,
                filteredList: _filteredList
            }

        case actions.STENGELISTE_SET_APITIMESTAMP:
            return {
                ...state,
                apiTimeStamp: action.payload.value
            }

        case actions.STENGELISTE_DATA_FETCHED:
            let fetchedSate = {
                ...state,
                filterString: '',
                closingList: [...action.payload.closingList],
                filteredList: [...action.payload.closingList],
                fetchedData: [...action.payload.fetchedData],
                hasStengeliste: true,
                fetched: true,
                loading: false,
                error: null
            };
            return setCounters(fetchedSate)

        case actions.STENGELISTE_DATA_CLEAR:

            let emptyState = {
                ...state,
                kisIntegration: '',
                closingList: [],
                fetchedData: [],
                filteredList: [],
                hasStengeliste: false,
                fetched: true,
                loading: false,
                error: null
            };
            return setCounters(emptyState)

        case actions.STENGELISTE_ERROR:
            return {
                ...state,
                loading: false,
                fetched: true,
                error: action.data
            }

        case actions.STENGELISTE_TOGGLE_SELECTED:
            let newState = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            newState.filteredList.forEach(e => {
                if (!e.disabled) {
                    if (e.i === action.payload.i) {
                        e.isSelected = action.payload.value
                    }
                }
            })
            newState.closingList.forEach(e => {
                if (!e.disabled) {
                    if (e.i === action.payload.i) {
                        e.isSelected = action.payload.value
                    }
                }
            })

            return setCounters(newState)

        case actions.STENGELISTE_TOGGLE_SELECTED_ON_HOLD:
            let newOnHoldState = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            newOnHoldState.filteredList.forEach(e => {
                if (!e.onHold) {
                    if (e.i === action.payload.i) {
                        e.isSelected = action.payload.value
                    }
                }
            })
            newOnHoldState.closingList.forEach(e => {
                if (!e.onHold) {
                    if (e.i === action.payload.i) {
                        e.isSelected = action.payload.value
                    }
                }
            })

            return setCounters(newOnHoldState)

        case actions.STENGELISTE_TOGGLE_SELECTED_MOVED:
            let newMovedState = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            newMovedState.filteredList.forEach(e => {
                if (!e.moved) {
                    if (e.i === action.payload.i) {
                        e.isSelected = action.payload.value
                    }
                }
            })
            newMovedState.closingList.forEach(e => {
                if (!e.moved) {
                    if (e.i === action.payload.i) {
                        e.isSelected = action.payload.value
                    }
                }
            })

            return setCounters(newMovedState)

        case actions.STENGELISTE_DISABLE_SELECTED:
            let newState2 = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            newState2.closingList.forEach(e => {
                if (e.isSelected) {
                    e.isSelected = false
                    e.disabled = 'disabled'
                }
            })

            // TODO filrert liste er tom når man kommer hit
            newState2.filteredList.forEach(e => {
                if (e.isSelected) {
                    e.isSelected = false
                    e.disabled = 'disabled'
                }
            })

            let _filteredListX = []
            if (!action.payload.value) {
                _filteredListX = [...state.closingList]
            }
            else {
                _filteredListX = [...state.closingList.filter((rec) => rec.searchIndex.includes(action.payload.value.toUpperCase()))]
            }
            newState2.filteredList = _filteredListX
            return setCounters(newState2)

        case actions.STENGELISTE_DISABLE_SELECTED_ON_HOLD:
            let newOnHoldState2 = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            newOnHoldState2.closingList.forEach(e => {
                if (e.isSelected) {
                    e.isSelected = false
                    e.onHold = 'onHold'
                }
            })

            // TODO filrert liste er tom når man kommer hit
            newOnHoldState2.filteredList.forEach(e => {
                if (e.isSelected) {
                    e.isSelected = false
                    e.onHold = 'onHold'
                }
            })

            let _filteredListZ = []
            if (!action.payload.value) {
                _filteredListZ = [...state.closingList]
            }
            else {
                _filteredListX = [...state.closingList.filter((rec) => rec.searchIndex.includes(action.payload.value.toUpperCase()))]
            }
            newOnHoldState2.filteredList = _filteredListZ
            return setCounters(newOnHoldState2)

        case actions.STENGELISTE_DISABLE_SELECTED_MOVED:
            let newMovedState2 = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            newMovedState2.closingList.forEach(e => {
                if (e.isSelected) {
                    e.isSelected = false
                    e.moved = 'moved'
                }
            })

            // TODO filrert liste er tom når man kommer hit
            newMovedState2.filteredList.forEach(e => {
                if (e.isSelected) {
                    e.isSelected = false
                    e.moved = 'moved'
                }
            })

            let _filteredListY = []
            if (!action.payload.value) {
                _filteredListY = [...state.closingList]
            }
            else {
                _filteredListY = [...state.closingList.filter((rec) => rec.searchIndex.includes(action.payload.value.toUpperCase()))]
            }
            newMovedState2.filteredList = _filteredListY
            return setCounters(newMovedState2)

        case actions.STENGELISTE_TOGGLE_ALL:
            let allState = {
                ...state,
                loading: false,
                fetched: true,
                error: null
            }
            allState.closingList.forEach(e => {
                if (!e.disabled) {
                    e.isSelected = action.payload.value
                }
            })

            return setCounters(allState)


        default:
            return state;
    }
}
