import React, { useEffect, useState } from 'react';
import LocaleContext from '../../state/contexts/LocaleContext'
import CaseDetailsContext from './CaseDetailsContext'
import { messages } from './CaseDetailsContainer.i18n'
import { useDispatch, useSelector } from 'react-redux';

import { BlueRow } from '../../Styling/styledForm/styledBlueForm';
import { Container, Col, Alert } from "react-bootstrap";

import CaDetReskontroComponent from './components/infogrids/CaDetReskontroComponent';
import CaDetDebtorComponent from './components/infogrids/CaDetDebtorComponent';
import CaDetSaksOversiktComponent from './components/infogrids/CaDetSaksOversiktComponent';
import CaDetTabContainer from './components/tabs/CaDetTabContainer';
import CaDetHeaderComponent from './components/infogrids/CaDetHeaderComponent';
import CaDetIconsComponent from './components/CaDetIconsComponent';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner'
import { dispatchGetCaseDetails } from '../../reduxStore/saksdetaljer/saksdetaljerActions';

import { useTrekkSakModal } from '../caseModals/trekkSak/useTrekkSakModal'
import { useUtsettSakModal } from '../caseModals/utsettSak/useUtsettSakModal'
import { useAvdragsordModal } from '../caseModals/avdragsordning/useAvdragsordModal'
import { useFakturaUploadModal } from '../caseModals/fakturaUpload/useFakturaUploadModal';
import { useMeldingModal } from '../caseModals/melding/useMeldingModal';
import { useBetalingModal } from '../caseModals/betaling/useBetalingModal'

import TrekkSak01Modal from '../caseModals/trekkSak/TrekkSakModal01';
import TrekkSak02Modal from '../caseModals/trekkSak/TrekkSakModal02';
import UtsettSakModal from '../caseModals/utsettSak/UtsettSakModal'
import AvdragsordModal from '../caseModals/avdragsordning/AvdragsordModal';
import FakturaUploadModal from '../caseModals/fakturaUpload/FakturaUploadModal';
import MeldingModal from '../caseModals/melding/MeldingModal'
import BetalingModal from '../caseModals/betaling/BetalingModal'
import { getSbUrlListe } from '../../reduxStore/SbUrlListe/sbUrlListeActions';

export const MODALFORMS = {
    TREKK_SAK1: 'TREKK_SAK1',
    TREKK_SAK2: 'TREKK_SAK2',
    AVDRAGSORDNING: 'AVDRAGSORDNING',
    UTSETT_SAK: 'UTSETT_SAK',
    FAKTURA_UPLOAD: 'FAKTURA_UPLOAD',
    NY_MELDING: 'NY_MELDING',
    BETALING: 'BETALING'
}


const CaseDetailsContainerRedux = (props) => {

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const showToasts = true;

    const state = useSelector(state => state.saksdetaljer)
    const headerState = useSelector(state => state.sakslisteHeader)
    const sbUrlListState = useSelector(state => state.sbUrlListe)
    
    const dispatch = useDispatch()

    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {
        // Hent url til saksbehandlergruppe sine PDFer
        dispatch(getSbUrlListe())
       
    }, [props.caseId, props.crNo])

    useEffect(() => {
        if (state.fetched && (state.data.caseNo === props.caseId) && (state.data.creditorNo === props.crNo)) {
            // Dont fetch if already in state
        } else {
            setMessage('')
            setErrorMessage('')
            dispatch(dispatchGetCaseDetails(props.crNo, props.caseId, showToasts, localeMessages, null))
        }        
    }, [props.caseId, props.crNo])
    
    const tsFuncs = useTrekkSakModal(setErrorMessage, setMessage, setShowSpinner)
    const usFuncs = useUtsettSakModal(setErrorMessage, setMessage, setShowSpinner)
    const aoFuncs = useAvdragsordModal(setErrorMessage, setMessage, setShowSpinner)
    const fuFuncs = useFakturaUploadModal(setErrorMessage, setMessage, setShowSpinner)
    const meFuncs = useMeldingModal(setErrorMessage, setMessage, setShowSpinner)
    const btFuncs = useBetalingModal(setErrorMessage, setMessage, setShowSpinner)

    const showSendMelding = meFuncs.showDialogHandler
    const showSendBetaling = btFuncs.showDialogHandler
    
    const getModalWindows = () => {
        return (
            <>
                {showSpinner && <OverlaySpinner />}
            
                
                {tsFuncs.showTrekkSak01 && <TrekkSak01Modal tsFuncs={tsFuncs} />}
                {tsFuncs.showTrekkSak02 && <TrekkSak02Modal tsFuncs={tsFuncs} />}
                {usFuncs.showUtsettSakModal && <UtsettSakModal funcs={usFuncs} />}
                {aoFuncs.modalVisible && <AvdragsordModal funcs={aoFuncs} />}
                {fuFuncs.dialogVisible && <FakturaUploadModal funcs={fuFuncs} />}
                {meFuncs.dialogVisible && <MeldingModal funcs={meFuncs} samleSakStatus={state.data.fetchedData.caseCollectionStatus}/>}
                {btFuncs.dialogVisible && <BetalingModal funcs={btFuncs} />}
            </>
        )
    }


    return (
        <CaseDetailsContext.Provider value={{ localeMessages, showToasts, showSendMelding, showSendBetaling }}>

            {(state.error) &&
                <Alert variant='danger'>
                    {state.error}
                </Alert>}
            
            {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
            {message && <Alert variant='success'>{message}</Alert>}

            {state.loading && <OverlaySpinner />}

            {state.fetched &&
                <>
               
                    {getModalWindows()}
                    
                    <CaDetHeaderComponent 
                        samlesakHeader={state.data.fetchedData.caseCollectionStatus}
                    />

                    <br />

                    <Container fluid="true">
                        <BlueRow>
                        
                            <Col xs="4">
                                <CaDetReskontroComponent />
                            </Col>
                            <Col xs="4">
                                <CaDetDebtorComponent />
                            </Col>
                            <Col xs="4">
                                <CaDetSaksOversiktComponent />
                            </Col>
                        </BlueRow>

                    </Container>

                    <br />

                    <Container fluid="true">
                    <CaDetIconsComponent
                        showTrekkSak={tsFuncs.showTrekkSak}
                        showUtsettSak={usFuncs.showUtsettSakDialog}
                        showAvrdOrdn={aoFuncs.showDialog}
                        showFaktUpload={fuFuncs.showDialogHandler}
                        samleSakStatus={state.data.fetchedData.caseCollectionStatus}                        
                    />


                            <Col xs="12">
                                <CaDetTabContainer
                                    crNo={props.crNo}
                                    caseId={props.caseId}
                                    showSendMelding={meFuncs.showDialogHandler}
                                />
                            </Col>
                        
   

                    </Container>
                </>
            }
                    
        </CaseDetailsContext.Provider>
    )
}

export default CaseDetailsContainerRedux