import styled from 'styled-components'
import colorConfig from '../../colorConfig'

const RootContainer = styled.div`  
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 125px 40px auto 40px;
  grid-template-areas:     
    "Header2Area" 
    "BlueHeader1Area"
    "ContentArea" 
    "FooterArea";  
  height: 100vh;
  font-size: 16px;
  font-family: 'Fellix', sans-serif;
  text-align: left;
  min-width: 1366px;
  color: #003e60;
  //background-color: ${colorConfig.tabell_lysGul};
`
export default RootContainer

/*
KRED.NO variant

const RootContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 60px 125px auto 40px;
  grid-template-areas:
    "Header1Area"
    "Header2Area"
    "ContentArea"
    "FooterArea";
  height: 100vh;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  min-width: 1366px;
  color: #003e60;
`

*/