// REDUX Variant

import { mruActions } from "./mruActions";

const initialState = {
    data: [],
    fetched: false,
    loading: false,
    error: ''
}

const mruReducer = (state = initialState, action) => {

    switch (action.type) {

        case mruActions.LOADING:
            return {
                ...state,
                fetched: false,
                loading: true,
                error: null
            };

        case mruActions.DATA_FETCHED:
            return {
                ...state,
                data: [...action.payload.data],
                fetched: true,
                loading: false,
                error: null
            };

        case mruActions.ERROR:
            return {
                ...state,
                loading: false,
                error: action.data
            }

        default:
            return state;
    }
}

export default mruReducer


