import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';
import { dateToString, dateToStringDMY } from '../../util/date/dateRoutines';
import { numberToLocaleString } from '../../util/numbers/numberRoutines';


export const actions = {
    STENGELISTE_LOADING: 'STENGELISTE_LOADING',
    STENGELISTE_CLEAR_LOADING: 'STENGELISTE_CLEAR_LOADING',
    STENGELISTE_ERROR: 'STENGELISTE_ERROR',
    STENGELISTE_DATA_FETCHED: 'STENGELISTE_DATA_FETCHED',
    STENGELISTE_DATA_CLEAR: 'STENGELISTE_DATA_CLEAR',
    STENGELISTE_TOGGLE_SELECTED: 'STENGELISTE_TOGGLE_SELECTED',
    STENGELISTE_TOGGLE_SELECTED_ON_HOLD: 'STENGELISTE_TOGGLE_SELECTED_ON_HOLD',
    STENGELISTE_TOGGLE_SELECTED_MOVED: 'STENGELISTE_TOGGLE_SELECTED_MOVED',
    STENGELISTE_TOGGLE_ALL: 'STENGELISTE_TOGGLE_ALL',
    STENGELISTE_DISABLE_SELECTED: 'STENGELISTE_DISABLE_SELECTED',
    STENGELISTE_DISABLE_SELECTED_ON_HOLD: 'STENGELISTE_DISABLE_SELECTED_ON_HOLD',
    STENGELISTE_DISABLE_SELECTED_MOVED: 'STENGELISTE_DISABLE_SELECTED_MOVED',
    STENGELISTE_SET_KISINTEGRATION: 'STENGELISTE_SET_KISINTEGRATION',
    STENGELISTE_SET_APITIMESTAMP: 'STENGELISTE_SET_APITIMESTAMP',
    STENGELISTE_SET_SOKEVERDI: 'STENGELISTE_SET_SOKEVERDI'
}

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    } else {
        return err.message.toString()
    }
}

const mapDataToList = (closingList) => {

    const uCase = (e, fieldName) => {
        if (e.hasOwnProperty(fieldName)) {
            let val = e[fieldName]
            if (val) {
                return val.toUpperCase()
            }
        }
        return ''
    }

    return closingList.map((e, i) => {
        const item = {
            i: i,
            isSelected: false,
            disabled: '',
            onHold: '',
            moved: '',
            closingListId: e.closingListId,
            customerNo: e.customerNo,
            debtorAddress: e.debtorAddress,
            debtorCategory: e.debtorCategory,
            debtorName: e.debtorName,
            debtorNo: e.debtorNo,
            debtorPostalNumber: e.debtorPostalNumber,
            debtorRating: e.debtorRating,
            debtorPlace: e.debtorPlace,
            firstInvoiceDate: e.firstInvoiceDate,
            meeterPointId: e.meeterPointId,
            regDate: e.regDate,
            remainingPrincepal: e.remainingPrincepal,
            remainingTotal: e.remainingTotal,
            status: e.status,
            updatedDate: e.updatedDate,
            utilityAddress: e.utilityAddress,
            closingDate: e.closingDate,
            paymentDate: e.paymentDate,
            searchIndex: `${dateToStringDMY(e.paymentDate)};${dateToStringDMY(e.regDate)};${dateToStringDMY(e.closingDate)};${e.debtorPostalNumber};${uCase(e, "utilityAddress")};${uCase(e, "debtorName")};${e.customerNo};${(e.meeterPointId)};${numberToLocaleString(e.remainingTotal)}${numberToLocaleString(e.remainingPrincepal)}`,
            closingListCaseLength: e.closingListCase.length,
            closingListCase: e.closingListCase.map((f, j) => {
                        return {
                            j: j+1, // Index + 1 for vi viser linje 1, 2, osv
                            caseStatusCode: f.caseStatusCode,
                            caseStatusText: f.caseStatusText,
                            closingListId: f.closingListId,
                            creditorNo: f.creditorNo,
                            caseNumber: f.caseNumber,
                            invoiceAmount: f.invoiceAmount,
                            invoiceDate: f.invoiceDate,
                            invoiceNo: f.invoiceNo,
                            paidPrincepal: f.paidPrincepal,
                            princepal: f.princepal,
                            registrationDate: f.registrationDate,
                            remainingPrincepal: f.remainingPrincepal,
                            remainingTotal: f.remainingTotal,
                            closingNotificationSent: f.closingNotificationSent,
                            dueDate: f.dueDate
                        }})
        }
        
        return item
    })
}

export const clearData = () => {
    return dispatch => {
        dispatch({ type: actions.STENGELISTE_DATA_CLEAR, payload: null })
    }
}

export const toggleSelected = (i, newVal) => {
    return dispatch => {
        dispatch({ type: actions.STENGELISTE_TOGGLE_SELECTED, payload: { i: i, value: newVal } })
    }
}

export const toggleSelectedOnHold = (i, newVal) => {
    return dispatch => {
        dispatch({ type: actions.STENGELISTE_TOGGLE_SELECTED_ON_HOLD, payload: { i: i, value: newVal } })
    }
}

export const toggleSelectedMoved = (i, newVal) => {
    return dispatch => {
        dispatch({ type: actions.STENGELISTE_TOGGLE_SELECTED_MOVED, payload: { i: i, value: newVal } })
    }
}

export const toggleAll = (newVal) => {
    return dispatch => {
        dispatch({ type: actions.STENGELISTE_TOGGLE_ALL, payload: { value: newVal } })
    }
}

export const getStengeliste = (creditorNo, status, showToasts, messages) => {

    return dispatch => {

        getStengelisteDispatcher(dispatch, creditorNo, status, showToasts, messages)
    }
}


export const setApiTimestamp = (apiTimeSTamp) => {
    return dispatch => {
        dispatch({ type: actions.STENGELISTE_SET_APITIMESTAMP, payload: { value: apiTimeSTamp } })
    }
}

export const setStengelisteFilterString = (filterString) => {
    return dispatch => {
        dispatch({ type: actions.STENGELISTE_SET_SOKEVERDI, payload: { value: filterString } })
    }
}


const getStengelisteDispatcher = (dispatch, creditorNo, status, showToasts, messages) => {

    const errorMessage = messages['toastHentStengelisteFailed']
    const okMessage = messages['toastHentStengelisteOk']

    dispatch({ type: actions.STENGELISTE_LOADING, data: null })
    dispatch({ type: actions.STENGELISTE_SET_APITIMESTAMP, payload: { value: '' } })

    axiosTokenInstance
        ({
            method: 'GET',
            url: `/api/stengeliste/getClosingList/${creditorNo}/${status}`,
        })
        .then((result) => {

            if (result.status !== 200) {

                (showToasts && toast.error(errorMessage, { autoClose: false }))
                dispatch({ type: actions.STENGELISTE_ERROR, data: errorMessage })

            } else {

                const data = result.data

                if (data !== null) {

                    (showToasts && toast.info(okMessage))
                    dispatch({ type: actions.STENGELISTE_SET_KISINTEGRATION, payload: { kisIntegration: data.kisIntegration } })
    
                    if (data) {
                        dispatch({ type: actions.STENGELISTE_DATA_FETCHED, payload: { closingList: mapDataToList(data), fetchedData: data } })
                        if (data.length > 0)
                            dispatch({ type: actions.STENGELISTE_SET_APITIMESTAMP, payload: { value: data.apiTimeStamp } })
                            
                    } else {
                        dispatch({ type: actions.STENGELISTE_DATA_FETCHED, payload: { closingList: [], fetchedData: [] } })
                    }

                } else {
                    (showToasts && toast.warning(errorMessage + ', ' + result.data.errorMessage))
                    dispatch({ type: actions.STENGELISTE_ERROR, data: errorMessage + ', ' + result.data.errorMessage })
                }
            }
        })
        .catch((err) => {
            getErrorMessage(err)
            // let lErrMess = errorMessage + ' ' + getErrorMessage(err)
            let lErrMess = 'Noe gikk galt ved henting av stengeliste, vennligst prøv igjen. Ta kontakt med Kreditorforeningen om feilen vedvarer.'
            toast.error(lErrMess, { autoClose: false })
            dispatch({ type: actions.STENGELISTE_ERROR, data: lErrMess })
        })
}


export const sendInnValgteStengeKandidater = (formData, newStatus, showToasts, messages) => {

    return dispatch => {
        sendInnValgteStengeKandidaterDispatcher(dispatch, formData, newStatus, showToasts, messages)
    }
}

const sendInnValgteStengeKandidaterDispatcher = (dispatch, formData, newStatus, showToasts, messages) => {

    const errorMessage = messages['toastSendStengelisteFailed']
    const okMessage = messages['toastSendStengelisteOk']
    const sendtMessage = messages['toastSendStengeliste']
    const flyttetMessage = messages['toastFlytteStengeliste']
    const sattPåHoldMessage = messages['toastPåHoldStengeliste']

    // Setter showMessage toasten basert på hvilken status som blir satt
    const showMessage = newStatus === 'NY' ? flyttetMessage : newStatus === 'SLETTET' ? sattPåHoldMessage : sendtMessage

    dispatch({ type: actions.STENGELISTE_LOADING, data: null })

    axiosTokenInstance
        ({
            method: 'POST',
            url: `/api/stengeliste/changeStatus/`,
            data: formData
        })
        .then((result) => {

            dispatch({ type: actions.STENGELISTE_CLEAR_LOADING, data: null })
           newStatus === 'SENDT STENGING' ? dispatch({ type: actions.STENGELISTE_DISABLE_SELECTED, payload: { value: false } }) :
           newStatus === 'SLETTET' ? dispatch({ type: actions.STENGELISTE_DISABLE_SELECTED_ON_HOLD, payload: { value: false } }) : dispatch({ type: actions.STENGELISTE_DISABLE_SELECTED_MOVED, payload: { value: false } })

            if (result.status !== 200) {
                (showToasts && toast.error(errorMessage, { autoClose: false }))
            } else {

                if (result.data.errorCode === "000") {
                    (showToasts && toast.info(showMessage))
                } else {
                    (showToasts && toast.error(errorMessage + ', ' + + ', ' + result.data.errorMessage, { autoClose: false }))
                }
            }
        })
        .catch((err) => {
            dispatch({ type: actions.STENGELISTE_CLEAR_LOADING, data: null })
            let lErrMess = errorMessage + ' ' + getErrorMessage(err)
            toast.error(lErrMess)
        })

}