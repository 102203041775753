import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import PbiSvgLogo from '../../../assets/pbiicon.svg'
import colorConfig from '../../../colorConfig';

const PbiLogo = () => {
    return (
        <img style={{marginTop: "-12px"}} src={PbiSvgLogo} alt='logo' height='25px' />
    )
}

const ReportButtons = styled.div`
    text-align: left; 
    margin: 15px 15px 15px 15px auto; 
    clear: both;      
`

const ReportButton = styled.div`
    width: 300px; 
    height: 55px;
    padding: 17px;
    margin-right: 10px;
    margin-bottom: 10px;
    float: left;
    border-radius: 0.5em;
    font-family: "Fellix", "sans-serif";
    font-size: 16px;
    color: black;    
    background-color: ${colorConfig.button_bg};
    :hover {
        background-color: ${colorConfig.button_focused};
        color: white;     
        -webkit-transition: background-color 1.5s ease-out;
        -moz-transition: background-color 1.5s ease-out;
        -o-transition: background-color 1.5s ease-out;
        transition: background-color 1.5s ease-out;
        transition-delay:0.1s;
    }
    :focus {
        background-color: ${colorConfig.button_focused};
        color: white !important;     
    }
`

export const ReportNavLink = styled(NavLink)`
    all: unset;
    text-decoration: none;    
    background-color: 'transparent';

    :hover {
        text-decoration: underline;  
        color: white !important;    
        -webkit-transition: background-color 1.5s ease-out;
        -moz-transition: background-color 1.5s ease-out;
        -o-transition: background-color 1.5s ease-out;
        transition: background-color 1.5s ease-out;
        transition-delay:0.1s;  
    }
`

const printRow = (report, i) => {

    const uri = `/Reports/Run/${report.reportId}`

    return (
        <>
            <ReportButton>
               <ReportNavLink to={uri}>
                    <PbiLogo />&nbsp;<b><span>{report.reportName}</span></b>
               </ReportNavLink>
            </ReportButton>
        </>
    )    
}

const ReportListComponent = (props) => {

    const localeMessages = props.messages
    const reportMenuState = props.reportMenuState
    return (
        <div>
            <ReportButtons>             
                <br/>
                <h4 style={{align: "left"}}>{props.groupname}</h4>
                {props.reportItems.map((row, i) => printRow(row, i))}      
            </ReportButtons>   
            <br/>
        </div>
    )
}

export default ReportListComponent