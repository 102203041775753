export const messages = {
    nb: {
        
        GHS_antall: 'Antall',
        GHS_velg: 'Velg',
        GHS_status: 'Status',
        GHS_customer_ref: 'Kunde ref',
        GHS_measuring_ID: 'MålepunktsID',
        GHS_customer_name: 'Kundenavn',
        GHS_utility_address: 'Anleggsadresse',
        GHS_postnr: 'Postnr',
        GHS_caseNr: 'Saksnr inkasso',
        GHS_closing_amount: 'Stengebeløp',
        GHS_date_closingList: 'Dato stengeliste',
        GHS_line: 'Linje',
        GHS_invoice_date: 'Fakturadato',
        GHS_due_date: 'Forfall',
        GHS_invoice_nr: "Fakturanr.",
        GHS_case_status_text: 'Beskrivelse',
        GHS_princepal: 'HS',
        GHS_remaining_princepal: 'HS Rest',
        GHS_remaining_total: 'Saldo totalt',
        GHS_selected_date: 'Plukket til stenging',
        GHS_rating: 'Kredittvurderingsinfo',
        GHS_payment_date: 'Dato betaling',
        GHS_closing_sent: 'Stengevarsel sendt',
        
        SistOppdatert: 'Stengeliste sist oppdatert: ',
        VisAlle: 'Vis alle',
        Reduser: "Reduser",
        MerkAlle: 'Merk alle',
        MerkIngen: 'Merk ingen',
        Send: 'Send',
        Flytt: 'Flytt',

        antall: 'Antall på stengeliste',
        sum_alle: 'Sum HS stengeliste',
        antall_valgte: 'Antall valgte for stenging',
        sum_valgte: 'Sum rest HS valgte for stenging',
        antall_ikke_valgte: 'Antall ikke valgte for stenging',
        sum_ikke_valgte: 'Sum rest HS ikke valgte for stenging',

        BE_Header: 'Bekreft innsending av stenging',
        BE_Move_Header: 'Bekreft flytting',
        BE_On_Hold_Header: 'Bekreft',
        BE_Ja: 'Fortsett',
        BE_Nei: 'Avbryt',

        VelgKreditor: 'For å hente stengeliste, må du velge en kreditor fra nedtrekkslisten oppe til høyre.',
        ingenStengeliste: 'Det er ingen på stengeliste for denne kreditor, ',
        ikkeStengeliste: 'Denne kreditoren har ikke stengeliste. ',
        ingenPåListen: 'Det er ingen på listen.',

        toastHentStengelisteFailed: 'En feil oppstod under henting av stengeliste: ',
        toastHentStengelisteOk: 'Stengeliste er hentet. ',

        toastSendStengeliste: 'Valgte på stengeliste er sendt. ',
        toastFlytteStengeliste: 'Valgte er flyttet til listen. ',
        toastPåHoldStengeliste: 'Valgt er satt på hold. ',
        toastSendStengelisteOk: 'Innsendte på stengeliste er godkjent innsendt. ',
        toastSendStengelisteFailed: 'Innsending av valgte på stengeliste feilet. ',

        sHentetFor: ', stengeliste hentet for siste',
        sIngenListeRet: 'Ingen stengeliste returnert',
        sSlow: 'Vennligst vent, henter data fra regnskapssystem, det kan ta litt tid....',

        onClosingListTab: 'På stengeliste',
        chosenTab: 'Valgt for stenging',
        paymentReceivedTab: 'Betaling mottatt',
        onHoldTab: 'På hold',
    }
}