import React, { useState } from 'react';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './pbiTabContainer.i18n'
import CONFIG from '../../config'

import { BlueRow } from '../../Styling/styledForm/styledBlueForm';
import { Container, Col, Alert } from "react-bootstrap";
import { TabWrapper } from '../../components/tabs/TabWrapper';
import { Tabs, Tab } from "react-bootstrap";

import { IntlProvider } from 'react-intl'


import { useSelector } from 'react-redux'
import { PbiReportListTab } from './reportsTab/PbiReportListTab';
import { PbiPlannerForm } from './varslingTab/PbiPlannerForm';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';


const PbiTabContainer = (props) => {

    const IS_POWERBI_ENABLED = CONFIG.POWERBI_ENABLED

    // Localisation
    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]
 
    const state = useSelector(state => state.powerBi)
  
    const [showSpinner, setShowSpinner] = useState(false)

    const showTheSpinner = () => (state.loading || showSpinner)

    const [key, setKey] = useState('Rapporter');

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>

                {(state.error) &&
                    <Alert variant='danger'>
                        {state.error}
                    </Alert>}    
            
                {showTheSpinner() && <OverlaySpinner/>}                 
                                           
                <Container fluid="true">
                    <>
                        <Col xs="10">
                        <TabWrapper>
                            <Tabs id="oversiktTab" activeKey={key} onSelect={(k) => setKey(k)} style={{ backgroundColor: "white", fontWeight: "400", "color": "rgb(0, 62, 96)" }} >
                                
                                <Tab eventKey="Rapporter" title={`${localeMessages['TaC_T1']}`}>
                                    <PbiReportListTab/>
                                </Tab>
                                
                                <Tab eventKey="Varsling" title={`${localeMessages['TaC_T2']}`}>
                                    <PbiPlannerForm
                                        setShowSpinner={setShowSpinner}                                        
                                    />
                                </Tab>
                                                                        
                            </Tabs>
                        </TabWrapper>
                        </Col>

                    </>
                </Container>                                    
        </IntlProvider>
    )
}

export default PbiTabContainer