import React from 'react';
import { MfaWelcome } from './components/MfaWelcome';
import { useMfaContainer } from './useMfaContainer';
import { DisplayQrCode } from './components/DisplayQrCode';
import { MfaRemove } from './components/MfaRemove';
import { ValidateSMSCode } from './components/ValidateSMSCode';
import Spinner from '../../Styling/FormComponents/StyledSpinner';

const MfaContainer = (props) => {

    const {
        isLoading, displayWelcome, displayQrCode, qrCodeUri, displayMfaOk,
        removeMfa, showQrCode, localeMessages, postValidationCode, postUnRegisterMfa,
        twoFactorEnabled, handleSMSClick, displaySMSValidator, postValidateSMSCode,
        profilState,
    } = useMfaContainer()

    if (isLoading) {
        return (<Spinner />)
    }

    if (profilState.loading) {
        return (<Spinner />)
    }

    if (twoFactorEnabled === 'true') {
        return (
            <>
                <MfaRemove
                    messages={localeMessages}
                    postUnRegisterMfa={postUnRegisterMfa}>                
                </MfaRemove>
            </>
        )    
    }

    return (
        <>
            {displayWelcome && <MfaWelcome
                messages={localeMessages}
                showQrCode={showQrCode}
                handleSMSClick={handleSMSClick}
                profile={profilState.profile}
            />}
            
            {displayQrCode && <DisplayQrCode
                messages={localeMessages}
                qrCodeUri={qrCodeUri}
                postValidationCode={postValidationCode}
            />}

            {displaySMSValidator && <ValidateSMSCode
                messages={localeMessages}
                qrCodeUri={qrCodeUri}                
                postValidationCode={postValidateSMSCode}
            />}    

                      
        </>        
    )
}

export default MfaContainer