const getNewPassWordRequired = (errorMessage) => {

    const newPassWordRequired = (val, name, formState) => {
    
        if (!val) {
            return errorMessage
        }
        return ''
    }

    return newPassWordRequired
}

const getCheckEqualPasswords = (errorMessage) => {

    const checkequalPasswords = (val, name, formState) => {       

        if (val !== formState.Password1) {
            return errorMessage
        }
        return ''
    }

    return checkequalPasswords
}

const getValidatePassword = (errorMessage) => {

    const validatePassword = (val, name, formState) => {

        // (?=.*?[#?!@$%^&*-])

        let errors = false;

        // 8 Tegn
        if (val.length < 8) {
            errors = true;
        }

        // minst en bokstav
        if (val.search(/[a-z]/) < 0) {
            errors = true
        }

        // minst en stor bokstav
        if (val.search(/[A-Z]/) < 0) {
            errors = true
        }

        // Minst ett tall
        if (val.search(/[0-9]/) < 0) {
            errors = true
        }

        // Minst ett spesialtegn
        if (val.search(/[#?!@$%^&*-]/) < 0) {
            errors = true
        }

        if (errors) {
            return errorMessage
        }
        return '';
    }

    return validatePassword
}

export const resetPasswordValidators = (messages) => {
      
    const checkEqualPasswords = getCheckEqualPasswords(messages['checkEqualPasswords'])
    const passWordRequired = getNewPassWordRequired(messages['Password1Required'])
    const validatePassword = getValidatePassword(messages['validatePassword'])

    const formValidators = {        
        Password1: [
            passWordRequired,
            validatePassword
        ],
        Password2: [
            checkEqualPasswords,
        ]
    }

    return formValidators
}


