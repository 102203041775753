import React, { useState } from 'react'
import { useUcForm } from '../../../hooks/useUcForm';
import { getFakturaFormValidators } from './fakturaFormValidators'
import { startUploadFaktura, startEditFaktura, startFinalizeFaktura, startDeleteFaktura, startClearFaktura } from '../../../reduxStore/newCase/newCaseActions';
/*
import {
    dispatchUploadFaktura, dispatchFinalizeFaktura,
    dispatchEditFaktura, dispatchDeleteFaktura,
    dispatchClearFaktura
} from '../globalCaseNewReducer/globalCaseNewActions'
*/

const useFakturaForm = (state, dispatch, showToast, localeMessages) => {

    // Sjekk på state for å inialisere formverdier
    let formValues = {
        lineNo: '',
        fakturanummer: '',
        kid: '',

        fakturatekst: '',

        forfallsdato: '',
        fakturadato: '',
        valuta: 'NOK',
        belop: '',    
    }

    // -----------------------------------------------------------------
    // start: hent eventuell rad for editering
    // -----------------------------------------------------------------

    if ((state.displayForm === 'FAKTURAUPLOADEDIT') && (state.editLineNo)) {

        const editFaktura = state.invoices.filter((e) => e.lineNo === state.editLineNo)[0];
        
        if (editFaktura) {
            console.log('edit faktura')
            formValues = {
                lineNo: editFaktura.lineNo,

                fakturanummer: editFaktura.fakturaNummer,
                kid: editFaktura.kid,

                fakturatekst: editFaktura.fakturaTekst,

                forfallsdato: editFaktura.forfallsDato,
                fakturadato: editFaktura.fakturaDato,
                valuta: editFaktura.valuta,
                belop: editFaktura.belop.toFixed(2).replace('.', ','),
            }       
        }
    }

    // -----------------------------------------------------------------
    // start: Sett opp en ucForm med formvalues og validators
    // -----------------------------------------------------------------

    const [extraErrors, setExtraErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('')

    let formValidators = getFakturaFormValidators(localeMessages)
   
    const {
        handleChange, handleCheckboxChange, setField, handleMultiSelectChange,
        formState, validateField, validateAll, errorMessages, formIsValid,
        validatorsState, clearField
    } = useUcForm(formValues, formValidators)

    const hasErrors = Object.values(errorMessages).some(x => (x !== null && x !== '')) || Object.values(extraErrors).some(x => (x !== null && x !== ''));
  
    // -----------------------------------------------------------------
    // Nødvendig for uploading av filer
    // -----------------------------------------------------------------

    const [selectedUploadFile, setSelectedUploadFile] = useState()

    const fileUploadRef = React.useRef();

    const onUploadFileChange = event => {
        console.log(event.target.files)
        setSelectedUploadFile(event.target.files)
    };

    // -----------------------------------------------------------------
    // Valuta dropdown
    // -----------------------------------------------------------------

    const currencyOptions = () => {
        return (
            <>
                <option key={-1} value={""}>Velg valuta</option>
                {state.currencyList.map((item, i) => (
                    <option key={i} value={item.countryCode}>{item.countryCode}</option>
                ))}
            </>
        )
    }

    // -----------------------------------------------------------------
    // Sett opp en ucForm med formvalues og validators
    // -----------------------------------------------------------------
    
    const clearForm = () => {
        
        clearField("lineNo", "")
        clearField("fakturanummer", "")
        clearField("kid", "")
        clearField("fakturatekst", "")
        clearField("forfallsdato", "")
        clearField("fakturadato", "")
        clearField("valuta", "NOK")
        clearField("belop", "")
        
        setSelectedUploadFile(null)

        fileUploadRef.current.value = ""
    
    }
    const formatDate = d => `${d.substring(6, 10)}-${d.substring(3, 5)}-${d.substring(0, 2)}`  
    const formatDecimal = d => parseFloat(d.toString().replace(/ /g, '').replace(/,/, '.'))

    const submitForm = () => { }

    const uploadFaktura = () => {
        let formDontValidate = validateAll();

        // Sjekk felter som ikke valideres på cursor
        setExtraErrors({})

        // Test på om det finnes error
        if (formDontValidate) {
            console.log('FakturaUpload: Cannot be submitted')
        } else {
            console.log('FakturaUpload submitting')

            // Dette objektet skal til fakturalisten
            let fakturaAdded = {
                lineNo: formState.lineNo,
                fakturaNummer: formState.fakturanummer,
                kid: formState.kid,
                fakturaTekst: formState.fakturatekst,
                fakturaDato: formState.fakturadato,
                forfallsDato: formState.forfallsdato,
                valuta: formState.valuta,
                belop: formatDecimal(formState.belop),
                fileName: ''
            }

            // Dette objektet skal til kos
            const formData = new FormData();

            // Update the formData object
            if (selectedUploadFile) {

                // TODO iterate all files
                Array.from(selectedUploadFile).forEach(uplfile => {
                    formData.append(
                        "files",
                        uplfile,
                        uplfile.name    
                )})
                /*
                formData.append(
                    "files",
                    selectedUploadFile,
                    selectedUploadFile.name
                );*/

                // Keep track of uploaded file in redux store
                //fakturaAdded.fileName = selectedUploadFile.name
                Array.from(selectedUploadFile).forEach(uplfile => {
                    if (!fakturaAdded.fileName)
                        fakturaAdded.fileName = uplfile.name    
                    else 
                        fakturaAdded.fileName += ', ' +  uplfile.name
                })
            }          

            // Sjekk om ny eller gammel sak
            let lineNo = state.lineNo

            // Dersom ny faktura, må begge objektene oppdateres
            if (!formState.lineNo) {
                lineNo = lineNo + 1
                fakturaAdded.lineNo = lineNo
                formData.append('lineNo', lineNo )
            } else {
                formData.append('lineNo', formState.lineNo)
            }

            formData.append('preCaseId', state.preCaseId)
            formData.append('creditorNo', state.kreditorId)
            formData.append('fakturanummer', formState.fakturanummer)
            formData.append('fakturatekst', formState.fakturatekst)
            formData.append('forfallsdato', formatDate(formState.forfallsdato))
            formData.append('kid', formState.kid)
            formData.append('fakturadato', formatDate(formState.fakturadato))
            formData.append('valuta', formState.valuta)
            formData.append('belop', formatDecimal(formState.belop))           

            //let lLineNo = state.lineNo + 1
            let fakturaListe = []

            if (!formState.lineNo) {
                // Ny faktura                
                fakturaListe = [...state.invoices, fakturaAdded]
            } else {
                // Redigert faktura
                fakturaListe = [...state.invoices]    
                let objIndex = state.invoices.findIndex((obj => obj.lineNo === formState.lineNo));
                if (objIndex !== -1) {
                    fakturaListe[objIndex] = fakturaAdded
                }
            }            

            // TODO: Legg til anntall faktura uploads på en faktura
            dispatch(startUploadFaktura(formData, fakturaListe, lineNo, clearForm, showToast, localeMessages))
        }
    }

    //const editFaktura = lineNo => dispatchEditFaktura (lineNo, dispatch, actions, showToast)
    const editFaktura = lineNo => dispatch(startEditFaktura(lineNo, showToast))

    const deleteFaktura = lineNo => {

        let objIndex = state.invoices.findIndex((obj => obj.lineNo === lineNo));
        let deleteObj = state.invoices[objIndex]
                
        const formData = new FormData();        
        formData.append('preCaseId', state.preCaseId)
        formData.append('lineNo', lineNo)
        formData.append('creditorNo', state.kreditorId)

        let fakturaListe = state.invoices.filter(obj => obj.lineNo !== lineNo)

        //dispatchDeleteFaktura(formData, fakturaListe, clearForm, localeMessages, dispatch, actions, showToast)
        dispatch(startDeleteFaktura(formData, fakturaListe, clearForm, localeMessages, showToast))
        
    }

    //const emptyForm = () => dispatchClearFaktura(dispatch, actions, showToast)
    const emptyForm = () => dispatch(startClearFaktura())
    
    const finalizeFaktura = () => {
     
        // Test på om det finnes error
        if (state.invoices.length < 1) {
            console.log('FakturaUpload: Cannot be finalized')
        } else {
            console.log('FakturaUpload Finalizing')

            //dispatch
            //const formData = new FormData();

            //formData.append('preCaseId', state.preCaseId)
            //formData.append('creditorNo', state.kreditorId) 
            
            const formData = {
                preCaseId: state.preCaseId,
                creditorNo: state.kreditorId
            }

            //dispatchFinalizeFaktura(formData, showToast, localeMessages, dispatch, actions)
            dispatch(startFinalizeFaktura(formData, showToast, localeMessages))
        }

    }

    const postForm = () => {  }

    const debugInfo = () => { }

    // -----------------------------------------------------------------
    // Returnerer verdier/funcs som skal benyttes i formen
    // -----------------------------------------------------------------

    return {
        formValues, formValidators, handleChange, formState, validateAll,
        hasErrors, errorMessages, extraErrors, setExtraErrors,
        submitForm, errorMessage, localeMessages,  
        onUploadFileChange, fileUploadRef, currencyOptions,
        uploadFaktura, finalizeFaktura, editFaktura, deleteFaktura, emptyForm
    }
}

export default useFakturaForm