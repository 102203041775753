import { useState, useEffect } from 'react';
import Axios from 'axios'
import { useUcForm } from '../../hooks/useUcForm'
//import { onBoardingFormValidators } from './onBoardingFormValidators'
import  axiosTokenInstance from '../../Instances/axiosTokenInstance'
import { toast } from 'react-toastify';
import { getFromSession, setSessionStorage, removeFromSession, restoreFormFromStorage } from '../../state/storageUtil'
import _ from 'lodash'
import { getKontaktFormValidators } from './kontaktForm/KontaktFormValidators';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';
import authInstance from '../../Instances/axios-auth';

export const useKontaktSideContainer = (localeMessages) => {

    const auth = useAuth()

    const showToasts = true

    const [isLoading, setIsLoading] = useState(false)
    const [pageHasError, setPageHasError] = useState('')
    const [savedOk, setSavedOk] = useState('')
    

    let formValues = {
        navn: '',
        epost: '',
        emne: '', 
        melding: ''
    }

    if (auth.isAuthenticated) {
        formValues.navn = auth.user.name
        formValues.epost = auth.user.email
    }

    const ClearFormValues = () => {        

        if (auth.isAuthenticated) {
            clearField('navn', auth.user.name)
            clearField('epost', auth.user.email)
        } else {
            clearField('navn', '')
            clearField('epost', '')
        }
        clearField('emne', '')
        clearField('melding')
    
    }

    const formValidators = getKontaktFormValidators(localeMessages)

    const {
        handleChange, handleCheckboxChange, setField, handleMultiSelectChange, formState, validateField,
        validateAll, errorMessages, formIsValid,
        validatorsState, clearField } = useUcForm(formValues, formValidators)
    
    const [extraErrors, setExtraErrors] = useState({})
    const hasErrors = Object.values(errorMessages).some(x => (x !== null && x !== '')) || Object.values(extraErrors).some(x => (x !== null && x !== ''));

    useEffect(() => {
        // Set epostadresse og telefon til default
    }, []);

    const submitForm = () => {
               
    
        console.log(errorMessages)
        console.log('submitting', JSON.stringify(formState))

        let formDontValidate = validateAll();

        // Sjekk felter som ikke valideres på cursor
        setExtraErrors({})
        
        // Test på om det finnes error
        if (formDontValidate) {
            console.log('cannot be submitted')
        } else {
            console.log('Canbesubmitted')

            setIsLoading(true)
            setPageHasError('')
            setSavedOk('')

            const formData = new FormData();                    
            formData.append('Navn', formState.navn)
            formData.append('Epost', formState.epost)
            formData.append('Emne', formState.emne)
            formData.append('Melding', formState.melding)

            //console.log(JSON.stringify(submitData))
            
            let OkMess = localeMessages['toastSendError'] ? localeMessages['toastSendError'] : 'Meldingen er sendt.'
            let ErrMess = localeMessages['toastSendOk'] ? localeMessages['toastSendOk'] : 'Meldingen kunne ikke sendes'

            if (!auth.isAuthenticated) {

                authInstance({
                    method: 'POST',
                    url: `/api/email/KontaktSkjemaSendEpost`,
                    data: formData
                }).then((result) => {
                    setIsLoading(false)
                    setPageHasError('')
                    setSavedOk('')
                    console.log(result)

                    if (result.status !== 200) {
                        (showToasts && toast.error(ErrMess, {autoClose: false}))                        
                        setPageHasError(ErrMess)                    
                    } else {
                        (showToasts && toast.warning(OkMess))
                        console.log(JSON.stringify(result.data))
                        setSavedOk(OkMess)
                        ClearFormValues()
                        setPageHasError('')
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    //loadash is better than if (err.response.status === 400) {
                    if (_.has(err, 'response.data')) {   
                        setPageHasError(ErrMess + ', ' + err.response.data)
                        console.log(err)
                    } else {
                        setPageHasError(ErrMess + ', statuscode: ' + err.message)
                        console.log(err)
                    }                    
                })  

            } else {
                axiosTokenInstance
                ({
                    method: 'POST',
                    url: `/api/email/KontaktSkjemaSendEpost`,
                    data: formData
                })
                .then((result) => {
                    setIsLoading(false)
                    setPageHasError('')
                    setSavedOk('')
                    console.log(result)

                    if (result.status !== 200) {
                        (showToasts && toast.error(ErrMess, {autoClose: false}))                        
                        setPageHasError(ErrMess)                    
                    } else {
                        (showToasts && toast.warning(OkMess))
                        console.log(JSON.stringify(result.data))
                        setSavedOk(OkMess)
                        ClearFormValues()
                        setPageHasError('')
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    //loadash is better than if (err.response.status === 400) {
                    if (_.has(err, 'response.data')) {   
                        setPageHasError(ErrMess + ', ' + err.response.data)
                        console.log(err)
                    } else {
                        setPageHasError(ErrMess + ', statuscode: ' + err.message)
                        console.log(err)
                    }                    
                })  
            }
            
            
        
                      
        }



    }

    return {
        formValues, formValidators, handleChange,
        formState, validateAll, hasErrors, savedOk,
        errorMessages, extraErrors, setExtraErrors,
        submitForm, isLoading, pageHasError
    }

}