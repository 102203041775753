import React, { useRef } from "react";
import { Form, Col, Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
} from "../../../Styling/styledModal/styledModals";
import DateTimePicker from '../../../components/dateTimePicker/DateTimePicker'
import Cleave from "cleave.js/react";
import _ from 'lodash'


// Eksempel på file upload i react
// https://www.geeksforgeeks.org/file-uploading-in-react-js/

// Det finnes eksempler på hvordan man kan kommer rundt styling av knapp for upload

const Betaling01Modal = (props) => {

    const showRedBorder = (errorMessage => {      
        if (!errorMessage)
            return { display: 'inline-block' }

        return { border: '4px solid #cd4631', display: 'inline-block' }
    })

    const localeMessages =  props.funcs.localeMessages 
    
    let btnRef = useRef();
    
    const fakturaSelect = () => {

        console.log('Fakturaselect', props.funcs.fakturaListe)

        return (
            <>
                <select className="form-control" name="faktura" value={props.funcs.fakturaId} onChange={props.funcs.onFakturaIdChange}>
                    <option value="">{localeMessages["BT1M_faktura"]}</option>
                    {props.funcs.fakturaListe.map((fakt, i) =>
                        <option key={fakt.invoiceNo} value={fakt.invoiceNo}>{fakt.invoiceNo} - {fakt.invoiceRegarding}</option>
                    )}
                </select>
            </>
        )
    }


    return (

        <StyledModal centered show={true} onHide={props.funcs.cancelHandler}>

            <StyledModalBody>
                <h4>{localeMessages["BT1M_Overskrift"]}</h4>
                <Form.Group>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["BT1M_sak"]}
                        </Form.Label>
                        <Col>
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["BT1M_paymentDate"]}
                        </Form.Label>
                        <Col>
                            <div style={showRedBorder(props.funcs.paymentDateError)}>
                                
                                <DateTimePicker
                                    selected={props.funcs.paymentDate}
                                    onChange={props.funcs.handleChangePaymentDate}

                                    dateFormat="dd.MM.yyyy"
                                    timeCaption="time"                                               
                                />
                            </div>    
                        </Col>
                        {!props.funcs.validPaymentDate &&
                            <>
                                <Form.Label column="sm" lg={12} style={{color: '#cd4631', fontSize: '18px'}}>
                                {props.funcs.paymentDateError}
                                </Form.Label>
                            </>
                        }                        
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["BT1M_Belop"]}
                        </Form.Label>
                        <Col>                            
                            <Cleave placeholder="0,00"                               
                                onChange={props.funcs.onAmountChange}
                                value={props.funcs.amountString}
                                options={{
                                    numeral: true,
                                    numeralThousandsGroupStyle: 'thousand',
                                    delimiter: ' ',
                                    numeralDecimalMark: ','
                                }}

                            />

                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["BT1M_valuta"]}
                        </Form.Label>
                        <Col>
                            {props.funcs.currency}
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["BT1M_faktura"]}
                        </Form.Label>
                        <Col>
                            {fakturaSelect()}
                        </Col>
                    </Form.Row>
                    

                </Form.Group>

            </StyledModalBody>

            <StyledModalFooter>
                <Button
                    ref={btnRef}
                    variant="secondary"
                    disabled={props.funcs.validates()}
                    onClick={props.funcs.okHandler}>
                    {localeMessages["BT1M_Ok"]}
                </Button>
                <Button variant="secondary" onClick={props.funcs.cancelHandler}>
                    {localeMessages["BT1M_Avbryt"]}
                </Button>

            </StyledModalFooter>

        </StyledModal>
    );
};

export default Betaling01Modal;