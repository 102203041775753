import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

export const FETCH_TOOLSMENU_BEGIN = 'FETCH_TOOLSMENU_BEGIN'
export const FETCH_TOOLSMENU_SUCCESS = 'FETCH_TOOLSMENU_SUCCESS'
export const FETCH_TOOLSMENU_FAILURE = 'FETCH_TOOLSMENU_FAILURE'

const showToasts = true

export const fetchToolsMenuBegin = () => ({
    type: FETCH_TOOLSMENU_BEGIN
});

export const fetchToolsMenuSuccess = menuItems => ({
    type: FETCH_TOOLSMENU_SUCCESS,
    payload: { menuItems }
});

export const fetchToolsMenuFailure = error => ({
    type: FETCH_TOOLSMENU_FAILURE,
    payload: { error }
});

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}

export const getToolsMenu = () => {
    return dispatch => {

        const errorMessage = 'Henting av toolsMeny feilet'
        const okMessage = 'ToolsMeny er hentet'

        dispatch(fetchToolsMenuBegin())

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/menu/GetVerktoyMenu`,
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage, {autoClose: false}))
                    dispatch(fetchToolsMenuFailure(errorMessage))                   
                } else {

                    let cmsContent = ''
                    if (_.has(result, 'data')) {
                        cmsContent = result.data
                    }

                    //(showToasts && toast.info(okMessage))
                    dispatch (fetchToolsMenuSuccess(cmsContent))
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchToolsMenuFailure(lErrMess))
            })
    }
}


