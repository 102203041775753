import { actions } from "./sakslisteHeaderActions";

const initialState = {
    kreditorliste: [],

    kreditorId: '',
    kreditorName: '',
    filter: '',
    aktive: 'ALL',

    fakturaAdm: false,
    stengeliste: false,
    fetched: false,
    loading: false,
    error: ''
}

export default function sakslisteHeaderReducer(state = initialState, action) {

    switch (action.type) {

        case actions.SAKSLISTEHEADER_LOADING:
            return {
                ...state,
                fetched: false,
                loading: true,
                error: null
            };

        case actions.SAKSLISTEHEADER_KREDITORSLISTE_FETCHED:
            return {
                ...state,
                kreditorliste: [...action.payload.data],
                fakturaAdm: action.payload.fakturaAdm,
                stengeliste: action.payload.stengeliste,
                fetched: true,
                loading: false,
                error: null
            };
        
        case actions.SAKSLISTEHEADER_FILTER_CHANGED:
            return {
                ...state,                               
                filter: action.payload.filter,
                fetched: true,
                loading: false,
                error: null
            };

        case actions.SAKSLISTEHEADER_ACTIVE_CHANGED:
            return {
                ...state,               
                aktive: action.payload.aktive,
                fetched: true,
                loading: false,
                error: null
            };

        case actions.SAKSLISTEHEADER_KREDITOR_SELECTED:
            return {
                ...state,
                kreditorId: action.payload.kreditorId,
                kreditorName: action.payload.kreditorName,
                fetched: true,
                loading: false,
                error: null
            };
        
        case actions.SAKSLISTEHEADER_ERROR:
            return {
                ...state,
                loading: false,
                fetched: true,
                error: action.data
            }

        default:
            return state;
    }
}
