import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosTokenInstance from "../../../Instances/axiosTokenInstance";
import { isValidEmail, isValidCellPhone } from '../../../util/validering/valideringsRutiner'
import LocaleContext from "../../../state/contexts/LocaleContext";
import { createIntl, createIntlCache } from "react-intl";
import { messages } from './AvdragsordModal.i18n'
import _ from 'lodash'

export const useAvdragsordModal = (setErrorMessage, setMessage, setShowSpinner) => {
    
    const locale = useContext(LocaleContext);
    const localeMessages = messages[locale]

    const cache = createIntlCache()

    // Create the `intl` object
    const intl = createIntl(
        {
            // Locale of the application
            locale: locale,
            // Locale of the fallback defaultMessage
            defaultLocale: 'nb',
            messages: localeMessages,
        },
        cache
    )
    
    const showToasts = true;

    // Styring av modale
    const [modalVisible, setModalVisible] = useState(false)
    
    // Parametre til rest kallene
    const [creditorNo, setCreditorNo] = useState('')
    const [caseNo, setCaseNo] = useState('')

    //const totalBalance = Math.ceil(props.balanceTotal)

    // Disse settes initielt fra sak
    const [totalBalance, setTotalBalance] = useState(0)
    const [epost, setEpost] = useState('')

    // Disse må nullstilles hver gang
    const [terminLengde, setTerminLengde] = useState('15')
    const [antAvdrag, setAntAvdrag] = useState('2')
    const [mobEpost, setMobEpost] = useState('epost')
    const [terminBelop, setTerminBelop] = useState(0)
    const [AVOErrorMessage, setAVOErrorMessage] = useState('')
    const [debtorEmail, setDebtorEmail] = useState('')
    const [skyldnerEpost, setSkyldnerEpost] = useState('')
    const [caseInfo, setCaseInfo] = useState('')
           
    // RESETS MODAL BOXES AND STATE
    const resetModal = () => {
        
        setErrorMessage('')
        setMessage('')
                                
        setShowSpinner(false)
    }
    
    useEffect(() => {        
        resetModal()
        
    }, [creditorNo, caseNo])

    // TRIGGER FOR MODAL SEQUENCE
    const showError = (errorMessage) => {
        (showToasts && toast.error(errorMessage, {autoClose: false}))
        setErrorMessage(errorMessage)
        console.error('TrekkSakModal: ', errorMessage)
    }

    const showMessage = (message) => {
        (showToasts && toast.info(message))
        setMessage(message)       
    }

    const showDialog = (creditorNo, caseNo, balanceTotal, pDebtorEmail, pCaseInfo) => {
        setErrorMessage('')
        setMessage('')

        setCreditorNo(creditorNo)
        setCaseNo(caseNo)
        setEpost(pDebtorEmail)
        setMobEpost('epost')
        setTotalBalance(balanceTotal)

        setDebtorEmail(pDebtorEmail)
        setSkyldnerEpost(pDebtorEmail)
                     
        setTerminLengde('15')
        setAntAvdrag('2')
        setTerminBelop(getMinBel2(balanceTotal))

        setCaseInfo(pCaseInfo)
        
        setModalVisible(true)
    }

    const cancelHandler = e => {
        setModalVisible(false)       
        setShowSpinner(false)
    } 

    const getErrorMessage = (err) => {
        if (_.has(err, 'response.data.title')) {
            return err.response.data.title
        }
        else if (_.has(err, 'response.data'))
        {
            return err.response.data
        }
        else if (_.has(err, 'response.statusText')) {
            return err.response.statusText
        }
        else
        {
            return err.message.toString()
        }
    }

    const getMinBel = (saldo) => {
        let saldoAsFloat1 = parseFloat(saldo)
        let saldoAsFloat2 = Math.ceil(saldoAsFloat1)
        let MinBel1 = saldoAsFloat2 / 4
        return Math.ceil(MinBel1)
    }
    
    const getMinBel2 = (saldo) => {
        let saldoAsFloat1 = parseFloat(saldo)
        let saldoAsFloat2 = Math.ceil(saldoAsFloat1)
        let MinBel1 = saldoAsFloat2 / 2
        return Math.ceil(MinBel1)
    }

    
    const digits_only = string => [...string.toString()].every(c => '0123456789'.includes(c));

    const allFieldsHasValues = () => {        
        return (terminBelop && terminLengde && antAvdrag) ? '' : 'disabled'        
    }

    const getValidationErrorMessages = () => {

        let result = ""

        if (!antAvdrag) {
            result = result + 'antall avdrag ikke valgt'
        }
        if (!digits_only(terminBelop))
            result = result + localeMessages['AO1M_terminbelHeleKr']
        else {
            // Beløp må være mellom foreslått beløp og rest på saken
            let cbBelop = parseInt(terminBelop)
            let cbMax = totalBalance
            let numAvdrag = parseInt(antAvdrag)
            let minimumbel = parseInt(getMinBel(totalBalance))

            if (cbBelop < minimumbel) {
                result = result + intl.formatMessage({ id: 'terminMindreEnn', description: '', defaultMessage: localeMessages['AO1M_terminMindreEnn'] }, { minbel: minimumbel })
            }

            if ((cbBelop > cbMax) || (cbBelop < minimumbel)) {
                result = result + intl.formatMessage({ id: 'terminMerEnnMax', description: '', defaultMessage: localeMessages['AO1M_terminMerEnnMax'] }, { maxbel: cbMax })
            }
        }

        if ((!isValidEmail(epost)) && (mobEpost === 'epost')) {
            result = result + localeMessages['AO1M_epostNotValid']
        }

        if ((!isValidCellPhone(epost)) && (mobEpost === 'mobil')) {
            result = result + localeMessages['AO1M_mobilNotValid']
        }

        return result
    }
    
    const antAvdragChanged = (tAntAvrd) => {
        setAntAvdrag(tAntAvrd)
        let cbMax = totalBalance
        let numAvdrag = parseInt(tAntAvrd)
        setTerminBelop( Math.ceil(cbMax / numAvdrag))
    }

    const setMobEpostChanged = (val) => {        
        setMobEpost(val)
        if (val === 'epost') {
            setEpost(debtorEmail)
        } else {
            setEpost('')
        }
    }

    const okHandler = (value) => {

        let errorMessage = getValidationErrorMessages()
        setAVOErrorMessage(errorMessage)

        if (errorMessage === '') {            
            addInstallment(epost, mobEpost, _.parseInt(terminLengde), terminBelop)   
        }
    }

    const addInstallment = () => {

        const toastAvdrOrdnOk = localeMessages['toastAvdrOrdnOk']
        const toastAvdrOrdnFailed = localeMessages['toastAvdrOrdnFailed']

        setShowSpinner(true)

        var startDate = new Date()
        startDate.setDate(startDate.getDate() + _.parseInt(terminLengde)) // userData.terminLengde)
        let startDateAsJsonDate = startDate.toJSON()

        let _epost = epost
        let _mobil = ''
        if (mobEpost === 'mobil') {
            _epost = ''
            _mobil = epost
        }

        let AddInstallmentModel = {
            'CaseNo': caseNo,
            'CreditorNo': creditorNo,
            'DepartmentId': 0,
            'StartDate': startDateAsJsonDate,
            'Period': terminLengde.toString(), 
            'Amount': _.parseInt(terminBelop),
            'DebtorEmail': _epost,
            'DebtorMobile': _mobil
        }
        
        //console.log('Før axios')
        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/sak/OpprettAvdragsOrdning`,
                data: AddInstallmentModel
            })
            .then((result) => {
                //console.log('etter axios, then')
                console.log(`/api/sak/OpprettAvdragsOrdning, ${creditorNo}/${caseNo} returnerte:`, result)
            
                setModalVisible(false)
                setShowSpinner(false)
            
                showMessage(`${toastAvdrOrdnOk}  (saksnr: ${caseNo})`)                                
            })
            .catch((err) => {
                //console.log('etter axios')
                setShowSpinner(false)
                setModalVisible(false)
                showError(toastAvdrOrdnFailed + ' ' + getErrorMessage(err))
            })
    }
    
    return {
        modalVisible, showDialog, okHandler, cancelHandler, antAvdrag, antAvdragChanged,
        terminLengde, setTerminLengde, terminBelop, setTerminBelop, epost, setEpost, caseInfo,
        mobEpost, setMobEpostChanged, allFieldsHasValues, totalBalance, AVOErrorMessage, localeMessages
    }
}
