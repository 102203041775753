import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { StyledModal, StyledModalBody, StyledModalFooter } from '../../../Styling/styledModal/styledModals';
import { BfButtonBlue } from '../../../Styling/styledForm/styledBlueForm'

export const ConfirmActionModal = ({ show, onHide, okHandler, cancelHandler, orgOrPersonNr, header, messagePerson, messageOrg, okCaption, cancelCaption, }) => {

  const handleOkClick = () => {
      okHandler();
  };

  const handleCancelClick = () => {
    cancelHandler();
  };

  const nrTekst = orgOrPersonNr.length === 9 ? 'Utfør kredittvurdering av orgnr ' : orgOrPersonNr.length === 11 ? 'Utfør kredittvurdering av person ' : null;

  return (
    <StyledModal show={show} onHide={onHide} centered size="lg">
      <StyledModalBody style={{ width: '100%' }}>
        {(<h4><b>{header}</b></h4>)}
        {(<p>{nrTekst} <b>{orgOrPersonNr}</b> ?</p>)}
        {(<p>{orgOrPersonNr.length === 9 ? messageOrg : orgOrPersonNr.length === 11 ? messagePerson : null}</p>)}    
      </StyledModalBody>
      <StyledModalFooter style={{ width: '100%' }}>
        <div style={{ display: 'flex', width: '100%', paddingBottom: '10px' }}>
          {(<BfButtonBlue variant="secondary" onClick={handleOkClick}>
            {okCaption}
          </BfButtonBlue>)}
          &nbsp;
          &nbsp;
          <BfButtonBlue variant="primary" onClick={handleCancelClick}>
            {cancelCaption}
          </BfButtonBlue>
        </div>
      </StyledModalFooter>
    </StyledModal>
  );
};

