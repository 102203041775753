import React, {useContext, useEffect} from 'react';
import { Redirect } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { resetContainer } from '../../reduxStore/newCase/newCaseActions';

const NewCaseRedirect = (props) => {

    const dispatch = useDispatch()
   
    useEffect(() => {        
        //console.log('dispatch')           
        dispatch(resetContainer())

        // Ideelt burde man her sjekket om man har kun en kreditor i dropdown og i så fall satt den.
    });

    return (
        <Redirect to={'/CaseNew'} />
    )

}

export default NewCaseRedirect

