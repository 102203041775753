import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

/*
To use:
   import { useSelector, useDispatch } from 'react-redux'
   import { ..exported dispatchers... } from '../../reduxStore/...';

   // Angi ønsket redux store du vil hente state fra
   const state = useSelector(state => state.oversikt)

   // Få tak i redux dispatheren som du evt kan gi eget navn
   const _dispatch = useDispatch()
    ..

   // Kjør kall for å endre state
   _dispatch(setSomeShit(shitparam1))

*/

export const actions = {
    SAKSLISTE_LOADING: 'SAKSLISTE_LOADING',
    SAKSLISTE_ERROR: 'SAKSLISTE_ERROR',
    SAKSLISTE_DATA_FETCHED: 'SAKSLISTE_DATA_FETCHED',
    SAKSLISTE_DATA_FILTERED: 'SAKSLISTE_DATA_FILTERED',
    SAKSLISTE_DATA_UNFILTERED: 'SAKSLISTE_DATA_UNFILTERED',
}

export const actionExample = () => {
    return dispatch => {

    }
}

// TODO gjør global?
export const initialState = {
    caseList: [],
    caseFiltered: [],
    
    filtered: false,
    
    numberOfItems: 0,
    sumOriginalClaim: 0,
    sumInnbetHs: 0,
    sumRestHs: 0,
    sumRemainBalance: 0,

    filtredNumberOfItems: 0,
    filtredSumOriginalClaim: 0,
    filteredSumInnbetHs: 0,
    filteredSumRestHs: 0,
    filtredSumRemainBalance: 0,
    
    fetched: false,
    loading: false,
    error: ''
}


export const getCaseList = (aktive, showToasts, messages) => {
    return dispatch => {
        getCaseListDispatcher(dispatch, aktive, showToasts, messages)
    }
}

export const filterCases = (creditorNumber, filter, state) => {
    return dispatch => {
        filterCaselistDispatcher(dispatch, creditorNumber, filter, state)
    }
}

const getCaseListDispatcher = (dispatch, aktive, showToasts, messages) => {

    let caseListFound = 'Hentet ${poster} saker fra server (redux).'
    let caseListNone = 'Ingen saker funnet'
    let caseListErr = 'En feil oppstod under henting av saker'

    if (messages !== null) {
        caseListFound = messages['caseListFound'] 
        caseListNone = messages['caseListNone']
        caseListErr = messages['caseListErr']
    }

    dispatch({ type: actions.SAKSLISTE_LOADING, data: null })

    //NB tom creditorliste kan crashe roberts server
    // DepartmentId blir satt på server basert på hvem du er logget på som
    const getCasesParams = {
        "fromDate": null,
        "toDate": null,
        "creditorList": null,
        "caseFilter": aktive,
        "departmentId": 0
    }

    axiosTokenInstance
        ({
            method: 'POST',
            url: `/api/Sak/getCasesV3`,
            data: getCasesParams,
            timeout: 120000
        })
        .then((result) => {
            if (result.status !== 200) {

                (showToasts && toast.error(caseListErr, {autoClose: false}))
                dispatch({ type: actions.SAKSLISTE_DATA_FETCHED, payload: initialState })
                console.log('dispatchGetCaseListGlobal_ok!200', caseListErr)

            }
            else if (result.data.pcaseList2.length > 0) {

                (showToasts && toast.info(caseListFound.replace('${poster}', result.data.pcaseList2.length)))

                let numberOfItems = result.data.pcaseList2.length
                let sumOriginalClaim = 0.0
                let sumRemainBalance = 0.0
                let sumInnbetHs = 0.0
                let sumRestHs = 0.0
                let i;
                for (i = 0; i < result.data.pcaseList2.length; i++) {
                    sumOriginalClaim += result.data.pcaseList2[i].originalClaim
                    sumInnbetHs += result.data.pcaseList2[i].paidClaim * -1
                    sumRestHs += result.data.pcaseList2[i].restHS
                    sumRemainBalance += result.data.pcaseList2[i].totalBalance
                }

                dispatch({
                    type: actions.SAKSLISTE_DATA_FETCHED,
                    payload: {
                        caseList: result.data.pcaseList2,
                        numberOfItems: numberOfItems,
                        sumOriginalClaim: sumOriginalClaim,
                        sumRemainBalance: sumRemainBalance,
                        sumInnbetHs: sumInnbetHs,
                        sumRestHs: sumRestHs
                    }
                })            

            } else {

                (showToasts && toast.warning(caseListNone))
                dispatch({ type: actions.SAKSLISTE_DATA_FETCHED, payload: initialState })                
            }
        })
        .catch((err) => {
            if (_.has(err, 'response.data')) {
                toast.error(caseListErr, {autoClose: false})
                dispatch({ type: actions.SAKSLISTE_ERROR, payload: caseListNone })
            } else {
                toast.error(caseListErr + ' ' + err.message, {autoClose: false})
                dispatch({ type: actions.SAKSLISTE_ERROR, payload: caseListNone + ' ' + err.message })
            }
            //console.log('dispatchGetCaseListGlobal', err)
        })

}


const filterCaselistDispatcher = (dispatch, creditorNumber, filter, state) => {

    let numberOfItems = 0
    let sumOriginalClaim = 0.0
    let sumRemainBalance = 0.0
    let sumInnbetHs = 0.0
    let sumRestHs = 0.0
    
    let filteredCases = []
    const upperFilter = filter.toUpperCase()
        
    if (state.caseList.length > 0) {
        
        dispatch({ type: actions.SAKSLISTE_LOADING, data: null })

        // Filtrer på både kreditor og filterstreng
        if ((creditorNumber !== '') && (filter)) {
            filteredCases = state.caseList.filter((x) => {
                return ((x.crNo === creditorNumber) && (x.searchString.indexOf(upperFilter) !== -1))
            })
        }
        // Filtrer på kreditornummer
        else if (creditorNumber !== '') {
            filteredCases = state.caseList.filter((x) => {
                return (x.crNo === creditorNumber)
            })
        // Filtrer på streng
        } else if (filter) {
            filteredCases = state.caseList.filter((x) => {
                // includes virker ikke alltid
                return (x.searchString.indexOf(upperFilter) !== -1)
            })
        }

        if (filteredCases.length === 0) {

            // Vi har valgt dropdown verdi eller skrevet i filter teksten
            if (creditorNumber || filter) {
                
                // Vi har valgt kreditor, eller filter, men listen er tom
                dispatch({
                    type: actions.SAKSLISTE_DATA_FILTERED,
                    payload: {
                        caseList: filteredCases,
                        filtredNumberOfItems: 0,
                        filtredSumOriginalClaim: 0,
                        filtredSumRemainBalance: 0.0,
                        filteredSumInnbetHs: 0.0,
                        filteredSumRestHs: 0.0
                    }
                })

            } else {
                // Case der vi ikke har skrevet i filtertekst eller valgt i dropdown
                // Da er vi på defaultcase der vi viser alle
                dispatch({ type: actions.SAKSLISTE_DATA_UNFILTERED, data: null })
            }

        } else {

            // Summer           
            let i;
            numberOfItems = filteredCases.length
            for (i = 0; i < filteredCases.length; i++) {
                sumOriginalClaim += filteredCases[i].originalClaim
                sumInnbetHs += filteredCases[i].paidClaim * -1
                sumRestHs += filteredCases[i].restHS
                sumRemainBalance += filteredCases[i].totalBalance
            }

            dispatch({
                type: actions.SAKSLISTE_DATA_FILTERED,
                payload: {
                    caseList: filteredCases,
                    filtredNumberOfItems: numberOfItems,
                    filtredSumOriginalClaim: sumOriginalClaim,
                    filtredSumRemainBalance: sumRemainBalance,
                    filteredSumInnbetHs: sumInnbetHs,
                    filteredSumRestHs: sumRestHs
                }
            })
        }
    }           
}