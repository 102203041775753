import React, { useState } from 'react';
import { IntlProvider } from 'react-intl'
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './ProfilContainer.i18n'
import { ArticleArea } from '../../Styling/Layout/ContentArea'
import { ProfilForm } from './ProfilForm'
import { useProfil } from './useProfil'
import { Spinner } from '../../components/spinner/Spinner'
import { Alert, Comtainer, Container } from "react-bootstrap";
import MFAInformation from './MFAInformation';

const ProfilContainer = (props) => {

    //console.log('Profil')
    let showCheckProfile = props.history.location.state?.showcheck
    console.log('ShowCheck?: ', showCheckProfile)
    const [showCheck, setShowCheck] = useState(showCheckProfile)

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const {
        handleChange, formState,
        hasErrors, errorMessages, extraErrors,
        submitForm, isLoading, errorMessage,
        twoFactorEnabled
    } = useProfil(localeMessages)

    if (isLoading) {
        return (
            <Spinner></Spinner>
        )
    }

    return (
      
        <IntlProvider locale={locale} messages={messages[locale]}>
            
            <Container>

                {!twoFactorEnabled && <MFAInformation />}

                {/* 
                {showCheck ? <Alert variant='info'>{localeMessages.sjekkprofilen}</Alert> : null}
                */}

                {errorMessage ? <Alert variant='danger'>{errorMessage}</Alert> : null}

                <ArticleArea>
                    <h1>{localeMessages.header}</h1>
                    {/*
                    <p>{localeMessages.beskrivelse}</p>
                     */}
                </ArticleArea>
            </Container>

            <ProfilForm
                handleChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                localeMessages={localeMessages}
                formState={formState}                
                hasErrors={hasErrors}                
                submitForm={submitForm}
                twoFactorEnabled={twoFactorEnabled}
            />
        </IntlProvider>
    )
}

export default ProfilContainer 