import { getValidatePhoneIntl } from "../../util/validering/valideringsRutiner"

const getIsRequired = (errorMessage) => {

    const isRequired = (val, name, formState) => {

        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getValidatePhone = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {
        let _val = val.replace(/\s/g, '') 

        // Space tom streng
        if (!_val) {
            return ''
        }

        // Må ha korrekt antall siffer
        if (_val.length === length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}


const getMinLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {

        if (val.length > length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getNewPassWordRequired = (errorMessage) => {

    const newPassWordRequired = (val, name, formState) => {

        //if ((formState.newUser) && (!val)) {
        //    return errorMessage
        //}

        if ((formState.Passord) && (!val)) {
            return errorMessage
        }
        return ''
    }

    return newPassWordRequired
}

const getCheckEqualPasswords = (errorMessage) => {

    const checkequalPasswords = (val, name, formState) => {
        //console.log('Password1', val)
        //console.log('Password2', formState.newPassword1)

        if (val !== formState.Passord1) {
            return errorMessage
        }
        return ''
    }

    return checkequalPasswords
}

const getValidatePassword = (errorMessage) => {

    const validatePassword = (val, name, formState) => {

        // (?=.*?[#?!@$%^&*-])
        if (!val) {
            return ''
        }
            

        let errors = false;

        // 8 Tegn
        if (val.length < 8) {
            errors = true;
        }

        // minst en bokstav
        if (val.search(/[a-z]/) < 0) {
            errors = true
        }

        // minst en stor bokstav
        if (val.search(/[A-Z]/) < 0) {
            errors = true
        }

        // Minst ett tall
        if (val.search(/[0-9]/) < 0) {
            errors = true
        }

        // Minst ett spesialtegn
        if (val.search(/[#?!@$%^&*-]/) < 0) {
            errors = true
        }

        if (errors) {
            return errorMessage
        }
        return '';
    }

    return validatePassword
}

const getValidateEmail = (errorMessage) => {

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // const re2 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    // https://www.w3resource.com/javascript/form/email-validation.php
    const validateEmail = (val, name, formState) => {
        if (re.test(val)) {
            return ''
        }

        return errorMessage
    }

    return validateEmail
}


export const profileFormValidators = (messages) => {
   
    const brukeridIsRequired = getIsRequired(messages['EpostRequired'])
    const IsRequired = getIsRequired(messages['IsRequired'])

    const fullNameisRequired = getIsRequired(messages['NavnRequired'])
    
    const passWordRequired = getIsRequired(messages['PassordRequired'])

    const passWord1Required = getNewPassWordRequired(messages['Passord1Required'])
    const validatePassword = getValidatePassword(messages['validatePassword'])
    const validateEmail = getValidateEmail(messages['validateEmail'])
    const checkEqualPasswords = getCheckEqualPasswords(messages['checkEqualPasswords'])
    const minLength5 = getMinLenght(messages['minLength5'], 5)
    const minLength8 = getMinLenght(messages['minLength8'], 8)
    const validatePhone = getValidatePhoneIntl(messages['GyldigMobil'])

    const formValidators = {
        Navn: [
            fullNameisRequired,
            minLength5
        ],
        Epost: [
            brukeridIsRequired,
            validateEmail
        ],
        PhoneNumber: [
            validatePhone,
        ],
        Passord: [
            //passWordRequired,            
        ],
        Passord1: [
            //passWord1Required,
            validatePassword
        ],
        Passord2: [
            checkEqualPasswords,
        ]        
    }

    return formValidators
}


