import styled from 'styled-components'
import colorConfig from '../../colorConfig'

const FooterArea = styled.div`
    padding: 37px 50px 15px 50px;    
    color: #be9695;
    background-color: ${colorConfig.header_bg};
    min-height: 300px;
    font-family: 'Fellix', sans-serif;
    font-size: 20px;


    @media print { 
      display: none      
    }
`
export default FooterArea