import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash';
import LocaleContext from '../../../state/contexts/LocaleContext'
import {
    IntlProvider,
    //FormattedMessage,
    FormattedDate, FormattedNumber
} from 'react-intl'
import { messages } from './caseListTable.i18n'
import { Container, Col, Badge } from "react-bootstrap";
import { BfRowForm, BfButtonBlue, BfButtonBlueToggle, BfCheckboxContainer, BfCheckboxInput, BfCheckboxLabel } from '../../../Styling/styledForm/styledBlueForm'
import { StyledKfTable, StyledNavLink, StyledPropotionalNavLink } from './../../../Styling/styledTable/StyledTable';
import CaseListToolTip from './CaseListTooltip'
import { getCredorNoFromSearchString, getDebitorNoFromSearchString } from '../caseListUtils';
import styled from 'styled-components';
import { DisplayTextOnHover } from '../../../components/fullTextOnHover/HoverBox';
import { TextRight } from 'react-bootstrap-icons';
import { keyframes } from "styled-components";
import { array } from 'prop-types';

const TableBadge = styled.span`
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    width: 23px;
    height: 23px;
    border: 1px solid #0d6efd;
    font-size: 15px;
    color: navy;
`
const breatheAnimation = keyframes`
        0% { transform: translateX(0) }
        25% { transform: translateX(4px) }
        50% { transform: translateX(-4px) }
        75% { transform: translateX(4px) }
        100% { transform: translateX(0) }
        `;

const StyledTh = styled.th`
        animation-name: ${breatheAnimation};
        animation-duration: 0.5s;
        animation-iteration-count: 1;
    `

/*
 Legg inne et felt for filtrering av tabellen

*/
const CaseListTable = (props) => {

    //const casesFilteredByStatus = props.caseList

    const locale = useContext(LocaleContext)
    const localMessages = messages[locale]

    // *******************************
    // Drag and drop
    const originalTableOrder = [
        'regCloseDate',
        'caseNumber',
        'debtorName',
        'crNo',
        'creditorName',
        'caseReference',
        'product',
        'lastNote',
        'originalClaim',
        'paidClaim',
        'restHS',
        'totalBalance',
        'meterPointId',
        'buttons'
    ]

    const [colOrder, setColOrder] = useState(originalTableOrder)
    const [isModified, setIsModified] = useState(false);
    const [showMeterPointId, setShowMeterPointId] = useState(false);

    const resetColOrder = () => {
        setColOrder(originalTableOrder)
        saveColSettings(originalTableOrder)
        setIsModified(false);
    }
    const [dragOver, setDragOver] = useState("");
    const [fromCol, setFromCol] = useState();

    const handleDragStart = e => {
        const { id } = e.target;
        console.log('handleDragStart: id:', id)
        setFromCol(id)
    };

    const handleDragOver = e => e.preventDefault();
    const handleDragEnter = e => {
        const { id } = e.target;
        console.log('handleDragEnter', id)
        setDragOver(id);
    };

    const handleDelCol = e => {
        console.log('remove col: ', fromCol)
        const tempCols = [...colOrder];
        tempCols.splice(fromCol, 1)
        setColOrder(tempCols);
        setDragOver("");
        setFromCol(null)
        saveColSettings(tempCols)
    }

    const delColByName = (names) => {
        console.log('remove cols: ', names);
        const tempCols = [...colOrder];

        names.forEach(name => {
            let fromCol = tempCols.indexOf(name);
            if (fromCol !== -1) {
                tempCols.splice(fromCol, 1);
            }
        });

        setColOrder(tempCols);
        setDragOver("");
        setFromCol(null);
        saveColSettings(tempCols);
    };

    const hideSelectedColumns = () => {
        delColByName(selectedColumns);
        setSelectedColumns([]);
        setIsModified(true);
    };

    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };


    const handleOnDrop = e => {
        const { id } = e.target;
        console.log('handleOnDrop', id, fromCol)
        let tempCols = array_move(colOrder, fromCol, id)
        setColOrder(tempCols);
        saveColSettings(tempCols)
        setFromCol(null)
        setDragOver("");
        setIsModified(true);
    };

    const saveColSettings = (cols) => {
        localStorage.setItem("caseListCols", JSON.stringify(cols));
    }

    // Sjekker om originalTableOrder har blitt endret, enten ved å skjule eller endre posisjon på kolonne(r)
    // Slik at Tilbakestill kolonner knappen er synlig om bruker logger av og på
    const checkIfModified = (arr1, arr2) => {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    };

    useEffect(() => {
        var savedColorder = JSON.parse(localStorage.getItem("caseListCols"));
        if (savedColorder) {
            setColOrder(savedColorder)
            if (!checkIfModified(originalTableOrder, savedColorder)) {
                setIsModified(true);
            }
        }
    }, [])

    const [isEditing, setIsEditing] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
        setSelectedColumns([]);
        setFromCol(null)
    };

    const handleCheckboxChange = (colname) => {
        setSelectedColumns(prevState =>
            prevState.includes(colname) ? prevState.filter(col => col !== colname) : [...prevState, colname]
        );
    };

    const BfCheckbox = ({ label, value, onChange }) => {
        return (
            <BfCheckboxContainer>
                <BfCheckboxInput checked={value} onChange={onChange} />
                <BfCheckboxLabel>{label}</BfCheckboxLabel>
            </BfCheckboxContainer>
        )
    }

    // Drag and drop
    // *******************************

    const datoHeader = () => {
        let result = localMessages['allDate']
        if (props.aktive === 'ACTIVE') {
            result = localMessages['registerDate']
        } else if (props.aktive === 'CLOSED') {
            result = localMessages['ikkeAktivDate']
        }
        return result;

    }

    const DelIcon = (props) => {
        const sortField = props.colname
        const { isEditing, selectedColumns, onCheckboxChange } = props;
        const isChecked = selectedColumns.includes(sortField);
        return (
            isEditing ? <BfCheckbox value={isChecked} onChange={onCheckboxChange} /> : null
        )
    }



    const ThExt = (props) => {
        const i = props.i
        const headerCaption = props.headerCaption
        const sortField = props.colName
        const sortType = props.sortType
        const t1IndicaterIcon = props.t1IndicaterIcon
        const t1ChangeSortField = props.t1ChangeSortField

        let textAlign = 'left'
        let paddingLeft = '0px'
        if (i.toString() === fromCol) {
            textAlign = 'center'
            paddingLeft = '0px'
        }
        else if (sortType === 'num' && sortField !== 'meterPointId') {
            textAlign = 'right'
            paddingLeft = '0px'
        }
        else if (sortField === 'meterPointId') {
            textAlign = 'left'
            paddingLeft = '40px'
        }
            
        let bgColor = '#f5f2eb'
        if (i.toString() === fromCol)
            bgColor = '#C8AD7F'

        let _style = {
            textAlign: textAlign,
            backgroundColor: bgColor,
            borderRadius: '4px',
            paddingLeft: paddingLeft,
        }

        if (isEditing) {
            return (
                <StyledTh
                    id={i}
                    draggable
                    onClick={handleDragStart}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDrop={handleOnDrop}
                    onDragEnter={handleDragEnter}
                    style={_style}
                >
                    <span className="fa fa-caret-left" />&nbsp;&nbsp;
                    {headerCaption}
                    {props.children}
                    &nbsp;&nbsp;<span className="fa fa-caret-right" />
                    &nbsp;
                    <DelIcon
                        key={sortField}
                        isEditing={isEditing}
                        colname={sortField}
                        selectedColumns={selectedColumns}
                        onCheckboxChange={() => handleCheckboxChange(sortField)}
                    />
                </StyledTh>
            );
        } else {
            return (
                <th
                    id={i}
                    onClick={() => t1ChangeSortField(sortField, sortType)}
                    style={_style}
                >
                    {headerCaption}
                    {props.children}
                    {t1IndicaterIcon(sortField)}
                    &nbsp;
                    <DelIcon
                        key={sortField}
                        isEditing={isEditing}
                        colname={sortField}
                        selectedColumns={selectedColumns}
                        onCheckboxChange={() => handleCheckboxChange(sortField)}
                    />
                </th>
            );
        }
    }

    const PrintHeaderItem2 = props => {
        var colname = props.colName
        var i = props.i
        var t1IndicaterIcon = props.t1IndicaterIcon
        var t1ChangeSortField = props.t1ChangeSortField

        if (colname === 'regCloseDate') return (
            <ThExt i={i} headerCaption={datoHeader()} colName={colname} sortType={'datetime'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'caseNumber') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'debtorName') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'crNo') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'creditorName') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'caseReference') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'refnr') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'product') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'lastNote') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'originalClaim') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'num'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'paidClaim') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'num'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'restHS') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'num'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'totalBalance') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'num'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'meterPointId' && showMeterPointId) return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'num'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else if (colname === 'buttons') return (
            <ThExt i={i} headerCaption={localMessages[colname]} colName={colname} sortType={'string'} t1IndicaterIcon={t1IndicaterIcon} t1ChangeSortField={t1ChangeSortField} />
        )
        else {
            return <></>
        }

    }

    //https://codesandbox.io/s/html-table-column-drag-n-drop-reorder-with-react-hooks-uvzpi?file=/src/index.js

    /*
    const PrintHeaderItem = (props) => {
        var colname = props.colName
        var i = props.i
        var t1IndicaterIcon = props.t1IndicaterIcon
        var t1ChangeSortField = props.t1ChangeSortField
        const showBar = () => {
            let result = (i.toString() === fromCol)
            return result
        }

        if (colname === 'regCloseDate') return (
            <th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
                onClick={handleDragStart}
                onDoubleClick={() => t1ChangeSortField('regCloseDate', 'datetime')}>
                <span onClick={() => delColByName('regCloseDate')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
                {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}{datoHeader()}{t1IndicaterIcon('regCloseDate')}
            </th>)
        else if (colname === 'caseNumber') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}
            onDoubleClick={() => t1ChangeSortField('caseNumber', 'string')}>
            <span onClick={() => delColByName('caseNumber')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['caseNumber']}{t1IndicaterIcon('caseNumber')}</th>)
        else if (colname === 'debtorName') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}
            onDoubleClick={() => t1ChangeSortField('debtorName', 'string')}>
            <span onClick={() => delColByName('debtorName')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['debtorName']}{t1IndicaterIcon('debtorName')}</th>)
        else if (colname === 'crNo') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}
            onDoubleClick={() => t1ChangeSortField('crNo', 'string')}>
            <span onClick={() => delColByName('crNo')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['crNo']}{t1IndicaterIcon('crNo')}</th>)
        else if (colname === 'creditorName') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}
            onDoubleClick={() => t1ChangeSortField('creditorName', 'string')}>
            <span onClick={() => delColByName('creditorName')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;            
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['creditorName']}{t1IndicaterIcon('creditorName')}</th>)
        else if (colname === 'caseReference') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}
            onDoubleClick={() => t1ChangeSortField('caseReference', 'string')}>
            <span onClick={() => delColByName('caseReference')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['refnr']}{t1IndicaterIcon('caseReference')}</th>)
        else if (colname === 'product') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}
            onDoubleClick={() => t1ChangeSortField('product', 'string')}>
            <span onClick={() => delColByName('product')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['product']}{t1IndicaterIcon('product')}</th>)
        else if (colname === 'lastNote') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}>
            <span onClick={() => delColByName('lastNote')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}{localMessages['lastNote']}</th>)
        else if (colname === 'originalClaim') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}
            style={{ textAlign: 'right' }} onDoubleClick={() => t1ChangeSortField('originalClaim', 'num')}>
            <span onClick={() => delColByName('originalClaim')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['originalClaim']}{t1IndicaterIcon('originalClaim')}</th>)
        else if (colname === 'paidClaim') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}
            style={{ textAlign: 'right' }} onDoubleClick={() => t1ChangeSortField('paidClaim', 'num')}>
            <span onClick={() => delColByName('regCloseDate')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['innbetHs']}{t1IndicaterIcon('paidClaim')}</th>)
        else if (colname === 'restHS') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            onClick={handleDragStart}
            style={{ textAlign: 'right' }} onDoubleClick={() => t1ChangeSortField('restHS', 'num')}>
            <span onClick={() => delColByName('restHS')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['restHs']}{t1IndicaterIcon('restHS')}</th>)
        else if (colname === 'totalBalance') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}
            style={{ textAlign: 'right' }}
            onClick={handleDragStart}
            onDoubleClick={() => t1ChangeSortField('totalBalance', 'num')} align="right">
            <span onClick={() => delColByName('totalBalance')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}
            {localMessages['remainBalance']}{t1IndicaterIcon('totalBalance')}</th>)
        else if (colname === 'buttons') return (<th id={i} draggable onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleOnDrop} onDragEnter={handleDragEnter} dragOver={colname === dragOver}>
            <span onClick={() => delColByName('buttons')} className="fa fa-trash" style={{ color: 'black', border: '2px', borderColor: '#C8AD7F', height: '28px', padding: '4px', borderRadius: '4px' }} />&nbsp;
            {(showBar()) && <span className="fa fa-arrows-h">&nbsp;</span>}&nbsp;
            
            </th>)
        else
            return (
                <></>
            )
    }
    */

    const printHeader = (t1IndicaterIcon, t1ChangeSortField) => {
        return (
            <thead>
                <tr>

                    {colOrder.map((colName, i) =>
                        <PrintHeaderItem2
                            colName={colName}
                            t1ChangeSortField={t1ChangeSortField}
                            t1IndicaterIcon={t1IndicaterIcon}
                            i={i}
                        />)
                    }
                </tr>
            </thead>
        )
    }

    const PrintDetailItem = (props) => {
        var colname = props.colName
        //var i = props.key
        var caseModel = props.caseModel
        var printRowIcons = props.printRowIcons
        var closedIndicator = props.closedIndicator
        var caseDetUri = props.caseDetUri
        //console.log('colname', colname)
        // checkForMeterPointId(caseModel);

        if (colname === 'regCloseDate') return (<td><FormattedDate value={caseModel.regCloseDate} day="2-digit" month="2-digit" year="numeric" />{closedIndicator}</td>)
        else if (colname === 'caseNumber') return (
            <td>
                <StyledPropotionalNavLink to={caseDetUri}>
                    {caseModel.caseNumber}
                </StyledPropotionalNavLink>
                &nbsp;&nbsp;
                {caseModel.isCaseCollection && caseModel.isMainCase &&
                    <CaseListToolTip id={'sa' + caseModel.caseNumber} caption="Dette er en samlesak">
                        <TableBadge>S</TableBadge>
                    </CaseListToolTip>
                }

                {caseModel.isCaseCollection && !caseModel.isMainCase &&
                    <CaseListToolTip id={'sa' + caseModel.caseNumber} caption="Dette er en undersak i en samlesak">
                        <TableBadge>U</TableBadge>
                    </CaseListToolTip>
                }
            </td>
        )
        else if (colname === 'debtorName') return (<td>{caseModel.debtorName}</td>)
        else if (colname === 'crNo') return (<td>{caseModel.crNo}</td>)
        else if (colname === 'creditorName') return (<td>{caseModel.creditorName}</td>)
        else if (colname === 'caseReference') return (<td>{caseModel.caseReference}</td>)
        else if (colname === 'product') return (<td>{caseModel.product}</td>)
        else if (colname === 'lastNote') return (<td>
            <CaseListToolTip id={'n' + caseModel.caseNumber} caption={caseModel.lastNote}>
                <span className="fa fa-commenting" />
            </CaseListToolTip>
        </td>)
        else if (colname === 'originalClaim') return (<td align="right">{<FormattedNumber
            value={caseModel.originalClaim}
            style='decimal'
            minimumFractionDigits={2}
        />}</td>)
        else if (colname === 'paidClaim') return (<td align="right">{<FormattedNumber
            value={caseModel.paidClaim}
            style='decimal'
            minimumFractionDigits={2}
        />}</td>)
        else if (colname === 'restHS') return (<td align="right">{<FormattedNumber
            value={caseModel.restHS}
            style='decimal'
            minimumFractionDigits={2}
        />}</td>)
        else if (colname === 'totalBalance') return (<td align="right">{<FormattedNumber
            value={caseModel.totalBalance}
            style='decimal'
            minimumFractionDigits={2}
        />}</td>)
        else if (colname === 'meterPointId' && showMeterPointId) return (<td align="center">{caseModel.meterPointId ?? ''}</td>)
        else if (colname === 'buttons') return (printRowIcons(caseModel))
        else
            return (
                <></>
            )
    }

    const checkForMeterPointId = (caseModels) => {
        const hasValidMeterPointId = caseModels.some(caseModel => 
            caseModel.meterPointId !== null && 
            caseModel.meterPointId !== '' && 
            caseModel.meterPointId !== undefined
        );
        if (!showMeterPointId) {
            setShowMeterPointId(hasValidMeterPointId);
        }
    };

    const printRow = (caseModel) => {
        // checkForMeterPointId(caseModel);
        let caseDetUri = '/CaseDetails/' + caseModel.crNo + '/' + caseModel.caseNumber

        let closedIndicator = ""
        if (caseModel.activeClosed === 'C') {
            closedIndicator = ' (Avsl)'
        }

        return (
            <tr key={caseModel.caseNumber} onClick={() => { setFromCol("") }}>

                {colOrder.map((colName, i) =>
                    <PrintDetailItem
                        colName={colName}
                        caseModel={caseModel}
                        printRowIcons={printRowIcons}
                        closedIndicator={closedIndicator}
                        caseDetUri={caseDetUri}
                        key={i}
                    />)
                }
            </tr>
        )
    }


    const printRowIcons = (caseModel) => {

        return (
            <td align="left">


                {caseModel.ops[0] === 'f' ? <span className="fa fa-times" style={{ color: 'LightGray' }} /> :
                    <CaseListToolTip id={'t' + caseModel.caseNumber} caption={'Trekk sak ' + caseModel.caseNumber}>
                        <span className="fa fa-times"
                            onClick={
                                () => {
                                    props.showTrekkSak(caseModel.crNo, caseModel.caseNumber)
                                }
                            } />
                    </CaseListToolTip>
                }
                &nbsp;
                &nbsp;

                {caseModel.ops[1] === 'f' ? <span className="fa fa-credit-card" style={{ color: 'LightGray' }} /> :
                    <CaseListToolTip id={'u' + caseModel.caseNumber} caption={'Utsett sak ' + caseModel.caseNumber}>
                        <span className="fa fa-credit-card"
                            onClick={
                                () => {
                                    props.showUtsettSak(caseModel.crNo, caseModel.caseNumber)

                                }
                            } />
                    </CaseListToolTip>
                }
                &nbsp;
                &nbsp;

                {caseModel.ops[2] === 'f' ? <span className="fa fa-calendar" style={{ color: 'LightGray' }} /> :
                    <CaseListToolTip id={'a' + caseModel.caseNumber} caption={'Lag avdragsordning ' + caseModel.caseNumber}>
                        <span className="fa fa-calendar"
                            onClick={
                                () => {
                                    props.showAvrdOrdn(caseModel.crNo, caseModel.caseNumber, caseModel.totalBalance, caseModel.debtorEmail, caseModel.caseNumber)
                                }
                            }
                        />
                    </CaseListToolTip>
                }
                &nbsp;
                &nbsp;

                {caseModel.ops[3] === 'f' ? <span className="fa fa-cloud-download" style={{ color: 'LightGray' }} /> :
                    <CaseListToolTip id={'f' + caseModel.caseNumber} caption={'Last opp kopi av faktura ' + caseModel.caseNumber}>
                        <span className="fa fa-cloud-download"
                            onClick={
                                () => {
                                    const debtorNo = getDebitorNoFromSearchString(caseModel.searchString)
                                    if (props.aktive !== 'false')
                                        props.showFaktUpload(caseModel.crNo, caseModel.caseNumber, debtorNo, caseModel.debtorName)
                                }
                            }
                        />
                    </CaseListToolTip>
                }
                &nbsp;

            </td>
        )
    }

    return (
        <>


            <IntlProvider locale={locale} messages={messages[locale]}>

                <Container fluid="true">

                    <BfRowForm>

                        <Col xs={12} md={12}>

                            <props.PaginationDropdown />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <BfButtonBlueToggle variant="primary" type="button" onClick={toggleEditMode} isActive={isEditing} >
                                {isEditing ? messages.nb['stopEditing'] : messages.nb['editColumns']}
                            </BfButtonBlueToggle> &nbsp;&nbsp;

                            {isModified && (<BfButtonBlue variant="primary" type="button" onClick={resetColOrder}>
                                {messages.nb['resetColumns']}
                            </BfButtonBlue>)} &nbsp;&nbsp;

                            {selectedColumns.length > 0 && (
                                <BfButtonBlue variant="primary" type="button" onClick={hideSelectedColumns}>
                                    {messages.nb['hideColumns']}
                                </BfButtonBlue>)}

                            <StyledKfTable>
                                {printHeader(props.t1IndicaterIcon, props.t1ChangeSortField)}

                                <tbody>
                                    <props.Paginate>
                                        {/* paginate forventer en funksjon */}
                                        {row => printRow(row)}
                                    </props.Paginate>
                                </tbody>
                            </StyledKfTable>
                            <br />
                            <props.PaginationIndex />
                            <br /><br />
                            <props.PaginationNav />

                        </Col>

                    </BfRowForm>

                </Container>

            </IntlProvider>
        </>
    )
}

export default React.memo(CaseListTable)