import { useContext, useEffect, useState } from "react";

export const useBekreftOnHold = (messages, onOk, confirmOnHoldMessageFunc, toggleOnHoldRow) => {

    const showToasts = true;

    const [dialogVisible, setDialogVisible] = useState(false)
    const [confirmMessageOnHold, setConfirmOnHoldMessage] = useState('')
    const [selectedStengelisteId, setSelectedStengelisteId] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState('');

    // Checkboxen blir "checked" når bruker trykker på sett på hold ikonet. 
    // Setter toggleOnHoldRow til false om bruker trykker på avbryt
    const cancelHandler = e => {
        setDialogVisible(false)
        toggleOnHoldRow(selectedIndex, false)       
    }

    // Sender id'en for kandidaten som skal bli satt på hold
    const okHandler = () => {
        onOk(selectedStengelisteId);
        setDialogVisible(false);
    }

    // Får og setter id'en for den aktuelle raden som er valgt
    const showPutOnHoldDialog = (stengelisteId, i) => {       
        setSelectedStengelisteId(stengelisteId)
        setSelectedIndex(i)
        setConfirmOnHoldMessage(confirmOnHoldMessageFunc())
        setDialogVisible(true)
    } 

    return {dialogVisible, showPutOnHoldDialog, cancelHandler, messages, confirmMessageOnHold, okHandler}

}