import React, { useEffect } from 'react';
import axiosTokenInstance from '../../Instances/axiosTokenInstance';
import _ from 'lodash'
import OversiktContainer from '../oversikt/OversiktContainer';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';



// Komponent som skal vise spinner, dersom den gamle nettkontor er veldig treg å svare
// I dag står man da bare med en hvit skjerm
export const SpinnerDnnLoginRedirect = (menuItem) => {
    useEffect(() => {
        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/DNN/GetURI`
            })
            .then((result) => {
                console.log(result)

                // Sender deg til Gammelt nettkontor vha endre window location
                window.location.assign(result.data)
            })
            .catch((err) => {
                if (_.has(err, 'response.data')) {
                    console.log(err)
                } else {
                    console.log(err)
                }
            })

    }, [])


    return (
        <>
            <h5>vennligst vent, du forlater nå den nye portalen og du overføres nå til gammelt nettkontor.</h5>
            <OverlaySpinner />
        </>
    )

}


// Etter at vi går i prod, finnes bare rapporter og vi kan derfor bruke denne til å logge på.
// Du kommer alltid til samme siden i gammelt nettkontor
// brukes i menu
export const dnnLoginAndRedirect = () => {

    axiosTokenInstance
        ({
            method: 'GET',
            url: `/api/DNN/GetURI`
        })
        .then((result) => {
            console.log(result)
            window.location.assign(result.data)
        })
        .catch((err) => {
            if (_.has(err, 'response.data')) {
                //setErrorMessage(err.response.data)
                console.log(err)
            } else {
                //setErrorMessage(err.message)
                console.log(err)
            }
            console.log(err)
            return (<OversiktContainer />)
        })

}

// Gammel metode, som krevde at man  var innlogget
export const dnnRedirect = (menuItem) => {

    axiosTokenInstance
        ({
            method: 'GET',
            url: `/api/DNN/GetMenuUri/${menuItem}`,
        })
        .then((result) => {
            console.log(result)
            window.location.assign(result.data)
        })
        .catch((err) => {
            if (_.has(err, 'response.data')) {
                //setErrorMessage(err.response.data)
                console.log(err)
            } else {
                //setErrorMessage(err.message)
                console.log(err)
            }

            console.log(err)
        })
   
}

//import { dnnRedirect } from '../containers/dnnSite/dnnFunctions'
//import { NavLink, Link } from 'react-router-dom';
//<NavLink to="/" onClick={() => dnnRedirect('Oversikt')}>{localeMessages['mOversikt']}</NavLink> 

export const dnnRedirectToSak = (CreditorNo, Caseno) => {

    axiosTokenInstance
        ({
            method: 'GET',
            url: `/api/DNN/GetSakURI/${CreditorNo}/${Caseno}`,
        })
        .then((result) => {
            console.log(result)
            window.location.assign(result.data)
        })
        .catch((err) => {
            if (_.has(err, 'response.data')) {
                //setErrorMessage(err.response.data)
                console.log(err)
            } else {
                //setErrorMessage(err.message)
                console.log(err)
            }

            console.log(err)
        })

}
