import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosTokenInstance from "../../../Instances/axiosTokenInstance";
import LocaleContext from "../../../state/contexts/LocaleContext";
import { messages } from './betalingModal.i18n'
import _ from 'lodash'
import { useSelector } from 'react-redux';


export const useBetalingModal = (setErrorMessage, setMessage, setShowSpinner) => {
    
    const locale = useContext(LocaleContext);
    const localeMessages = messages[locale]
    
    // Merk at vi har state, og trenger ikke ta inn parametre, siden denne kun vises fra saksdetaljer
    const state = useSelector(state => state.saksdetaljer)
    
    const showToasts = true;

    // Styring av modalt vindu
    const [dialogVisible, setDialogVisible] = useState(false)
    
    // Parametre til rest kallene
    const [creditorNo, setCreditorNo] = useState('')
    const [caseNo, setCaseNo] = useState('')

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [fakturaId, setFakturaId] = useState('')
    const [fakturaListe, setFakturaListe] = useState([])
    const [currency, setCurrency] = useState('')
    const [amountString, setAmountString] = useState('')
    const [amount, setAmount] = useState(0.0)
    const [paymentDate, setPaymentDate] = useState(new Date())
    const [validPaymentDate, setValidPaymentDate] = useState(false)
    const [paymentDateError, setPaymentDateError] = useState('')
  
    const showError = (errorMessage) => {
        (showToasts && toast.error(errorMessage, {autoClose: false}))
        setErrorMessage(errorMessage)
        console.error('TrekkSakModal: ', errorMessage)
    }
    
    const showMessage = (message) => {
        (showToasts && toast.info(message))
        setMessage(message)
        console.log('TrekkSakModal: ', message)
    }
    
    // trigger for modal visning
    const showDialogHandler = (creditorNo, caseNo, invoiceNo, currencyCode) => {
        
        setShowSpinner(false)
        
        setErrorMessage('')
        setMessage('')
                                
        setCreditorNo(creditorNo)
        setCaseNo(caseNo)

        let _fakturaId = ''
        let _currencyCode = ''
        
        if (invoiceNo !== '') {
            _fakturaId = invoiceNo        
            _currencyCode = 'NOK'
            if (currencyCode !== '') {
                _currencyCode = currencyCode
            }
        }
        
        var _date = new Date();
        setSelectedDate(_date) 
        handleChangePaymentDate(_date)

        setFakturaId(_fakturaId)
        setFakturaListe(state.data.fetchedData.caseInvoiceList)
        setCurrency(_currencyCode)
        setAmount(0.0)
        setAmountString('0,0')
        setPaymentDate(new Date())        
                      
        setDialogVisible(true)
    }

    const cancelHandler = e => {
        setDialogVisible(false)       
        setShowSpinner(false)
    }

    const getErrorMessage = (err) => {
        if (_.has(err, 'response.data.title')) {
            return err.response.data.title
        }
        else if ((_.has(err, 'response.data')) && (err.response.data)) {
            return err.response.data
        }
        else if (_.has(err, 'response.statusText')) {
            return err.response.statusText
        }
        else {
            return err.message.toString()
        }
    }

    const validates = () => {
        return (
            ((validPaymentDate) && (fakturaId) && (amount > 0)) ? '' : 'disabled'
        )
    }

    const stringToDate = function (dateString) {
        const [dd, mm, yyyy] = dateString.split(".");
        return new Date(`${yyyy}-${mm}-${dd}`);
    };

    const handleChangePaymentDate = (eventDate) => {

        
        let CurrentDate = new Date()
        let CurrentDateAYearAgo = new Date()
        let thisYear = CurrentDateAYearAgo.getFullYear()
        CurrentDateAYearAgo.setFullYear(thisYear-1)
        

        // Tom
        if (!eventDate) {
            setValidPaymentDate(false)
            setPaymentDateError(localeMessages.BT1M_DateInvalid)
        } else {

            // Fremdatert
            if (eventDate > CurrentDate) {
                setValidPaymentDate(false)
                setPaymentDateError(localeMessages.BT1M_DateToNew)
            } else {
                         
                // Tilbakedatert mer enn et år
                if (eventDate < CurrentDateAYearAgo) {
                    setValidPaymentDate(false)
                    setPaymentDateError(localeMessages.BT1M_DateToOld)                    
                } else {
                    setValidPaymentDate(true)
                    setPaymentDateError('')                    
                }
            }
        }
              
        setPaymentDate(eventDate);
    }

    const onFakturaIdChange = event => {
        
        setCurrency('')
        //console.log(event.target.value)
        let invoiceNo = event.target.value
        if (invoiceNo) {
            const invoice = fakturaListe.filter(f => f.invoiceNo === invoiceNo);
            if (invoice.length > 0) {            
                let currencyCode = invoice[0].currencyCode
                if (currencyCode) {
                    setCurrency(currencyCode)
                } else {
                    setCurrency('NOK')
                }
            }
        } else {
            //setCurrency('NOK')
        }
        
        setFakturaId(event.target.value)
       
    };

    const onAmountChange = e => {
        const amount = e.target.value;
        setAmountString(amount)

        //console.log(amount.replaceAll(' ', '').replaceAll(',', '.'))
        let amountAsFloat = parseFloat(amount.replaceAll(' ', '').replaceAll(',', '.'))
        //console.log(amountAsFloat)
        setAmount(amountAsFloat)
    };
    
    const okHandler = () => {

        
        setShowSpinner(true)   
        
        //Format: isoPaymentDate = '2021-04-23T00:00:00.000Z'
        let isoPaymentDate =
        paymentDate.getFullYear()
        + "-" + (paymentDate.getMonth() + 1)
        + "-" + paymentDate.getDate()
        
        let amountAsFloat = parseFloat(amount)
        
        const formData = new FormData();
        
        formData.append('CaseNumber', caseNo)
        formData.append('crNo', creditorNo)
        formData.append('CurrencyCode', currency)
        formData.append('PaymentType', 'payment')
        formData.append('InvoiceNo', fakturaId)
        formData.append('Amount', amountAsFloat)
        formData.append('PaymentDate', isoPaymentDate)
        
        console.log(`/api/sak/PostSendPayment, ${creditorNo}/${caseNo} formData: `, JSON.stringify(formData))
        
        let toastSendBetalingOk = localeMessages['toastSendBetalingOk']
        let toastSendBetalingFailed = localeMessages['toastSendBetalingFailed']
        
        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/sak/PostSendPayment`,
                data: formData
            })
            .then((result) => {

                setDialogVisible(false)
                setShowSpinner(false)

                console.log(`/api/sak/PostSendPayment, ${creditorNo}/${caseNo} returnerte:`, result)
                
                if (result.status !== 200) {
                    showError(toastSendBetalingFailed)             
                } else {
                    if (result.data.errorCode === "000") {
                        showMessage(`${toastSendBetalingOk}  (saksnr: ${caseNo})`)                        
                    } else {
                        showError(toastSendBetalingFailed + ', ' + result.data.errorMessage)                        
                    }
                }                
            })
            .catch((err) => {

                setDialogVisible(false)
                setShowSpinner(false)

                showError(toastSendBetalingFailed + ' ' + getErrorMessage(err))
            })
       
    }

    return {
        okHandler, cancelHandler, showDialogHandler, dialogVisible, 
        onAmountChange, handleChangePaymentDate, onFakturaIdChange,
        selectedDate, setSelectedDate, fakturaId, setFakturaId, fakturaListe,
        currency, setCurrency,
        amountString, setAmountString,
        amount, setAmount,
        paymentDate, setPaymentDate, paymentDateError, validPaymentDate,
        validates, localeMessages
    }
   
}
