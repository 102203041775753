import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';
import LocaleContext from '../../state/contexts/LocaleContext';
import { messages } from './BisNodeContainer.i18n'
import { ArticleArea } from '../../Styling/Layout/ContentArea';
import { useSelector, useDispatch } from 'react-redux'

//import _ from 'lodash'
//import { toast } from 'react-toastify'
//import axiosTokenInstance from '../../Instances/axiosTokenInstance';

const BisNodeContainer = () => {

    const showToasts = true
    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]
  
    const state = useSelector(state => state.bisnode)  
    //const dispatch = useDispatch()  

    return (
        <>
            {(state.loading || state.loading) && <OverlaySpinner></OverlaySpinner>}

            {(state.error) && <Alert variant='danger'>{state.error}</Alert>}

            <ArticleArea>
                <h1>{localeMessages.Header1}</h1>
                <p>{localeMessages.Paragraph1}</p>                    
            </ArticleArea>
                  
        </>
    )

}

export default BisNodeContainer

/*

export const useGetBisNodeInfo = () => {

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const showToasts = true;

    const errorMessage = messages['toastBisnodeFailed']
    const okMessage = messages['toastGetBisnodeOk']

    const runGetInfo = () => {

        //setIsLoading(true)

        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/useradmin/GetBisNodeUri`,
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage))
                } else {

                    let obj = JSON.parse(result.data);
                    let redirectUri = obj.url;
                    window.open(redirectUri, "_blank")

                    (showToasts && toast.info(okMessage))
                    // Redirekt til ny rute
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage
                toast.error(lErrMess)
            })
    }

    return runGetInfo 

}

export default function BisNodeContainer() {
    
    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]
    
    const showToasts = true;
    
    const [error, setError] = useState('')
    const [loading, setIsLoading] = useState(false)

    useEffect(() => {
        //console.log('henter bisnode informasjon')
        getBisNodeInfo(showToasts, localeMessages)
    }, [])


    const getBisNodeInfo = (showToasts, messages) => {

        const errorMessage = messages['toastBisnodeFailed']
        const okMessage = messages['toastGetBisnodeOk']

        setIsLoading(true)        

        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/useradmin/GetBisNodeUri`,
            })
            .then((result) => {
                setIsLoading(false)
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage))
                    setError(errorMessage)
                } else {

                    let obj = JSON.parse(result.data);
                    let redirectUri = obj.url;
                    let cmsContent = ''
                    if (_.has(result, 'data')) {
                        cmsContent = result.data
                    }
                    
                    (showToasts && toast.info(okMessage))
                    // Redirekt til ny rute
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage
                
                setError(errorMessage)
                setIsLoading(false)
                
                toast.error(lErrMess)                
            })

    }

    return (
        <>bisnode</>
    )

}

*/