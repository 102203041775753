import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

export const FETCH_REPORTSMENU_BEGIN = 'FETCH_REPORTSMENU_BEGIN'
export const FETCH_REPORTSMENU_SUCCESS = 'FETCH_REPORTSMENU_SUCCESS'
export const FETCH_REPORTSSCEDULE_SUCCESS = 'FETCH_REPORTSSCEDULE_SUCCESS'
export const FETCH_DATAINTERVALS_SUCCESS = 'FETCH_DATAINTERVALS_SUCCESS'
export const FETCH_SCEDULEINTEVALS_SUCCESS = 'FETCH_SCEDULEINTEVALS_SUCCESS'
export const FETCH_REPORTSMENU_FAILURE = 'FETCH_REPORTSMENU_FAILURE'
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS'

const showToasts = true

export const fetchToolsMenuBegin = () => ({
    type: FETCH_REPORTSMENU_BEGIN
});

export const fetchToolsMenuSuccess = menuItems => ({
    type: FETCH_REPORTSMENU_SUCCESS,
    payload: { menuItems }
});

export const fetchReportsScheduleSuccess = menuItems => ({
    type: FETCH_REPORTSSCEDULE_SUCCESS,
    payload: { menuItems }
});

export const fetchReportsSuccess = menuItems => ({
    type: FETCH_REPORTS_SUCCESS,
    payload: { menuItems }
});

export const fetchDataIntervalsSuccess = items => ({
    type: FETCH_DATAINTERVALS_SUCCESS,
    payload: { items }
});

export const fetchScheduleIntevalsSuccess = items => ({
    type: FETCH_SCEDULEINTEVALS_SUCCESS,
    payload: { items }
});


export const fetchToolsMenuFailure = error => ({
    type: FETCH_REPORTSMENU_FAILURE,
    payload: { error }
});

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}


export const getReportsScheduleList = () => {
    return dispatch => {

        const errorMessage = 'Henting av rapporter feilet'
        const okMessage = 'rapporter er hentet'

        dispatch(fetchToolsMenuBegin())

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/report/GetReportSchedule`,
            })
            .then((result) => {
                //console.log(result)
              if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage, {autoClose: false}))
                    dispatch(fetchToolsMenuFailure(errorMessage))
                } else {

                    let reportsScheduleList = []
                    if (_.has(result, 'data')) {
                        reportsScheduleList = result.data
                    }

                    let strippedScList = reportsScheduleList.map(e => {
                        let intervalsDisp = false
                        if (e.scheduleIntervalsJson && !intervalsDisp) {
                            dispatch(fetchScheduleIntevalsSuccess(e.scheduleIntervalsJson))
                            dispatch(fetchDataIntervalsSuccess(e.dataIntervalsJson))
                            intervalsDisp = true;
                        }  
                        
                        delete e["scheduleIntervalsJson"]
                        delete e["dataIntervalsJson"]
                        //e.scheduleIntervalsJson = null
                        //e.dataIntervalsJson = null  
                        return e                  
                    })

                    //(showToasts && toast.info(okMessage))
                    dispatch(fetchReportsScheduleSuccess(reportsScheduleList))

                    
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchToolsMenuFailure(lErrMess))
            })
    }
}

export const getReportsList = () => {
    return dispatch => {

        const errorMessage = 'Henting av rapporter feilet'
        const okMessage = 'rapporter er hentet'

        dispatch(fetchToolsMenuBegin())

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/report/GetReportList`,
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage, {autoClose: false}))
                    dispatch(fetchToolsMenuFailure(errorMessage))
                } else {

                    let reportsList = ''
                    if (_.has(result, 'data')) {
                        reportsList = result.data
                    }

                    //(showToasts && toast.info(okMessage))
                    dispatch(fetchReportsSuccess(reportsList))

                    /*
                    reportsList.map(e => {
                        if (e.scheduleIntervalsJson) {
                            dispatch(fetchScheduleIntevalsSuccess(e.scheduleIntervalsJson))
                            dispatch(fetchDataIntervalsSuccess(e.dataIntervalsJson))
                        }
                    })
                    */
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchToolsMenuFailure(lErrMess))
            })
    }
}

const getReportsMenu = () => {
    return dispatch => {

        const errorMessage = 'Henting av rapportmeny feilet'
        const okMessage = 'rapportmeny er hentet'

        dispatch(fetchToolsMenuBegin())

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/report/GetReportMenu`,
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage, {autoClose: false}))
                    dispatch(fetchToolsMenuFailure(errorMessage))
                } else {

                    let cmsContent = ''
                    if (_.has(result, 'data')) {
                        cmsContent = result.data
                    }

                    //(showToasts && toast.info(okMessage))
                    dispatch(fetchToolsMenuSuccess(cmsContent))
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchToolsMenuFailure(lErrMess))
            })
    }
}


