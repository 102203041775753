
import { headlessCmsActions } from "./headlessCmsActions";

const initialState = {
    data: {},
    fetched: false,
    loading: false,
    error: ''
}

const headlessCmsReducer = (state = initialState, action) => {

    switch (action.type) {

        case headlessCmsActions.CMS_LOADING:
            return {
                ...state,
                fetched: false,
                loading: true,
                error: null
            };

        case headlessCmsActions.CMS_DATA_FETCHED:
            let newState = { ...state.data }
            newState[action.payload.data.id] = action.payload.data.htmlCode
            return {
                ...state,
                data: newState,
                fetched: true,
                loading: false,
                error: null
            };

        case headlessCmsActions.CMS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.data
            }

        default:
            return state;
    }
}

export default headlessCmsReducer


