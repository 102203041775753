
const getIsRequired = (errorMessage) => {

    const isRequired = (val, name, formState) => {
        //console.log ('is not blank', val)
        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getMinLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {

        if (!val) {
            return ''
        }
        else if (val.length >= length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

export const pbiPlannerFormValidators = (messages) => {

    const reqiredReportname = getIsRequired(messages.reportNameRequired)
    const requiredSelectedKreditors  = getMinLenght(messages.unSelectedActorsRequired, 1)
    const reqiredScheduleInterval = getIsRequired(messages.scheduleintervalRequired)
    //const reqiredDataInterval = getIsRequired(messages.dataintervalRequired)
    const requiredActors = getMinLenght(messages.listactorRequired, 1)


    const formValidators = {
    
        reportName: [
            reqiredReportname,
        ],
        scheduleInterval: [
            reqiredScheduleInterval,
        ],
        selectedKreditors: [
            requiredSelectedKreditors,
        ],
        //dataInterval: [
            //reqiredDataInterval,
        //],
        listactor: [
           requiredActors
        ],        
    }

    return formValidators
}
