import React, { useRef } from "react";
import { Form, Col, Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
} from "../../../Styling/styledModal/styledModals";


// Eksempel på file upload i react
// https://www.geeksforgeeks.org/file-uploading-in-react-js/

// Det finnes eksempler på hvordan man kan kommer rundt styling av knapp for upload

const MeldingModal = (props) => {

    const localeMessages = props.funcs.localeMessages
    
    let btnRef = useRef();

    return (

        <StyledModal centered show={true} onHide={props.funcs.cancelHandler} size="xl" >

            <StyledModalBody>
                {props.funcs.taskId && <h4>{localeMessages["ML1M_Overskrift_svar"]}</h4>}
                {!props.funcs.taskId && <h4>{localeMessages["ML1M_Overskrift_ny"]}</h4>}
                {props.samleSakStatus === 'Undersak' && <h4 style={{color:'red'}}>{localeMessages["ML1M_Undersak"]}</h4>}
                <Form.Group>     

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["ML1M_sak"]}
                        </Form.Label>
                        <Col>
                           {props.funcs.getM1Tekst()}
                        </Col>
                    </Form.Row>

                    {props.funcs.taskId && <>
                        <Form.Row>
                        <Form.Label column="sm" lg={2}>
                            {localeMessages["MS1M_melding"]}
                        </Form.Label>
                        <Col>
                            
                            <Form.Control id="Oppgave" name="meldingstekst" as="textarea" rows={8} readOnly style={{ backgroundColor: '#ffd7ba', whiteSpace: 'pre-line'}}
                                value={props.funcs.orgMessage}
                            />
                        </Col>
                    </Form.Row>

                    <div>&nbsp;</div> 
                    </>}


                    <Form.Row>
                        {props.funcs.taskId && <Form.Label column="sm" lg={4}>{localeMessages["ML1M_oppgave_s"]}</Form.Label>}
                        {!props.funcs.taskId && <Form.Label column="sm" lg={4}>{localeMessages["ML1M_oppgave_m"]}</Form.Label>}
                        <Col>
                             <Form.Control id="Oppgave" name="Oppgave" as="textarea" rows={12} 
                                value={props.funcs.oppgave} onChange={(e) => props.funcs.setOppgave(e.target.value)}                                
                             />
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>

                        </Form.Label>
                        <Col>
                            <input type="file" onChange={props.funcs.onUploadFileChange} />
                        </Col>
                    </Form.Row>

                </Form.Group>

            </StyledModalBody>

            <StyledModalFooter>
                <Button
                    ref={btnRef}
                    variant="secondary"
                    disabled={props.funcs.validates()}
                    onClick={props.funcs.okHandler}>
                    {localeMessages["ML1M_Ok"]}
                </Button>
                <Button variant="secondary" onClick={props.funcs.cancelHandler}>
                    {localeMessages["ML1M_Avbryt"]}
                </Button>

            </StyledModalFooter>

        </StyledModal>
    );
};

export default MeldingModal;