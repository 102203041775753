export const messages = {
    nb: {
        header: 'Rentekalkulator',
        beskrivelse: 'For å beregne rentebeløp, fyll ut datoer og beløp i feltene nedenfor, og trykk beregn.',
        
        startDateLabel: `Startdato`,
        startDatePlaceholder: `dd.mm.yyyy `,
        startDateSmall: `F.eks. fakturadato`,
        
        endDateLabel: `Sluttdato`,
        endDatePlaceholder: `dd.mm.yyyy`,
        endDateSmall: `F.eks. dato man skal beregne renter til.`,
        
        interestLabel: `Rentesats`,
        interestPlaceholder: `0,0`,
        interestSmall: `Prosentsats for renter`,
        
        amountLabel: `Beløp`,
        amountPlaceholder: `0,00`,
        amountSmall: `Beløp man skal regne renter på`,
        
        Calculate: `Beregn`,

        startDateIsRequired: 'Må oppgis. ',
        endDateIsRequired: 'Må oppgis. ',
        interestIsRequired: 'Må oppgis. ',
        amountIsRequired: 'Må oppgis',

        isForfallOk: 'Sluttdato må være etter startdato. ',
        minLenght10: 'Fylles ut på formen dd.mm.yyyy. ',

        youHaveErrors: 'Du har feil i skjemaet som må korrigeres før man kan beregne rentebeløp. '

    }
}