import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CONFIG from '../../config';
import { getEmbeddedBbiUri2 } from '../../reduxStore/PowerBI/PowerBiActions';
import { getReportsList } from '../../reduxStore/ReportMenu/reportMenuActions';
import LocaleContext from '../../state/contexts/LocaleContext';
import PbiReportComponent from './PbiReportComponent';
import { messages } from './pbiReportContainer.i18n'


export const PbiReportContainer = (props) => {

    //console.log(CONFIG)
    const IS_POWERBI_ENABLED = CONFIG.POWERBI_ENABLED
    
    const showToasts = true;

    // Localisation
    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    //const location = useLocation()

    // Global Redux setup
    const reportMenuState = useSelector(state => state.reportsMenu)
    const state = useSelector(state => state.powerBi)
    const crState = useSelector(state => state.sakslisteHeader)
    const dispatch = useDispatch()


    // Embedded token has a limited duration, we reload if more than 30 seconds
    const embedTokenIsNotToOld = () => {

        let nowms = new Date().getTime()

        if (state.timestamp) {
            let diff = nowms - state.timestamp

            //console.log(diff)
            if (diff > 30000) {
                return true
            }
        }

        return false;
    }

    const getReportURI = (props) => {             
        
        // Avoid loading same report again
         if ((state.params !== window.location.hash) || (embedTokenIsNotToOld())) {

            //let filter = ''
            //let settings = ""
            //let filterAsString = ''
            //let paneSettings = ''
             
            if (reportMenuState.reportItems) {

                const reportToRender = reportMenuState.reportItems.find(element => element.reportId === props.reportId);
                if (!reportToRender) {
                    console.log("finner ikke rapporten i listen")
                    return
                }

                // Filter skjer på server, og er ikke aktuelt lenger
                let filter = '' 

                // Settings skal brukes av React pbi component 
                // https://learn.microsoft.com/en-us/javascript/api/overview/powerbi/configure-report-settings                let settings = ""
                //{\"panes\":{\"filters\":{\"expanded\":false,\"visible\": false},\"pageNavigation\": {\"visible\": false}}}
                let settings = JSON.parse(reportToRender.settings)    //'{"panes":{"filters":{"expanded":false,"visible": false},"pageNavigation": {"visible": false}}}')
                                                
                dispatch(getEmbeddedBbiUri2(props.reportId, settings, filter, window.location.hash, showToasts, localeMessages))            
            }
        }
    }

    

    // Vi må hente denne rapporten dersom det ikke er den samme som før
    useEffect(() => {   

        // Ikke last i prod miljø
        if (IS_POWERBI_ENABLED) {
        
            // Dersom du kom hit på første url, er ikke rapportlisten hentet ennå, du må hente den også
            if (!reportMenuState.reportItems && !reportMenuState.loading && reportMenuState.error === null) {
                dispatch(getReportsList())
            }

            getReportURI(props)
        }    
    }, [props.reportId, reportMenuState.reportItems])
    


    return (
        <> 
            <PbiReportComponent
                reportId={props.reportId}
                state={state}
                crState={crState} 
                reportMenuState={reportMenuState}
            />
            
        </>
    )
}
