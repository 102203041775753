import React from 'react';
import { Button } from "react-bootstrap";
import { FormattedNumber } from 'react-intl'
import { HoverBox } from '../../../components/fullTextOnHover/HoverBox';
import CaseListToolTip from '../../caselist_redux/caseListTable/CaseListTooltip'
import { StyledKfTable } from './../../../Styling/styledTable/StyledTable';

const FakturaTable = (props) => {

    const localeMessages = props.messages

    return (
  
        <StyledKfTable>
            <thead>
                <tr>
                    <th>{localeMessages['fg_linje']}</th>
                    <th>{localeMessages['fg_fnr']}</th>
                    <th>{localeMessages['fg_fdato']}</th>
                    <th>{localeMessages['fg_forf']}</th>
                    <th>{localeMessages['fg_tekst']}</th>
                    <th>{localeMessages['fg_vedlegg']}</th> 
                    <th>{localeMessages['fg_kid']}</th>
                    <th>{localeMessages['fg_val']}</th>
                    <th>{localeMessages['fg_bel']}</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                {props.fakturaListe.map((row, i) =>
                    <tr key={i}>
                        <td>{row.lineNo}</td>
                        <td><Button variant="link" size="lg" onClick={() => props.editFaktura(row.lineNo)}>{row.fakturaNummer}</Button></td>
                        <td>{row.fakturaDato}</td>
                        <td>{row.forfallsDato}</td>
                        <td><HoverBox minWidth={150}>{row.fakturaTekst}</HoverBox></td>
                        <td><HoverBox>{row.fileName}</HoverBox></td>                        
                        <td>{row.kid}</td>
                        <td>{row.valuta}</td>
                        <td align="right"><FormattedNumber value={row.belop} style="decimal" minimumFractionDigits='2' /></td>
                        <td>
                            <CaseListToolTip id={i} caption={localeMessages['fg_edit'] + row.fakturaNummer}>
                                <span className="fa fa-edit"
                                    onClick={
                                        () => {
                                            props.editFaktura(row.lineNo)
                                        }
                                    } />
                            </CaseListToolTip>
                            &nbsp;


                            <CaseListToolTip id={i} caption={localeMessages['fg_slett'] + row.fakturaNummer}>
                                <span className="fa fa-trash"
                                    onClick={
                                        () => {
                                            props.deleteFaktura(row.lineNo)
                                        }
                                    } />
                            </CaseListToolTip>
                            &nbsp;


                        </td>
                    </tr>
                )}
            </tbody>
        </StyledKfTable>
    )
}

export default FakturaTable