import React, { useState, useContext } from 'react';
import CaseDetailsContext from '../../CaseDetailsContext'
import LocaleContext from '../../../../state/contexts/LocaleContext';
import { StyledKfTable, StyledKfTextTable } from '../../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../../components/useTable/UseTable'
import usePaginator from '../../../../components/useTable/UsePaginator'
import { MODALFORMS } from '../../CaseDetailsContainer'
import { IntlProvider, FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { fixMissingTimeFromDate } from '../../../../util/date/dateRoutines';
import { useSelector } from 'react-redux';
import colorConfig from '../../../../colorConfig';

const BfButtonBlue = styled(Button)`    
    border: none;
    font-family: "Fellix", "sans-serif";
    font-size: 20px;
    font-weight: 700; 
    color: black;
    background-color: ${colorConfig.button_bg};
    padding-top: 1px;
    padding-bottom: 1px;
    @media print
    {    
        .no-print, .no-print *
        {
            display: none !important;
        }
    }
   
    :disabled {        
        opacity: 0.6;
        background-color: white;
        color: gray;        
    }
    :hover {
        background-color: ${colorConfig.button_focused};
        -webkit-transition: background-color 1.5s ease-out;
        -moz-transition: background-color 1.5s ease-out;
        -o-transition: background-color 1.5s ease-out;
        transition: background-color 1.5s ease-out;
        transition-delay:0.1s;
    }
`

const CaDetFakturaerComponent = (props) => {

    const { localeMessages, showSendBetaling } = React.useContext(CaseDetailsContext);
    const state = useSelector(state => state.saksdetaljer)
    //const locale = useContext(LocaleContext)

    // Felter
    const f1 = 'invoiceNo'
    const f2 = 'invoiceRegarding'
    const f3 = 'invoiceDate'
    const f4 = 'dueDate'

    const f5 = 'currencyCode'
    const f6 = 'amount'
    const f7 = 'paidAmount'
    const f8 = 'balanceAmount'    
    const f9 = 'interest'
    const f10 = ''

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down'); // default
    const [currentField, setCurrentField] = useState(f3);    // feltnavn
    const [currentType, setCurrentType] = useState('datetime'); // num
   

    let pageSize = 1000
    if (!props.isReport) {
        pageSize = 10
    }

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(pageSize)
    const [activePage, setActivePage] = useState(1);
    
    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )
    
    const gridData = state.data.invoices 

    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        gridData.sort(t1GetSortFunc().fn), // <==== fix henting av data
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const getPageHeader = (fld) => localeMessages['faktLst_' + fld]
        
    const asDate = (val) => {
        // Kludge fordi FireFox ikke godtar dato uten tid uten videre
        let valok = fixMissingTimeFromDate(val)
        return (
            <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
        )
    }

    const asDecimal = (val) => {        
        return (
            <FormattedNumber
                value={val}
                style='decimal'
                minimumFractionDigits={2}
            />
        )
    }

    const showModal = (form, row) => {        
        //dispatch({ type: actions.SHOWMODAL_FORM, data: { modalForm: form, modalData: row } })
    }

    const printBetalButton = (row) => {

        if (!props.isReport) {
            
            return (
            <>
                <BfButtonBlue disabled={!state.data.fetchedData.paymentAllowed}
                    onClick={() => showSendBetaling(
                        state.data.fetchedData.caseCreditor.customerNo, state.data.fetchedData.caseNo,
                        row.invoiceNo, row.currencyCode)}>
                    <span className="fa fa-money" />&nbsp;&nbsp;{localeMessages['IC_Betaling']}
                </BfButtonBlue>                
            </>
            )
        }
    }
   
    return (
        <>   
            <StyledKfTable>
                <thead>
                    <tr>
                        <th onClick={() => t1ChangeSortField(f1, 'string')}>{getPageHeader('f1')}{!props.isReport && t1IndicaterIcon(f1)}</th>
                        <th></th>
                        <th onClick={() => t1ChangeSortField(f2, 'string')}>{getPageHeader('f2')}{!props.isReport && t1IndicaterIcon(f2)}</th>
                        <th onClick={() => t1ChangeSortField(f3, 'datetime')}>{getPageHeader('f3')}{!props.isReport && t1IndicaterIcon(f3)}</th>
                        <th onClick={() => t1ChangeSortField(f4, 'datetime')}>{getPageHeader('f4')}{!props.isReport && t1IndicaterIcon(f4)}</th>
                        <th onClick={() => t1ChangeSortField(f5, 'string')}>{getPageHeader('f5')}{!props.isReport && t1IndicaterIcon(f5)}</th>
                        <th onClick={() => t1ChangeSortField(f6, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f6')}{!props.isReport && t1IndicaterIcon(f6)}</th>
                        <th onClick={() => t1ChangeSortField(f7, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f7')}{!props.isReport && t1IndicaterIcon(f7)}</th>
                        <th onClick={() => t1ChangeSortField(f8, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f8')}{!props.isReport && t1IndicaterIcon(f8)}</th>
                        <th onClick={() => t1ChangeSortField(f9, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f9')}{!props.isReport && t1IndicaterIcon(f9)}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>

                    <Paginate>
                        {row =>
                            <tr key={row[f1]}>                                
                                <td>{row[f1]}</td>
                                <td>{printBetalButton(row)}</td>
                                <td>{row[f2]}</td>
                                <td>{asDate(row[f3])}</td>
                                <td>{asDate(row[f4])}</td>
                                <td>{row[f5]}</td>
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f6])}</td>
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f7])}</td>
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f8])}</td>
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f9])}</td>
                                <td></td>
                            </tr>
                        }
                    </Paginate>

                </tbody>

            </StyledKfTable>
            <br />
            {(!props.isReport) && <><PaginationNav /><PaginationIndex /></>}            
        </>
    )
}

export default CaDetFakturaerComponent