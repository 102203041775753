import { useState } from 'react';
import { sendMelding } from '../../../reduxStore/oversikt/oversiktActions';

const useSvarModal = (localeMessages, state, dispatch, showToasts) => {

    const [svarModel, setSvarModel] = useState({})
    const [showSvar, setShowSvar] = useState(false);

    const handleSvarClose = () => {
        setShowSvar(false);
    }

    const handleSvarShow = (model) => {
        setShowSvar(true)
        setSvarModel(model)
    }

    const getNewStateArrays = (model) => {

        let newOppgaveListe = state.data.oppgaveListe.filter((m) => m.i !== model.i)
        let newBesvarteListe = [...state.data.besvarteListe, model]

        return {
            oppgaveListe: newOppgaveListe,
            besvarteListe: newBesvarteListe,
            avptHa: state.data.avptHa,
            avptCr: state.data.avptCr,
        }

    }

    const handleSvarOk = (model) => {

        const formData = new FormData();

        // Update the formData object
        if (model.selectedUploadFile) {
            formData.append(
                "file",
                model.selectedUploadFile,
                model.selectedUploadFile.name
            )
        }

        formData.append('CaseNumber', model.caseNo)
        formData.append('crNo', model.creditorNo)
        formData.append('NoteText', model.noteText)

        if (model.taskId) {
            formData.append('taskId', model.taskId)
            formData.append('newTask', false)
        } else {
            formData.append('taskId', 0)
            formData.append('newTask', true)
        }


        console.log("FormData")
        for (var pair of formData.entries()) {
            console.log(pair[0] + ': "' + pair[1] + '"');
        }

        let newState = getNewStateArrays(model)

        dispatch(sendMelding(formData, newState, showToasts, localeMessages))

        setShowSvar(false)
    }

    return { showSvar, svarModel, handleSvarClose, handleSvarShow, handleSvarOk }
}

export default useSvarModal