import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dispSetActivePage, dispSetCurrentField, dispSetCurrentSeq, dispSetcurrentType, dispSetNumberPrPage, dispSetSortClicked } from '../../../reduxStore/sakslisteGridState/saksListeGridStateActions';

export const useGridProperties = () => {

    const gridState = useSelector(state => state.saksListeGridState)
    const headerState = useSelector(state => state.sakslisteHeader)
    const dispatch = useDispatch()
  
    // --------------- til redux -------------------------
    // Lag reducer slik at man da kan huske verdiene 
    // --------------- til redux -------------------------
    // State for grid sorting
    const [currentSeq, setCurrentSq] = useState(gridState.currentSeq); // default
    const [currentField, setCurrentFld] = useState(gridState.currentField);    // feltnavn
    const [currentType, setCurrentTyp] = useState(gridState.currentType); // num
    const [sortClicked, setSortClk] = useState(gridState.sortClicked)
  
    // state for grid paginator
    const [numberPrPage, setNumberPrPg] = useState(gridState.numberPrPage)
    //const [activePage, setActivePg] = useState(gridState.activePage);
    // --------------- til redux -------------------------
   
    // Lagrer i redux store for å huske posisjon etc
    const setCurrentSeq = (seq) => {
        setCurrentSq(seq)
        dispatch(dispSetCurrentSeq(seq))
    }
    
    const setCurrentField = (fieldName) => {
        setCurrentFld(fieldName)
        dispatch(dispSetCurrentField(fieldName))
    }
    
    const setCurrentType = (sortType) => {
        setCurrentTyp(sortType)
        dispatch(dispSetcurrentType(sortType))
    }
    
    const setSortClicked = (val) => {
        setSortClk(val)
        dispatch(dispSetSortClicked(val))    
    }

    const setNumberPrPAge = (num) => {
        setNumberPrPg(num)
        dispatch(dispSetNumberPrPage(num))
      }
    
    const activePage = gridState.activePage
    const setActivePage = (page) => {
      //setActivePg(page)
      dispatch(dispSetActivePage(page))
    }


    useEffect(() => {
        //dispatch(dispSetActivePage(1))
    }, [headerState.kreditorId, headerState.filter, headerState.aktive])
    
  
    return { currentSeq, setCurrentSeq, currentField, setCurrentField, currentType, setCurrentType, sortClicked, setSortClicked, numberPrPage, setNumberPrPAge,  activePage, setActivePage }
  }