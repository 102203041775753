
import { reportFormsActions } from "./reportFormsActions";
import _ from 'lodash'

/*
=======================================================
 NOTE TO SELF:  
     HER MÅTTE MAN BRUKE LOADASH DEEP CLONE, ELLERS
     BLE INITIALSTATE ENDRET VED HVER KJØRING.
     DERMED BLE IKKE STATE NULLSTILT VED UTLOGGING
=======================================================     
*/

const initialState = {
    data: {
        SingleRun: [],
        Planned: []
    }   
}

export default function reportFormsReducer (state = initialState, action) {
   
    switch (action.type) {

        case reportFormsActions.REPFORM_SAVE_FORM:
            let stateCopy = _.cloneDeep(state) // <=== Deep cloning, holder ikke med spread...
            let repType = stateCopy.data[action.payload.reportType]
                .filter(e => {
                    console.log(e)
                    return e.reportId !== action.payload.id
                })    
            repType = [
                ...repType,
                {reportId: action.payload.id, data: {...action.payload.data}}
            ]            
            let newState = { ...stateCopy }
            newState.data[action.payload.reportType] = repType
            return {
                ...newState
            }
                                                 
        default:
            return state;
    }
}