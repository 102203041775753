import { useState } from 'react';
import { sendMelding } from '../../../reduxStore/oversikt/oversiktActions';

const useMeldingModal = (localeMessages, state, dispatch, showToasts) => {

    const [lesModel, setLesModel] = useState({})
    const [showLes, setShowLes] = useState(false);
    
    const handleLesClose = () => {
        setShowLes(false);
    }
    
    const handleLesShow = (model) => {
        setShowLes(true)
        setLesModel(model)
    }

    const getNewStateArrays = (model) => {

        let newOppgaveListe = state.data.oppgaveListe.filter((m) => m.i !== model.i)
        let newBesvarteListe = [...state.data.besvarteListe, model]

        return {
            oppgaveListe: newOppgaveListe,
            besvarteListe: newBesvarteListe,
            avptHa: state.data.avptHa,
            avptCr: state.data.avptCr,
        }

    }
    
    const handleLesOk = (model) => {
            
        console.log (state)

        console.log(model)
        setShowLes(false)

        const formData = new FormData();        

        formData.append('CaseNumber', model.caseNo)
        formData.append('crNo', model.creditorNo)
        formData.append('NoteText', '')

        if (model.taskId) {
            formData.append('taskId', model.taskId)
            formData.append('newTask', false)
        } else {
            formData.append('taskId', 0)
            formData.append('newTask', true)
        }

        console.log("FormData")
        for(var pair of formData.entries()) {
            console.log(pair[0]+ ': "'+ pair[1] + '"');
        }

        let newState = getNewStateArrays(model)

        //dispatchSendMelding(formData, newState, showToasts, localeMessages, dispatch, actions)
        dispatch(sendMelding(formData, newState, showToasts, localeMessages))

        setShowLes(false);
    }

    return { showLes, lesModel, handleLesClose, handleLesShow, handleLesOk }
}

export default useMeldingModal