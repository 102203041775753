import React, { useState, useRef } from 'react';
import { IntlProvider, FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl'
import UseTableSorter from '../../../components/useTable/UseTable';
import usePaginator from '../../../components/useTable/UsePaginator'
import { StyledKfTextTable } from '../../../Styling/styledTable/StyledTable';
import { StyledKfTable, StyledNavLink } from './../../../Styling/styledTable/StyledTable';
import { ModalFooter, OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components';

const CaseListToolTip = props => {

    let ref = React.useRef(null);

    return (

        <OverlayTrigger
            animation={false}
            id={'ot' + props.id}
            key={'kt' + props.id}
            ref={r => (ref = r)}

            placement='top'
            overlay={<Tooltip key={'tkt' + props.id} id={props.id}>{props.caption}</Tooltip>}
        >
            {props.children}
        </OverlayTrigger>

    )
}


export const SceduledReportsTable = (props) => {

    const localeMessages = props.messages
    
    // Filtrer vekk 
    const tableData = props.state.scheduleItems
        .filter(e => (e.pbiScheduleId))

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down') // default
    const [currentField, setCurrentField] = useState('reportName')    // feltnavn
    const [currentType, setCurrentType] = useState('string') // num

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10)
    const [activePage, setActivePage] = useState(1);


    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        tableData.sort(t1GetSortFunc().fn), // <==== sorting inputdata =========
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const ClickableText = styled.span`
        text-decoration: none;
        color: '#007bff';
        background-color: 'transparent';

        :hover {
            text-decoration: underline;
        }
    `


    const printHeader = () => {

        return (
            <thead>
                <tr>
                    <th onClick={() => t1ChangeSortField('reportName', 'string')}>{localeMessages.RSC_GRH1}{t1IndicaterIcon('reportName')}</th>
                    <th onClick={() => t1ChangeSortField('dateIntervalName', 'string')}>{localeMessages.RSC_GRH2}{t1IndicaterIcon('dateIntervalName')}</th>
                    <th onClick={() => t1ChangeSortField('lastSchedule', 'datetime')}>{localeMessages.RSC_GRH3}{t1IndicaterIcon('lastSchedule')}</th>
                    <th onClick={() => t1ChangeSortField('isEmpty', 'string')}>{localeMessages.RSC_GRH4}{t1IndicaterIcon('isEmpty')}</th>
                    <th onClick={() => t1ChangeSortField('nextSchedule', 'datetime')}>{localeMessages.RSC_GRH5}{t1IndicaterIcon('nextSchedule')}</th>
                </tr>
            </thead>
        )
    }

    const selectRow = (model) => {
        //console.log(model)
        props.gridRowClicked(model)   
    }

    const PrintRow = (model, i) => {
         
        // TODO: Ved klikk på en rad, må du overføre data mhp navn, intervaller og krditorer
        return (
            <>
                <tr key={i.toString()} onClick={() => selectRow(model)}>                    
                    <td><ClickableText>{model.scheduleName}</ClickableText></td>
                    <td>{model.schedIntvalName}</td>
                    <td>
                        {model.lastSchedule && <>
                            <FormattedDate value={model.lastSchedule} day="2-digit" month="2-digit" year="numeric" />
                        </>}
                    </td>
                    <td>{model.isEmpty ? "Varsel sendt" : "Ingen endring"}</td>
                    <td>
                        {model.lastSchedule && <>
                            <FormattedDate value={model.nextSchedule} day="2-digit" month="2-digit" year="numeric" />
                        </>}
                    </td>

                </tr>                

            </>
        )
    }

    return (
        <>
            {tableData.length !== 0 && <PaginationDropdown />}
            <StyledKfTable hoverOpacity={1.0}>
                {printHeader()}

                <tbody>
                    <Paginate>
                        {/* paginate forventer en funksjon */}
                        {(row, i) =>
                            PrintRow(row, i)}
                    </Paginate>
                </tbody>
            </StyledKfTable>
            {tableData.length !== 0 &&
                <>
                    <br />
                    <PaginationIndex />
                    <br /><br />
                    <PaginationNav />
                </>
            }

        </>
    )
}