import React, { useState } from 'react';
import { Container, Col, Form, Alert, Spinner } from "react-bootstrap";
import { BfColInput } from '../../../Styling/FormComponents/FormInput'
import { BfYellowRowForm, BfTightYellowRowForm, BfButtonBlue, BfRowSpacer, YellowRoundedContainer, BfYellowRowFormScore, RatingContainer, BfYellowRowTerms, ButtonContainer, RatingBox, RatingForm } from '../../../Styling/styledForm/styledBlueForm'
import { ArticleArea } from '../../../Styling/Layout/ContentArea';
import useDebitorForm from './useDebitorForm';
import { useHistory } from 'react-router-dom';
import { ConfirmModal } from '../../../components/confirmModal/ConfirmModal';
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { useAuth } from '../../../hooks/useAuth';

const FieldLabel = styled.label`
  display: block;
  font-weight: 400;
`;

const Circle = styled.button`    
    background: ${props => props.primary || "white"};
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 1px solid black;
`;

const DebitorForm = (props) => {

    const {
        formValues, formValidators, handleChange, handleBlur, formState, validateAll,
        hasErrors, errorMessages, extraErrors, setExtraErrors,
        submitForm, errorMessage, localeMessages, produktOptions,
        debtorOptions, debtorTypeOptions, countryOptions, handlePnrChange,
        newDebtorCheckReference, existingDebtorCheckReference, debtorWithReference,
        confirmModalVisible, confirmModalOk, confirmModalCancel, altDebitor
    } = useDebitorForm(props.state, props.dispatch, props.showToasts, props.localeMessages )


    const auth = useAuth()

    const history = useHistory();
    const state = props.state;
    let selectedKreditor = state.kreditorId;

    // const [ratingLoading, setRatingLoading] = useState(false);
    // const [companyRating, setCompanyRating] = useState('');
    // const [personRating, setPersonRating] = useState('');

    // const [canDoCreditCheckValue, setCanDoCreditCheck] = useState(
    //     JSON.parse(localStorage.getItem('canDoCheckValue')) || false
    // );

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
                onBlur={handleBlur}
            />
        )
    }

    const getFormFieldPnr = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handlePnrChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
            />
        )
    }

    // const BfColRating = (props) => {
    //     const { height, square, value } = props;
    //     // Bruker box for karakter, score og trafikklys-vurdering, form for tekst
    //     if (square) {
    //         return (
    //             <RatingBox height={height}>
    //                 {value}
    //             </RatingBox>
    //         );
    //     } else {
    //         return (
    //             <RatingForm>
    //                 {value}
    //             </RatingForm>
    //         );
    //     }
    // };

    // const getFormFieldScore = (fieldname, value, height, square = false) => {
    //     return (
    //         <RatingContainer >
    //             {/* Litt hacky måte å få label til å ligge fint over boks når ikke square */}
    //             <FieldLabel style={{ position: square ? 'auto' : 'relative', top: square ? 'auto' : '30%' }}>{fieldname}</FieldLabel>
    //             <BfColRating
    //                 value={value}
    //                 height={height}
    //                 square={square}
    //             />
    //         </RatingContainer>
    //     );
    // };

    // const getMethodToUse = () => {
    //     if (formState.debtorType === 'Næring' && formState.debtorIdentificationNumber && formState.debtorIdentificationNumber.trim() !== '') {
    //         return 'getCompanyRating';
    //     }
    //     if (formState.debtorType === 'Privat' && formState.birthNumber && formState.birthNumber.trim() !== '') {
    //         return 'getPersonRating';
    //     }
    //     return null;
    // };

    // const canDoCreditCheck = () => {
    //     return getMethodToUse() !== null;
    // };

    // const getChosenRating = async () => {
    //     const method = getMethodToUse();
    //     if (!method) return;

    //     setCompanyRating(null);
    //     setPersonRating(null);

    //     setRatingLoading(true);
    //     const orgNr = formState.debtorIdentificationNumber.replace(/\s/g, "");
    //     const ssn = formState.birthNumber.replace(/\s/g, "");
    //     try {
    //         const result = method === 'getCompanyRating'
    //             ? await dispatch(getCompanyRating(selectedKreditor, orgNr, auth.user.email, showToasts, localeMessages))
    //             : await dispatch(getPersonRating(selectedKreditor, ssn, auth.user.email, showToasts, localeMessages));

    //         if (result !== null) {
    //             method === 'getCompanyRating' ? setCompanyRating(result) : setPersonRating(result);
    //         } else {
    //             console.log('No valid data received');
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         setRatingLoading(false);
    //     }
    // };

    const pStyle = {
        width: '80%'
    }

    const disableExisting = () => {
        return true;
    }

    const getPrivateRow = (disabled) => {

        if (disabled) {
            return (
                <>
                    {getFormField(3, 'firstName', null, null, disabled, 40)}
                    {getFormField(3, 'lastName', null, null, disabled, 40)}
                    {getFormField(2, 'birthDate', 'cleaveDate', null, disabled)}
                    {getFormField(2, 'birthNumber', 'cleaveFnrNr', null, disabled)}

                    {formState.debtorNo &&
                        getFormField(2, 'debtorNo', null, null, props.disabled)}
                </>
            )
        }
        else {
            return (
                <>
                    {getFormField(3, 'firstName', null, null, disabled, 40)}
                    {getFormField(3, 'lastName', null, null, disabled, 40)}
                    {getFormField(2, 'birthDate', 'cleaveDate', null, disabled)}
                    {getFormField(2, 'birthNumber', 'cleaveFnrNr', null, disabled)}
                    {formState.debtorNo &&
                        getFormField(2, 'debtorNo', null, null, props.disabled)}
                </>
            )
        }

    }

    const getNaeringRowRow = () => {
        return (
            <>
                {getFormField(6, 'debtorName', null, null, props.disabled, 40)}
                {getFormField(2, 'debtorIdentificationNumber', 'cleaveOrgNr', null, props.disabled)}
                {formState.debtorNo &&
                    getFormField(2, 'debtorNo', null, null, props.disabled)}

            </>
        )
    }

    // const printRating = () => {
    //     const ratingData = companyRating || personRating || {};
    //     function isNumeric(num) {
    //         return !isNaN(num)
    //     }

    //     let ratingstring = ratingData.score ? ratingData.score : ''
    //     let outputString = ''
    //     let rcolor = 'white'
    //     let showBall = false


    //     if (!ratingstring) {
    //         outputString = ''
    //     } else {
    //         if (isNumeric(ratingstring)) {
    //             let rating = parseInt(ratingstring)
    //             showBall = true

    //             if (rating <= 30) {
    //                 rcolor = 'red'
    //             } else if (rating <= 65) {
    //                 rcolor = 'yellow'
    //             } else {
    //                 rcolor = 'green'
    //             }
    //         }
    //     }
    //     /*
    //     Rød "prikk" hvis ratingstallet er <30
    //     Gul "prikk" hvis ratingstallet er mellom 30 og 60
    //     Grønn "prikk" hvis ratingstallet >60
    //     */
    //     return (
    //         <div>
    //             {showBall &&
    //                 <Circle primary={rcolor}></Circle>
    //             }
    //             {outputString}
    //         </div>
    //     )
    // }

    // const getKredittsjekkRow = () => {
    //     const ratingData = companyRating || personRating || {};
    //     return (
    //         <>
    //             <FieldLabel style={{ padding: '10px 0 0 30px', fontWeight: '600', fontSize: '30px' }}>{localeMessages.credit_check}</FieldLabel>
    //             <FieldLabel style={{ padding: '0 0 0 30px' }}>{localeMessages.creditCheck_required}</FieldLabel>

    //             <BfYellowRowFormScore>
    //                 {getFormFieldScore(localeMessages.credit_ratingLabel, ratingData.scoreKarakter, '80px', true)}
    //                 {getFormFieldScore(localeMessages.credit_scoreLabel, ratingData.score, '80px', true)}
    //                 {getFormFieldScore(localeMessages.credit_colorLabel, printRating(ratingData.score), '80px', true)}
    //                 {getFormFieldScore(localeMessages.score_explanationLabel, ratingData.scoreForklaring, false)}
    //                 {getFormFieldScore(localeMessages.recommended_limitLabel, ratingData.kredittgrense, false)}
    //                 {ratingLoading ? (
    //                     <ButtonContainer style={{ padding: '0 0 40px 80px' }}>
    //                         <Spinner animation="border" />
    //                     </ButtonContainer>
    //                 ) : (
    //                     <ButtonContainer>
    //                         <BfButtonBlue variant="primary" type="button" onClick={getChosenRating} disabled={!canDoCreditCheck()}>
    //                             {localeMessages.do_creditCheck}
    //                         </BfButtonBlue>
    //                     </ButtonContainer>
    //                 )}
    //             </BfYellowRowFormScore>
    //         </>
    //     );
    // }

    // const getTermsNotAcceptedRow = () => {
    //     return (
    //         <BfYellowRowTerms>
    //             <FieldLabel style={{ padding: '10px 0 0 30px', fontSize: '30px', fontWeight: '500' }}>Kredittsjekk</FieldLabel>
    //             <FieldLabel style={{ padding: '10px 0 10px 30px' }}>Denne brukeren har ikke godkjent avtalen som er påkrevd for å kunne utføre kredittsjekk, naviger til <b>Kredittvurdering</b> om du ønsker å lese og godkjenne avtalen for kreditor <b>{state.kreditorId}</b>.</FieldLabel>
    //             <>
    //                 &nbsp;   &nbsp;   &nbsp;
    //                 <BfButtonBlue variant="primary" type="button" onClick={() => history.push("/creditRating")}>
    //                    {localeMessages.navigate_creditTab}
    //                 </BfButtonBlue>
    //             </>

    //         </BfYellowRowTerms>
    //     );
    // }

    const showConfirmModal = () => {
        const setHeader = `${localeMessages.md_Header1}: ${altDebitor.firstName} ${altDebitor.lastName}`
        return (
            <ConfirmModal
                okHandler={confirmModalOk}
                cancelHandler={confirmModalCancel}
                message={localeMessages.md_Message}
                header={setHeader}
                okCaption={localeMessages.md_Ok}
                cancelCaption={localeMessages.md_Cancel}
                data={altDebitor}
            >
            </ConfirmModal>
        )
    }

    const dispatch = useDispatch()
    const showToasts = true

    return (
        <Container fluid style={pStyle}>

            {confirmModalVisible && showConfirmModal()}

            <ArticleArea>
                <h1>{localeMessages.SK_Head}</h1>
                <p>{localeMessages.SK_Caption}</p>

            </ArticleArea>

            <Form autocomplete="chrome-off">
                <YellowRoundedContainer fluid style={{ padding: '15px' }}>

                    {hasErrors && (
                        <Alert fluid variant='danger'>
                            {localeMessages.youHaveErrors}
                        </Alert>
                    )}

                    <BfYellowRowForm>
                        {getFormField(4, 'produktType', 'select', produktOptions)}
                        {getFormField(8, 'referanseNummer', null, null, false, 32)}
                    </BfYellowRowForm>

                </YellowRoundedContainer>
                <BfRowSpacer>

                </BfRowSpacer>

                <YellowRoundedContainer fluid style={{ padding: '15px' }}>


                    <BfYellowRowForm>
                        {getFormField(2, 'debtorType', 'select', debtorTypeOptions, props.disabled)}

                        {formState.debtorType === 'Næring' &&
                            getNaeringRowRow()
                        }
                        {formState.debtorType === 'Privat' &&
                            getPrivateRow(props.disabled)
                        }
                        {formState.debtorType === '' &&
                            getPrivateRow(true)
                        }


                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(4, 'postalAddress', null, null, props.disabled, 63)}
                        {getFormField(4, 'streetAddress', null, null, props.disabled, 63)}
                        {getFormField(4, 'email', null, null, props.disabled, 63)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormFieldPnr(2, 'postalCode', null, null, props.disabled, 10)}
                        {getFormField(4, 'postalPlace', null, null, props.disabled, 30)}
                        {getFormField(2, 'countryCode', 'select', countryOptions, props.disabled)}

                        {getFormField(3, 'phone', null, null, props.disabled, 11)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(12, 'caseMessage', 'textarea')}
                    </BfYellowRowForm>



                    {/* {canDoCreditCheckValue ? getKredittsjekkRow() : getTermsNotAcceptedRow()} */}

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>
                            <BfButtonBlue variant="primary" type="button" onClick={submitForm}>
                                {localeMessages.Lagre}
                            </BfButtonBlue>

                            &nbsp;

                            <BfButtonBlue variant="primary" type="button" onClick={() => history.push("/CaseNew/init")}>
                                {localeMessages.butTilbake}
                            </BfButtonBlue>
                            &nbsp;

                        </Col>
                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm />

                </YellowRoundedContainer>
            </Form>
        </Container>
    )
}

export default DebitorForm

