import { useState } from "react"

/* =======================================================================

 En hook som lar deg taste inn verdier, men som setter en verdi når du
 stopper å skrive.
 
 delay = antall millisek du skal vente etter siste tastetrykk
 SetFilterValue = state setter for felt der du skriver inn søkeverdien
 SetFilterTrigger = felt som benyttes for å trigge event/søk

=======================================================================
*/


export const useTimerDelay = (delay, setFilterTrigger, setFilterValue, setInputValue) => {

    const [timer, setTimer] = useState()

    const resetFilterTimer = (filter) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => setFilterTrigger(filter), delay))
    }

    const handlefilterValueChange = (e) => {
        setFilterValue(e.target.Value)        
        resetFilterTimer(e.target.value)
    }

    return { handlefilterValueChange }

}