export const messages = {
    nb: {
        WelcomeHeader: `Registrer kodeapp`,
        WelcomeParagaph1: `Dersom du ønsker multifaktor pålogging med kode app, kan du klikke på linken på denne 
        siden. Du kan fritt velge kode app, som f.eks. Google Authenticator.  Du må laste ned en kode app på telefonen,
        og klikke på ikon for å legge til et nytt nettsted.`, 
        
        WelcomeParagaph2: `For å registrere, gjør følgende: Klikk på link for å generere QR code.  Scan denne inn i authenticator appen,
        du vil få en kode som du taster inn i skjermbildet. Tofaktor pålogging er nå aktivert.`, 
        showQrCode: 'Fortsett',

        WelcomeHeaderSMS: `Registrer mobiltelefon`,
        WelcomeParagaph1SMS: `Dersom du ønsker multifaktor pålogging med tekstmeldinger/sms, kan du klikke på linken på denne 
        siden. Du får da tilsendt en melding til mobilnummeret du har registrert i profilen din.`, 
        
        WelcomeParagaph2SMS: `For å registrere, gjør følgende: tast inn koden du fikk på mobil i neste skjermbilde.  Du vil da kunne
        bruke mobiltelefonen din som tofaktor enhet ved pålogging`, 
        sendSMSCode: 'Fortsett',

        WelcomeParagaph1SMS: `Du kan foreløpig ikke motta tofaktor koder på SMS ettersom du ikke har registrert telefonnummeret ditt ennå.
        Du kan registrere telefonnummeret ditt i profilbildet.  Velg "Profil" fra menyen.`, 
               
        DisplayQrCodeHeader: 'Registrere tofaktor med kode app',
        DisplayQrCodeParagraph: 'Start kode app på telefon, og scan qr koden nedenfor',

        DisplaySendSMSCodeHeader: 'Registrere tofaktor med tekstmeldinger/SMS',
        DisplaySendSMSCodeParagraph: 'Det ble sendt en kode til mobiltelefonen din. Om du ikke mottar kode, sjekk mobilnummeret du har registrert i din profil.',

        MfaOkHeader: `Tofaktor pålogging er nå aktivert`,
        MfaOkParagaph: `Du vil nå benytte tofaktor neste gang du logger på applikasjonen.`, 

        MfaRemoveHeader: 'Slå av tofaktor pålogging',
        MfaRemoveParagaph1: 'Klikk på knappen nedenfor for å slå av toraktor pålogging.  Du vil da kunne logge på med epostadresse og passord.',
        MfaRemoveButton: 'Slå av tofaktor pålogging',
        
        RemoveHeader: `Fjerne tofaktor pålogging`,
        RemoveParagaph: `Klikk på lenken for å slå av tofaktor påloggingen.`,     
        
        KodeLabel: `Nytt Passord`,
        KodePlaceholder: `Oppgi nytt passord`,
        KodeSmall: `Oppgi passordet du vil endre til her.`,
        KodeRequired: `Du må oppgi passord.`,

        SendKode: 'Send kode',
        RegistrerSMSKode: 'Registrer kode',
        Kode: 'Skriv inn koden fra kode app:',
        SMSKode: 'Skriv inn koden fra fra tekstmeldingen:',
        //Det ble sendt en kode til mobiltelefonen din. Om du ikke mottar kode, sjekk mobilnummeret du har registrert i din profil.
    
    }
  } 