import styled from 'styled-components'

const ArticleArea = styled.div`
   margin-top: 0;
   font-size: 25px;
   font-weight: 200;
   line-height: 33.6px;
   margin-bottom: 0px;
   font-family: "Fira Sans", "sans-serif";
   color: #003e60;

   >h1, h2, h3, h4, h5, h6{
    font-weight: 500;    
    margin-bottom: 10px;
    font-size: 35px;
    }

    >p{
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 10px;
    }
`

const ContentArea = styled.div`
    padding: 37px 50px 15px 50px;     
    font-weight: 300;
 
`
export {ContentArea, ArticleArea}