export const messages = {
    nb: {
        HDET_Kreditor: 'No. ',
        HDET_Referansenr: 'Referansenr',
        HDET_Saksnr: 'Saksnr',
        HDET_SisteAkt: 'Siste aktivitet',
        HDET_innbet: 'Innbetalt',
        HDET_rest: 'HDET_rest',
        HDET_regdato: 'Registrert',
        HDET_sakstype: 'Sakstype',


        SS_H01: `Samlesak`,
        SS_H02: `Hovedstol`,
        SS_H03: `Hovedstol rest`,  
        SS_H06: `Saldo totalt`,     
        SS_H05: `Liste fakturanr.`,     

        TaC_T1: 'Fakturaer',
        TaC_T2: 'Meldinger',
        TaC_T3: 'Notater',
        TaC_T4: 'Aktiviteter',
        TaC_T5: 'Samlesak',

        ResK_H1: '',
        ResK_H2: 'Krav',
        ResK_H3: 'Innbetalt',
        ResK_H4: 'Rest',
        ResK_C1: [            
            'Hovedstol',
            'Rente',
            'Salær',
            'Gebyr',
            'Rettslig salær',
            'Omkostningsrente',
            'Sum'
        ],

        DbT_H1: 'Debitor',
        DbT_D: [
            'Debitor',
            'C/O',
            'Postadresse',
            'Besøksadresse',
            'Poststed',
            'Fødselsnr/Orgnr',
            'E-post',
            'Rating',
            'Telefon'
        ],

        SO_H1: 'Saksoversikt',
        SO_C1: [
            'Registrert dato',
            'Kontonummer',
            'KID',
            'Inkassotype',
            'Siste innbet.',
            'Saksbehandler',
            'E-post',
            'Telefon',                    
        ],

        faktLst_f1: 'Fakturanr',
        faktLst_f2: 'Beskrivelse',
        faktLst_f3: 'Fakturadato',
        faktLst_f4: 'Forfallsdato',

        faktLst_f5: 'Valuta',
        
        faktLst_f6: 'Beløp',
        faktLst_f7: 'Innbetalt',
        faktLst_f8: 'Saldo',
        
        faktLst_f9: 'Renter', 
        faktLst_f10: '',
        faktLst_betal: 'Betale',

        aktLst_f1: 'Dato',
        aktLst_f2: 'Aktivitet',
        aktLst_f3: 'Kanal',
        aktLst_f4: 'Innbetalt',
        aktLst_f5: 'Gebyr',
        aktLst_f6: 'Salær',
        aktLst_f7: 'Rettslig salær',
        aktLst_f8: 'Hovedstol',
        aktLst_f9: 'Rente',
        aktLst_f10: '',
        aktLst_download: 'Last ned',

        notesLst_f1: 'Dato',
        notesLst_f2: 'Subject',
        notesLst_f3: 'Tekst',        

        toastGetCaseDetailsOk: 'Sak hentet fra databasen OK.',
        toastGetCaseDetailsFailed: 'Henting av saksdetaljer feilet. ',

        TS1M_Overskrift: `Er saken feilsendt?`,
        TS1M_Ok: `Ja, fortsett`,
        TS1M_Avbryt: `Nei, Avbryt`,
        TS1M_Ja: `Ja, fortsett`,
        TS1M_Nei: `Nei, avbryt`, 

        TS2M_Overskrift: `Er saken feilsendt?`,
        TS2M_terminationCause: 'Årsak',
        TS2M_Ok: `Trekk saken`,
        TS2M_Avbryt: `Avbryt`,
        TS2M_Melding: `Saken blir vurdert avsluttet og dere kan bli belastet i henhold til samarbeidsavtalen.`,

        US1M_Overskrift: `Utsett sak`,
        US1M_alt1: `Utsett i 7 dager`,
        US1M_alt2: `Utsett i 14 dager`,
        US1M_alt3: `Utsett i 1 måned`,
        US1M_Ok: `Utsett`,
        US1M_Avbryt: `Avbryt`,  

        AO1M_andAvdr: 'Antall avdrag',
        AO1M_Overskrift: `Avdragsordning`,
        AO1M_terminbelop: `Terminbeløp`,
        AO1M_phterminbelop: `Oppgi belop i hele kroner`,

        AO1M_epostNotValid: 'Oppgi gyldig epost',
        AO1M_mobilNotValid: 'Oppgi gyldig mobilnr',

        AO1M_andAvdr2: '2',
        AO1M_andAvdr3: '3',
        AO1M_andAvdr4: '4',
        AO1M_balanceTotal: 'Sluttsum',
        AO1M_forsRt: 'Forsinkelsesrenter og inkassosalær kan påløpe i perioden.',
        AO1M_terminLengde: `Terminlengde`,
        AO1M_alt1: `15 dager`,
        AO1M_alt2: `30 dager`,
        AO1M_skyldner: `Skyldner`,
        AO1M_alt_mob: 'Mobil',
        AO1M_alt_epost: 'Epost',
        AO1M_epost: `Epost`,
        AO1M_phepost: `Oppgi gyldig epostadresse`,
        AO1M_pnr: `Person/Orgnr`,
        AO1M_phpnr: `Oppgi Personnummer eller Orgnr`,
        AO1M_Ok: `Opprett`,
        AO1M_Avbryt: `Avbryt`,

        AO2M_Overskrift: `Er saken feilsendt?`,
        AO2M_Ok: `Trekk saken`,
        AO2M_Avbryt: `Avbryt`,
        AO2M_Melding: `Saken vil bli avsluttet og dere vil bli belastet {belop} i omkostninger.`,

        FU1M_Overskrift: `Last opp faktura på sak`,
        FU1M_sak: `Sak`,
        FU1M_kreditor: `Kreditor`,
        FU1M_velgFakt: `Velg faktura`,
        FU1M_filnavn: `filnavn`,
        FU1M_filVelg: `Velg fil`,
        FU1M_Ok: `Last opp`,
        FU1M_Avbryt: `Avbryt`,

        ML1M_Overskrift: 'Send melding',
        ML1M_sak: 'Sak:',
        ML1M_forfall: 'Forfall',
        ML1M_oppgave: 'Oppgave',
        ML1M_Sendkopi: 'Send kopi',
        ML1M_Ok: 'Lagre',
        ML1M_Avbryt: 'Avbryt',

        toastSendMeldingFailed: 'En feil oppstod under sending av melding: ',
        toastMeldingUploadOk: 'Melding er sendt.',

        getFakturalisteError: 'Feil oppstod under henting av fakturaer: ',
        getFakturalisteOk: 'Fakturaer for sak hentet OK',

        postFakturaUploadError: 'Feil oppstod under opplasting av fakturavedlegg. ',
        postFakturaUploadOk: 'Fakturavedlegg er lastet opp OK',

        AO1M_terminbelHeleKr: 'Terminbeløp må oppgis i hele kroner. ',
        AO1M_terminMindreEnn: 'Terminbeløp kan ikke være mindre enn {minbel} kr. ',
        AO1M_terminMerEnnMax: 'Terminbeløp kan ikke være større enn {maxbel} kr. ',
        AO1M_epostNotValid: 'Du har ikke oppgitt en gyldig epostadresse. ',

        toastTrekkSakNotAllowed: 'Denne saken kan ikke trekkes.',
        toastTrekkSakFailed: 'En feil oppstod, saken kunne ikke trekkes.',
        toastTrekkSakDenied: 'Denne saken kan ikke trekkes. ',
        toastTrekkSakOk: 'Saken er trukket.',
        toastGetCaseStatusFailed: 'Kan ikke hente status for denne saken, kan ikke fortsette med trekking av sak. Grunn: ',
        toastUtsettSakOk: 'Utsetting av sak er registrert OK.',
        toastUtsettSakFailed: 'Utsetting av sak feilet.',    
        toastAvdrOrdnOk: 'Oppretting av avdragsordning er registrert OK.',
        toastAvdrOrdnFailed: 'Oppretting av avdragsordning feilet.',

        toastSendBetalingFailed: 'Sending av betaling feilet. ',
        toastSendBetalingOk: 'Betaling registrert OK. ',

        IC_Ustkrift: 'Skriv ut',
        IC_NyMelding: 'Ny melding',
        IC_Besvar: 'Send svar',
        IC_Betaling: 'Betaling',
        IC_Utsett: 'Utsett',
        IC_avdrag: 'Avdragsordning',
        IC_Trekk: 'Trekk saken',
        IC_FaktOppl: 'Last opp fakt.',

        BT1M_Overskrift: 'Registrer betaling',
        BT1M_paymentDate: 'Betaling registrert',
        BT1M_Belop: 'Beløp',
        BT1M_valuta: 'Valuta',
        BT1M_faktura: 'Velg faktura',
        BT1M_Ok: 'Lagre',
        BT1M_Avbryt: 'Avbryt',
    }
    
} 