import React, { useState, useEffect } from 'react';
import LocaleContext from './state/contexts/LocaleContext'
import MainLayout from './Styling/Layout/MainLayout'

import AuthContext from './state/contexts/AuthContext'
import { AuthReducer } from './state/reducers/AuthReducer'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const [locale] = useState('nb')

  useEffect(() => {
    document.title = "Kreditorforeningen"
  }, []);

  // https://dev.to/efearas/how-to-usecontext-and-set-value-of-context-in-child-components-in-3-steps-3j9h

  return (
    <div>
      
      <LocaleContext.Provider value={locale}>
        <AuthContext.Provider value={AuthReducer()}>
          <MainLayout />
          <ToastContainer position="bottom-right" />
        </AuthContext.Provider>
      </LocaleContext.Provider>
     
    </div>
  )
}

export default App;
