import React from 'react';
import { Container, Col, Form, Alert } from "react-bootstrap";
import { BfColInput } from '../../../Styling/FormComponents/FormInput'
import { BfYellowRowForm, BfTightYellowRowForm, YellowRoundedContainer, BfRowForm, BfTightRowForm, BfButtonBlue, BfFormLabelSm } from '../../../Styling/styledForm/styledBlueForm'
import FakturaTable from './FakturaTable';
import { ArticleArea } from '../../../Styling/Layout/ContentArea';
import useFakturaForm from './useFakturaForm';


const FakturaForm = (props) => {

    let state = props.state

    /*const {
        formValues, formValidators, handleChange, formState, validateAll,
        hasErrors, errorMessages, extraErrors, setExtraErrors,
        submitForm, errorMessage, localeMessages, produktOptions, debtorOptions, debtorTypeOptions,
        onUploadFileChange, fileUploadRef, currencyOptions,
        uploadFaktura, finalizeFaktura, editFaktura, deleteFaktura, emptyForm
    } = props.props
    */

    const {
        formValues, formValidators, handleChange, formState, validateAll,
        hasErrors, errorMessages, extraErrors, setExtraErrors,
        submitForm, errorMessage, localeMessages, produktOptions, debtorOptions, debtorTypeOptions,
        onUploadFileChange, fileUploadRef, currencyOptions,
        uploadFaktura, finalizeFaktura, editFaktura, deleteFaktura, emptyForm
    } = useFakturaForm(props.state, props.dispatch, props.showToasts, props.localeMessages)

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                //state={state}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
            />
        )
    }

    const pStyle = {
        width: '80%'
    }

    
    const HighAmountAlert = () => {

        if (formState.belop) {
            let fBelop = parseFloat(formState.belop.replaceAll(' ', '').replaceAll(',','.'))
            if (fBelop > 1000000)
                return (
                    <Alert variant="info">Du har oppgitt et beløp større enn en million.  Kontroller at du ikke har tastet feil beløp.</Alert>
                )
        } 
        
        return null

    }
    


    return (
        <Container fluid style={pStyle}>

            <Form autocomplete="chrome-off" onSubmit={e => e.preventDefault()}>

                <ArticleArea>
                    <h1>{localeMessages.FK_Head} ({props.state.fakturaHeader})</h1>
                    <p>{localeMessages.FK_Caption}</p>
                </ArticleArea>

                <YellowRoundedContainer fluid style={{ padding: '15px' }}>

                    <HighAmountAlert />

                    {hasErrors && (
                        <Alert fluid variant='danger'>
                            {localeMessages.youHaveErrors}
                        </Alert>
                    )}

                    <BfYellowRowForm>
                        {getFormField(4, 'fakturanummer', null, null, false, 60)}
                        {getFormField(4, 'fakturatekst', null, null, false, 30)}
                        {getFormField(4, 'kid', null, null, false, 25)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(3, 'fakturadato', 'cleaveDate')}
                        {getFormField(3, 'forfallsdato', 'cleaveDate')}
                        {getFormField(3, 'valuta', 'select', currencyOptions)}
                        {getFormField(3, 'belop', 'cleaveAmount')}
                    </BfYellowRowForm>

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>
                            <BfFormLabelSm>{localeMessages.velgFilLabel}</BfFormLabelSm><br />
                            <input type="file" onChange={onUploadFileChange} ref={fileUploadRef} multiple="multiple" />
                        </Col>
                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>
                            <BfButtonBlue variant="primary" type="button" onClick={() => uploadFaktura()}>
                                {!formState.lineNo ? localeMessages.LeggTilFaktura : localeMessages.Oppdater}
                            </BfButtonBlue>
                            &nbsp;

                            <BfButtonBlue variant="primary" type="button"
                                onClick={() => emptyForm()} >
                                {localeMessages.ClearForm}
                            </BfButtonBlue>

                        </Col>
                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm />

                </YellowRoundedContainer>



            </Form>

            <br />

            <ArticleArea>
                <h1>{localeMessages.FL_Head}</h1>
                {(props.state.invoices.length === 0) && <p>{localeMessages.FL_Caption2}</p>}
                {(props.state.invoices.length !== 0) && <p>{localeMessages.FL_Caption}</p>}
            </ArticleArea>

            {(props.state.invoices.length !== 0) &&
                <Container fluid>
                    <BfTightRowForm>
                        <FakturaTable
                            fakturaListe={props.state.invoices}
                            editFaktura={editFaktura}
                            deleteFaktura={deleteFaktura}
                            messages={localeMessages}
                        />
                    </BfTightRowForm>

                    <BfTightRowForm>
                        <Col xs={12} md={12}>
                            <BfButtonBlue variant="primary" type="button"
                                onClick={() => finalizeFaktura()}
                                disabled={props.state.invoices.length === 0}>
                                {localeMessages.SendInn}
                            </BfButtonBlue>
                        </Col>
                    </BfTightRowForm>

                    <BfTightRowForm />

                </Container>
            }

        </Container>
    )
}

export default FakturaForm
