import React from 'react';
import { StyledDropdown } from '../../../components/useTable/UsePaginator'
import { Dropdown } from 'react-bootstrap'


export const FakturaMonthSelector = (props) => {

    return (
        <>
            {props.messages.faHentetFor}
            <StyledDropdown>
                <Dropdown.Toggle size="sm" variant="secondary">
                    {props.invMonths.caption}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {props.selectorValues.map(i =>
                        <Dropdown.Item
                            key={i.value}
                            onClick={() => {
                                props.setInvMonths({ value: i.value, caption: i.caption })
                            }}
                        >
                            {i.caption}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </StyledDropdown>
        </>
    )
}