export const messages = {
    nb: {

        toastSendBetalingFailed: 'Sending av betaling feilet. ',
        toastSendBetalingOk: 'Betaling registrert OK. ',

        BT1M_Overskrift: 'Registrer betaling',
        BT1M_paymentDate: 'Betaling registrert',
        BT1M_Belop: 'Beløp',
        BT1M_valuta: 'Valuta',
        BT1M_faktura: 'Velg faktura',
        BT1M_Ok: 'Lagre',
        BT1M_Avbryt: 'Avbryt',

        BT1M_DateToNew: 'Feil: Dato større enn dagens dato er ikke tillatt.',
        BT1M_DateToOld: 'Feil: Dato mer enn ett år tilbake er ikke tillatt.',
        BT1M_DateInvalid: 'Feil: Ugyldig dato',
    }
    
} 