export const messages = {
    nb: {
        Ex: `text`,
        toastGetReportFailed: 'Henting av PowerBI rapport feilet',
        toastGetReportOk: 'Vennligst vent, PowerBi rapport lastes',

        HEADER_T1: 'Velg Rapport',
        PARAGRAPH_P1: 'Velg rapport fra listen under. Trykk på knapp for å starte kjøring av rapporten.',
        
        
        
        RSC_T2: 'Planlagte rapporter',

        RSC_GRH1: 'Rapport',
        RSC_GRH2: 'Gruppe',
        RSC_GRH3: 'Siste kjørt',
        RSC_GRH4: 'Intervall',
        RSC_GRH5: 'Planlagt kjørt',
        RSC_GRH6: 'Varsler',        

        //schedIntvalName: 'Intervall',
    }
}