
import { powerBiActions } from "./PowerBiActions";

const initialState = {
    data: {},
    params: '',
    timestamp: 0,
    fetched: false,
    loading: false,
    error: ''
}

const powerBiReducer = (state = initialState, action) => {

    let timeStamp = new Date().getTime()

    switch (action.type) {

        case powerBiActions.PBI_REPORT_LOADING:
            return {
                ...state,
                params: action.payload.reportParams,
                fetched: false,
                loading: true,
                error: null
            };

        case powerBiActions.PBI_REPORT_DATA_FETCHED:

            return {
                ...state,
                data: { ...action.payload.data },
                params: action.payload.reportParams,
                timestamp: timeStamp,
                fetched: true,
                loading: false,
                error: null
            };

        case powerBiActions.PBI_REPORT_ERROR:
            return {
                ...state,
                params: '',
                loading: false,
                error: action.data
            }

        case powerBiActions.PBI_REPORT_CLEAR:
            return {
                ...initialState
            }
        
        default:
            return state;
    }
}

export default powerBiReducer