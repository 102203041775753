export const messages = {
    nb: {

        ML1M_Overskrift_ny: 'Send melding',
        ML1M_Overskrift_svar: 'Svar på melding',
        ML1M_Undersak: 'Dette er en undersak, melding bør legges inn på hovedsak.',
        MS1M_melding: 'Melding',
        ML1M_sak: 'Sak:',
        ML1M_forfall: 'Forfall',
        ML1M_oppgave_m: 'Melding',
        ML1M_oppgave_s: 'Svar',
        ML1M_Sendkopi: 'Send kopi',
        ML1M_Ok: 'Lagre',
        ML1M_Avbryt: 'Avbryt',

        toastSendMeldingFailed: 'En feil oppstod under sending av melding: ',
        toastMeldingUploadOk: 'Melding er sendt.',

        toastGetCaseDetailsFailed: 'Saken er oppdatert. Lasting på nytt feilet',
        toastGetCaseDetailsOk: 'Saken er oppdatert og lastet på nytt.'

        
    }
    
} 