import React, { useContext, useEffect, useState, useReducer, useCallback } from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl'
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './UserAdminContainer.i18n'

//import { ArticleArea } from '../../Styling/Layout/ContentArea'
//import { Container, Row, Col, Button, Form, InputGroup, FormControl } from "react-bootstrap";
//import { BfRowForm, BfTightRowForm, BfRowSpacer, BfFormLabel, BfFormLabelSm, BfFormControl, BfButtonBlue } from '../../Styling/styledForm/styledBlueForm'
import  EditProfile  from './editProfile/EditProfile'
//import styled from 'styled-components'
import SearchComponent from './search/SearchComponent';
import SearchResultComponent from './searchResult/SearchResultComponent'
//import useAxios from '../../hooks/useAxios'
import useUserAdminReducer from './useUserAdminReducer'
import { Alert } from "react-bootstrap";

import { mockData } from '../../assets/Brukeradmin'
import {Spinner} from '../../components/spinner/Spinner'

const UserAdminContainer = (props) => {

    const locale = React.useContext(LocaleContext);

    const { state, displayForms, resetContainer, showSearchForm, gotoSearchResult, 
        searchUsers, setCurrentUser, getUser, updateUser, createUser, deleteUser, 
        getKreditorListe, isAdminKreditor, copyKonserAdmin 
    } = useUserAdminReducer(messages[locale])
    
    const mockedData = mockData

    const [cases, setCases] = useState([])

    // klikk på menyen setter en prop med random verdi for å tvinge rerender av aktiv form
    useEffect(() => {
        //console.log('***RESET form***', JSON.stringify(props))
        resetContainer();
    }, [props]);


    return (

        <IntlProvider locale={locale} messages={messages[locale]}>
            
            {state.error && <Alert variant='danger'>{state.error}</Alert>}

            {state.savedMessage && <Alert variant='info'>{state.savedMessage}</Alert>}

            {(state.activeForm == displayForms.SEARCHFORM) && <SearchComponent
                searchUsers={searchUsers}
                createUser={createUser} 
                isAdminKreditor={isAdminKreditor}
                copyKonserAdmin={copyKonserAdmin}
                loading={state.loading}
                state={state}
            />}

            {(state.activeForm == displayForms.SEARCHRESULT) &&
                <SearchResultComponent
                    setCurrentUser={setCurrentUser}
                    saker={state.users}                 
                    loading={state.loading}
                    showSearchForm={showSearchForm}
                />
            }

            {(state.activeForm == displayForms.EDITFORM) &&
                <EditProfile
                    formValues={state.user}
                    foreningkreditorListe={state.currentForeningKreditorListe}
                    updateUser={updateUser}
                    createUser={createUser}
                    deleteUser={deleteUser}                    
                    getKreditorListe={getKreditorListe}
                    gotoSearchResult={gotoSearchResult}
                    loading={state.loading}
                    state={state}
                />    
            }

            {/*
            {JSON.stringify(props)}
            {state.activeForm}
            {JSON.stringify()}
             */} 

        </IntlProvider>
    )
}

export default UserAdminContainer