import React from 'react';
import { Container, Col, Form, Alert } from "react-bootstrap";
import { BfColInput } from '../../Styling/FormComponents/FormInput';
import { BfYellowRowForm, BfTightYellowRowForm, BfButtonBlue, YellowRoundedContainer } from '../../Styling/styledForm/styledBlueForm'
import { NavLink, Link } from 'react-router-dom';

export const ProfilForm = (props) => {

    const handleChange = props.handleChange
    const errorMessages = props.errorMessages
    const extraErrors = props.extraErrors
    const localeMessages = props.localeMessages
    const formState = props.formState
    const hasErrors = props.hasErrors
    const submitForm = props.submitForm
    const twoFactorEnabled = props.twoFactorEnabled

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        //console.log('GetformField:', fieldname, maxLength)
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}   
                autocomplete={fieldname}             
            />
        )
    }

    return (
        <YellowRoundedContainer fluid="md">
            <Form autoComplete="off">
                <Container >

                    {/*
                    Denne trigger i det bruker skriver noe i Nytt passord feltet, brukere har rapportert det som forvirrende
                    {hasErrors && (
                        <Alert variant='danger'>
                            {localeMessages.youHaveErrors}
                        </Alert>
                    )} */}

                    <BfYellowRowForm>
                        {getFormField(12, 'Navn', null, null, true, 50)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(12, 'Epost', null, null, true, 63)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(12, 'Passord', 'password', null, null, 50)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(12, 'Passord1', 'password', null, null, 50)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(12, 'Passord2', 'password', null, null, 50)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(12, 'PhoneNumber', null, null, null, 30)}
                    </BfYellowRowForm>

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>
                            <BfButtonBlue variant="primary" type="button" 
                                onClick={submitForm} disabled={hasErrors}>
                                {localeMessages.Lagre}
                            </BfButtonBlue>

                            &nbsp;&nbsp;&nbsp;

                            {!twoFactorEnabled &&
                                <NavLink to="/mfa">
                                    <BfButtonBlue variant="primary" type="button">
                                        Registrer tofaktor pålogging
                                    </BfButtonBlue>
                                </NavLink>
                            }

                            {twoFactorEnabled &&
                                <>
                                    <NavLink to="/mfa">
                                        <BfButtonBlue variant="primary" type="button">
                                            Slå av tofaktor pålogging
                                        </BfButtonBlue>
                                    </NavLink>
                                </>
                            }

                        </Col>
                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm />




                </Container>
            </Form>


        </YellowRoundedContainer>
    )
}