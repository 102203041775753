import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,

} from "../../../../Styling/styledModal/styledModals";


const BekreftOnHold = (props) => {

    const messages = props.stFuncs.messages   

    return (

        <StyledModal centered show={true} onHide={props.stFuncs.cancelHandler}>

            <StyledModalBody>
                <h4>{messages.BE_On_Hold_Header}</h4>
                <p style={{whiteSpace: "pre-line"}}>{props.stFuncs.confirmMessageOnHold}</p>
            </StyledModalBody>
           
            <StyledModalFooter>
                <Button variant="secondary" onClick={props.stFuncs.okHandler}>
                    {messages.BE_Ja}
                </Button>
                <Button variant="secondary" onClick={props.stFuncs.cancelHandler}>
                    {messages.BE_Nei}
                </Button>
            </StyledModalFooter>

        </StyledModal>
    );
};

export default BekreftOnHold;