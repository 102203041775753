import React from 'react';
import { IntlProvider, FormattedMessage } from 'react-intl'
import { asDecimal, asDate } from '../../../../reduxStore/saksdetaljer/caseMapper'

import { StyledKfTable } from './../../../../Styling/styledTable/StyledTable';
import CaseDetailsContext from '../../CaseDetailsContext'

import { useSelector } from 'react-redux';

const data = [
  { i: 0, c1: '', c2: 1231, c3: 123, c4: 2342 },
  { i: 1, c1: '', c2: '1 231 113.40', c3: 123, c4: '2,342,132.50' },
  { i: 2, c1: '', c2: 1231, c3: 123, c4: 2342 },
  { i: 3, c1: '', c2: 1231, c3: 123, c4: 2342 },
  { i: 4, c1: '', c2: 1231, c3: 123, c4: 2342 },
  { i: 5, c1: '', c2: 1231, c3: 123, c4: 2342 },
  { i: 6, c1: '', c2: 1231, c3: 123, c4: 2342 }
]

const CaDetReskontroComponent = (props) => {  

  const { localeMessages } = React.useContext(CaseDetailsContext);

  const state = useSelector(state => state.saksdetaljer)

  const printHeader = () => {
    return (
      <thead>
        <tr>
          <th></th>
          <th style={{ textAlign: 'right' }}>{localeMessages['ResK_H2']}</th>
          <th style={{ textAlign: 'right' }}>{localeMessages['ResK_H3']}</th>
          <th style={{ textAlign: 'right' }}>{localeMessages['ResK_H4']}</th>
        </tr>
      </thead>
    )
  }

  const printDecimal = (val) => {
    let res = val ? asDecimal(val) : asDecimal(0)
    //console.log(val, res)
    if ((val === undefined) || (val === 0))
      return '0.00'
    return (
      asDecimal(val)
    )
  }

  // Skriver bold for den siste {model.x ? model.x.trim() : ''}
  const printRow = (model, i) => {
    if (model.i < 7) {
      return (
        <tr key={model.i}>
          <td>{localeMessages['ResK_C1'][i]}</td>
          <td align="right">{printDecimal(model.c1)}</td>
          <td align="right">{printDecimal(model.c2)}</td>
          <td align="right">{printDecimal(model.c3)}</td>
        </tr>
      )
    } else {
      return (
        <tr key={model.i}>
          <td><b>{localeMessages['ResK_C1'][i]}</b></td>
          <td align="right"><b>{printDecimal(model.c1)}</b></td>
          <td align="right"><b>{printDecimal(model.c2)}</b></td>
          <td align="right"><b>{printDecimal(model.c3)}</b></td>
        </tr>
      )
    }

  }

  return (
    <StyledKfTable>
      {printHeader()}
      <tbody>
        {state.data.reskontro.map((row, i) => printRow(row, i))}
      </tbody>
    </StyledKfTable>   
  )
}

export default CaDetReskontroComponent