import { useCallback, useState, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import { messages } from './StengelisteContainer.i18n'
import { useSelector, useDispatch } from 'react-redux'
import { clearData, getStengeliste, sendInnValgteStengeKandidater, toggleAll, toggleSelected, toggleSelectedMoved, toggleSelectedOnHold } from '../../reduxStore/stengeliste/stengelisteActions'
import { useBekreftInnsending } from './modals/bekreftInnsending/useBekreftInnsending'
import { useBekreftFlytting } from './modals/bekreftInnsending/useBekreftFlytting'
import { CurrencyExchange } from 'react-bootstrap-icons'
import { useBekreftOnHold } from './modals/bekreftInnsending/useBekreftOnHold'

export const useStengelisteContainer = (locale) => {

    const showToasts = true;

    const localeMessages = messages[locale]

    // Activate global redux reducer
    const state = useSelector(state => state.stengeliste)
    const headerState = useSelector(state => state.sakslisteHeader)

    const dispatch = useDispatch()
    let selectedKreditor = headerState.kreditorId

    const [key, setKey] = useState('statusNyTab');

    let getNumDisabled = () => {

        let numDisabled = 0

        state.closingList.forEach(e => {
            if (e.disabled) {
                numDisabled++
            }
        })

        return numDisabled
    }

    let getNumOnHold = () => {

        let numDisabled = 0

        state.closingList.forEach(e => {
            if (e.onHold) {
                numDisabled++
            }
        })

        return numDisabled
    }

    let getNumMoved = () => {

        let numDisabled = 0

        state.closingList.forEach(e => {
            if (e.moved) {
                numDisabled++
            }
        })

        return numDisabled
    }

    let getSumDisabled = () => {

        let totalDisabled = 0.0

        state.closingList.forEach(e => {

            if (e.disabled) {
                totalDisabled += e.remainingTotal
            }
        })

        return totalDisabled
    }

    // Statusen som blir hentet basert på tab som blir valgt
    // Merk at paymentReceivedTab henter to statuser
    const tabStatusMap = {
        statusNyTab: 'NY',
        chosenTab: 'SENDT STENGING',
        paymentReceivedTab: 'BETALT ETTER STENGING',
        onHoldTab: 'SLETTET',
    };

    // Hent data hver gang kreditor blir endret i dropdown
    useEffect(() => {
        if (!headerState.kreditorId) {
            // TODO Vise feilmeldingsside 
        } else {
            let currentKreditor = headerState.kreditorliste.find(e => e.customerNo === headerState.kreditorId)
            // Dispatch hening av data
            // NB DepartementID trengs ikke settes, det finner man ut i C# Api
            dispatch(clearData())
            // Henter data hver gang bruker bytter tab, finner hvilken status basert på tab
            const tabStatus = tabStatusMap[key];
            if ((currentKreditor) && currentKreditor.stengeliste) {
                dispatch(getStengeliste(headerState.kreditorId, tabStatus, key, showToasts, localeMessages))
            }
        }
    }, [headerState.kreditorId, key])

    const toggleRow = (i, toggleValue) => {
        dispatch(toggleSelected(i, toggleValue))
    }

    const toggleOnHoldRow = (i, toggleValue) => {
        dispatch(toggleSelectedOnHold(i, toggleValue))
    }

    const toggleMovedRow = (i, toggleValue) => {
        dispatch(toggleSelectedMoved(i, toggleValue))
    }

    const toggleAllRows = toggleValue => dispatch(toggleAll(toggleValue))

    const sendInn = () => {
        console.log('OK')

        let stengeliste = state.closingList.filter(e => e.isSelected).map(e => e.closingListId)
        // Om status er "NY" kan vi sette den til "SENDT STENGING", om status er "SLETTET" (På hold) kan vi sette den til "NY"
        let newStatus = 'SENDT STENGING';

        const formData = new FormData()
        formData.append('CreditorNo', headerState.kreditorId)

        for (var i = 0; i < stengeliste.length; i++) {
            formData.append('ClosingListId[]', stengeliste[i]);
        }
        formData.append('NewStatus', newStatus)
        dispatch(sendInnValgteStengeKandidater(formData, newStatus, showToasts, localeMessages))
    }

    const flyttTilListe = () => {
        console.log('OK')

        let stengeliste = state.closingList.filter(e => e.isSelected).map(e => e.closingListId)
        // Om status er "NY" kan vi sette den til "SENDT STENGING", om status er "SLETTET" (På hold) kan vi sette den til "NY"
        let newStatus = 'NY';

        const formData = new FormData()
        formData.append('CreditorNo', headerState.kreditorId)

        for (var i = 0; i < stengeliste.length; i++) {
            formData.append('ClosingListId[]', stengeliste[i]);
        }
        formData.append('NewStatus', newStatus)
        dispatch(sendInnValgteStengeKandidater(formData, newStatus, showToasts, localeMessages))
    }

    const settPåHold = (selectedStengelisteId) => {
        console.log('OK')

        let newStatus = "SLETTET"

        const formData = new FormData()
        formData.append('CreditorNo', headerState.kreditorId)

        formData.append('ClosingListId[]', selectedStengelisteId)
        formData.append('NewStatus', newStatus)

        dispatch(sendInnValgteStengeKandidater(formData, newStatus, showToasts, localeMessages))
    }

    const confirmMessage = () => `Du har valgt ${state.antall_valgte} til stenging, 
    totalt beløp er ${new Intl.NumberFormat('nb-NO').format(state.sum_valgte, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}.\n
    Trykk "Fortsett" for å sende inn`

    const confirmMoveMessage = () => `Du har valgt ${state.antall_valgte} til flytting, ønsker du å flytte de til "På stengeliste"?\n
    Trykk "Fortsett" for å flytte`

    const confirmOnHoldMessage = () => `Er du sikker på at du vil sette denne saken på hold?`


    const bsFuncs = useBekreftInnsending(localeMessages, sendInn, confirmMessage)
    const fbFuncs = useBekreftFlytting(localeMessages, flyttTilListe, confirmMoveMessage)
    const stFuncs = useBekreftOnHold(localeMessages, settPåHold, confirmOnHoldMessage, toggleOnHoldRow)

    return {
        localeMessages, key, setKey, selectedKreditor, state, headerState, getNumDisabled, getNumOnHold, getNumMoved, getSumDisabled,
        toggleRow, toggleOnHoldRow, toggleMovedRow, toggleAllRows, bsFuncs, fbFuncs, stFuncs

    }



}