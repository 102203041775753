import { useContext } from 'react'
import jwt_decode from 'jwt-decode'
import * as actionTypes from '../state/reducers/actionTypes'
import authInstance from '../Instances/axios-auth'
import AuthContext from '../state/contexts/AuthContext'
import * as roleTypes from '../Utility/Types/roleTypes'
import { toast } from 'react-toastify';
import { getFromStorage, removeFromStorage, setStorage } from '../state/storageUtil'
import { resetStore } from '../reduxStore'
import { useDispatch } from 'react-redux'
import { getToolsMenu } from '../reduxStore/toolsMenu/toolsMenuActions'
import { getReportsScheduleList } from '../reduxStore/ReportMenu/reportMenuActions'
import { getCaseList } from '../reduxStore/saksliste/sakslisteActions'
import { useHistory } from 'react-router'
import CONFIG from '../config'

export function useAuth() {

    const globalDispatch = useDispatch()
    const history = useHistory()

    const resetReduxStore = () => {
        globalDispatch(resetStore())
    }

    // context
    const [state, dispatch] = useContext(AuthContext)

    const accessLevels = {
        SUPERVISOR: "Supervisor",
        USERADMIN: "UserAdmin",
        CUSTOMERADMIN: "CustomerAdmin",
        USER: "User"        
    }

    const storeTokenToSessionStorage = (idToken, expiresIn, refreshToken, userId) => {
        setStorage('kf.token', idToken)
        setStorage('kf.expirationDate', expiresIn)
        //sessionStorage.setItem('kf.token', idToken)
        //sessionStorage.setItem('kf.expirationDate', expiresIn)
        /*localStorage.setItem('kf.refreshToken', refreshToken)
        localStorage.setItem('kf.userid', userId)*/
    }

    const IS_POWERBI_ENABLED = CONFIG.POWERBI_ENABLED

    const tokenSignIn = (token, startPage) => {
        
        dispatch({ type: actionTypes.AUTH_START })

        resetReduxStore()

        try {
            const decodedToken = jwt_decode(token)
            const expirationDate = new Date(decodedToken.exp * 1000);
            //const roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]

            let payload = {
                token: token,
                role: decodedToken.Rolle,
                creditorClaims: retireveCreditorClaimsFromDecodedToken(decodedToken),
                name: decodedToken.Fullname,
                email: decodedToken.email,
                passwordUser: decodedToken.passwordUser,
                passwordUserId: decodedToken.passwordUserId,
                phonenumber: decodedToken.PhoneNumber,
                username: decodedToken.email,
                id: decodedToken.jti,
                assiociationId: decodedToken.ForeningsId,
                assiociationname: decodedToken.ForeningsNavn,
                bisnodeEnabled: decodedToken.BisNodeEnabled,
                twoFactorEnbled: decodedToken.TwoFactorEnabled,
                forceMfaDate: getSpaceOrDate (decodedToken.forceMfaDate),
                //new Date(decodedToken.forceMfaDate * 1000),
                forceMfaNow: decodedToken.forceMfaNow,
                bisLabDate: decodedToken.bisLabDate
            }

            storeTokenToSessionStorage(token, expirationDate, '', payload.email)

            checkAuthTimeout(decodedToken.exp)

            dispatch({
                type: actionTypes.AUTH_SUCCESS,
                payload: payload
            })

            // Hent toolsmeny, som krevere pålogging på server
            globalDispatch(getToolsMenu())
            //globalDispatch(getReportsMenu())

            // Ikke last i prod miljø
            if (IS_POWERBI_ENABLED) {
                //globalDispatch(getReportsScheduleList())
            }

            if ((payload.role !== accessLevels.SUPERVISOR) && (payload.role !== accessLevels.USERADMIN)  && (payload.creditorClaims.length > 0)) {
                globalDispatch(getCaseList('ALL', true, null))
            }

            if (startPage) {
                history.push(startPage)
            }


        } catch (error) {
            console.log(error)                       
            toast.error('Kunne ikke logge på med oppgitt token.', {autoClose: false})                
        }
                
    }
    
// ┌──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────┐
// │ Login via dialogboks med brukerId og passord                                                                         │
// └──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────┘
    const signIn = (email, password, mfaCode, loginAsUser) => {

        resetReduxStore()

        dispatch({ type: actionTypes.AUTH_START })

        var remTokenName = "sdtToken_" + email;
        let sdtToken = localStorage.getItem(remTokenName)

        const authData = {
            Username: email,
            Password: password,
            AuthenticatorCode: mfaCode,
            SdtToken: sdtToken,
            loginAsUser: loginAsUser
        }
        authInstance.post('api/ApiSecurity/Auth', authData)
            .then(response => {
                const decodedToken = jwt_decode(response.data.token)
                const expirationDate = new Date(decodedToken.exp * 1000);
                //const roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]

                let payload = {
                    token: response.data.token,                    
                    role: decodedToken.Rolle,
                    creditorClaims: retireveCreditorClaimsFromDecodedToken(decodedToken),
                    name: decodedToken.Fullname,
                    email: decodedToken.email,
                    passwordUser: decodedToken.passwordUser,
                    passwordUserId: decodedToken.passwordUserId,
                    phonenumber: decodedToken.PhoneNumber,
                    username: decodedToken.email,
                    id: decodedToken.jti,
                    assiociationId: decodedToken.ForeningsId,
                    assiociationname: decodedToken.ForeningsNavn,
                    bisnodeEnabled: decodedToken.BisNodeEnabled,
                    twoFactorEnbled: decodedToken.TwoFactorEnabled,
                    forceMfaDate: getSpaceOrDate (decodedToken.forceMfaDate),
                    //new Date(decodedToken.forceMfaDate * 1000),
                    forceMfaNow: decodedToken.forceMfaNow,
                    bisLabDate: decodedToken.bisLabDate
                }

                storeTokenToSessionStorage(payload.token, expirationDate, response.refreshToken, response.userId)

                
                // Lagre secure devoce tokenet i localstorage og send det med hver request 
                if (response.data.sdtToken) {
                    var sdtToken = response.data.sdtToken;
                    var secureDeviceTokenName = "sdtToken_" + decodedToken.email;
                    localStorage.setItem(secureDeviceTokenName, sdtToken)
                }

                checkAuthTimeout(decodedToken.exp)
                dispatch({
                    type: actionTypes.AUTH_SUCCESS,
                    payload: payload
                })

                // Hent toolsmeny, som krevere pålogging på server
                globalDispatch(getToolsMenu())
                //globalDispatch(getReportsMenu())

                // Ikke last i prod miljø
                if (IS_POWERBI_ENABLED) {
                    //globalDispatch(getReportsScheduleList())
                }
                
                if ((payload.role !== accessLevels.SUPERVISOR) && (payload.role !== accessLevels.USERADMIN) && (payload.creditorClaims.length > 0)) {
                    globalDispatch(getCaseList('ALL', true, null))
                }

            })
            .catch(error => {

                console.log(error)

                // MFA => Check if MFA code is required to log in
                if ((error.response?.data === 'MFA REQUIRED') && (error.response?.status === 401))  {
                    dispatch({
                        type: actionTypes.AUTH_MFA,
                        payload: {}
                    })
                    toast.info("Krever tofaktor pålogging")

                } else {
                    if (error.message === 'Network Error') {
                        toast.error('FEIL: Server for pålogging svarer ikke.', {autoClose: false})
                    } else {
                        toast.error('Kunne ikke logge på med oppgitt bruker og passord.', {autoClose: false})
                    }
                    dispatch({
                        type: actionTypes.AUTH_FAIL,
                        payload: {
                            error: error
                        }
                    })
                }                           
            })
        
    }


    
    const logOut = () => {

        resetReduxStore()

        removeFromStorage('kf.token')
        removeFromStorage('kf.expirationDate')
        //sessionStorage.removeItem('kf.token')
        //sessionStorage.removeItem('kf.expirationDate')
        /*localStorage.removeItem('kf.refreshToken')
        localStorage.removeItem('kf.userid')*/
        dispatch({
            type: actionTypes.AUTH_LOGOUT
        })

        //history.push('/Login')

        //dnnRedirectKred(`https://www.kred.no/bedrift/login/`)
    }

    const getSpaceOrDate = e => e ? new Date(e * 1000) : '' 


    // ------------------------------------------------------------------------
    // Denne kjøres når du laster applikasjonen på nytt, Dersom du har token
    // På disk, og det er ok, sendes du ikke til login
    // Spesialcase: Om du kommer fra regnskapssystem integrasjon, vil du først
    //              bli logget inn, det legges token på disk, så kjøres denne
    // ------------------------------------------------------------------------
    const authCheckState = () => {

        resetReduxStore()

        console.log("[useAuth.js] authCheckState")

        const token = getFromStorage('kf.token')
        if (!token) {
            logOut()
        }
        else {

            const decodedToken = jwt_decode(token)
            const expirationDate = new Date(decodedToken.exp * 1000);
            const now = new Date()
                                
            if (expirationDate > new Date()) {
           
                let payload = {
                    token: token,                    
                    role: decodedToken.Rolle,
                    creditorClaims: retireveCreditorClaimsFromDecodedToken(decodedToken),
                    name: decodedToken.Fullname,
                    email: decodedToken.email,
                    username: decodedToken.email,
                    phonenumber: decodedToken.PhoneNumber,
                    passwordUser: decodedToken.passwordUser,
                    passwordUserId: decodedToken.passwordUserId,
                    id: decodedToken.jti,
                    assiociationId: decodedToken.ForeningsId,
                    assiociationname: decodedToken.ForeningsNavn,
                    bisnodeEnabled: decodedToken.BisNodeEnabled,
                    twoFactorEnbled: decodedToken.TwoFactorEnabled,
                    forceMfaDate: getSpaceOrDate (decodedToken.forceMfaDate),
                    //new Date(decodedToken.forceMfaDate * 1000),
                    forceMfaNow: decodedToken.forceMfaNow,
                    bisLabDate: decodedToken.bisLabDate
                }

                checkAuthTimeout(decodedToken.exp)
                dispatch({
                    type: actionTypes.AUTH_SUCCESS,
                    payload: payload
                })

                // Hent toolsmeny, som krevere pålogging på server
                globalDispatch(getToolsMenu())
                //globalDispatch(getReportsMenu())
                
                // Ikke last i prod miljø
                if (IS_POWERBI_ENABLED) {
                    //globalDispatch(getReportsScheduleList())
                }

                if (payload.role !== accessLevels.SUPERVISOR && payload.role !== accessLevels.USERADMIN) {
                    globalDispatch(getCaseList('ALL', true, null))
                }

            }
            else {
                logOut()
            }
        }
    }

    const minutesLeftOfToken = () => {
        
        let now = new Date();

        const token = getFromStorage('kf.token')
        const decodedToken = jwt_decode(token)
        let expirationDateTime = new Date(0)
        expirationDateTime.setUTCSeconds(decodedToken.exp)
      
        var diff = expirationDateTime - now

        //console.log ('minutesLeftOfToken, diff', diff)

        var minutes = Math.round((diff / 1000) / 60);
        
        console.log (`Token expires in ${minutes} minutes.` )

        return minutes    
        
    }


    // ------------------------------------------------------------------------
    // Her settes det opp en timer funksjon som logger deg ut når tokenet går 
    // ut.
    // ------------------------------------------------------------------------
    const checkAuthTimeout = (expirationTime) => {
        /*
        FLYTTET UT TIL HOVEDMENYEN
        var dateTimenowUnixMS = (new Date().getTime())
        var expirationTimeInMS = expirationTime * 1000;
        var elapsingTime = expirationTimeInMS - dateTimenowUnixMS

        setTimeout(() => {
            //LOGGED OUT            
            console.log("Logged out!")                       
            //logOut()

        }, elapsingTime)
        */
    }

    const retireveCreditorClaimsFromDecodedToken = (decodedToken) => {
        let claims = []
        Object.keys(decodedToken).filter(function (k) {
            return k.includes('Kreditor:')
        }).forEach(
            claim => {
                const newClaim = {
                    [claim.split(':')[1]]: decodedToken[claim]
                }
                claims.push(newClaim)
            }
        );
        return claims
    }

    const removeMfa = () => {
        dispatch({
            type: actionTypes.AUTH_NO_MFA,
            payload: {}
        })
        toast.info("Tofaktor pålogging slått av.")        
    }

    const setMfa = () => {
        dispatch({
            type: actionTypes.AUTH_MFA,
            payload: {}
        })
        toast.info("Tofaktor pålogging er aktiv.")
    }

    const isSupervisor = () => state.role === accessLevels.SUPERVISOR
    const isForeningSupervisor = () => state.role === accessLevels.USERADMIN
    const isAdminKreditor = () => state.role === accessLevels.CUSTOMERADMIN  
    const isUser = () => state.role === accessLevels.USER
    const hasBisnode = () => state.user.bisnodeenabled === 'true'
    const forceMfaNow = () => state.user.forceMfaNow !== 'false' 
    
    return {
        tokenSignIn,
        signIn,
        logOut,
        authCheckState,
        isAuthenticated: state.authenticated,
        minutesLeftOfToken,
        isSupervisor,
        isForeningSupervisor,
        isAdminKreditor,
        isUser,
        creditors: state.claims.creditors,
        loading: state.loading,
        user: state.user,
        role: state.role,
        forening: state.user.forening,
        checkAuthTimeout,
        error: state.error,
        accessLevels,
        hasBisnode,
        removeMfa,
        setMfa,
        //hasMfa,
        state,
        forceMfaDate: state.user.forceMfaDate,
        forceMfaNow
    }
}