export const messages = {
    nb: {
        caseListFound: 'Hentet ${poster} saker fra server. (redux)',
        caseListNone: 'Ingen saker funnet',
        caseListErr: 'En feil oppstod under henting av saker',
        IndexCaption: 'Hjem',
        UserAdminCaption: 'Brukere',
        LoginCaption: `Logg på`,
        saksListeCaption: 'Aktive',
        historikkCaption: 'Avsluttet',
        alleCaption: 'Alle',
        
        toastGetCaseDetailsOk: 'Sak hentet fra databasen OK. ',
        toastGetCaseDetailsFailed: 'Henting av saksdetaljer feilet. ',
    }
}