import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { BfCheckboxContainer, BfCheckboxInput, BfButtonBlue } from '../../Styling/styledForm/styledBlueForm'
import {
  StyledModal,
  StyledModalBody,
  StyledModalFooter,

} from "../../Styling/styledModal/styledModals";
import styled from 'styled-components';


const CheckboxText = styled.span`
  font-size: 1.2rem; 
  padding-left: 20px;
  text-align: center;
  font-family: "Fellix", "sans-serif";
`;

export const BislabTermsModal = ({ show, onHide, okHandler, cancelHandler, header, message, checkboxText, okCaption, cancelCaption, pdfBase64, loading, viewOnlyMode }) => {
  const [checked, setChecked] = useState(false);

  const handleOkClick = () => {
    if (checked) {
      okHandler();
    }
  };

  const handleCancelClick = () => {
    cancelHandler();
  };

  const handleChange = () => {
    setChecked(!checked);
  };


  return (
    <StyledModal show={show} onHide={onHide} centered size="lg" style={{ transform: 'translate(-15%, 0%)' }} >
      <StyledModalBody style={{ width: '150%' }}>
        {!viewOnlyMode && (<h4>{header}</h4>)}
        {!viewOnlyMode && (<p>{message}</p>)}
        {!viewOnlyMode && (<p>Vennligst les gjennom avtalen og godta om du ønsker å kunne bruke kredittsjekk for denne kreditoren.</p>)}
        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : (
          pdfBase64 ? (
            <iframe
              title="PDF Viewer"
              src={pdfBase64}
              style={{ width: '100%', height: '70vh', border: 'none' }}
            />
          ) : (
            <p>Noe gikk galt ved lasting av dokument</p>
          )
        )}
      </StyledModalBody>

      <StyledModalFooter style={{ width: '150%', padding: '10px 10px 20px' }}>
        {!viewOnlyMode && (<BfCheckboxContainer style={{display: 'flex'}}>
          <BfCheckboxInput
            checked={checked}
            onChange={handleChange}
            disabled={false}
          />
          <CheckboxText>{checkboxText}</CheckboxText>

        </BfCheckboxContainer>)}
        <div style={{ display: 'flex', width: '100%', padding: '30px 0 30px 0' }}>
          {!viewOnlyMode && (<BfButtonBlue variant="secondary" onClick={handleOkClick} disabled={!checked}>
            {okCaption}
          </BfButtonBlue>)}
          &nbsp;
          &nbsp;
          <BfButtonBlue variant="primary" onClick={handleCancelClick}>
            {cancelCaption}
          </BfButtonBlue>
        </div>
      </StyledModalFooter>
    </StyledModal>
  );
};

