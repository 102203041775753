import React, { useState } from 'react';
import { IntlProvider, FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl'
import LocaleContext from '../../../state/contexts/LocaleContext'
import { Container, Col } from "react-bootstrap";
import { BfRowForm } from '../../../Styling/styledForm/styledBlueForm'
import UseTableSorter from '../../../components/useTable/UseTable'
import usePaginator from '../../../components/useTable/UsePaginator'
import { StyledKfTable, StyledNavLink, StyledPropotionalNavLink } from './../../../Styling/styledTable/StyledTable';

const CasesMRUTable = (props) => {

    const locale = React.useContext(LocaleContext);    
    //const localeMessages = props.messages[locale]

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down'); // default
    const [currentField, setCurrentField] = useState('visitedDate');    // feltnavn
    const [currentType, setCurrentType] = useState('string'); // num

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10)
    const [activePage, setActivePage] = useState(1);

    //const mockedData = mockData
    let casesMRU = [...props.data]
    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )
    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        casesMRU.sort(t1GetSortFunc().fn),
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )
    
    // Styrer sortering
    const _VisitedDate = 'visitedDate'
    const _DebitorNavn = 'debitorNavn'
    const _RefNr = 'refNr'
    const _SaksNr = 'saksNr'
    const _Saldo = 'saldo'
    const _Status = 'status'
    
    // Styrer header i18n
    const _HVisitedDate = 'visitedDate'
    const _HDebitorNavn = 'debitorNavn'
    const _HRefNr = 'refNr'
    const _HSaksNr = 'saksNr'
    const _HSaldo = 'saldo'
    const _HStatus = 'status'

    const printHeader = () => {
        return (
            <thead>
                <tr>
                    <th onClick={() => t1ChangeSortField(_VisitedDate, 'datetime')}><FormattedMessage id={_HVisitedDate} />{t1IndicaterIcon(_VisitedDate)}</th>
                    <th onClick={() => t1ChangeSortField(_SaksNr, 'string')}><FormattedMessage id={_HSaksNr} />{t1IndicaterIcon(_SaksNr)}</th>
                    <th onClick={() => t1ChangeSortField(_DebitorNavn, 'string')}><FormattedMessage id={_HDebitorNavn} />{t1IndicaterIcon(_DebitorNavn)}</th>
                    <th onClick={() => t1ChangeSortField(_RefNr, 'string')}><FormattedMessage id={_HRefNr} />{t1IndicaterIcon(_RefNr)}</th>
                    <th style={{ textAlign:'right'}} onClick={() => t1ChangeSortField(_Saldo, 'num')}><FormattedMessage id={_HSaldo} />{t1IndicaterIcon(_Saldo)}</th>
                    <th onClick={() => t1ChangeSortField(_Status, 'string')}><FormattedMessage id={_HStatus} />{t1IndicaterIcon(_Status)}</th>
                </tr>
            </thead>
        )
    }

    const printRow = (model) => {

        let caseDetUri = '/CaseDetails/' + model.crNo + '/' + model.saksNr

        return (
            <tr key={model.id}>
                <td>
                    <FormattedDate value={model.visitedDate} day="2-digit" month="2-digit" year="numeric" />
                </td>
                <td>
                    <StyledPropotionalNavLink to={caseDetUri}>{model.saksNr}</StyledPropotionalNavLink>                    
                </td>
                <td>{model.debitorNavn}</td>
                <td>{model.refNr}</td>                
                <td align="right">{<FormattedNumber
                    value={model.saldo}
                    style='decimal'
                    minimumFractionDigits={2}
                />}</td>
                <td>{model.status}</td>
            </tr>
        )
    }


    return (
        <IntlProvider locale={locale} messages={props.messages[locale]}>
           
            <Container fluid="true">            

                <BfRowForm>

                    <Col xs={12} md={12}>


                        <PaginationDropdown />
                        <StyledKfTable>
                            {printHeader()}

                            <tbody>

                                <Paginate>
                                    {row =>
                                        printRow(row)
                                    }
                                </Paginate>

                            </tbody>

                        </StyledKfTable>
                        <br />
                        <PaginationNav /><PaginationIndex />
                        <br /><br />


                    </Col>

                </BfRowForm>                

            </Container>


        </IntlProvider>
    )
}

export default CasesMRUTable