import React, { useEffect } from 'react';
import { useAuth } from "../../hooks/useAuth";
import _ from 'lodash'
import OversiktContainer from '../oversikt/OversiktContainer';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';


export const TokenLogin = (props) => {

    //console.log('TokenLogin, ', props)
    // http://localhost:3000/#/tokenlogin/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNhdG9Aa3JlZC5ubyIsImp0aSI6Ijg1MGZkODVmLTk4OWItNGUzMi1hZDhhLWYzMTI4OTQ4ZTUyNiIsIkZ1bGxuYW1lIjoiQ2F0byBGaW5zw6VzIiwiQ29uY2VudFVzZXJOYW1lIjoiY2F0byIsIkV4dHJhSW5mbzEiOiIiLCJFeHRyYUluZm8yIjoiIiwiUGhvbmVOdW1iZXIiOiI0ODIgNDQgNDQ2IiwiUm9sbGUiOiJVc2VyIiwiRm9yZW5pbmdzSWQiOiIzMCIsIkZvcmVuaW5nc05hdm4iOiJLcmVkaXRvckZvcmVuaW5nZW4gVmVzdCIsIkJpc05vZGVFbmFibGVkIjoidHJ1ZSIsIkJpc05vZGVVc2VyIjoib3NoMjA0IiwiQmlzTm9kZVBhc3N3b3JkIjoib3NpZDQ0NCIsIktyZWRpdG9yOkRFS0tNRVNURVJFTiBBUyI6IjE4NDEzOTc7Nzc2MjgwOzMwOyIsIktyZWRpdG9yOk5PUkdFU0RFS0sgQVMoMjAwMDgyKSI6IjgzMDIwODsyMDAwODI7MzA7IiwiZXhwIjoxNjM2NTc1NzExLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdCJ9.3-V3esfdUmCwwtTp48f5qF8ruEWzctqADM-aFEs9U1g

    // /200082/3S6DGP
    //

    //  http://localhost:3000/#/tokenlogincasedet/200082/3S6DGP/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNhdG9Aa3JlZC5ubyIsImp0aSI6Ijg1MGZkODVmLTk4OWItNGUzMi1hZDhhLWYzMTI4OTQ4ZTUyNiIsIkZ1bGxuYW1lIjoiQ2F0byBGaW5zw6VzIiwiQ29uY2VudFVzZXJOYW1lIjoiY2F0byIsIkV4dHJhSW5mbzEiOiIiLCJFeHRyYUluZm8yIjoiIiwiUGhvbmVOdW1iZXIiOiI0ODIgNDQgNDQ2IiwiUm9sbGUiOiJVc2VyIiwiRm9yZW5pbmdzSWQiOiIzMCIsIkZvcmVuaW5nc05hdm4iOiJLcmVkaXRvckZvcmVuaW5nZW4gVmVzdCIsIkJpc05vZGVFbmFibGVkIjoidHJ1ZSIsIkJpc05vZGVVc2VyIjoib3NoMjA0IiwiQmlzTm9kZVBhc3N3b3JkIjoib3NpZDQ0NCIsIktyZWRpdG9yOkRFS0tNRVNURVJFTiBBUyI6IjE4NDEzOTc7Nzc2MjgwOzMwOyIsIktyZWRpdG9yOk5PUkdFU0RFS0sgQVMoMjAwMDgyKSI6IjgzMDIwODsyMDAwODI7MzA7IiwiZXhwIjoxNjM2NTc1NzExLCJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdCJ9.3-V3esfdUmCwwtTp48f5qF8ruEWzctqADM-aFEs9U1g    
    const auth = useAuth();
    

    useEffect(() => {
        console.log('Tokenlogin, token in useeffect: ', props.token)
      
        let startPage = '/Overview' 

        if (props.token) {
            auth.tokenSignIn(props.token, startPage)            
        } else {
            console.log('Tokenlogin, BUT NO TOKEN?!')
        }
    }, [props.token])
  
    return (
        <>
            <OverlaySpinner />
            <p>
                Validerer pålogging med direktelogin til kunderportalen..
            </p>
        </>
    )
}