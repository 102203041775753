export const messages = {
    nb: {

        creditRating: 'Kredittvurdering',

        checkTitle: `Kredittsjekk`,
        checkParagraph1: `Sjekk hvor betalingsdyktig kunden din er med en kredittsjekk.`,
        formFieldTitle: `Org nummer eller personnummer`,
        orgOrPersonNrLabel:  `Org eller personnummer`,

        GH_name: 'Navn',
        GH_type: 'Type',
        GH_creditMark: 'Karakter',
        GH_creditScore: 'Score',
        GH_creditRating: 'Vurdering',
        GH_date: 'Dato kredittsjekk',
        GH_recommended: 'Anbefalt kredittgrense',
        GH_performNewCheck: 'Utfør ny kredittsjekk',
        GH_caseNumber: 'Fødselsnr/Orgnnr',
        GH_debtorNo: 'Debitornummer',

        newCheckButton: 'Utfør kredittsjekk',
        deactivateButton: 'Deaktiver',
        updateCheckButton: 'Oppdater tall',
        showTermsButton: 'Vis avtale',
        openTermsButton: 'Les avtale',
        mustAcceptTitle: 'Avtale må godkjennes',
        cannotUpdatePersonButton: 'Rad mangler personnummer',
        cannotUpdateCompanyButton: 'Rad mangler orgnummer',
        credit_ratingLabel: 'Karakter',
        credit_scoreLabel: 'Score',
        credit_colorLabel: 'Vurdering',
        score_explanationLabel: 'Forklaring',
        recommended_limitLabel: 'Anbefalt kredittgrense',
        deactivate_title: 'Deaktiver kredittsjekk',
        deactivate_text: 'Om du ønsker å deaktivere kredittsjekk for denne kreditor kan du trykke på knappen under.',
        creditCheck_failed: 'Noe gikk galt ved henting av kredittvurdering',
        howToCheck_title: 'Hvordan fungerer kredittsjekk?',
        howToCheck_text: 'Kredittopplysningsforetaket innhenter kredittopplysninger, blant annet om inntekt, gjeld, alder, bosted, jobbsituasjon og eventuelle betalingsanmerkninger eller inkassosaker, fra offentlige kilder. Basert på innhentet informasjon utarbeider kredittopplysningsforetaket en såkalt kredittscore som sier noe om personen eller foretaket som vurderes er kredittverdig. Du søker gjennom vår løsning, og vil bli etterfakturert for tjenesten. Raskt og enkelt!',
        openTerms_text: 'Om du ønsker å lese avtalen på nytt kan du trykke  ',
        termsAgreedDate_text: 'Avtale inngått',
        download: 'Last ned',
        refreshRow: 'Rad vil bli oppdatert når listen blir hentet på nytt.',

        accept_terms: 'Godta avtale',
        terms_caption: 'For å kunne gjøre kredittsjekk må du først lese og godta avtalen under.',
        checkbox_text: 'Jeg godtar avtalen',
        accept: 'Godta',
        cancel: 'Avbryt',

        confirm_action_title: 'Du er i ferd med å utføre en kredittsjekk!',
        confirm_action_person: 'En person som blir kredittvurdert, vil få et gjenpartsbrev som informerer om at det er gjort en slik vurdering og hvem som har utført sjekken. Tjenesten vil bli etterfakturert.',
        confirm_action_org: 'Tjenesten vil bli etterfakturert.',
        confirm_action_ok: 'Utfør kredittsjekk',
        confirm_action_cancel: 'Avbryt',

        kreditorNoSel: 'Ingen kreditor er valgt',
        filterNone: 'Ingen på listen',

        toastGetDebitorsFailed: 'Henting av debitorer for valgt kreditor feilet. ',
        toastGetDebitorsOk: 'Hentet debitorer for valgt kreditor',

        toastGetBasicDataFailed: 'Basisdata (valutakoder, landkoder og postnummerlist) feilet. ',
        toastGetBasicDataOk: 'Basisdata (valutakoder, landkoder og postnummerlist) hentet.',

        toastGetCompanyRatingFailed: 'Henting av kredittvurdering feilet. Sjekk om orgnummer ',
        toastGetCompanyRatingOk: 'Hentet kredittvurdering for orgnummer ',

        toastGetPersonRatingFailed: 'Henting av kredittvurdering feilet. Sjekk om fødselsnummer ',
        toastGetPersonRatingOk: 'Hentet kredittvurdering for personnummer ',

        toastGetRatingInfoFailed: 'Sjekk om kreditor kan utføre kredittsjekk feilet. ',
        toastGetRatingInfoNoGo: 'Denne kreditor kan ikke utføre kredittsjekk. ',
        toastGetRatingInfoOk: 'Denne kreditor har signert avtale for å kunne utføre kredittsjekk. ',

        toastGetBislabTermsFailed: 'Henting av avtale feilet. ',
        toastGetBislabTermsOk: 'Hentet avtale for signering. ',

        toastAcceptRatingFailed: 'Signering av avtale feilet for bruker ',
        toastAcceptRatingOk: 'Avtalen har blitt signert for bruker ',

        toastUpdateDebtorRatingFailed: 'Oppdatering av kredittvurdering feilet for debitor ',
        toastUpdateDebtorRatingOk: 'Kredittvurdering har blitt oppdatert for debitor ',

        toastDisableDebtorRatingFailed: 'Deaktivering av kredittsjekk feilet for kreditor ',
        toastDisableDebtorRatingOk: 'Kredittsjekk har blitt deaktivert for kreditor ',

        toastNoUsersError: 'Ingen brukere funnen (en feil har oppstått',
        toastNoUsersFound: 'Ingen brukere tilfredstillet kriteriene',
        toastFoundUsers: 'Lastet ${poster} rader med brukere og kreditorer',    
        
        toastNoUserError: 'Kan ikke laste bruker (en feil har oppstått)',
        toastNoUserFound: 'Finner ikke bruker i database.',
        toastFoundUser: 'Brukerprofile lastet fra server.',

        toastUserHasBislab: 'Bruker har signert avtale for kredittvurdering',
        toastUserNoBislab: 'Bruker har ikke signert avtale for kredittvurdering',

    }
}

