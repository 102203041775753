import { useContext, useEffect, useState } from "react";

export const useBekreftInnsending = (messages, onOk, confirmMessageFunc) => {

    const showToasts = true;

    const [dialogVisible, setDialogVisible] = useState(false)
    const [confirmMessage, setConfirmMessage] = useState('')

    const cancelHandler = e => {
        setDialogVisible(false)       
    }

    const okHandler = e => {
        onOk()
        setDialogVisible(false)
        
    }

    const showDialog = () => {        
        setConfirmMessage(confirmMessageFunc())
        setDialogVisible(true)
    } 

    return {dialogVisible, showDialog, cancelHandler, messages, confirmMessage, okHandler}

}