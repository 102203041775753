/*

How to:

1. Denne modulen skal kjøres i kontaineren.
2. Den bruker lokal state for å angi hvilket vindu som evt skal vises, må eksporteres til containeren
3. Containeren skal importere komponent for modale 
4. Den må eksportere alle metoder som skal benyttes av de modale, 
   disse sendes inn som properties i fra containeren
5. Ideelt bør en trukket sak fjernes fra sakslisten/endre status?

*/

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosTokenInstance from "../../../Instances/axiosTokenInstance";
import LocaleContext from "../../../state/contexts/LocaleContext";
import { messages } from './utsettSak.i18n'
import _ from 'lodash'
import { useAuth } from "../../../hooks/useAuth";

export const useUtsettSakModal = (setErrorMessage, setMessage, setShowSpinner) => {
    
    const auth = useAuth()
    const locale = useContext(LocaleContext);
    const utsettSakMessages = messages[locale]
    
    const showToasts = true;

    // Styring av modale
    const [showUtsettSakModal, setShowUtsettSakModal] = useState(false)
    
    // Parametre til rest kallene
    const [creditorNo, setCreditorNo] = useState('')
    const [caseNo, setCaseNo] = useState('')
        
    // RESETS MODAL BOXES AND STATE
    const resetModal = () => {
        
        setErrorMessage('')
        setMessage('')
                                
        setShowSpinner(false)
    }
    
    useEffect(() => {        
        resetModal()
        
    }, [creditorNo, caseNo])

    // TRIGGER FOR MODAL SEQUENCE
    const showError = (errorMessage) => {
        (showToasts && toast.error(errorMessage, {autoClose: false}))
        setErrorMessage(errorMessage)
        console.error('TrekkSakModal: ', errorMessage)
    }

    const showMessage = (message) => {
        (showToasts && toast.info(message))
        setMessage(message)
        console.log('TrekkSakModal: ', message)
    }

    const showUtsettSakDialog = (creditorNo, caseNo) => {
        setErrorMessage('')
        setMessage('')

        setCreditorNo(creditorNo)
        setCaseNo(caseNo)
        setShowUtsettSakModal(true)
    }

    const cancelHandler = e => {
        setShowUtsettSakModal(false)       
        setShowSpinner(false)
    } 

    const getErrorMessage = (err) => {
        if (_.has(err, 'response.data.title')) {
            return err.response.data.title
        }
        else if (_.has(err, 'response.data'))
        {
            return err.response.data
        }
        else if (_.has(err, 'response.statusText')) {
            return err.response.statusText
        }
        else
        {
            return err.message.toString()
        }
    }

    const OkHandler = (dager, cause) => {

        const toastUtsettSakOk = utsettSakMessages['toastUtsettSakOk']
        const toastUtsettSakFailed = utsettSakMessages['toastUtsettSakFailed']

        setShowSpinner(true)

        let daysToPostPone = 0;
        if (dager === '7d') {
            daysToPostPone = 7
        } else if (dager === '14d') {
            daysToPostPone = 14
        } 
        /*else if (dager === '30d') {
            daysToPostPone = 30
        }*/
    
        let postParams = {
            'CaseNo': caseNo,
            'CreditorNo': creditorNo,
            'DaysToPostpone': daysToPostPone,
            'DepartmentId': -1,
            'TerminationCause': `Utsatt av ${auth.user.name} i Kundeportal pga: ${cause}.`
        }
            
        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/sak/UtsettSak`,
                data: postParams
            })
            .then((result) => {
                console.log(`/api/sak/UtsettSak, ${creditorNo}/${caseNo} returnerte:`, result)
            
                setShowUtsettSakModal(false)
                setShowSpinner(false)
            
                if (result.status !== 200) {
                    showError(toastUtsettSakFailed)
                } else {             
                    showMessage(`${toastUtsettSakOk}  (saksnr: ${caseNo})`)
                }
                
            })
            .catch((err) => {
                setShowSpinner(false)
                setShowUtsettSakModal(false)
                showError(toastUtsettSakFailed + ' ' + getErrorMessage(err))
            })
    }
    

    return {showUtsettSakModal, showUtsettSakDialog, OkHandler, cancelHandler, utsettSakMessages}

}
