export const messages = {
    nb: {
        Header1: `Login på Kundeportalen`,
        Paragraph1: `Full oversikt over ditt kundeforhold og løpende inkassosaker hos Kreditorforeningen.`,
        EmailCap: 'Oppgi epostadresse',
        EmailErr: 'Feltet er påkrevd, vennligs oppgi en gyldig epostadresse',
        mfaCodeCap: 'Oppgi tofaktor kode',
        mfaCodeErr: 'Feltet er påkrevd, vennligs oppgi en gyldig epostadresse',
        PassCap: 'Oppgi passord',
        PassErr: 'Feltet er påkrevd, oppgi passord',

        EmailAsCap: 'Logg in på vegne av bruker',
        EmailAsErr: 'Feltet er påkrevd, vennligs oppgi en gyldig epostadresse',

        SubButCap: 'Logg inn',
        LoginFailedErr : 'Pålogging med angitt bruker og passord feilet, korriger og prøv igjen.',
        FormNotValidErr: 'Du må fylle ut begge feltene før du kan logge på.',
        
        mfaModalHeader: 'pålogging krever tofaktor kode',
        confirmMessage: 'Bruk kode app til å hente ut en engangs kode. Merk at kodene har kun begrenset gyldighet.',
        confirmMessage2: 'Dersom du ønsker, kan du alternativt få tilsendt en engangskode i sms/tekstmelding.',
        mfaOk: 'Fortsett',
        mfaSendSMS: 'Send SMS med kode',
        mfaCancel: 'Avbryt'
    }
  } 