/*

How to:

1. Denne modulen skal kjøres i kontaineren.
2. Den bruker lokal state for å angi hvilket vindu som evt skal vises, må eksporteres til containeren
3. Containeren skal importere komponent for modale 
4. Den må eksportere alle metoder som skal benyttes av de modale, 
   disse sendes inn som properties i fra containeren
5. Ideelt bør en trukket sak fjernes fra sakslisten/endre status?

*/

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosTokenInstance from "../../../Instances/axiosTokenInstance";
import LocaleContext from "../../../state/contexts/LocaleContext";
import { messages } from './trekksakModal.i18n'
import _ from 'lodash'

export const useTrekkSakModal = (setErrorMessage, setMessage, setShowSpinner) => {
    
    const locale = useContext(LocaleContext);
    const trekkSakMessages = messages[locale]
    
    const showToasts = true;

    // Styring av modale og spinner
    const [showTrekkSak01, setShowTrekkSak01] = useState(false)
    const [showTrekkSak02, setShowTrekkSak02] = useState(false)
    
    // Parametre til rest kallene
    const [creditorNo, setCreditorNo] = useState('')
    const [caseNo, setCaseNo] = useState('')
    
    const [remainingCaseFee, setRemainingCaseFee] = useState(null)
    
    // RESETS MODAL BOXES AND STATE
    const resetModal = () => {
        
        setErrorMessage('')
        setMessage('')
                
        setRemainingCaseFee(0)
        //setTerminationCause('')        

        //setShowTrekkSak01(false)
        setShowTrekkSak02(false)
        setShowSpinner(false)
    }
    
    useEffect(() => {
        // TODO Reset
        resetModal()
        
    }, [creditorNo, caseNo])

    // TRIGGER FOR MODAL SEQUENCE
    const showError = (errorMessage) => {
        (showToasts && toast.error(errorMessage, {autoClose: false}))
        setErrorMessage(errorMessage)
        console.error('TrekkSakModal: ', errorMessage)
    }

    const showMessage = (message) => {
        (showToasts && toast.info(message))
        setMessage(message)
        console.log('TrekkSakModal: ', message)
    }

    const showTrekkSak = (creditorNo, caseNo) => {
        setErrorMessage('')
        setMessage('')

        setCreditorNo(creditorNo)
        setCaseNo(caseNo)
        setShowTrekkSak01(true)
    }

    const cancelTrekkSak = e => {
        setShowTrekkSak01(false)
        setShowTrekkSak02(false)
        setShowSpinner(false)
    } 

    const getErrorMessage = (err) => {
        if (_.has(err, 'response.data.title')) {
            return err.response.data.title
        }
        else if (_.has(err, 'response.data'))
        {
            return err.response.data
        }
        else if (_.has(err, 'response.statusText')) {
            return err.response.statusText
        }
        else
        {
            return err.message.toString()
        }
    }

    const showTrekkSak1OK = (val) => {

        const toastTrekkSakNotAllowed = trekkSakMessages['toastTrekkSakNotAllowed']
        const toastGetCaseStatusFailed = trekkSakMessages['toastGetCaseStatusFailed']

        setShowSpinner(true)
        
        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/sak/GetCaseStatus/${creditorNo}/${caseNo}`,
            })
            .then((result) => {
                console.log(`/api/sak/GetCaseStatus/${creditorNo}/${caseNo} returnerte:`, result)
            
                setShowTrekkSak01(false)
                setShowSpinner(false)
            
                if (result.status === 200) {

                    //"{\"caseNo\":\"3S4L01\",\"creditorNo\":\"000002\",\"departmentId\":30,\"errorCode\":\"000\",\"errorMessage\":\"OK\",\"installmentAllowed\":false,\"postponeAllowed\":false,\"remainingCaseFee\":0.0,\"terminationAllowed\":true}"
                    var resObj = JSON.parse(result.data)
                    if (resObj.terminationAllowed === true) {
                    
                        setRemainingCaseFee(resObj.remainingCaseFee)

                        setShowTrekkSak02(true)
                   
                    } else {
                        showError(toastTrekkSakNotAllowed + ' ' + resObj.errorMessage)                        
                    }
                } else {
                    showError(toastTrekkSakNotAllowed)                    
                }
            })
            .catch((err) => {
                setShowSpinner(false)
                setShowTrekkSak01(false)
                showError(toastGetCaseStatusFailed + ' ' + getErrorMessage(err))
            })
    }


    const showTrekkSak2OK = (terminationCause) => {
        
        const toastTrekkSakOk = trekkSakMessages['toastTrekkSakOk']
        const toastTrekkSakDenied = trekkSakMessages['toastTrekkSakDenied']
        const toastTrekkSakFailed = trekkSakMessages['toastTrekkSakFailed']

        setShowSpinner(true)

        console.log("showTrekkSak2OK", caseNo)

        let Model = {
            'creditorNo': creditorNo,
            'caseNo': caseNo,
            'belop': remainingCaseFee,
            'terminationCause': terminationCause,
        }

        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/sak/SakErFeilsendt/`,
                data: Model
            })
            .then((result) => {
                setShowSpinner(false)                
                setShowTrekkSak02(false)
                console.log(`/api/sak/SakErFeilsendt/`, result)

                if (result.status !== 200) {
                    showError(toastTrekkSakFailed +  + ', ' + result.status)                         
                } else {
                    //"{\"caseNo\":\"3S56S0\",\"creditorNo\":\"775978\",\"departmentId\":30,\"errorCode\":\"100\",\"errorMessage\":\"Not allowed\",\"installmentAllowed\":false,\"postponeAllowed\":false,\"terminationAllowed\":false}"
                    if (result.data.errorCode === "000") {
                        (showToasts && toast.warning(toastTrekkSakOk))
                        showMessage (`${toastTrekkSakOk}  (saksnr: ${caseNo})` )
                    } else {
                        showError(toastTrekkSakDenied + + ', ' + result.data.errorMessage)      
                    }
                }
            })
            .catch((err) => {
                setShowSpinner(false)
                setShowTrekkSak02(false)
                let errormessage = toastTrekkSakFailed + ' ' + getErrorMessage(err)
                showError(errormessage)      
            })
    }

    return {showTrekkSak01, showTrekkSak02, showTrekkSak, showTrekkSak1OK, showTrekkSak2OK, cancelTrekkSak, trekkSakMessages}

}
