import React from "react"
import styled, { keyframes } from 'styled-components';
import { Modal } from 'react-bootstrap'

const rotation = keyframes`
    from{
        transform: rotate(0deg);
    }
    
    to{
        transform: rotate(360deg);
    }
`

const Spinner = styled.div`
    height: 30px;
    width: 30px;
    border: 1px solid #f8049c;
    border-radius: 50%;
    border-top: none;
    border-right: none;
    margin: 16px auto;
    animation: ${rotation} 1s linear infinite;    
`

const OverlaySpinner = (props) => {
    
    return (

        <Modal centered show={true} size={'sm'}>                    
             
            <Spinner animation={false}>
            

            </Spinner>

        </Modal> 
    );
};

export {OverlaySpinner}