export const fixMissingTimeFromDate = (val) => {
    
    // Kludge fordi FireFox ikke godtar dato uten tid uten videre      
    if (val) {
        if (!val.includes('T')) {
            return val.replace('Z', 'T00:00:00.0000000Z')
        }
    }
    
    return val
}

export const stringToDate = function (dateString) {
    const [dd, mm, yyyy] = dateString.split(".");
    return new Date(`${yyyy}-${mm}-${dd}`);
}


export const dateToString = dateVal => {
    
    const dateOptionsNbNo = {     
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };

    if (dateVal) {
        let res = dateVal.toLocaleString('no-NO', dateOptionsNbNo)
        return res
    }    
    return ''
}

export const dateToStringDMY = val => {


    const dateOptionsNbNo = {     
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      };
    
      if (val) {
          let date = new Date(val)
          let res = date.toLocaleString('no-NO', dateOptionsNbNo)
          return res
      }    
      return ''
}

export const dateToStringDMYHR = val => {

    // Cheatsheet dateoptions format https://devhints.io/wip/intl-datetime

    const dateOptionsNbNo = {     
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: '2-digit',
        minute: '2-digit',
      };
    
      if (val) {
          let date = new Date(val)
          let res = date.toLocaleString('no-NO', dateOptionsNbNo)
          return res
      }    
      return ''
}