import React from 'react';
import { FormattedDate } from 'react-intl'
import { Container, Row, Col, Button } from "react-bootstrap";
import { BfRowForm } from '../../../Styling/styledForm/styledBlueForm'
import { StyledKfTable, StyledLink } from './../../../Styling/styledTable/StyledTable';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ArticleArea } from '../../../Styling/Layout/ContentArea';

const Circle = styled.button`    
    background: ${props => props.primary || "white"};
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 1px solid black;
`;

const DebitorList = (props) => {

  const localMessages = props.localeMessages

  const printHeader = (t1IndicaterIcon, t1ChangeSortField) => {
    return (
      <thead>
        <tr>
          <th onClick={() => t1ChangeSortField('birthDate', 'string')}>{localMessages['GH_birthDate']}{t1IndicaterIcon('birthDate')}</th>
          <th onClick={() => t1ChangeSortField('debtorIdentificationNumber', 'string')}>{localMessages['GH_caseNumber']}{t1IndicaterIcon('debtorIdentificationNumber')}</th>
          <th onClick={() => t1ChangeSortField('debtorName', 'string')}>{localMessages['GH_debtorName']}{t1IndicaterIcon('debtorName')}</th>
          <th onClick={() => t1ChangeSortField('debtorNo', 'string')}>{localMessages['GH_debtorNo']}{t1IndicaterIcon('debtorNo')}</th>
          <th onClick={() => t1ChangeSortField('debtorRating', 'string')}>{localMessages['GH_creditScore']}{t1IndicaterIcon('debtorRating')}</th>
          <th onClick={() => t1ChangeSortField('ratingDate', 'datetime')}>{localMessages['GH_date_creditScore']}{t1IndicaterIcon('ratingDate')}</th>
          <th onClick={() => t1ChangeSortField('email', 'string')}>{localMessages['GH_email']}{t1IndicaterIcon('email')}</th>
          <th onClick={() => t1ChangeSortField('postalCode', 'string')}>{localMessages['GH_postalCode']}{t1IndicaterIcon('postalCode')}</th>
          <th onClick={() => t1ChangeSortField('postalPlace', 'string')}>{localMessages['GH_postalPlace']}{t1IndicaterIcon('postalPlace')}</th>
        </tr>
      </thead>
    )
  }

  const selectDebitor = model => {
    console.log('model selected', model)
    props.selectDebitor(model)
  }

  const printRow = (model) => {

    const DateOrBlank = (birthDate) => {

      if (!birthDate) {
        return ('')
      } else {
        return (
          <FormattedDate value={birthDate} day="2-digit" month="2-digit" year="numeric" />
        )
      }

    }

    const formatDate = (val) => {

      let splittedDate = val.replace(' ', '-').split('-');
      let splittedDay = splittedDate[2].replace(' ', 'T').split('T');
      let formattedDate = splittedDay[0] + '.' + splittedDate[1] + '.' + splittedDate[0];
      return formattedDate;

    }

    const asDate = (val) => {
      if (val !== null && val !== undefined) {
        let formattedDate = formatDate(val);
        return <span>{formattedDate}</span>;
      } else {
        return ''
      }
    }

    const checkTimeFromDate = (dateString) => {
      if (!dateString) {
        return '';
      }
      // Fjern [UTC] fra slutten av dateString ellers er det helt lotto om Javascript gjør den om til Date eller ikke.
      const dateStringWithoutUTC = dateString.replace('[UTC]', '');
      const date = new Date(dateStringWithoutUTC);
      const currentDate = new Date();

      const difference = currentDate.getTime() - date.getTime();
      const differenceInMonths = difference / (1000 * 60 * 60 * 24 * 30);

      if (differenceInMonths >= 6) {
        return 'NB! Over 6 mnd gammel';
      } else {
        return '';
      }
    };


    const printRating = () => {


      function isNumeric(num) {
        return !isNaN(num)
      }

      let ratingstring = model.debtorRating ? model.debtorRating.trim() : ''
      let outputString = ''
      let rcolor = 'white'
      let showBall = false


      if (!ratingstring) {
        outputString = 'Ikke ratet'
      } else {
        if (isNumeric(ratingstring)) {
          let rating = parseInt(ratingstring)
          showBall = true

          if (rating <= 30) {
            rcolor = 'red'
          } else if (rating <= 65) {
            rcolor = 'yellow'
          } else {
            rcolor = 'green'
          }
        }
      }

      /*
    
      Rød "prikk" hvis ratingstallet er <30
      Gul "prikk" hvis ratingstallet er mellom 30 og 60
      Grønn "prikk" hvis ratingstallet >60
      
      */

      return (

        <td>
          {showBall &&
            <Circle primary={rcolor}></Circle>
          }
          {outputString}
        </td>

      )

    }

    return (
      <tr key={model.i}>

        <td>{DateOrBlank(model.birthDate)}</td>
        <td>{model.debtorIdentificationNumber}</td>
        <td><StyledLink to="#" variant="link" onClick={() => selectDebitor(model)} >{model.debtorName}</StyledLink></td>
        <td>{model.debtorNo}</td>
        <td>{printRating(model.debtorRating)}</td>
        <td>
          <>
            {asDate(model.ratingDate)}
            {model.ratingDate && (
              <span style={{ marginLeft: '8px', fontWeight: 'bold' }}>
                {checkTimeFromDate(model.ratingDate)}
              </span>
            )}
          </>
        </td>
        <td>{model.email}</td>
        <td>{model.postalCode}</td>
        <td>{model.postalPlace}</td>
      </tr>

    )
  }

  return (
    <>

      <Container fluid="true">
        <ArticleArea>
          <h1>{localMessages['arth']}</h1>
          <p>{localMessages['artd1']}</p>
          <p>{localMessages['artd2']}</p>
          <p>{localMessages['artd3']}</p>
        </ArticleArea>
      </Container>


      <Container fluid="true">

        <BfRowForm>

          <Col xs={12} md={12}>

            <props.PaginationDropdown />
            <StyledKfTable>
              {printHeader(props.t1IndicaterIcon, props.t1ChangeSortField)}

              <tbody>
                <props.Paginate>
                  {/* paginate forventer en funksjon */}
                  {row => printRow(row)}
                </props.Paginate>
              </tbody>
            </StyledKfTable>
            <br />
            <props.PaginationIndex />
            <br /><br />
            <props.PaginationNav />

          </Col>

        </BfRowForm>

      </Container>

    </>
  )
}

export default React.memo(DebitorList)



