import { actions } from "./filbehandlingActions";

const initialState = {
    data: { mottatteFiler: [], sendteFiler: [] },
    fetched: false,
    loading: false,
    error: ''
}

export default function filbehandlingReducer(state = initialState, action) {

    switch (action.type) {

        case actions.FILBEHANDLING_LOADING:
            return {
                ...state,
                fetched: false,
                loading: true,
                error: null
            };

        case actions.FILBEHANDLING_DATA_FETCHED:
            return {
                ...state,
                data: { ...action.payload },
                fetched: true,
                loading: false,
                error: null
            };
        
        case actions.FILBEHANDLING_MARK_DOWNLOADED:
            let newState = {...state}
                
            newState.data.mottatteFiler.forEach((item, index, arr) => {
                if (item.fileId === action.payload.fileId) {
                    item.fileStatus = ''
                    item.downloadUserName = action.payload.userName
                    item.isDownloaded = true
                }
                
            })
            return {
                ...state,
                data: {...newState.data},
                fetched: true,
                loading: false,
                error: null
            };
        
        case actions.FILBEHANDLING_ERROR:
            return {
                ...state,
                loading: false,
                fetched: true,
                error: action.data
            }

        default:
            return state;
    }
}
