import React from 'react';
import { StyledKfTable, StyledPropotionalNavLink } from './../../../../Styling/styledTable/StyledTable';
import CaseDetailsContext from '../../CaseDetailsContext'
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export const StyledLink = styled.a`
    text-decoration: none !important;    
    color: rgb(0,62,96) !important; 
    background-color: 'transparent' !important;
    
    font-feature-settings: 'tnum' !important;
    font-variant-numeric: 'tabular-nums' !important;
    
    font-weight: 600 !important;
    font-family: "Fira Mono" !important;
    font-kerning: normal !important;    

    :hover {
        text-decoration: underline !important;
    }
`

const data = [
    { i: 1, c1: 'Registrert dato', c2: '12.12.2012' },
    { i: 2, c1: 'Kontonummer', c2: '2625 12 23345' },
    { i: 3, c1: 'KID', c2: '1123123123123123123' },
    { i: 4, c1: 'Inkassotype', c2: '013-Egeninkasso' },
    { i: 5, c1: 'Siste innbet', c2: '' },
    { i: 6, c1: 'Saksbehandler', c2: 'Vibeke Sæther' },
    { i: 7, c1: 'Epost', c2: 'gam@kred.no' },
    { i: 8, c1: 'Telefon', c2: '922 22 252' },
]

const CaDetSaksOversiktComponent = (props) => {

    const { localeMessages } = React.useContext(CaseDetailsContext);
    const state = useSelector(state => state.saksdetaljer)
    const sbUrlListState = useSelector(state => state.sbUrlListe)

    const printHeader = () => {
        return (
            <thead>
                <tr>
                    <th>{localeMessages['SO_H1']}</th>
                    <th></th>
                </tr>
            </thead>
        )
    }

    const printRow = (model, i) => {

        if (i === 5) {

            let cellval = model.c1
            const caseHandlerId = state.data.fetchedData.caseHandlerId
            const found = sbUrlListState.items.find(element => {
                return element.sb === caseHandlerId;
            });
            if (found) {
                return (<tr key={i}>
                    <td>{localeMessages['SO_C1'][i]}</td>
                    <td align="left">                        
                        <StyledLink href={found.url} target="_blank">
                        {model.c1}                                                                        
                    </StyledLink>
                    </td>
                    
                </tr>)
            } else {
                return (<tr key={i}>
                    <td>{localeMessages['SO_C1'][i]}</td>
                    <td align="left">{model.c1}</td>
                </tr>)
            }



            
        }

        return (
            <tr key={i}>
                <td>{localeMessages['SO_C1'][i]}</td>
                <td align="left">{model.c1}</td>
            </tr>
        )

    }

    return (
        <StyledKfTable>
            {printHeader()}

            <tbody>
                {state.data.saksOversikt.map((row, i) => printRow(row, i))}
            </tbody>
        </StyledKfTable>
    )
}

export default CaDetSaksOversiktComponent