import React, { useEffect } from 'react';
import { StyledKfTextTable } from '../../../../Styling/styledTable/StyledTable';
import CaseDetailsContext from '../../CaseDetailsContext'
import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components'
import { IntlProvider, FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl'
import { useSelector } from 'react-redux';
import { useState } from 'react';

const StyledNavLink = styled(NavLink)`
    background-color: none !important;
`


const CaDetSamleSakComponent = (props) => {

    const { localeMessages } = React.useContext(CaseDetailsContext);
    const state = useSelector(state => state.saksdetaljer)
    const kreditorListeState = useSelector(state => state.sakslisteHeader)

    // State som hoster den filtrerte sakslisten for samlesaker brukeren har tilgang til
    const [caseCollectionList, setCaseCollectionList] = useState([])

    // Filtrere bort saker på kreditorer som ikke finnes i kreditorlisten
    useEffect(() => {
        let validCreditors = kreditorListeState.kreditorliste.map(i => i.customerNo)
        console.log("validCreditors", validCreditors)
        let filteredCaseCollectionList = state.data.caseCollectionList.filter(i => {
            let found = validCreditors.includes(i.creditorNo)
            return found
        })
        console.log("validCaseCollectionList", filteredCaseCollectionList)
        setCaseCollectionList(filteredCaseCollectionList)

    }, [state.data.caseCollectionList, kreditorListeState.kreditorliste])


    // Felter
    const f1 = 'caseNo'
    const f2 = 'orginalClaim'
    const f3 = 'balanceClaim'
    const f4 = 'isMainCase'
    const f5 = 'invoiceNo'
    const f6 = 'balanceTotal'

    const asDecimal = (val) => {
        if (val) {

            return (
                <FormattedNumber
                    value={val}
                    style='decimal'
                    minimumFractionDigits={2}
                />
            )
        }
        else {
            return '0,00'
        }
    }

    const printHeader = () => {
        return (
            <thead>
                <tr>
                    <th style={{ textAlign: 'left', width: '25%' }}>{localeMessages['SS_H01']}</th>
                    <th style={{ textAlign: 'right', width: '12%' }}>{localeMessages['SS_H02']}</th>
                    <th style={{ textAlign: 'right', width: '12%' }}>{localeMessages['SS_H03']}</th>
                    <th style={{ textAlign: 'right', width: '12%' }}>{localeMessages['SS_H06']}</th>
                    <th style={{ textAlign: 'left'}}>&nbsp;&nbsp;{localeMessages['SS_H05']}</th>                   
                </tr>
            </thead>
        )
    }

    const printLink = model => {
        let caseDetUri = '/CaseDetails/' + model.creditorNo + '/' + model.caseNo

        if (model.isMainCase) {
            return (
                <b>
                    <NavLink to={caseDetUri} >
                        {model[f1]} (hovedsak)
                    </NavLink>
                </b>
            )
        }
        else {
            return (              
                <NavLink to={caseDetUri} >
                    {model[f1]}
                    </NavLink>
            )
        }
    }

    const printRow = (model, i) => {

        return (
            <tr key={model.i}>
                <td>{printLink(model)}</td>
                <td style={{ textAlign: 'right' }}>{asDecimal(model[f2])}</td>
                <td style={{ textAlign: 'right' }}>{asDecimal(model[f3])}</td>
                <td style={{ textAlign: 'right' }}><b>{asDecimal(model[f6])}</b></td>
                <td>&nbsp;&nbsp;{model[f5]}</td>
            </tr>
        )

    }    

    
    if (props.isReport && (caseCollectionList.length === 0)) {
        return (
            <></>
        )
    }

    const sumBalanceClaim = () => caseCollectionList.reduce((accumulator, object) => {
        return accumulator + object.balanceClaim
    }, 0);
      
    const sumOrginalClaim = () => caseCollectionList.reduce((accumulator, object) => {
        return accumulator + object.orginalClaim
    }, 0);

    const balanceTotalClaim = () => caseCollectionList.reduce((accumulator, object) => {
        return accumulator + object.balanceTotal
    }, 0);
    

    
    
    return (

        <StyledKfTextTable>
            {printHeader()}
            <tbody>
                {caseCollectionList.map((row, i) => printRow(row))}
            </tbody>
            <tfoot>
                <td><b>Sum:</b></td>
                <td style={{ textAlign: 'right' }}><b>{asDecimal(sumOrginalClaim())}</b></td>
                <td style={{ textAlign: 'right' }}><b>{asDecimal(sumBalanceClaim())}</b></td>
                <td style={{ textAlign: 'right' }}><b>{asDecimal(balanceTotalClaim())}</b></td>
            </tfoot>
        </StyledKfTextTable>   
    )
}

export default CaDetSamleSakComponent
