export const messages = {
    nb: {
        regCloseDate: "Dato",
        allDate: "Dato",
        registerDate: "Dato",
        ikkeAktivDate: "Avsluttet dato",
        caseNumber: 'Saksnr',
        crNo: 'Kreditornr',
        creditorName: 'Kreditor',
        debtorName: 'Debitor navn',
        refnr: 'Ref. nr',
        caseReference: 'Ref. nr',
        product: 'Produkt',
        lastNote: 'Notat',
        meterPointId: 'MålerpunktsID',
        originalClaim: 'Opprinnelig HS',
        innbetHs: 'Innbet HS',
        paidClaim: 'Innbet HS',
        restHs: 'Rest HS',
        restHS: 'Rest HS',
        remainBalance: 'Saldo totalt',
        totalBalance: 'Saldo totalt',
        editColumns: 'Rediger kolonner',
        stopEditing: 'Stopp redigering',
        resetColumns: 'Tilbakestill kolonner',
        hideColumns: 'Skjul valgte'
    }
}
