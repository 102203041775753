import React, { useContext, useRef } from 'react';
import styled from 'styled-components'
import {Button} from 'react-bootstrap'
import CaseDetailsContext from '../CaseDetailsContext'
import { MODALFORMS } from '../CaseDetailsContainer'
import { useReactToPrint } from 'react-to-print';
import { CaseDetailsReportComponentToPrint} from '../CaseDetailsReport'
import { useSelector } from 'react-redux';
import colorConfig from '../../../colorConfig';

const EqualSpaced = styled.div`
    width:100%;
    text-align:center;
    background-color: white;
    color: rgb(0, 62, 96);
    border-bottom: 0px;
    padding: 8px 10px 20px 10px;
    border: 0px solid black;
    font-family: "Fellix","sans-serif";
    font-size: 24px !important;
    font-weight: 400;
    min-width: 1800px;

    @media print { 
        display: none      
    }


    > div {
        width: calc(100% / 7);  
        display: inline-block;
        vertical-align: top;   
        border:0px solid red;
        text-align:center;
        //margin: 0px, 10px, 0px, 10px;    
        //padding: 0px, 10px, 0px, 20px;       
    }            
`

const BfButtonBlue = styled(Button)`    
    border-radius: 0.5em;
    font-family: "Fellix", "sans-serif";
    font-size: 20px;
    border: 0px;
    background-color: ${colorConfig.button_bg};
    color: black;
    padding: 4px 20px 4px 20px;
    font-weight: 700;         

    :hover {
        background-color: ${colorConfig.button_focused};
        -webkit-transition: background-color 1.5s ease-out;
        -moz-transition: background-color 1.5s ease-out;
        -o-transition: background-color 1.5s ease-out;
        transition: background-color 1.5s ease-out;
        transition-delay:0.1s;
    }
    :focus {
        background-color: ${colorConfig.button_focused};
    }
    :disabled {
        background-color: gray;
    }
`

const CaDetIconsComponent = (props) => {

    const { localeMessages, showToasts, showSendMelding, showSendBetaling } = React.useContext(CaseDetailsContext);   
    const state = useSelector(state => state.saksdetaljer)
    
    const componentRef = useRef();
    const handlePrint = useReactToPrint({        
        content: () => componentRef.current,
    });

    const trekkSak = () => props.showTrekkSak(state.data.creditorNo, state.data.caseNo)
    const utsettSak = () => props.showUtsettSak(state.data.creditorNo, state.data.caseNo)
    const avdragsOrdn = () => props.showAvrdOrdn(state.data.creditorNo, state.data.caseNo, state.data.fetchedData.balanceClaim, state.data.fetchedData.caseDebtor.email, state.data.caseNo)
    const faktUpload = () => props.showFaktUpload(state.data.creditorNo, state.data.caseNo, state.data.fetchedData.caseDebtor.debtorIdentificationNumber, state.data.fetchedData.caseDebtor.debtorName)
    const showSendMld = () => showSendMelding(state.data.creditorNo, state.data.caseNo, '', '', state.data.fetchedData.caseDebtor.debtorName)
    const showSendBet = () => showSendBetaling(state.data.creditorNo, state.data.caseNo, '', '')
    
    return (

        <>
            <CaseDetailsReportComponentToPrint ref={componentRef} />

            <EqualSpaced>   
                <div><BfButtonBlue onClick={() => handlePrint()}><span className="fa fa-print" />&nbsp;&nbsp;{localeMessages['IC_Ustkrift']}</BfButtonBlue></div>
                <div><BfButtonBlue onClick={showSendMld}><span className="fa fa-envelope" />&nbsp;&nbsp;{localeMessages['IC_NyMelding']}</BfButtonBlue></div>
                <div><BfButtonBlue disabled={!state.data.fetchedData.paymentAllowed} onClick={showSendBet}><span className="fa fa-money" />&nbsp;&nbsp;{localeMessages['IC_Betaling']}</BfButtonBlue></div>
                <div><BfButtonBlue disabled={!state.data.fetchedData.postponeAllowed} onClick={utsettSak}><span className="fa fa-credit-card" />&nbsp;&nbsp;{localeMessages['IC_Utsett']}</BfButtonBlue></div>
                <div><BfButtonBlue disabled={!state.data.fetchedData.instalmentAllowed}onClick={avdragsOrdn}><span className="fa fa-calendar" />&nbsp;&nbsp;{localeMessages['IC_avdrag']}</BfButtonBlue></div>
                <div><BfButtonBlue disabled={!state.data.fetchedData.terminationAllowed}onClick={trekkSak}><span className="fa fa-times" />&nbsp;&nbsp;{localeMessages['IC_Trekk']}</BfButtonBlue></div>
                <div><BfButtonBlue onClick={faktUpload}><span className="fa fa-cloud-download" />&nbsp;&nbsp;{localeMessages['IC_FaktOppl']}</BfButtonBlue></div>
            </EqualSpaced>
                
        </>
    )
}

export default CaDetIconsComponent

