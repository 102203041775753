import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAhlinCMSbyId } from '../../../reduxStore/HeadlessCMS/headlessCmsActions';
import { useSelector, useDispatch } from 'react-redux';
import { OverlaySpinner } from '../../../components/spinner/OverlaySpinner';


const NyheterCMS = (props) => {
    const [content, setContent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    const localeMessages = props.localeMessages
    const articleId = props.articleId
    const showToasts = true;

    const dispatch = useDispatch()

    useEffect(() => {
        let isMounted = true;
    
        const fetchData = async () => {
            try {
                const data = await dispatch(getAhlinCMSbyId(articleId, showToasts, localeMessages));
                if (isMounted) {
                    setContent(data);
                    setLoading(false);
                }
            } catch (err) {
                if (isMounted) {
                    setError(err.message);
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [articleId, dispatch, showToasts, localeMessages]);

    if (loading) return <OverlaySpinner></OverlaySpinner>;
    if (error) return <p>Noe gikk galt ved henting av artikkel</p>;

    return (
        <div style={{ backgroundColor: '#fdfbf2', width: '1500px', padding: '20px' }}>
            {content.map((item) => (
                <div key={item.id}>
                    <h1 dangerouslySetInnerHTML={{ __html: item.title.rendered }}></h1>
                    <div dangerouslySetInnerHTML={{ __html: item.content.rendered }}></div>
                </div>
            ))}
        </div>
    );
};

export default NyheterCMS;
