import { toast } from 'react-toastify';
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

export const reportFormsActions = {
    REPFORM_SAVE_FORM: 'REPFORM_SAVE_FORM',
}

const saveReportFormSuccess = (reportType, id, formData) => ({
    type: reportFormsActions.REPFORM_SAVE_FORM,
    payload: { reportType: reportType, id: id, data: formData }
})

export const saveReportForm = (reportType, id, formData, showToasts, messages) => {

    return dispatch => {

        dispatch(saveReportFormSuccess(reportType, id, formData))
    }
}

