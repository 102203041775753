import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArticleArea } from '../../../Styling/Layout/ContentArea';
import { StyledNavLink } from '../../../Styling/styledTable/StyledTable';

const Receipt = (props) => {

    const localeMessages = props.messages
    
    let caseDetUri = '/CaseDetails/' + props.state.kreditorId + '/' + props.state.caseId

    return (
       
        <ArticleArea>
            <h1>{localeMessages.ReceiptH1}</h1> 
            <p>{localeMessages.Receipt1}</p>
            <p>
                {localeMessages.Receipt2a}
                <NavLink to="/Caselist">sakslisten</NavLink>
                {localeMessages.Receipt2b}
            
            </p>
            {/*
            <p>
                Klikk på lenken for å gå til saksdetaljer for den nye saken:&nbsp; 
                <StyledNavLink to={caseDetUri}>
                    saksnr: {props.state.caseId}
                </StyledNavLink>
            </p>
             */}


        </ArticleArea>
    )

}

export default Receipt