export const messages = {
    nb: {
        Ex: `text`,

        toastGetFilesFailed: 'Henting av filer feilet. ', 
        toastGetFilesOk: 'Oversikt over filer ble hentet OK. ',
        toastFileExceedsMax: 'Denne filen overskrider maksimal størrelse: ',
        toastFilesUploadedOk: 'Filer er lastet opp. ',
        toastFilesUploadedFailed: 'Opplasting av filer feilet. ',


        LASTOPP: 'Last opp',
        CLEAR: 'Tøm fillisten',

        TaC_T1: 'Mottatte filer',
        TaC_T2: 'Sendte filer',
        TaC_T3: 'Last opp filer',

        GHF_fileDate: 'Dato',
        GHF_fileName: 'Filnavn',
        GHF_documentType: 'Filtype',
        GHF_lastetNed: 'Lastet ned',
        GHF_lastetOpp: 'Lastet opp',
        GHF_fileStatus: 'Status',

        KreditorNotSelected: 'Du kan ikke laste opp filer uten å velge kreditor fra nedtrekkslisten oppe til høyre. ',
        NoFilesInList: 'Du kan ikke gå videre før du har valgt noen filer for opplasting. '
    }
}