import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DummyContainer = (props) => {
    
    const state = useSelector(state => state.sakslisteHeader)
    const saksListeState = useSelector(state => state.saksliste)
    const saksdetaljerState = useSelector(state => state.saksdetaljer)
    const dispatch = useDispatch()
    
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(state.loading || saksListeState.loading)
    }, [state.loading, saksListeState.loading])


    return (
        <>  {isLoading && <div>laster</div>} 
            Content in dummyContainer<br />
            valgt kreditor: {state.kreditorId}<br />
            filterstreng: {state.filter}<br />
            IsLoading {isLoading ? 'true' : 'false'}<br />
            Number of items {saksListeState.numberOfItems}<br />
            Filtered Number of items {saksListeState.filtredNumberOfItems}<br />
        </>
    )
}

export default DummyContainer
