import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import LocaleContext from "../../state/contexts/LocaleContext";
import { messages } from "./Login.i18n";
import { useAuth } from "../../hooks/useAuth";
import LoginForm from "./components/LoginForm";
import LoginWelcome from "./components/LoginWelcome";
import Spinner from "../../Styling/FormComponents/StyledSpinner";
import _ from 'lodash'
import axiosTokenInstance from "../../Instances/axiosTokenInstance";
import { Alert } from "react-bootstrap";
import CONFIG from "../../config";
import { useLocation } from "react-router-dom/cjs/react-router-dom";


const LoginContainer = (props) => {
  const auth = useAuth();
  let history = useHistory();

  // Sjekk hvilken url du er å
  let location = useLocation();
  console.log(location.pathname)

  const validatePassword = (value) => {
    //console.log ('validating password')
    if (value) {
      setFieldError((prevState) => ({ ...prevState, ["password"]: "" }));
    } else {
      setFieldError((prevState) => ({
        ...prevState,
        ["password"]: "Felt er påkrevd",
      }));
    }
  };

  const validateEmail = (value) => {
    //console.log ('validating email')
    if (value) {
      if (isValidEmail(value))
        setFieldError((prevState) => ({ ...prevState, ["email"]: "" }));
      else
        setFieldError((prevState) => ({ ...prevState, ["email"]: "Ugyldig epostadresse" }));
    } else {
      setFieldError((prevState) => ({
        ...prevState,
        ["email"]: "Felt er påkrevd",
      }));
    }
  };

  // Valider påvegne av epostadressen
  const validateEmailAs = (value) => {
    //console.log ('validating email')
    if (value) {
      if (isValidEmail(value))
        setFieldError((prevState) => ({ ...prevState, ["emailas"]: "" }));
      else
        setFieldError((prevState) => ({ ...prevState, ["emailas"]: "Ugyldig epostadresse" }));
    } else {
      setFieldError((prevState) => ({
        ...prevState,
        ["emailas"]: "Felt er påkrevd",
      }));
    }
  };

  const isValidEmail = (val) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (val === '') {
      return true
    } else {
      if (re.test(val)) {
        return true
      }
    }
    return false
  }

  const locale = useContext(LocaleContext);

  const [fieldValues, setFieldValue] = useState({});
  const [fieldErrors, setFieldError] = useState({});
  const [pageInfo, setPageInfo] = useState('');

  // Sett validatorene i usestate initielt
  const validators = {
    password: validatePassword,
    email: validateEmail,
    emailas: validateEmailAs,
  };
  const [fieldValidators, setFieldValidator] = useState(validators);

  const [defaultUser, setDefaultUser] = useState(props.username)
  const [crNo, setCrNo] = useState(props.crNo)
  const [caseId, setCaseId] = useState(props.caseId)

  useEffect(() => {
    if (props.username) {
      setFieldValue({ ...fieldValues, ['email']: props.username });
    }
  }, [])

  const hasError = (fieldName) => fieldErrors[fieldName];
  const handleChange = (e) => {
    //console.log('handle change', e.target.name )
    setFieldValue({ ...fieldValues, [e.target.name]: e.target.value });
    if (fieldValidators[e.target.name]) {
      //console.log('validating ', e.target.name )
      fieldValidators[e.target.name](e.target.value);
    } //else {
    //console.log('ingen validator', e.target.name )
    //}
  };

  const debugInfo = () => {
    if (process.env.NODE_ENV == 'development') {
        return (
            <>
              fieldvalues: {JSON.stringify(fieldValues)}<br />
              fielderrors: {JSON.stringify(fieldErrors)}<br />               
            </>
        )
    }
}


  const submitGlemtPassordHandler = () => {
    setPageInfo('')
    if (!fieldValues["email"]) {
      setPageInfo('bruker ikke oppgitt')
    } else {
      axiosTokenInstance
        ({
          method: 'GET',
          url: `/api/apisecurity/SendResetPasswordLink/?Email=${fieldValues["email"]}`,
        })
        .then((result) => {
          console.log(result)
          setPageInfo('Dersom oppgitt bruker er registrert i systemet, vil du motta en epost med info for å angi nytt passord.')
        })
        .catch((err) => {
          console.log(err)
          setPageInfo('Dersom oppgitt bruker er registrert i systemet, vil du motta en epost med info for å angi nytt passord...')
        })
    }

  }

  const submitHandler = (e) => {
    e.preventDefault();
    setPageInfo('')
    if (!auth.state.mfa) {
      setFieldValue({ ...fieldValues, ['mfaCode']: '' });
    }
    //console.log("[Auth.js] signin TODO ADD SIGNING METHOD FROM useAuth.js")

    // TODO FJERNES FØR PROD
    if ((process.env.NODE_ENV == 'development') && (!(fieldValues['email']))) {
      //if (!(fieldValues["email"]))

      //auth.signIn('', '');
    }
    else {
      console.log("Signin in:");
      
      // Be sure to clean loginasuser
      var loginAsUser = ""
      if (location.pathname.toUpperCase() === "/LOGIN/SOM") {
        loginAsUser = fieldValues["emailas"]
      }
      auth.signIn(fieldValues["email"], fieldValues["password"], fieldValues["mfaCode"], loginAsUser);
    }
  };



  if (auth.loading) {

    return (<Spinner />)

  } else if (auth.isAuthenticated) {

    // Sending you to casedetails on url like this
    //http://localhost:3000/#/login/cato@kred.no/200082/3S61HX
    if (props.crNo && props.caseId) {
      console.log('du skal til saksdetaljer')

      let redirectTo = `/CaseDetails/${props.crNo}/${props.caseId}`
      history.push(redirectTo);

      return (<></>)
    }

    if ((auth.isSupervisor()) || (auth.isForeningSupervisor())) {

      // Vis bilde med sjekk om profilen din er korrekt...
      if (CONFIG.SHOW_CHECK_PROFILE) {
        console.log('Show profile with check...')
        // mhp tofaktor går hit:  let redirectTo = `/Profil`
        let redirectTo = `/Overview`
        history.push({
          pathname: redirectTo,
          state: {
            showcheck: true
          }
        })
        return (<></>)
      } else {
        return <LoginWelcome />
      }

    } else if (auth.creditors.length === 0) {
      let redirectTo = `/WelcomeNoAccess`
      history.push(redirectTo);
      return (<></>)
    } else {

      // Vis bilde med sjekk om profilen din er korrekt...
      if (CONFIG.SHOW_CHECK_PROFILE) {
        console.log('Show profile with check...')
        // mhp tofaktor går hit:  let redirectTo = `/Profil`
        let redirectTo = `/Overview`

        if (auth.user.twoFactorEnabled === 'false') {
          redirectTo = `/Profil`
        }

        history.push({
          pathname: redirectTo,
          state: {
            showcheck: true
          }
        });
      } else {
        let redirectTo = `/Overview`
        history.push(redirectTo);
      }

      return (<></>)
    }

  } else {
    return (
      <>

        {(pageInfo !== '') && <Alert variant='danger'>
          {pageInfo}
        </Alert>}

        <LoginForm
          handleChange={handleChange}
          submitHandler={submitHandler}
          hasError={hasError}
          authError={auth.Error}
          SubmitGlemtPassord={submitGlemtPassordHandler}
          fieldValues={fieldValues}
          setFieldValue={setFieldValue}
          mfa={auth.state.mfa}
          loginPath={location.pathname}
        />

        {debugInfo()}
      </>
    );
  }
};

export default LoginContainer;
