import React from 'react';
import styled from 'styled-components';
import { StyledKfTextTable } from '../../../../Styling/styledTable/StyledTable';

import {  useSelector } from 'react-redux';
import CaseDetailsContext from '../../CaseDetailsContext';

const Circle = styled.button`    
    background: ${props => props.primary || "white"};
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 1px solid black;
`;


const data = [
    { i: 1, c1: '', c2: 'Hardanger Skamplett' },
    { i: 2, c1: '', c2: 'PB 3' },
    { i: 3, c1: 'Besøksadresse', c2: '' },    
    { i: 4, c1: 'C/O', c2: '' },
    { i: 5, c1: 'Poststed', c2: '5469 KINSARVIK' },
    { i: 6, c1: 'Fødselsnr./orgnr,', c2: '999888444888' },
    { i: 7, c1: 'Epost', c2: '' },
    { i: 8, c1: 'Rating', c2: '  0 0 0' },
    { i: 9, c1: 'Telefon', c2: '  987 65 432' },      
]

const CaDetDebtorComponent = (props) => {

    const { localeMessages } = React.useContext(CaseDetailsContext);
    const state = useSelector(state => state.saksdetaljer)

    const printHeader = () => {
        return (
            <thead>
                <tr>
                    <th>{localeMessages['DbT_H1']}</th>
                    <th></th>
                </tr>
            </thead>
        )
    }

    const printRow = (model, i) => {

        if (model.i === 8) {
            return printRating(model, i)
        }
        
        return printText(model, i)

    }

    const printText = (model, i) => {

        return (
            <tr key={model.i}>
                <td>{localeMessages['DbT_D'][i]}</td>
                <td>{model.c1 ? model.c1.trim() : ''}</td>
            </tr>
        )

    }

    const printRating = (model, i) => {

        function isNumeric(num) {
            return !isNaN(num)
        }

        let ratingstring = model.c1 ? model.c1.trim() : ''
        let outputString = ''
        let rcolor = 'white'
        let showBall = false
    

        if (!ratingstring) {
            outputString = 'Ikke ratet'            
        } else {            
            if (isNumeric(ratingstring)) {
                let rating = parseInt(ratingstring)
                showBall = true
                
                if (rating <= 30) {
                    rcolor = 'red'               
                } else if (rating <= 65) {
                    rcolor = 'yellow'
                } else {
                    rcolor = 'green'
                }
            }
        }

        /*
        rating < 30 - sak - skal lete etter en slik sak
        30<rating<60 sak 3S4SZ5
        rating > 60 sak 3S5B4W

Rød "prikk" hvis ratingstallet er <30
Gul "prikk" hvis ratingstallet er mellom 30 og 60
Grønn "prikk" hvis ratingstallet >60
        
        */

        return (
            <tr key={model.i}>
                <td>{localeMessages['DbT_D'][i]}</td>
                <td>
                    {showBall &&
                        <Circle primary={rcolor}></Circle>
                     }
                    {outputString}
                </td>
            </tr>
        )

    }

    return (
        <StyledKfTextTable>
            {printHeader()}
            
            <tbody>
                {state.data.debtor.map((row, i) => printRow(row, i))}      
            </tbody>
        </StyledKfTextTable>   
    )
}

export default CaDetDebtorComponent
