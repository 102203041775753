import { combineReducers } from 'redux'
import counterReducer from './counter/counterReducer'
import headlessCmsReducer from './HeadlessCMS/headlessCmsReducer'
import mruReducer from './mru/mruReducer'
import newCaseReducer from './newCase/newCaseReducer'
import toolsMenuReducer from './toolsMenu/toolsMenuReducer'
import reportsMenuReducer from './ReportMenu/reportMenuReducer'
import powerBiReducer from './PowerBI/PowerBiReducer'
import kredSelReducer from './kredSel/kredSelReducer'
import oversiktReducer from './oversikt/oversiktReducer'
import filbehandlingReducer from './filbehandling/filbehandlingReducer'
import saksdetaljerReducer from './saksdetaljer/saksdetaljerReducer'
import saklisteReducer from './saksliste/sakslisteReducer'
import sakslisteHeaderReducer from './sakslisteHeader/sakslisteHeaderReducer'
import saksListeGridState from './sakslisteGridState/saksListeGridStateReducer'
import fakturaAdminReducer from './fakturaAdmin/fakturaAdminReducer'
import bisnodeReducer from './bisnode/bisnodeReducer'
import reportFormsReducer from './reportForms/reportFormsReducer'
import sbUrlListeReducer from './SbUrlListe/sbUrlListeReducer'
import ProfilReducer from './profil/profilReducer'
import stengelisteReducer from './stengeliste/stengelisteReducer'


const allReducersCombined = combineReducers({
    counter: counterReducer,
    toolsMenu: toolsMenuReducer,
    reportsMenu: reportsMenuReducer,
    mru: mruReducer,
    newCase: newCaseReducer,
    headlessCMS: headlessCmsReducer,
    powerBi: powerBiReducer,
    reportForms: reportFormsReducer,
    kredSel: kredSelReducer,
    oversikt: oversiktReducer,
    filbehandling: filbehandlingReducer,

    saksdetaljer: saksdetaljerReducer,
    saksliste: saklisteReducer,
    sakslisteHeader: sakslisteHeaderReducer,
    saksListeGridState: saksListeGridState,
    fakturaAdmin: fakturaAdminReducer,
    bisnode: bisnodeReducer,
    sbUrlListe: sbUrlListeReducer,
    profil: ProfilReducer,
    stengeliste: stengelisteReducer,
})

// reset the state of a redux store
const combinedReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
        state = undefined;
    }
    return allReducersCombined(state, action)
}
export const resetStore = () => {
    
    return {
        type: 'RESET_STORE'
    }
}

export default combinedReducer