import React, { useState } from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl'
import { fixMissingTimeFromDate } from '../../util/date/dateRoutines';


export const asDate = (val) => {
    if (val) {
        // Kludge fordi FireFox ikke godtar dato uten tid uten videre
        let valok = fixMissingTimeFromDate(val)
        return (
            <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
            )
    }
    return ''
}

export const asDecimal = (val) => {
    if (val) {

        return (
            <FormattedNumber
                value={val}
                style='decimal'
                minimumFractionDigits={2}
            />
        )
    }
    else {
        return ''
    }
}

const addIndex = (arr) => {
    if (!arr)
        return []
    return arr.map(
        (rec, i) => {
            return { i, ...rec } 
        })
}

const spaceOrValue = (val) => {
    if (val) { return val }
    return ''
}

export const mapCaseDetailsToWeb = (sak, caseNo, creditorNo) => {

    const colorMapper = (rs) => {
        if (!rs) return ''

        let r = parseInt (rs)
    
        if (r <= 30) return 'red'
        else if (r <= 65) return 'yellow'
        else return 'red'
    }
    

    try {

        let lResokontro = [
            // Krav(uten prefix), innbetm(paid), rest(balance)
            { i: 1, c1: sak.originalClaim, c2: sak.paidClaim, c3: sak.balanceClaim }, // Hovedstol  claim
            { i: 2, c1: sak.interest, c2: sak.paidInterest, c3: sak.balanceInterest }, // Rente - interest
            { i: 3, c1: sak.fees, c2: sak.paidFees, c3: sak.balanceFees }, // Salær fees
            { i: 4, c1: sak.fee, c2: sak.paidFee, c3: sak.balanceFee }, // Gebyr - fee
            { i: 5, c1: sak.legalFees, c2: sak.paidLegalFees, c3: sak.balanceLeegalFees }, // Rettslig salær  - legalfees
            { i: 6, c1: sak.costInterest, c2: sak.paidCostInterest, c3: sak.balanceCostInterest }, // Omostningsrente - constinterest
            { i: 7, c1: sak.total, c2: sak.paidTotal, c3: sak.balanceTotal }, // Sum - total                   
        ]

        let lSaksOversikt = [
            { i: 1, c1: asDate(sak.caseRegistrationDate) },  // ***
            { i: 2, c1: sak.KFaccountNo },
            { i: 3, c1: sak.caseKID },
            { i: 4, c1: `${sak.caseTypeText}` },
            
            
            { i: 5, c1: asDate(sak.lastPaidDate) },  // siste innbet            
            { i: 6, c1: spaceOrValue(sak.caseHandler) },
            { i: 7, c1: spaceOrValue(sak.caseHandlerEmail) },
            { i: 8, c1: spaceOrValue(sak.caseHandlerPhone) },
        ]                 

        let lDebtor = [
            { i: 1, c1: sak.caseDebtor.debtorName },
            { i: 2, c1: sak.caseDebtor.extraAddress },
            { i: 3, c1: sak.caseDebtor.postalAddress },
            { i: 4, c1: sak.caseDebtor.streetAddress },
            { i: 5, c1: `${sak.caseDebtor.postalCode} ${sak.caseDebtor.postalPlace}` },
            { i: 6, c1: (sak.caseDebtor.debtorIdentificationNumber) ? sak.caseDebtor.debtorIdentificationNumber : sak.caseDebtor.birthDate},
            { i: 7, c1: sak.caseDebtor.email },
            { i: 8, c1: sak.caseDebtor.creditRating },
            { i: 9, c1: sak.caseDebtor.phone },
        ]

        //let invoices = addIndex(sak.caseInvoiceList)

        let webSak = {
            caseNo : caseNo,
            creditorNo: creditorNo,
            reskontro: lResokontro,
            saksOversikt: lSaksOversikt,
            debtor: lDebtor,
            invoices: addIndex(sak.caseInvoiceList),
            activities: addIndex(sak.caseActivityList),
                    
            // Meldinger og notater
            taskList: addIndex(sak.caseMessageList),
            caseCollectionList: addIndex(sak.caseCollectionList),
            fetchedData: sak
        }

        return webSak 
        
    } catch (error) {
        console.log(error)   
        throw error
    }
   
}