export const messages = {
    nb: {
        IndexCaption: 'Hjem',
        SakCaption: 'Saksliste',        
        NySakCaption: 'Ny sak',
        UserAdminCaption: 'Brukeradministrasjon',
        ProfilCaption: 'Profil',
        LoginCaption: `Logg på`,
        LogoutCaption: `Logg av`,
        OnBoardingRegisterCaption: 'Registrer kunde',
        OnBoardingCaption: 'Bli kunde',
        mOversikt: 'Oversikt',
        mFilbehandling: 'Filbehandling',
        mRapporter: 'Rapporter',
        mPbiRapporter: 'PBI rapporter',
        mSisteSaker: 'Sist viste saker',
        mKredittInformasjon: 'Kredittinformasjon',
        mFakturaAdmin: 'Fakturaer',
        KontaktSkjemaCaption: 'Kontaktskjema',
        mStengeliste: 'Stengeliste',
        mKredittvurdering: 'Kredittvurdering (Ny)'
    }
  } 