import React from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import LocaleContext from "../../../state/contexts/LocaleContext";
import { messages } from "./LoginWelcome.i18n";
import { ArticleArea } from "../../../Styling/Layout/ContentArea";

const LoginWelcome = (props) => {
  const locale = React.useContext(LocaleContext);
  const { Header1, Paragraph1 } = messages[locale];

  return (
    <>
      <ArticleArea>
        <h1>{Header1}</h1>
        <p>{Paragraph1}</p>
      </ArticleArea>
    </>
  );
};

export default LoginWelcome;