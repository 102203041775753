import React, { useState } from 'react';
import { Tabs, Tab, Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components'
import CaDetReskontroComponent from '../infogrids/CaDetReskontroComponent';
import CaseDetailsContext from '../../CaseDetailsContext'
import CaDetFakturaerComponent from './CaDetFakturaerComponent';
import CaDetAktiviteterComponent from './CaDetAktiviteterComponent';
//import CaDetNotaterComponent from './CaDetNotaterComponentDeprecated';
import CaDetMeldingListComponent from './CaDetMeldingListComponent';
import CaDetSamleSakComponent from './CaDetSamleSakComponent';
import { TabWrapper } from '../../../../components/tabs/TabWrapper';


/*
Customizing tabs
https://turbofuture.com/computers/Apply-custom-styles-to-bootastrap-tabs-step-by-step
*/

const CaDetTabContainer = (props) => {

    // State for aktiv tab
    const [key, setKey] = useState('home');

    const { localeMessages } = React.useContext(CaseDetailsContext);

    return (
        <TabWrapper>

            <Tabs id="saksdettab" activeKey={key} onSelect={(k) => setKey(k)} style={{ backgroundColor: "white", fontWeight: "400", "color": "rgb(0, 62, 96)" }} >
               
                            
                <Tab eventKey="home" title={localeMessages['TaC_T1']}>
                    <CaDetFakturaerComponent/>
                </Tab>

                <Tab eventKey="meldinger" title={localeMessages['TaC_T2']}>
                    <CaDetMeldingListComponent
                        showSendMelding={props.showSendMelding}    
                    />
                </Tab>

                <Tab eventKey="aktiviteter" title={localeMessages['TaC_T4']}>
                    <CaDetAktiviteterComponent
                        crNo={props.crNo}
                        caseId={props.crNo}                        
                    />
                </Tab>   

                <Tab eventKey="samlesak" title={localeMessages['TaC_T5']}>
                    <CaDetSamleSakComponent />                    
                </Tab>   
                

            </Tabs>

        </TabWrapper>
    )
}

export default CaDetTabContainer