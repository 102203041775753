import { ArticleArea } from "../../../Styling/Layout/ContentArea"
import { QRCodeCanvas } from "qrcode.react";
import { Container, Col, Form, Alert } from "react-bootstrap";
import { BfButtonBlue, BfFormControl, BfFormLabel, BfRowForm, BfTightRowForm, BfTightYellowRowForm, BfYellowRowForm, YellowRoundedContainer } from "../../../Styling/styledForm/styledBlueForm";
import { BfColInput } from "../../../Styling/FormComponents/FormInput";
import { useState } from "react";

export const DisplayQrCode = (props) => {

    let messages = props.messages
    let url = props.qrCodeUri
    const postValidationCode= props.postValidationCode
    
    //console.log(url)

    const [mfaCode, setMfaCode] = useState('')
    const submitHandler = e => {postValidationCode(mfaCode)}

    return (

        <YellowRoundedContainer fluid="md">

            <Form autocomplete="off" onSubmit={submitHandler}>
                <Container >

                    <ArticleArea>
                        <h1>{messages.DisplayQrCodeHeader}</h1>
                        <p>{messages.DisplayQrCodeParagraph}</p>
                    </ArticleArea>

                    <BfYellowRowForm>
                        <QRCodeCanvas
                            id="qrCode"
                            value={url}
                            size={400}
                            bgColor={"#ffffff"}
                            level={"H"}
                        />
                    </BfYellowRowForm>

                    <BfTightYellowRowForm />
                    <BfTightYellowRowForm />

                    <BfYellowRowForm>
                        <Col xs={12} md={12}>
                            <Form.Group controlId="formHorizontalmfaCode">
                                
                                <BfFormLabel>{messages.Kode}</BfFormLabel>

                                <BfFormControl
                                    className="col-md2"
                                    type="input"
                                    placeholder=""
                                    name="mfacode"
                                    value={mfaCode}
                                    onChange={(event) => setMfaCode(event.target.value)}
                                />
                                
                            </Form.Group>
                        </Col>
                    </BfYellowRowForm>

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>
                            <BfButtonBlue variant="primary" type="button" disabled={!mfaCode} onClick={submitHandler}>
                                {messages.SendKode}
                            </BfButtonBlue>
                        </Col>
                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm />

                </Container>

            </Form>


        </YellowRoundedContainer>




    )
}