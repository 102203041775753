import styled from 'styled-components'
import colorConfig from '../../colorConfig'

// kred no har farge  4px solid #f19568
// Var border-bottom: 4px solid rgb(35, 75, 117);
const BlueHeader1Left = styled.div`  
  float: left;  
  margin-right: 20px;
  height: 34px;
  ${props => props.border === true && `
         border-bottom: 2px solid ${colorConfig.lys_gul};
  `}
  font-weight: 600;
  
  >a {
    color: ${colorConfig.lys_gul};
    text-decoration: none
  }
`

const BlueHeader1Right = styled.div`  
  float: right;  
  margin-right: 20px; 
  padding-bottom: 4px;
  font-weight: 400;
  color: ${colorConfig.lys_gul};
 
  >a {
    color: ${colorConfig.lys_gul};
    text-decoration: none
  }
`

const BlueHeader1Clear = styled.div`  
  clear: both; 
`
// Padding Top, right, bottom, left
const BlueHeader1Area = styled.div`  
  font-family: 'Fellix', sans-serif;
  height: 51px;
  font-size: 24px;
  padding: 13px 50px 0px 50px;
  margin: 0px 0px 40px 0px;
  background-color: ${colorConfig.header_bg};
  color: ${colorConfig.lys_gul};
  min-width: 1800px;

  border-bottom: 40px solid ${colorConfig.header_bg};

  @media print { 
      display: none      
  }
   
`

export { BlueHeader1Area, BlueHeader1Left, BlueHeader1Right, BlueHeader1Clear }