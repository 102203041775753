export const messages = {
    nb: {
        Overskrift: `Last opp faktura på sak`,
        sak: `Sak`,
        kreditor: `Kreditor`,
        velgFakt: `Velg faktura`,   
        filnavn: `filnavn`,        
        filVelg: `Velg fil`,        
        Ok: `Last opp`,
        Avbryt: `Avbryt`,

        getFakturalisteError: 'Feil oppstod under henting av fakturaer: ',
        getFakturalisteOk: 'Fakturaer for sak hentet OK',

        postFakturaUploadError: 'Feil oppstod under opplasting av fakturavedlegg. ',
        postFakturaUploadOk: 'Fakturavedlegg er lastet opp OK',
    
    }
}
