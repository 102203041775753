import React, { useContext } from 'react';
import LocaleContext from '../state/contexts/LocaleContext';
import { messages } from './Header1Menu.i18n'
import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth'
import { Dropdown } from "react-bootstrap";
import { useSelector } from 'react-redux'

const Header1Logout = (props) => {

    const KONTAKTSKJEMA_ENABLED = true

    const locale = useContext(LocaleContext);
    const { LogoutCaption } = messages[locale]

    const auth = useAuth()
    const history = useHistory()

    // Henter meny fra global state
    const toolsMenu = useSelector(state => state.toolsMenu)

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
   
        </a>
    ));

    const redirectToCMSPage = (item) => {
        console.log(item.id)
        history.push(`/HeadlessCMS/${item.id}`)
    }
    
    const renderToolsMenu = () => {
        if (toolsMenu.items) {

            var result = toolsMenu.items.map((item, i) => {
                if (item.type.toLowerCase() === 'e') {
                    return (
                        
                        <a href={item.uri} key={i} target="_blank" className="dropdown-item" role="Button" style={{ 'color': '#003e60' }}>
                        {item.caption}
                    </a>
                    )                        
                } else {
                    return (
                        <Dropdown.Item key={i} eventKey={i*10} onClick={() => redirectToCMSPage(item)}>{item.caption}</Dropdown.Item>
                    )
                }
            })

            return result                        
        }
        return null
    }

    return (
        <>           

            {auth.isAuthenticated &&
                <>
                <Dropdown>
                   
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        <span className="fa fa-align-justify" style={{ 'color': '#F6EABE'}} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        
                        {KONTAKTSKJEMA_ENABLED  &&  <Dropdown.Item key="229" eventKey="2" onClick={() => history.push(`/KontaktSkjema`)}>Kontaktskjema</Dropdown.Item>}                                    
                       
                        {renderToolsMenu()}
                        <Dropdown.Item key="997" eventKey="2" onClick={() => history.push(`/RenteKalkulator/`)}>Rentekalkulator</Dropdown.Item>

                        <Dropdown.Item key="998" eventKey="2" onClick={() => history.push(`/Profil/`)}>Du er pålogget som {auth.user.name}</Dropdown.Item>
                        <Dropdown.Item key="999" eventKey="1" onClick={auth.logOut}>Logg ut&nbsp;</Dropdown.Item>
                    </Dropdown.Menu>
  
                </Dropdown>
                </>                            
            }                
        </>
    )
}

export default Header1Logout