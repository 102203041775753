export const messages = {
    nb: {
 
        AO1M_andAvdr: 'Antall avdrag',
        AO1M_Overskrift: `Avdragsordning - sak: `,
        AO1M_terminbelop: `Terminbeløp`,
        AO1M_phterminbelop: `Oppgi belop i hele kroner`,

        AO1M_epostNotValid: 'Oppgi gyldig epost',
        AO1M_mobilNotValid: 'Oppgi gyldig mobilnr',

        AO1M_andAvdr2: '2',
        AO1M_andAvdr3: '3',
        AO1M_andAvdr4: '4',
        AO1M_balanceTotal: 'Sluttsum',
        AO1M_forsRt: 'Forsinkelsesrenter og inkassosalær kan påløpe i perioden.',
        AO1M_terminLengde: `Terminlengde`,
        AO1M_alt1: `15 dager`,
        AO1M_alt2: `30 dager`,
        AO1M_skyldner: `Skyldner`,
        AO1M_alt_mob: 'Mobil',
        AO1M_alt_epost: 'Epost',
        AO1M_epost: `Epost`,
        AO1M_phepost: `Oppgi gyldig epostadresse`,
        AO1M_pnr: `Person/Orgnr`,
        AO1M_phpnr: `Oppgi Personnummer eller Orgnr`,
        AO1M_Ok: `Opprett`,
        AO1M_Avbryt: `Avbryt`,
   
        AO1M_terminbelHeleKr: 'Terminbeløp må oppgis i hele kroner. ',
        AO1M_terminMindreEnn: 'Terminbeløp kan ikke være mindre enn {minbel} kr. ',
        AO1M_terminMerEnnMax: 'Terminbeløp kan ikke være større enn {maxbel} kr. ',
        AO1M_epostNotValid: 'Du har ikke oppgitt en gyldig epostadresse. ',

        toastAvdrOrdnOk: 'Oppretting av avdragsordning er registrert OK.',
        toastAvdrOrdnFailed: 'Oppretting av avdragsordning feilet.',


    }
    
} 