import React, { useEffect } from 'react';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './OversiktContainer.i18n'

import OversiktContext from './OversiktContext';
import { OversiktTabs } from './tabs/OversiktTabs';

import { OverlaySpinner } from '../../components/spinner/OverlaySpinner'
import { BlueRow } from '../../Styling/styledForm/styledBlueForm';
import { Container, Col, Alert } from "react-bootstrap";
import OversiktHeaderComponent from './header/OversiktHeader'
import { IntlProvider } from 'react-intl'

import useLesModal from './modals/useLesModal'
import LesMeldingModal from './modals/LesMeldingModal';
import useSvarModal from './modals/useSvarModal'
import SvarMeldingModal from './modals/SvarMeldingModal';

import { useSelector, useDispatch } from 'react-redux'
import { getOversiktDataDisp } from '../../reduxStore/oversikt/oversiktActions';
import { getCMSbyIndex } from '../../reduxStore/HeadlessCMS/headlessCmsActions';

const OversiktContainer = (props) => {
    
    const OversiktArticleId = '10173'

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const showToasts = true;

    // Activate global redux reducer
    //const selectedKreditorState = useSelector(state => state.kredSel)
    const oversiktDataState = useSelector(state => state.oversikt)
    const cmsArticlesState = useSelector(state => state.headlessCMS)
    const headerState = useSelector(state => state.sakslisteHeader)
    let state = oversiktDataState
    const dispatch = useDispatch()

    //const { state, dispatch, actions } = useOversiktReducer(localeMessages)       
    
    const { showLes, lesModel, handleLesClose, handleLesShow, handleLesOk }
        = useLesModal(localeMessages, state, dispatch, showToasts)
    
    const { showSvar, svarModel, handleSvarClose, handleSvarShow, handleSvarOk }
        = useSvarModal(localeMessages, state, dispatch, showToasts)
    
    // TODO FJERN STATE FRA PROVIDER, brukes ikke lenger
    const providerObject = {
        _state: state, _dispatch: dispatch,
        localeMessages, showToasts,
        handleLesShow, handleLesClose, handleLesOk,
        handleSvarClose, handleSvarShow, handleSvarOk
    }

    const messageIngen = localeMessages['ingen']

    // Hent data ved oppstart
    useEffect(() => {
        /* Fjernet til fordel for inkassoakademiet
        // Dispatch henting av data i redux
        let fetchCMS = (!cmsArticlesState.data[OversiktArticleId])
        if (fetchCMS) {
            dispatch(getCMSbyIndex(OversiktArticleId, showToasts, localeMessages))
        }
        */
        
        //dispatch(getOversiktDataDisp(selectedKreditorState.selectedKreditor, showToasts))
        let _kredId = '-1'
        if (headerState.kreditorId) {
            _kredId = headerState.kreditorId
        }
        dispatch(getOversiktDataDisp(_kredId, showToasts))
    }, [
        //selectedKreditorState.selectedKreditorState
        headerState.kreditorId
    ])

    function round(value, decimals) {        
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

   
    const avptHa = () => round(state.data.avptHa, 1)
    const avptCr = () => round(state.data.avptCr, 1)

    const ubesvarte = () => {
        if (!state.data.oppgaveListe) {
            return messageIngen
        }
        let count = state.data.oppgaveListe.filter((e) => e.isReplyable && !e.isHistorical)

        return count.length ? count.length : messageIngen
    }
    
    const uleste = () => {
        if (!state.data.oppgaveListe) {
            return messageIngen
        }
        let count = state.data.oppgaveListe.filter((e) => e.isInformation && !e.isHistorical)

        return count.length ? count.length : messageIngen
    }

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <OversiktContext.Provider value={providerObject} >

                {(state.error) &&
                    <Alert variant='danger'>
                        {state.error}
                    </Alert>}

                {state.loading && <OverlaySpinner />}

                {showLes && <LesMeldingModal
                    close={handleLesClose}
                    ok={handleLesOk}
                    localeMessages={localeMessages}
                    model={lesModel}
                />}

                {showSvar && <SvarMeldingModal
                    close={handleSvarClose}
                    ok={handleSvarOk}
                    localeMessages={localeMessages}
                    model={svarModel}
                />}

                {state.fetched &&
                    <>

                        <OversiktHeaderComponent
                            a1={localeMessages.HA1}
                            a2={ubesvarte()}
                            b1={localeMessages.HB1}
                            b2={uleste()}
                            c1={localeMessages.HC1}
                            c2={avptHa() + localeMessages.HDAGER}
                            d1={localeMessages.HD1}
                            d2={avptCr() + localeMessages.HDAGER}
                        />

                        <Container fluid="true">

                      
                                <Col xs="12">
                                    <OversiktTabs
                                        state={oversiktDataState}
                                        localeMessages={localeMessages}
                                        htmlArticle={cmsArticlesState.data[OversiktArticleId] || ''}
                                    />
                                </Col>

                       

                        </Container>
                    </>
                }

            </OversiktContext.Provider>
        </IntlProvider>
    )
}

export default OversiktContainer