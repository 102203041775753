export const numberToLocaleString = val => {
 
    // Cheatsheet https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat

    const dateOptionsNbNo = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'decimal', 
    };
    
      if (val) {
          let res = val.toLocaleString('no-NO',  dateOptionsNbNo)    
          return res     
      }    
      return ''
}