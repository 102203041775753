import React from "react";
import { Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,

} from "../../Styling/styledModal/styledModals";

export const ConfirmModal = (props) => {

    const okClicked = () => {
        console.log('you clicked ok', props.data)
        props.okHandler(props.data)
    }

    return (

        <StyledModal centered show={true} onHide={props.cancelHandler}>

            <StyledModalBody>
                <h4>{props.header}</h4>
                <p style={{whiteSpace: "pre-line"}}>{props.message}</p>
            </StyledModalBody>
           
            <StyledModalFooter>
                <Button variant="secondary" onClick={okClicked}>
                    {props.okCaption}
                </Button>
                <Button variant="secondary" onClick={props.cancelHandler}>
                    {props.cancelCaption}
                </Button>
            </StyledModalFooter>

        </StyledModal>
    );
};
