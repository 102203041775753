import styled from 'styled-components'
import colorConfig from '../../colorConfig'

// ┌──────────────────────────────────────────────────────────────────────────────┐
// │ Brukes for å få farge på selected tab som er en Link                         │
// └──────────────────────────────────────────────────────────────────────────────┘

export const TabWrapper = styled.div`
    font-family: 'Fellix';    

    font-size: 20px;   
    background-color: white;
    padding: 4px;
    color: rgb(0, 62, 96);
    a {        
        color: rgb(0, 62, 96)  !important;
        font-weight: 500;
    }
    a.active {
        background-color: ${colorConfig.taballrad_mork}  !important;
        color: rgb(0, 62, 96)  !important;
        font-weight: 800;
    }
`