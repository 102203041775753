export const messages = {
    nb: {
        header: `Onboarding hos Kreditorforeningen`,
        beskrivelse: `Dersom du ønsker å bli kunde hos kreditorforeningen, kan du selv registrere deg med brukernavn og passord her.`,
        Lagre: `Oppdater profilen`,

        getBronnoysundInfo: 'Klikk på knappen for å hente info fra brønnøysund',
        getBronnoysund: 'Hent firmainfo',

        orgnrLabel: 'Oppgi organisasjonsnr',
        orgnrPlaceholder: 'Oppgi 9 sifret organisasjonsnummer',
        orgnrSmall: '"Hent Firmainfo" henter data fra Brønnøysundregistrene. ',

        firmainformasjon: 'Informasjon om firma',
        brukerInformasjon: 'Informasjon om bruker av KF Portal',
        annenInfo: 'Annen informasjon',

        momsPliktigLabel: 'Momspliktig',
        momsPliktigPlaceholder: ' ',


        prodTypeLabel: 'Produkttype',
        prodTypePlaceholder: ' ',

        firmanavnLabel: 'Firmanavn',
        firmanavnPlaceholder: ' ',
        adresseLabel: 'Adresse',
        adressePlaceholder: ' ',
        postnrLabel: 'Postnr',
        postnrPlaceholder: ' ',
        poststedLabel: 'Poststed',
        poststedPlaceholder: ' ',
        firmaepostLabel: 'Firmaepost',
        firmaepostPlaceholder: ' ',
        kontonrLabel: 'Kontonr',
        kontonrPlaceholder: ' ',
        brukernavnLabel: 'Navn på bruker',
        brukernavnPlaceholder: ' ',
        brukeridLabel: 'Epostadresse (benyttes for pålogging) ',
        brukeridPlaceholder: ' ',
        passordLabel: 'Passord (min 8 tegn, store/små/tall og spesialtegn)',
        passordPlaceholder: '',
        gjentapassordLabel: 'Gjenta passord',
        gjentapassordPlaceholder: ' ',
        telefonnrLabel: 'Telefonnr ',
        telefonnrPlaceholder: ' ',
        okonomisystemLabel: 'Økonomisystem',
        okonomisystemPlaceholder: ' ',
        foreningLabel: 'Forening',
        foreningPlaceholder: ' ',
        kommentarLabel: 'Kommentar',

        orgNrIsRequired: 'Organisasjonsnummer må oppgis. ',
        isValidOrgNr: 'Du har ikke oppgitt et gyldig organisasjonsnummer. ',
        firmanavnIsRequired: 'Firmanavn må oppgis. ',
        adresseIsRequired: 'Adresse må oppgis. ',
        postnrIsRequired: 'Postnummer må oppgis. ',
        poststedIsRequired: 'Poststed må oppgis. ',
        kontonrIsRequired: 'Kontonummer må oppgis. ',
        isValidInteger: 'Kun siffer (0-9) tillatt. ',
        isValidKontoNr: 'Du har ikke oppgitt en gyldig bankkonto. ',
        fullNameisRequired: 'Fullt navn må oppgis. ',
        brukeridIsRequired: 'BrukerId må oppgis. ',
        momsPliktigIsRequired: 'Momsplikt må oppgis. ',
        prodTypeIsRequired: 'Produkttype må oppgis. ',
        foreningRequired: 'Forening må oppgis. ',
        financialSystemRequired: 'Økonomisystem må oppgis.',        
        passWordRequired: 'Passord må oppgis. ',
        isValidPhone: 'Telefonnummer er ikke gyldig. ',
        
        checkEqualPasswords: 'Passordene er ikke like. ',
        validatePassword: 'Passord må ha minst 8 tegn, både store og små bokstaver, tall og spesialtegn. ',

        validateEmail: 'Du har ikke oppgitt en gyldig epostadresse. ',
        minLength3: 'Feltet må inneholde minst 3 tegn. ',
        minLength5: 'Feltet må inneholde minst 5 tegn. ',
        minLength8: 'Feltet må inneholde minst 8 tegn. ',
        minLength11: 'Feltet må inneholde 11 siffer. ',

        youHaveErrors: 'Du har felter som ikke er utfylt eller har ugyldige verdier.  Du må korrigere dette før vi kan opprette ny kunde. ',

        prodTypeDefault: 'Velg fra listen',
        prodtypePurre: 'Purreservice – Vi sender inkassovarsel for deg',
        prodtypeInkasso: 'Inkasso – Dere har sendt inkassovarsel til kunden',

        mvapliktDefault: 'Velg fra listen',
        mvaplikt1: 'Ja',
        mvaplikt0: 'Nei',

        toastFinancialError: 'Kunne ikke hente ut økonomisystemer (getFinancialSystems)',
        toastFinancialSystems: 'Liste over økonomisystemer hentet.',

        toastFirmainfoNon200: 'Ingen firmainformasjont funnet i Brønnøysundregistrene.',
        toastFirmainfoError: 'Kunne ikke hente firmainformasjon fra Brønnøysundregistrene.',
        toastFirmaIntoHentet: 'Firmainformasjon ble hentet fra Brønnøysundregistrene.',

        toastSaveError: 'Kunne ikke opprette ny kreditor (newCreditor)',
        toastSaveOk: 'Ny kreditor er lagret.',

        oksysDefault: 'Velg fra listen',
        
    }
}