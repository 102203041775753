import React from 'react';
import { Container, Col, Form, Alert } from "react-bootstrap";
import { BfColInput } from '../../Styling/FormComponents/FormInput';
import { BfYellowRowForm, BfRowForm, BfTightRowForm, BfTightYellowRowForm, BfButtonBlue, YellowRoundedContainer } from '../../Styling/styledForm/styledBlueForm'

export const OnBoardingForm = (props) => {

    const handleChange = props.handleChange
    const errorMessages = props.errorMessages
    const extraErrors = props.extraErrors
    const localeMessages = props.localeMessages
    const formState = props.formState
    const getFirmaInfo = props.getFirmaInfo
    const hasErrors = props.hasErrors
    const savedOk = props.savedOk
    const okonomiSys = props.okonomiSys
    const momsPlikt = props.momsPlikt
    const produktType = props.produktType
    const foreninger = props.foreninger
    const submitForm = props.submitForm


    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}                
            />
        )
    }


/* 

<Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label>Example textarea</Form.Label>
    <Form.Control as="textarea" rows={3} />
  </Form.Group>
*/


    return (
        <YellowRoundedContainer fluid="md">

            <Form autoComplete="chrome-off">
                <Container >

                    {hasErrors && (
                        <Alert variant='danger'>
                            {localeMessages.youHaveErrors}
                        </Alert>
                    )}

                    {savedOk && (
                        <Alert variant='success'>
                            {savedOk}
                        </Alert>
                    )}

                    <BfYellowRowForm>
                        <Col>
                            <h4>{localeMessages.firmainformasjon}</h4>
                        </Col>
                    </BfYellowRowForm>
                    <BfTightYellowRowForm>
                        {getFormField(6, 'orgnr', null, null, false, 9)}
                        <Col xs={6} md={6}>
                            {localeMessages.getBronnoysundInfo}<br />
                            <BfButtonBlue variant="primary" onClick={() => getFirmaInfo()} disabled={!(formState.orgnr)}>
                                {localeMessages.getBronnoysund}
                            </BfButtonBlue>
                        </Col>
                    </BfTightYellowRowForm>
                    <BfTightYellowRowForm>
                        {getFormField(6, 'firmanavn', null, null, false, 40)}
                        {getFormField(6, 'adresse', null, null, false, 63)}
                    </BfTightYellowRowForm>
                    <BfTightYellowRowForm>
                        {getFormField(6, 'postnr', null, null, false, 10)}
                        {getFormField(6, 'poststed', null, null, false, 30)}
                    </BfTightYellowRowForm>
                    <BfTightYellowRowForm>
                        {getFormField(6, 'firmaepost', null, null, false, 63)}
                        {getFormField(6, 'kontonr', null, null, false, 11)}
                    </BfTightYellowRowForm>
                    <BfTightYellowRowForm>
                        {getFormField(6, 'momsPliktig', 'select', momsPlikt)}
                        {getFormField(6, 'prodType', 'select', produktType)}
                    </BfTightYellowRowForm>

                    <BfYellowRowForm>
                        <Col>
                            <h4>{localeMessages.brukerInformasjon}</h4>                            
                        </Col>
                    </BfYellowRowForm>
                    <BfYellowRowForm>
                        {getFormField(12, 'brukernavn', null, null, false, 50)}
                        {getFormField(6, 'brukerid', null, null, false, 100)}
                        {getFormField(6, 'telefonnr', null, null, false, 11)}
                        {getFormField(6, 'passord', 'password', null, null, false, 50)}
                        {getFormField(6, 'gjentapassord', 'password', null, null, false, 50)}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        <Col>
                            <h4>{localeMessages.annenInfo}</h4>                            
                        </Col>
                    </BfYellowRowForm>
                    <BfYellowRowForm>
                        {getFormField(6, 'okonomisystem', 'select', okonomiSys)}
                        {/*
                        {getFormField(6, 'forening', 'select', foreninger)}
                         */}
                    </BfYellowRowForm>
                    <BfYellowRowForm>
                        {getFormField(12, 'kommentar', 'textarea', null, null, false, 500)}
                    </BfYellowRowForm>

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>
                            <BfButtonBlue variant="primary" type="button" onClick={submitForm}>
                                Ok
                            </BfButtonBlue>                           
                        </Col>
                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm />

                </Container>
            </Form>
        </YellowRoundedContainer>
    )
}