import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import styled from 'styled-components'
import CaseDetailsContext from '../../CaseDetailsContext'
import { asDecimal, asDate } from '../../../../reduxStore/saksdetaljer/caseMapper'
import { useSelector } from 'react-redux';
import colorConfig from '../../../../colorConfig';

const StyledCol5 = styled(Col)`
    border-width: 0 0 0 1px;
    border-color:  rgb(0, 79, 113);
    border-style: solid;
    margin-bottom: 20px;

    position: relative;    
    width: 20%;
    float: left;

    @media print {
      border-width: 0 0 0 1px;
      font-family: 'Roboto', sans-serif; 
    }
`

const StyledColR5 = styled(Col)`
    border-width: 0 1px 0 1px;
    border-color:  rgb(0, 79, 113);
    border-style: solid;
    margin-bottom: 20px;

    position: relative;
    width: 20%;
    float: left;

    @media print {
      border-width: 0 1px 0 1px;
      font-family: 'Roboto', sans-serif; 
    }
`

const StyledBox = styled.div`
    margin-right: 15px;
   
    height: 68px;
    background-color: white;
    margin-bottom: 0px;

    font-size: 20px;
    font-weight: 500;
    color: ${colorConfig.tabellrad_tekst}; 
`

const StyledBoxHeader = styled.p`
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
    
    word-break:keep-all;
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;

    @media (max-width: 1024px) {
      font-size: 12px;
    }

    @media print {
      font-size: 9px;
      font-family: 'Roboto', sans-serif; 
    }
`

const StyledBoxNumber = styled.p`
    font-size: 30px;
    line-height: 45px;
    font-weight: 500;
    margin: 0px;
    padding: 0px 0px 8px 0px;
    color: ${colorConfig.header_tekst}; 
    
    word-break:keep-all;
    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;

    @media (max-width: 1900px) {
      font-size: 36px;
    }

    @media (max-width: 1600px) {
      font-size: 24px;
    }

    @media print {
      font-size: 16px;
      font-family: 'Roboto', sans-serif; 
    }    
`

const KeepAll = styled.div`
    margin:0 auto;
    word-break:keep-all;
    padding:0px 5px;
`

const leftBox5 = (caption, value, size, extraCaption) => {

  return (
    <StyledCol5>
      <StyledBox>
        
      <StyledBoxNumber>
          {value}
        </StyledBoxNumber>

        <StyledBoxHeader>
          {caption}
          {extraCaption && 
            <span>&nbsp;(
            <span style={{color:'red'}}><b>{extraCaption}</b></span>
            )</span>
          }
        </StyledBoxHeader>
      
      </StyledBox>
    </StyledCol5>
  )
}


const rightBox5 = (caption, value, size) => {

  return (
    <StyledColR5>
      <StyledBox>
        <StyledBoxNumber>
          {value}
        </StyledBoxNumber>
        <StyledBoxHeader>{caption}</StyledBoxHeader>
      </StyledBox>
    </StyledColR5>
  )
}

const CaDetHeaderComponent = (props) => {

  const printDecimal = (val) => {
    let res = val ? asDecimal(val) : asDecimal(0)
    console.log(val, res)
    if ((val === undefined) || (val === 0))
      return '0.00'
    return (
      asDecimal(val)
    )
  }

  const getLastActivity = (caseActivityList) => {
    if (!caseActivityList) {
      return ('')
    }
    let lastAct = caseActivityList[caseActivityList.length - 1]
    if (lastAct.activityText) {
      return lastAct.activityText
    }
    return ('')
  }

  const { localeMessages } = React.useContext(CaseDetailsContext);
  const state = useSelector(state => state.saksdetaljer)

  let a1 = state.data.fetchedData.caseCreditor.creditorName
  let a2 = state.data.fetchedData.caseCreditor.customerNo

  let b1 = localeMessages['HDET_Saksnr']
  let b2 = state.data.fetchedData.caseNo

  let c2 = state.data.fetchedData.caseReference

  let d2 = state.data.fetchedData.lastActivity

  let e1 = state.data.fetchedData.caseStatus
  let e2 = state.data.fetchedData.caseStatus2

  return (
    <Container fluid="true">
      <Row>
        {leftBox5(a1, a2, 3)}
        {leftBox5(b1, b2, 2, props.samlesakHeader)}
        
        {leftBox5(localeMessages['HDET_Referansenr'], c2, 2)}
        {leftBox5(localeMessages['HDET_SisteAkt'], d2, 2)}
        {rightBox5(e1, e2, 2)}
      </Row>
              
    </Container>
      
      )
    }
    
export default CaDetHeaderComponent

