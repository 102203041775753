import {
    FETCH_REPORTSMENU_BEGIN,
    FETCH_REPORTSMENU_FAILURE,
    FETCH_REPORTSMENU_SUCCESS,
    FETCH_DATAINTERVALS_SUCCESS,
    FETCH_SCEDULEINTEVALS_SUCCESS,
    FETCH_REPORTSSCEDULE_SUCCESS,
    FETCH_REPORTS_SUCCESS,

} from "./reportMenuActions"

const initialState = {
    menuItems: null,
    scheduleIntervals: null,
    dataIntervals: null,
    scheduleItems: null,
    reportItems: null,
    loading: false,
    error: null
}

const reportsMenuReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_REPORTSMENU_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_REPORTSMENU_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.menuItems
            }
        
        case FETCH_DATAINTERVALS_SUCCESS:
            return {
                ...state,
                loading: false,
                dataIntervals: action.payload.items
            }
    
        case FETCH_SCEDULEINTEVALS_SUCCESS:
            return {
                ...state,
                loading: false,
                scheduleIntervals: action.payload.items
            }
        
        case FETCH_REPORTSSCEDULE_SUCCESS:
            
            return {
                ...state,
                loading: false,
                scheduleItems: action.payload.menuItems
            }
                    
        case FETCH_REPORTS_SUCCESS:
        
            return {
                ...state,
                loading: false,
                reportItems: action.payload.menuItems
            }

        case FETCH_REPORTSMENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };

        default:
            return state
    }
}

export default reportsMenuReducer