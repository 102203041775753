import { useContext, useEffect, useState } from "react";

export const useBekreftFlytting = (messages, onOk, confirmMoveMessageFunc) => {

    const showToasts = true;

    const [dialogVisible, setDialogVisible] = useState(false)
    const [confirmMoveMessage, setConfirmMoveMessage] = useState('')

    const cancelHandler = e => {
        setDialogVisible(false)       
    }

    const okHandler = e => {
        onOk()
        setDialogVisible(false)
        
    }

    const showMoveDialog = () => {        
        setConfirmMoveMessage(confirmMoveMessageFunc())
        setDialogVisible(true)
    } 

    return {dialogVisible, showMoveDialog, cancelHandler, messages, confirmMoveMessage, okHandler}

}