import { useState, useEffect } from "react"
import { ArticleArea } from "../../../Styling/Layout/ContentArea"
import { BfButtonBlue } from "../../../Styling/styledForm/styledBlueForm"
import useUserAdminReducer from "../../useradmin/useUserAdminReducer"
import MFAInformation from "../../profil/MFAInformation"
import { Container } from "react-bootstrap"

export const MfaWelcome = (props) => {


    let messages = props.messages
    let handleClick = props.showQrCode
    let handleSMSClick = props.handleSMSClick
    let phoneNumber = props.profile?.phoneNumber

    const { state, setCurrentUser } = useUserAdminReducer(messages)
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false)

    useEffect(() => {
        if (state.user.username) {
            setTwoFactorEnabled(state.user.twoFactorEnabled)
        }
    }, [state.user])
    
    return (

        <Container>

            {!twoFactorEnabled && <MFAInformation />}

            <ArticleArea>
                <h1>{messages.WelcomeHeader}</h1>
                <p>{messages.WelcomeParagaph1}</p>
                <p>{messages.WelcomeParagaph2}</p>

               
                <BfButtonBlue onClick={() => handleClick()}>
                    {messages.showQrCode}
                </BfButtonBlue>

                <br /><br /><br />
                <h1>{messages.WelcomeHeaderSMS}</h1>

                {!phoneNumber && 
                <>
                    <p>{messages.WelcomeParagaph1SMS}</p>                                
                </>}

                {phoneNumber && 
                <>
                    <p>{messages.WelcomeParagaph1SMS}</p>
                    <p>{messages.WelcomeParagaph2SMS}</p>

                    <BfButtonBlue onClick={() => handleSMSClick()}>
                        {messages.sendSMSCode}
                    </BfButtonBlue>
                </>
                }
            </ArticleArea>

        </Container>
    )
}
