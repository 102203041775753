import React, { useEffect, useState } from "react";
import { FormattedDate, FormattedNumber } from 'react-intl'
import Workbook from 'react-excel-workbook'
import { fixMissingTimeFromDate } from "../../../util/date/dateRoutines";
import { BfButtonBlue } from "../../../Styling/styledForm/styledBlueForm";

export const ExcelSheet = (props) => {

  const asDateForExcel = (val) => {
    let splittedDate = val.replace(' ', '-').split('-');
    let ExcelDate = splittedDate[1] + '.' + splittedDate[2] + '.' + splittedDate[0];
    //console.log(ExcelDate);
    return ExcelDate
  }

  const asExcelNum = (val) => {
    return val
    return val.toString().replace(',', '.')
  }
 
  const getFileName = () => `${props.headerState.kreditorName}.xlsx`

  return (
    
    <Workbook
      filename={getFileName()}
      element={<BfButtonBlue>Excel export</BfButtonBlue>}>

      <Workbook.Sheet data={props.data} name="{props.filename}">
        
        <Workbook.Column label={props.localeMessages['GHF_invoiceDate']} value={row => asDateForExcel(row.invoiceDate)} />
        <Workbook.Column label={props.localeMessages['GHF_dueDate']} value={row => asDateForExcel(row.dueDate)}/>
        <Workbook.Column label={props.localeMessages['GHF_debitorId']} value="debitorId" />
        <Workbook.Column label={props.localeMessages['GHF_debitorName']} value="debitorName" />
        <Workbook.Column label={props.localeMessages['GHF_invoiceNumber']} value="invoiceNumber" />
        <Workbook.Column label={props.localeMessages['GHF_invoiceText']} value="invoiceText" />
        <Workbook.Column label={props.localeMessages['GHF_invoiceAmount']} value="invoiceAmount" />

      </Workbook.Sheet>

    </Workbook>

  )
}