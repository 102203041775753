const getIsRequired = (errorMessage) => {

    const isRequired = (val, name, formState) => {
        //console.log ('is not blank', val)
        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getMinLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {

        if (val.length >= length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}



const stringToDate = function (dateString) {
    const [dd, mm, yyyy] = dateString.split(".");
    return new Date(`${yyyy}-${mm}-${dd}`);
};

const getIsBeyondToday = (errorMessage, length) => {

    const isBeyondToday = (val, name, formState) => {

        if (!val) {
            return ''
        }
        
        let CurrentDate = new Date()
        let GivenDate = stringToDate(val)

        if (GivenDate > CurrentDate) {
            return errorMessage
        } else {
            return ''
        }
    }

    return isBeyondToday
}

const getIsBeyondThreeYearOld = (errorMessage, length) => {

    const isBeyondToday = (val, name, formState) => {

        if (!val) {
            return ''
        }

        if (val.length !== 10) {
            return ''
        }
        
        let CurrentDateThreeAYearsAgo = new Date()
        let thisYear = CurrentDateThreeAYearsAgo.getFullYear()
        CurrentDateThreeAYearsAgo.setFullYear(thisYear-3)
        let GivenDate = stringToDate(val)

        if (GivenDate < CurrentDateThreeAYearsAgo) {
            return errorMessage
        } else {
            return ''
        }
    }

    return isBeyondToday
}


const getForfallEtterFaktDate = (errorMessage, length) => {

    const validatorFunc = (val, name, formState) => {

        if (!val) {
            return ''
        }

        if (!formState.fakturadato) {
            return ''
        }

        let fakturaDate = stringToDate(formState.fakturadato)
        let forfall = stringToDate(val)

        if (forfall < fakturaDate) {
            return errorMessage
        } else {
            return ''
        }
    }

    return validatorFunc
}


export const getFakturaFormValidators = (messages) => {

    const fakturanummerIsRequired = getIsRequired(messages['fakturanummerIsRequired'])
    const fakturatekstIsRequired = getIsRequired(messages['fakturatekstIsRequired'])
    const forfallsdatoIsRequired = getIsRequired(messages['forfallsdatoIsRequired']) 
    const isBeyondThreeYearsOld = getIsBeyondThreeYearOld(messages['isBeyondThreeYearsOld'])
    const fakturadatoIsRequired = getIsRequired(messages['fakturadatoIsRequired'])
    const valutaIsRequired = getIsRequired(messages['valutaIsRequired'])
    const belopIsRequired = getIsRequired(messages['belopIsRequired'])

    const minLength10 = getMinLenght(messages['minLength10'], 10)
    const isBeyondToday = getIsBeyondToday(messages['isBeyondToday'], 10)
    const isForfallOk = getForfallEtterFaktDate(messages['isForfallOk'], 10)
    
    const formValidators = {

        fakturanummer: [
            fakturanummerIsRequired
        ],
        kid: [

        ],
        fakturatekst: [
            fakturatekstIsRequired
        ],

        forfallsdato: [
            fakturadatoIsRequired,
            minLength10,            
            isBeyondToday,
            isBeyondThreeYearsOld,
            isForfallOk,
        ],
        fakturadato: [
            fakturadatoIsRequired,
            minLength10,
            isBeyondToday,
            isBeyondThreeYearsOld
        ],
        valuta: [
            valutaIsRequired
        ],
        belop: [
            belopIsRequired
        ]    
    }

    return formValidators
}


