import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useUcForm } from '../../../hooks/useUcForm';
import LocaleContext from '../../../state/contexts/LocaleContext';
import { messages } from './pbiPlannerForm.i18n'
import { pbiPlannerFormValidators } from './pbiPlannerFormValidators';
import { getReportsScheduleList } from '../../../reduxStore/ReportMenu/reportMenuActions';
import axiosTokenInstance from '../../../Instances/axiosTokenInstance';
import { toast } from 'react-toastify';

export const usePbiPlannerForm = (props) => {

    const ALLE = 'ALLE'
    const ALLE_CAPT = 'ALLE (velger alle kreditorer)'

    const locale = useContext(LocaleContext);
    const localeMessages = messages[locale]

    const [showConfirm, setShowConfirm] = useState(false)

    const reportMenuState = useSelector(state => state.reportsMenu)
    const crState = useSelector(state => state.sakslisteHeader)
    const dispatch = useDispatch()

    const showToasts = true
    
    let formValues = {
        ReportId: '',
        reportName: '',
        scheduleInterval: '',
        dataInterval: '',
        listactor: '', 
        selectedKreditors: [],
        unSelectedActors: []     
    }

    // Vi må hente denne rapporten dersom det ikke er den samme som før
    useEffect(() => {          
        
        // Dersom du kom hit på første url, er ikke rapportlisten hentet ennå, du må hente den også
        if (!reportMenuState.scheduleItems && !reportMenuState.loading && reportMenuState.error === null) {
            dispatch(getReportsScheduleList())
        }   
    }, [])
    
    const formValidators = pbiPlannerFormValidators(localeMessages);

    const {
        handleChange, handleCheckboxChange, setField, handleMultiSelectChange, formState, validateField,
        validateAll, errorMessages, formIsValid, clearField, clearErrors,
        validatorsState, debuginfo} = useUcForm(formValues, formValidators)


    const [extraErrors, setExtraErrors] = useState({})
    const hasErrors = Object.values(errorMessages).some(x => (x !== null && x !== '')) || Object.values(extraErrors).some(x => (x !== null && x !== ''));

    const getScheduleIntervalRadioBtns = () => {
        if (!reportMenuState?.scheduleIntervals) 
            return (<></>)
        
        let intervals = JSON.parse(reportMenuState.scheduleIntervals)
        return (
            <div onChange={e => handleCheckboxChange(e)} >                            
                {intervals.map((e, i) => {
                    //console.log(e.scheduleIntervalId)
                    return <>
                        <input
                            type="radio"
                            name="scheduleInterval"
                            value={e.scheduleIntervalId}                            
                            key={i}
                            checked={e.scheduleIntervalId.toString() === formState.scheduleInterval}
                        />
                        &nbsp;<b>{e.intervalName}</b>&nbsp;&nbsp;&nbsp;
                    </>
                })}
            </div>
        )
    }

    const [alleIsSelected, setAlleIsSelected] = useState(false)

    const _alleIsSelected = () =>  {
        let result = (-1 != formState.selectedKreditors.indexOf(ALLE))
        setAlleIsSelected(result)
        return result
    }
   
    /*
      Listactor inneholder alle markerte verdier i listbox
      SelectedKreditors inneholder alle elementer i høyre  listbox
      Setter state med hvilke kreditorer som er valgt kjøres fra knapp på form
    */
    const leggTilCustomer = (e) => {    

        if (_alleIsSelected())
            return

        const alleSelected = formState.listactor.find(e => e === ALLE)
        if (alleSelected) {
            setField('selectedKreditors', [alleSelected])
            setAlleIsSelected(true)
            return
        }

        let newVal = []
        newVal = newVal.concat(formState.selectedKreditors, formState.listactor)
        
        // Remove duplicates from array
        newVal =  [...new Set(newVal)];
        setField('selectedKreditors', newVal)
        
                          
    }

    const trekkFraCustomer = (e) => {  

        if (formState.unSelectedActors.includes(ALLE))
            setAlleIsSelected(false)

        // unSelectedActors inneholder de valgte
        let newVal = []
        newVal = Array.from(formState.selectedKreditors).filter(i => !formState.unSelectedActors.includes(i))

        // Remove duplicates from array
        newVal =  [...new Set(newVal)];
        setField('selectedKreditors', newVal)

     }

    const getCustomersToSelect = () => {

        let displayCustomers = [{customerNo: ALLE, disp: ALLE_CAPT}]
        let availCustomers = displayCustomers.concat(crState.kreditorliste)

        if (formState.selectedKreditors)
            availCustomers = availCustomers.filter(i => !formState.selectedKreditors.includes(i.customerNo))

        return (
            <>                
                {availCustomers.map((item, i) => (
                    <option
                        key={i}
                        value={`${item.customerNo}`}                                    
                    >
                        {item.disp}
                    </option>
                ))}
            </> 
        )
    }

    const getCustomersToUnSelect = () => {       

        let displayCustomers = [{customerNo: ALLE, disp: ALLE_CAPT}]
        let availCustomers = displayCustomers.concat(crState.kreditorliste)

        if (formState.selectedKreditors){            
            availCustomers = availCustomers.filter(i => formState.selectedKreditors.includes(i.customerNo))
        } else {
            availCustomers = []
        }
        

        return (
            <>                 
                {availCustomers.map((item, i) => (
                    <option
                        key={i}
                        value={`${item.customerNo}`}                                    
                    >
                        {item.disp}
                    </option>
                ))}
            </> 
        )
        
    }

    const submitForm = () => {

        let formHasErrors = validateAll()

        
        if (!formHasErrors) {
            
            // Create object to be used for POST to server
            let reportObj = {            
                "ReportId": "1",
                "PbiScheduleId": formState.reportId,
                "ReportName": formState.reportName,
                "SchedIntvalId": formState.scheduleInterval,
                "SendNotice": true,
                "IsEmpty": true,            // vise i listen
                "allUserCreditors": alleIsSelected,  // allUserCreditors"
                "ListActor": []
            }
            
            reportObj.ListActor = formState.selectedKreditors.map(e => { return { customerNo: e, departmentId: "" } })
            
            postReport(reportObj)
        }
    
    }


    const postReport = (model) => {
        
        props.setShowSpinner(true)

        axiosTokenInstance
        ({
            method: 'POST',
            url: `/api/report/postReportsc`,
            data: model
        })
        .then((result) => {   
            
            props.setShowSpinner(false)

            if (result.status !== 200) {                  
                (showToasts && toast.error('En feil oppstod, rapporten ble ikke lagret', {autoClose: false}))
            } else {                
                if (result.data.errorCode === "000") {
                    (showToasts && toast.info('Rapporten er lagret'))  
                    
                    initializeForm()
                    
                    // Dette gikk bra kjør OK event og reload listen
                    dispatch(getReportsScheduleList())

                } else {                    
                    (showToasts && toast.error('En feil oppstod, rapporten ble ikke lagret', {autoClose: false}))
                }
            }
        })
        .catch((err) => {             
            (showToasts && toast.error('En feil oppstod, rapporten ble ikke lagret, '+ err, {autoClose: false}))
        }).then(() =>
            props.setShowSpinner(false)
        ) 
    }


    const deleteReport = () => {

        if (formState.reportId) {
            
            // Create object to be used for POST to server
            let reportObj = {            
                "ReportId": "1",
                "PbiScheduleId": formState.reportId,
                "ReportName": formState.reportName,
                "SchedIntvalId": formState.scheduleInterval,
                "SendNotice": true,
                "IsEmpty": true,            // vise i listen
                "allUserCreditors": alleIsSelected,  // allUserCreditors"
                "ListActor": []
            }        
            
           postDelete(reportObj)
           setShowConfirm(false)
        }
    
    }


    const postDelete = (model) => {
        
        props.setShowSpinner(true)

        axiosTokenInstance
        ({
            method: 'POST',
            url: `/api/report/delReportsc`,
            data: model
        })
        .then((result) => {   
            
            props.setShowSpinner(false)

            if (result.status !== 200) {                  
                (showToasts && toast.error('En feil oppstod, rapporten ble ikke slettet', {autoClose: false}))
            } else {                
                if (result.data.errorCode === "000") {
                    (showToasts && toast.info('Rapporten er slettet'))    

                    // Tøm formen
                    initializeForm()
                    
                    // Dette gikk bra kjør OK event og reload listen
                    dispatch(getReportsScheduleList())

                } else {                    
                    (showToasts && toast.error('En feil oppstod, rapporten ble ikke slettet', {autoClose: false}))
                }
            }
        })
        .catch((err) => {             
            (showToasts && toast.error('En feil oppstod, rapporten ble ikke slettet, '+ err, {autoClose: false}))
        }).then(() =>
            props.setShowSpinner(false)
        ) 
    }


    const gridRowClicked = (model) => {    
        toast.info(`Du har valgt rapport "${model.scheduleName}" for redigering`)
        setField('reportId', model.pbiScheduleId)
        setField('reportName', model.scheduleName)
        setField("scheduleInterval", model.scheduleId)

        let customerNos = model.listActor.map(e => e.customerNo)

        setField("selectedKreditors", customerNos)

        // Sjekk om alle er satt
        if (model.allUserCreditors) {
            setField("selectedKreditors", [ALLE])
            setAlleIsSelected(true)
        } else {
            setAlleIsSelected(false)
        }
    }


    const initializeForm = () => {
        clearField("reportId", "")
        clearField("reportName", "")
        clearField("scheduleInterval", "")
        clearField("dataInterval", "")
        clearField("listactor","")
        clearField("selectedKreditors",[])
        clearField("unSelectedActors", [])

        setAlleIsSelected(false)

        // Set Errors
        clearErrors()
        //errorMessages = []
    }

    
    const debugInfo = () => {
        let validStr = "False"
        if (formIsValid === true) 
           validStr = "True"

        if (process.env.NODE_ENV == 'development') {
            return (
                <>
                    FormisValid: {validStr}<br />
                    errors: {JSON.stringify(errorMessages)}<br />
                    ekstraErrors: {JSON.stringify(extraErrors)}<br />
                    { JSON.stringify(formState, null, "\n")}
                </>
            )
        }
    }


    return {
        handleChange, handleMultiSelectChange, formState, 
        errorMessages, debugInfo, getScheduleIntervalRadioBtns, 
        getKreditorsToSelect: getCustomersToSelect, getKreditorsToUnSelect: getCustomersToUnSelect, alleIsSelected, initializeForm,
        leggTilCustomer, trekkFraCustomer, reportMenuState, submitForm, deleteReport, gridRowClicked,
        showConfirm, setShowConfirm
    }

}