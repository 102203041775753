import { useReducer } from 'react';
import axiosTokenInstance from '../../Instances/axiosTokenInstance'
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth'
import _ from 'lodash'

/*
avoid autofill chrome:
https://www.codementor.io/@leonardofaria/disabling-autofill-in-chrome-zec47xcui

bruk fontawesome på knapper
https://medium.com/@christine_tran/react-font-awesome-brand-icons-5829482bf7d4
*/

// TODO: Ved oppdatering av epostadressen har søkegridet feil epostadresse, og den må oppdateres
//       evt må søket hentes på nytt

const useUserAdminReducer = (messages) => {

    const auth = useAuth()

    const isAdminKreditor = () =>  auth.isAdminKreditor()

    const showToasts = true;
    const { toastNoUsersError, toastNoUsersFound, toastFoundUsers,
        toastNoUserError, toastNoUserFound, toastFoundUser,
        toastKreditorsError, toastNoKreditorsFound, toastFoundKreditors } = messages


    const displayForms = {
        SEARCHFORM: 'SEARCHFORM',
        SEARCHRESULT: 'SEARCHRESULT',
        EDITFORM: 'EDITFORM'
    }

    const initialState = {
        searchForm: { 'searchField': '', 'searchValue': '' },
        users: [],
        user: {
            "newUser": true,
            "username": "",
            "fullName": "",
            "newPassword1": "",
            "newPassword2": "",
            "bisNodeEnabled": false,
            "webserviceBruker": false,
            "twoFactorEnabled": false,
            "bisNodeUser": "",
            "bisNodePassword": "",
            "disabled": false,
            "rolle": "",
            "forening": "",
            "bisLabDate": "",
            "kreditorListe": [],
        },
        currentForening: -1,
        currentForeningKreditorListe: [],
        activeForm: displayForms.SEARCHFORM,
        loading: false,
        error: '',
        savedMessage: ''

    }
    
    const actions = {
        LOADING: 'LOADING',
        LOADING_KREDITORER: 'LOADING_KREDITORER',
        RESET: 'RESET',
        SHOW_SEARCHFORM: 'SHOW_SEARCHFORM',
        SAVE_SEARCHFORM: 'SAVE_SEARCHFORM',
        SHOW_SEARCHRESULTS: 'SHOW_SEARCHRESULTS',
        UPDATE_SEARCHRESULTS: 'UPDATE_SEARCHRESULTS',
        USER_DELETED: 'USER_DELETED',
        GOTO_SEARCHRESULT: 'GOTO_SEARCHRESULT',
        SHOW_USERDETAILS: 'SHOW_USERDETAILS',
        GOTO_USERDETAILS: 'GOTO_USERDETAILS', 
        SHOW_EMPTYUSER: 'SHOW_EMPTYUSER',
        KREDITORS_FETCHED: 'KREDITORS_FETCHED', 
        DATA: 'DATA',
        ERROR: 'ERROR',
    }
    
    const dataReducer = (state, action) => {

        //console.log('useUserAdminReducer: ', JSON.stringify(action))

        switch (action.type) {

            case actions.LOADING:
                return {
                    ...state,                    
                    loading: true,
                    error: null,
                    savedMessage: '',
                    canDelete: false
                };

            case actions.ERROR:
                return {
                    ...state,                   
                    loading: false,
                    error: action.data,
                    savedMessage: ''
                };
            
            case actions.LOADING_KREDITORER:
                return {
                    ...state,                    
                    loading: true,
                    error: null,
                    savedMessage: ''
                };
           
            case actions.RESET:
                //console.log('*RESETTING*')
                return {
                    ...initialState
                };
             
            case actions.SAVE_SEARCHFORM:

                return {
                    ...state,
                    searchForm: action.data
                };
            
            case actions.SHOW_SEARCHFORM:

                return {
                    ...state,                    
                    activeForm: displayForms.SEARCHFORM,
                    loading: false,
                    error: null,
                    savedMessage: ''
                };
            
            case actions.SHOW_SEARCHRESULTS:

                return {
                    ...state,
                    users: [...action.data],
                    activeForm: displayForms.SEARCHRESULT,
                    loading: false,
                    error: null,
                    savedMessage: ''
                };

            case actions.UPDATE_SEARCHRESULTS:
                
                return {
                    ...state,
                    users: action.data,
                }

            case actions.USER_DELETED: 
                return {
                    ...state,
                    users: [...action.data],
                    activeForm: displayForms.SEARCHRESULT,
                    loading: false,
                    error: null,
                    savedMessage: action.savedMessage                    
                }
            
            case actions.GOTO_SEARCHRESULT: 
                return {
                    ...state,                    
                    activeForm: displayForms.SEARCHRESULT,
                    loading: false,
                    error: null,
                    savedMessage: ''
                };
            
            // Bruker hentet fra rest api
            case actions.SHOW_USERDETAILS:
                console.log(actions.SHOW_USERDETAILS, action.data)
                return {
                    ...state,
                    user: {...action.data},
                    activeForm: displayForms.EDITFORM,
                    loading: false,
                    error: null,
                    savedMessage: '',
                    canDelete: true
                };
            
            case actions.GOTO_USERDETAILS: 
                //console.log(actions.GOTO_USERDETAILS, action.data)
                return {
                    ...state,                    
                    activeForm: displayForms.EDITFORM,
                    loading: false,
                    error: null,
                    savedMessage: action.data
                }
        
            // Oppretter ny bruker
            case actions.SHOW_EMPTYUSER:
                return {
                    ...state,
                    user: action.data,
                    currentForening: -1,
                    currentForeningKreditorListe: [],
                    activeForm: displayForms.EDITFORM,
                    loading: false,
                    error: null,
                    savedMessage: '',
                    canDelete: false
                };
            
            case actions.KREDITORS_FETCHED:
                return {
                    ...state,
                    currentForening: action.data.foreningsId,
                    currentForeningKreditorListe: action.data.kreditorer,                   
                    loading: false,
                    error: null,
                    savedMessage: ''
                };                                

            default:
                return state;
        }
    }
    const [state, dispatch] = useReducer(dataReducer, initialState)

    // Hver metode under vil ha et eget axioskall med then og catch
    const resetContainer = () => {
        dispatch({ type: actions.SHOW_SEARCHFORM, data: initialState })
    }
   
    const showSearchForm = () => { 
        dispatch({ type: actions.SHOW_SEARCHFORM, data: null })
    }

    const gotoSearchResult = () => {
        if (state.users.length > 0) {
            dispatch({ type: actions.GOTO_SEARCHRESULT, data: null })
        }
        else {               
            dispatch({ type: actions.SHOW_SEARCHFORM, data: null })
        }
    }

    const searchUsers = (searchField, searchValue) => {
        //alert ('søk')
        //console.log('searchUsers clicked.')
        dispatch({ type: actions.SAVE_SEARCHFORM, data: { 'searchField': searchField, 'searchValue': searchValue } })
        dispatch({ type: actions.LOADING, data: null })
        axiosTokenInstance            
            ({
                method: 'GET',
                url: `api/UserAdmin/SearchUser?searchField=${searchField}&searchValue=${searchValue}`                
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(toastNoUsersError, {autoClose: false}))
                    dispatch({ type: actions.ERROR, data: toastNoUsersError })
                }    
                else if (result.data.length > 0) {
                    (showToasts && toast.info(toastFoundUsers.replace('${poster}', result.data.length)))
                    let dispResult = [...result.data].map((user) => {

                        let lockSort = '1000'
                        if (user.lockoutEnd) {
                            lockSort = 0
                        } else if (user.accessFailedCount) {
                            lockSort = 4 - user.accessFailedCount
                        } else if (user.disabled) {
                            lockSort = 99
                        }
                        user.lockSort = lockSort

                        // Kludge to display Webserviceusers, and make field sortable in grid
                        if (user.webserviceBruker == true) {
                            user.krRolle = "WSUser"
                        }

                        return user

                    } )
                    dispatch({ type: actions.SHOW_SEARCHRESULTS, data: dispResult })                    
                } else {
                    (showToasts && toast.warning(toastNoUsersFound))  
                    dispatch({ type: actions.SHOW_SEARCHFORM, data: initialState })
                    dispatch({ type: actions.ERROR, data: toastNoUsersFound })
                }
            })
            .catch((err) => {
                (showToasts && toast.error(toastNoUsersError, {autoClose: false}))
                console.log(err)
                dispatch({ type: actions.ERROR, data: err.message })
            })
    }

    const setCurrentUser = (userName) => {
        console.log("Henter bruker: ", userName)
        
        dispatch({ type: actions.LOADING, data: null })
        axiosTokenInstance
            ({
                method: 'GET',
                url: `api/UserAdmin/GetUser?email=${userName}`
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(toastNoUserError, {autoClose: false}))
                    dispatch({ type: actions.ERROR, data: toastNoUserError })                    
                }
                else if (result.data) {
                    (showToasts && toast.info(toastFoundUser))
                    dispatch({
                        type: actions.SHOW_USERDETAILS,
                        data: { ...result.data, orgUsername: result.data.username, DnnPassword: '', newUser: false }
                    })
                } else {
                    (showToasts && toast.warning(toastNoUserFound))
                    dispatch({ type: actions.ERROR, data: toastNoUserFound })                    
                }
            })
            .catch((err) => {
                (showToasts && toast.error(toastNoUserError, {autoClose: false}))
                console.log(err)
                dispatch({ type: actions.ERROR, data: err.message })
            })
    }

    // Skal lage en ny bruker med kopi av ens kreditorer
    const copyKonserAdmin = (pkreditorListe) => {
        let userName = auth.user.email
        //console.log("Henter bruker: ", userName)

        // Auth.crediors må transformeres til dette
        // {kreditorId: '831526;780243;20;', kreditorNavn: ' Moelv Næringspark AS (780243)'}
        let kreditorListe = pkreditorListe.map(x => {
            return {'kreditorId': x.ind, 'kreditorNavn': x.disp}
        })
            
        let newUserCopy = {
            newUser: true,
            "orgUsername": '',
            "username": "",
            "fullName": "",
            "DnnPassword": '',
            "newPassword1": "",
            "newPassword2": "",
            "disabled": false,
            "bisNodeEnabled": false,
            "bisLabDate": "",
            "webserviceBruker": false,
            "twoFactorEnabled": false,            
            "bisNodeUser": "",
            "bisNodePassword": "",
            "rolle": auth.accessLevels.USER,
            "forening": auth.forening,
            "kreditorListe": kreditorListe,   
        }
        
        dispatch({ type: actions.LOADING, data: null })
        axiosTokenInstance
            ({
                method: 'GET',
                url: `api/UserAdmin/GetUser?email=${userName}`
            })
            .then((result) => {
                if (result.status !== 200) {
                    (showToasts && toast.error(toastNoUserError, {autoClose: false}))
                    dispatch({ type: actions.ERROR, data: toastNoUserError })                    
                }
                else if (result.data) {
                    (showToasts && toast.info(toastFoundUser))                    
                    dispatch({
                        type: actions.SHOW_USERDETAILS,
                        data: { ...newUserCopy }
                    })
                } else {
                    (showToasts && toast.warning(toastNoUserFound))
                    dispatch({ type: actions.ERROR, data: toastNoUserFound })                    
                }
            })
            .catch((err) => {
                (showToasts && toast.error(toastNoUserError, {autoClose: false}))
                console.log(err)
                dispatch({ type: actions.ERROR, data: err.message })
            })
    }

    const getKreditorListe = (foreningsId) => {
        //console.log('GetKreditorListe')
                
        /* TODO
            SJEKK PÅ OM DET ER SAMME KREDITOR SOM SIST
            TRENGER VI EN CLEAR KREDITORS METODE??
            FIX TOAST MESSAGES
            FIX DISPATCH

            currentForening: -1,
        currentForeningKreditorListe: [],
         */

        if (!foreningsId) {
            dispatch({ type: actions.KREDITORS_FETCHED, data: { kreditorer: [], foreningsId: foreningsId } })
            return
        }

        if (state.currentForening !== foreningsId) {
            
            dispatch({ type: actions.LOADING_KREDITORER, data: null })
            axiosTokenInstance
            ({
                method: 'GET',
                url: `api/Kreditor/Kreditors4Dept/${foreningsId}`
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(toastKreditorsError, {autoClose: false}))
                }
                else if (result.data.length > 0) {
                    (showToasts && toast.info(toastFoundKreditors.replace('${poster}', result.data.length)))
                    dispatch({ type: actions.KREDITORS_FETCHED, data: { kreditorer: result.data, foreningsId: foreningsId} })
                } else {
                    (showToasts && toast.warning(toastNoKreditorsFound))
                    dispatch({ type: actions.KREDITORS_FETCHED, data: { kreditorer: [], foreningsId: foreningsId } })
                }
            })
            .catch((err) => {
                (showToasts && toast.error(toastKreditorsError, {autoClose: false}))
                console.log(err)
                dispatch({ type: actions.ERROR, data: err.message })
                
            })
        }
    }
    
    const getUser = (email) => { }

    const updateUser = (userData, clearForm) => { 
        dispatch({ type: actions.LOADING, data: null })

        //console.log("updateUser", JSON.stringify(userData))

        axiosTokenInstance
            ({
                method: 'POST',
                url: `api/UserAdmin/UpsertUser`,
                data: userData                
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastCannotSaveUser'], {autoClose: false}))                    
                } else {
                    (showToasts && toast.warning(messages['toastUserSaved']))                    
                    clearForm()
                    dispatch({ type: actions.GOTO_USERDETAILS, data: userData.Username + ' lagret i databasen.' })

                    // Her skal du loope gjennom alle og cleare badges og ikon for ikke pålogg feil
                    let updUsers = [...state.users].map(p => {
                        if (p.userName === userData.Username) {
                            p.lockoutEnd = null
                            p.accessFailedCount = null
                            p.lockSort = ''
                        }
                        return p
                    })

                    dispatch({ type: actions.UPDATE_SEARCHRESULTS, data: updUsers })
                }
                
            })
            .catch(err => {
                // Kludge pga Axios .. is not a function bug....
                // Men av en eller annen grunn går det bra dersom jeg henter ut response objektet, 
                // Da får du lest response data
                //console.log(err.toJSON())
                let response = { ...err.response }
                //console.log (response.data)
                if (_.has(err, 'response.data')) {
                    //let error = JSON.stringify(err)
                    (showToasts && toast.error(messages['toastCannotSaveUser'] + ' ' + err.response.data, {autoClose: false}))
                    dispatch({ type: actions.ERROR, data: err.response.data })
                    console.log('Error upsertuser:', err)
                } else {
                    //setErrorMessage(localeMessages['toastSaveError'] + ', statuscode: ' + err.message)
                    (showToasts && toast.error(messages['toastCannotSaveUser'] + ' ' + err.message, {autoClose: false}))
                    console.log(err.message)
                    dispatch({ type: actions.ERROR, data: err.message })
                }                
            })
    }


    const deleteUser = (userName) => { 
        dispatch({ type: actions.LOADING, data: null })

        axiosTokenInstance
            ({
                method: 'DELETE',
                url: `api/UserAdmin/DeleteUser?email=${userName}`,
                              
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastCannotDeleteUser'], {autoClose: false}))                    
                } else {
                    (showToasts && toast.warning(messages['toastUserDeleted']))   
                    
                   // Fjern den du slettet fra state
                   let updUsers = [...state.users].filter(p => p.userName !== userName)                   
                   dispatch({ type: actions.USER_DELETED, data: updUsers, savedMessage: `Bruker ${userName} er slettet` })
                }
                
            })
            .catch(err => {

                if (_.has(err, 'response.data')) {                    
                    (showToasts && toast.error(messages['toastCannotDeleteUser'] + ' ' + err.response.data, {autoClose: false}))
                    dispatch({ type: actions.ERROR, data: err.response.data })
                    console.log('Error upsertuser:', err)
                } else {                    
                    (showToasts && toast.error(messages['toastCannotDeleteUser'] + ' ' + err.message, {autoClose: false}))
                    console.log(err.message)
                    dispatch({ type: actions.ERROR, data: err.message })
                }                
            })
    }

    
    const createUser = (userData) => {
        
        if (auth.isSupervisor()) {
            administratorCreateNewUser()
        }    
        else if (auth.isForeningSupervisor()) {
            foreningCreateNewUser()
        }
        else if (auth.isAdminKreditor()) {
            konsernUserCreateNewUser()
        }        
    }

    // ----------------------------------------------
    // Når supervisoer oppretter en ny bruker skal 
    // alt være tomt
    // ----------------------------------------------
    const administratorCreateNewUser = () => {
        // 
        const newUser = {
            newUser: true,
            "orgUsername": '',
            "username": "",
            "fullName": "",
            "DnnPassword": '',
            "newPassword1": "",
            "newPassword2": "",
            "disabled": false,
            "bisNodeEnabled": false,
            "webserviceBruker": false,
            "twoFactorEnabled": false,            
            "bisNodeUser": "",
            "bisNodePassword": "",
            "rolle": "",
            "forening": auth.foreningsId,
            "bisLabDate": "",
            "kreditorListe": [],
        }
        dispatch({ type: actions.SHOW_EMPTYUSER, data: newUser })   
    }  

    // ----------------------------------------------
    // Når foreningsadmin oppretter en ny bruker
    // kan denne være user eller konsern. 
    // Forening er låst, så man kan hente kreditorer
    // ----------------------------------------------
    const foreningCreateNewUser = () => {
        // 
        const newUser = {
            newUser: true,
            "orgUsername": '',
            "username": "",
            "fullName": "",
            "DnnPassword": '',
            "newPassword1": "",
            "newPassword2": "",
            "disabled": false,
            "bisNodeEnabled": false,
            "webserviceBruker": false,
            "twoFactorEnabled": false,            
            "bisNodeUser": "",
            "bisNodePassword": "",
            "rolle": "",
            "forening": auth.forening,
            "bisLabDate": "",
            "kreditorListe": [],
        }
        dispatch({ type: actions.SHOW_EMPTYUSER, data: newUser })   
    }    

    // ----------------------------------------------
    // Når konsern oppretter en ny bruker, så skal 
    // forening være den samme, og level lik user,
    // og da kan du hente kreditorene til foreningen
    // ----------------------------------------------
    const konsernUserCreateNewUser = () => {
        // 
        const newUser = {
            newUser: true,
            "orgUsername": '',
            "username": "",
            "fullName": "",
            "DnnPassword": '',
            "newPassword1": "",
            "newPassword2": "",
            "disabled": false,
            "bisNodeEnabled": false,
            "webserviceBruker": false,
            "twoFactorEnabled": false,            
            "bisNodeUser": "",
            "bisNodePassword": "",
            "rolle": auth.accessLevels.USER,
            "forening": auth.forening,
            "bisLabDate": "",
            "kreditorListe": [],
        }
        dispatch({ type: actions.SHOW_EMPTYUSER, data: newUser })   
    }  

    return { state, displayForms, resetContainer, showSearchForm, 
        gotoSearchResult, searchUsers, setCurrentUser, getUser, updateUser,
        createUser, deleteUser, getKreditorListe, isAdminKreditor, copyKonserAdmin }
}

export default useUserAdminReducer