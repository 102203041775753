export const messages = {
    nb: {
        caseListFound: 'Hentet ${poster} saker fra server. (redux)',
        caseListNone: 'Ingen saker funnet',
        caseListErr: 'En feil oppstod under henting av saker',
        filterNone: 'Det er ingen saker som oppfyller de valgte filterkriteriene. Endre disse og prøv igjen.',
        toastTrekkSakOk: 'Trekking av sak er registrert OK.',
        toastTrekkSakFailed: 'Trekking av sak feilet.',

        toastUtsettSakOk: 'Utsetting av sak er registrert OK.',
        toastUtsettSakFailed: 'Utsetting av sak feilet.',

        toastAvdrOrdnOk: 'Oppretting av avdragsordning er registrert OK.',
        toastAvdrOrdnFailed: 'Oppretting av avdragsordning feilet.',

        getFakturalisteError: 'Feil oppstod under henting av fakturaer: ',
        getFakturalisteOk: 'Fakturaer for sak hentet OK',

        postFakturaUploadError: 'Feil oppstod under opplasting av fakturavedlegg. ',
        postFakturaUploadOk: 'Fakturavedlegg er lastet opp OK',

        viserAktive: 'Aktive',
        viserAvsluttede: 'Avsluttede',
        viserAlle: 'Alle (6mnd)',        

        viserSaker: 'Sakene i listen er',
        antSaker: 'Antall saker',
        totHS: 'Sum oppr. hovedstol',
        sumInnbetHs: 'Sum innbet hovedstol',
        sumRestHs: 'Sum rest hovedstol',
        saldoTot: 'Saldo totalt',

        toastTrekkSakNotAllowed: 'Denne saken kan ikke trekkes.',
        toastTrekkSakFailed: 'En feil oppstod, saken kunne ikke trekkes.',
        toastTrekkSakDenied: 'Denne saken kan ikke trekkes. ',
        toastTrekkSakOk: 'Saken er trukket.',
        toastUtsettSakOk: 'Utsetting av sak er registrert OK.',
        toastUtsettSakFailed: 'Utsetting av sak feilet.',

        fetchMessAll: 'Tabellen viser alle saker som er aktive, samt alle saker som er avsluttet siste 6 måneder.',        
        fetchMessActive: 'Tabellen viser alle saker som er aktive.',
        fetchMessClosed: 'Tabellen viser alle saker som er avsluttet siste 3 år.',

        howToDrag: 'Om du vil endre rekkefølgen på kolonnene, klikk på overskriften, og dra den til en ny posisjon.',
        howToReset: 'For å tilbakestille kolonner til orginalinstillinger, klikk på knappen for tilbakestilling.',
        howToHide: 'Du kan skjule en kolonne ved dobbelklikke på søppelbøtte ikonet i overskriften, eller klikke på knappen "Skjul kolonne..", når en kolonne er valgt',

    }
}
