import React from 'react'
import LocaleContext from '../../state/contexts/LocaleContext'
import styled, {css} from 'styled-components'


export default function UseTableSorter(
    //initalSeq, intialField, initalType
    currentSeq, setCurrentSeq, currentField, setCurrentField, currentType, setCurrentType
    ) {
    // https://www.florin-pop.com/blog/2019/07/sort-table-data-with-react/
    // Bedre eksempel, sorterer på any row
    // https://stackoverflow.com/questions/44375407/how-to-make-a-table-in-reactjs-sortable/44375705

    // Pagination https://codesandbox.io/s/l29rokm9rm
    //const [currentSeq, setCurrentSeq] = useState(initalSeq); // default
    //const [currentField, setCurrentField] = useState(intialField);    // feltnavn
    //const [currentType, setCurrentType] = useState(initalType); // num

    const locale = React.useContext(LocaleContext);
    //console.log('locale:', locale)

    const changeSortField = (field, dataType) => {
        let nextSeq;

        if (field !== currentField) nextSeq = 'up'
        else if (currentSeq === 'up') nextSeq = 'down';
        else if (currentSeq === 'down') nextSeq = 'default';
        else if (currentSeq === 'default') nextSeq = 'up';

        //console.log('SortChange ' + currentSort)

        setCurrentSeq(nextSeq);
        setCurrentField(field)
        setCurrentType(dataType)
    };

    // NB ved dato = "" havner den alltid nederst
    const sortFunctions = {
        stringAsc: (a, b) => new Intl.Collator(locale).compare(a[currentField], b[currentField]),
        stringDsc: (a, b) => new Intl.Collator(locale).compare(b[currentField], a[currentField]),
        stringDef: (a, b) => a,
        numAsc: (a, b) => a[currentField] - b[currentField],
        numDsc: (a, b) => b[currentField] - a[currentField],
        numDef: (a, b) => a,
        dateAsc: (a, b) => new Date(a[currentField]) - new Date(b[currentField]),
        dateDsc: (a, b) => new Date(b[currentField]) - new Date(a[currentField]),
        dateDef: (a, b) => a,
        defSort: (a, b) => a
    }

    const getSortFunc = () => {
        //console.log('getSortType', currentType, currentSeq, currentField)
        let sortFunc = sortFunctions.defSort;

        if ((currentType === 'string') && (currentSeq === 'up')) sortFunc = sortFunctions.stringAsc
        else if ((currentType === 'string') && (currentSeq === 'down')) sortFunc = sortFunctions.stringDsc
        else if ((currentType === 'string') && (currentSeq === 'default')) sortFunc = sortFunctions.stringDef
        else if ((currentType === 'num') && (currentSeq === 'up')) sortFunc = sortFunctions.numAsc
        else if ((currentType === 'num') && (currentSeq === 'down')) sortFunc = sortFunctions.numDsc
        else if ((currentType === 'num') && (currentSeq === 'default')) sortFunc = sortFunctions.numDef
        else if ((currentType === 'datetime') && (currentSeq === 'up')) sortFunc = sortFunctions.dateAsc
        else if ((currentType === 'datetime') && (currentSeq === 'down')) sortFunc = sortFunctions.dateDsc
        else if ((currentType === 'datetime') && (currentSeq === 'default')) sortFunc = sortFunctions.dateDef

        //console.log('Sortfunc', sortFunc)
        return { fn: sortFunc }
    }

    const getSortIndicatorIcon = (colName) => {
        if (colName === currentField) {
            if (currentSeq === 'up')
                return (<SortSpan className="fa fa-sort-amount-asc" />)
            else if (currentSeq === 'down')
                return (<SortSpan className="fa fa-sort-amount-desc" />)
            else
                return (<SortSpan disabled className="fa fa-sort-amount-asc" />)
        }
    }

    //console.log(onSortChange)
    return [changeSortField, getSortFunc, getSortIndicatorIcon]
}

const SortSpan = styled.span`
    padding-left: 7px;

    ${props =>
        props.disabled ?
        css`
        color: lightgrey;
        `:  css`
        
        `};
    
`
