import React, { useEffect, useState } from "react"
import { Form, Row, Col, Dropdown } from "react-bootstrap"
import styled from "styled-components"
import { Button } from "react-bootstrap"
import { BfSelect } from '../../Styling/styledForm/styledBlueForm'
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth'
import { useTimerDelay } from '../../hooks/useTimerDelay'

import LocaleContext from "../../state/contexts/LocaleContext"
import {messages} from './Header2NySakSelector.i18n'

import { useSelector, useDispatch } from 'react-redux'
import { getDebitorList, newCaseGetBasicData, filterDebitorList, newDebitor } from "../../reduxStore/newCase/newCaseActions"
import colorConfig from "../../colorConfig"
import { SearcableSelect } from "../searcableSelect/SearchableSelect"
import CONFIG from "../../config"

const H2SelSpan = styled.div`
  color: white;
  margin-top: -14px;
  padding-top: 0px; 
`

const H2Caption = styled(Form.Label)`
  font-size: 18px;
  font-weight: 400;
  font-family: "Fellix", "sans-serif";
  color: ${colorConfig.lys_gul};
  margin-bottom: 0px;
`

/*
const StyledFormControl = styled(Form.Control)`
  //font-size: 18px !important;
  //font-weight: 200;
  //font-family: "Fira Sans", "sans-serif";
  //color: white;
  //margin-bottom: 0px;
  //border-radius: 0px;
`
borderRadius: "0rem",
                                    fontFamily: "Fira Sans, sans-serif",
                                    fontSize: "20px",

                                    borderRadius: "0",
                                    color: "#212529",
                                    padding: ".375rem .75rem",
                                    fontWeight: "400",
                                    lineHeight: "1.5"
/ */

const OrangeButton = styled(Button)`    
   border-radius: 0.5em;
   border: 0px;
   background-color: ${colorConfig.button_bg};
   height: 44px;
   margin-top: 27px;
   font-family: "Fellix", "sans-serif";
   font-size: 20px;

   :hover {
            background-color: ${colorConfig.button_focused};
            -webkit-transition: background-color 1.5s ease-out;
            -moz-transition: background-color 1.5s ease-out;
            -o-transition: background-color 1.5s ease-out;
            transition: background-color 1.5s ease-out;
            transition-delay:0.1s;
    }
    :focus {
        background-color: ${colorConfig.button_focused};
    }
    :disabled {
        background-color: gray;
    }
 `

const Header2NySakSelector = (props) => {
    
    const auth = useAuth()

    const locale = React.useContext(LocaleContext)
    const localeMessages = messages[locale]

    const gncState = useSelector(state => state.newCase)
    const crState = useSelector(state => state.sakslisteHeader)
    const dispatch = useDispatch()
    
    const location = useLocation();
   
    // Ikke hent om du er administrator
    // Her kommer du inn første gangen
    useEffect(() => {

        if (auth.isAuthenticated) {    
            if (!auth.isSupervisor()) {
                //getCreditors(auth.forening)                
            }
            if (!gncState.basicDataFetched) {
                //dispatchGetBasicData(gncDispatch, gncActions, true, localeMessages)
                dispatch(newCaseGetBasicData(true, localeMessages))
            }
        }
    }, [auth.forening])

    const [kreditor, setKreditor] = useState('')
    const [sokVerdi, setSokVerdi] = useState('')

    // ----------------------------------------------------- 
    // START rutiner for timer for delay på filter
    // ----------------------------------------------------- 

    const [filterValue, setFilterValue] = useState('')
    const { handlefilterValueChange } = useTimerDelay(400, setSokVerdi, setFilterValue)
    
    const handleKreditorChange = e => {
        //console.log('handleKreditorChange')        
        //dispatchGetDebitorList(e.target.value, gncDispatch, gncActions, true, []) //*** skal vekk */
        dispatch(getDebitorList(e.target.value, true, localeMessages))
        setKreditor(e.target.value)
        setSokVerdi('')
        setFilterValue('')
    }

    // NB: Enter tast vil i enkelte tilfeller føre til reload av applikasjonen
    const keyPressHandler = (event) => {
        if (event.charCode === 13) {
            event.preventDefault()
        }
    }

    // Trigges ved endring av filter, problem er at vi ikke vil filtrere på henting av data
    useEffect(() => {
        
        if (auth.isAuthenticated) {
            if (gncState.fetched) {
                //ƒconsole.log(`Endring i filtrering kreditor:${kreditor} filtrer på:${sokVerdi}`)
                if ((!auth.isSupervisor()) && (gncState.debitorList.length > 1)) {
                    dispatch(filterDebitorList(sokVerdi, gncState, true, []))
                }
            }
        }
        //console.log('Nå må vi filtrere på', sokVerdi)
    }, [kreditor, sokVerdi])

    // Henter debitorlisten, når det bare er en kreditor 
    useEffect(() => {
        if ((!crState.loading) && (crState.kreditorliste !== null) && (Array.from(crState.kreditorliste).length === 1)) {

            let val = crState.kreditorliste[0].ind.split(';')[1]
    
            dispatch(getDebitorList(val, true, localeMessages))
            setKreditor(val)
            setSokVerdi('')
            setFilterValue('')

        }
    }, [crState.kreditorliste])

    const getKreditorOptions = () => {
        if (!crState.kreditorliste)
            return (<option key={-1} value={""}>Ingen kreditorer</option>)

        if (Object.keys(crState.kreditorliste).length === 0)            
            return (<option key={-1} value={""}>Ingen kreditorer</option>)
        
        if (crState.kreditorliste.length === 1) {
            let val = crState.kreditorliste[0].ind.split(';')[1]
            return (<option key={1} value={val}>{crState.kreditorliste[0].disp}</option>)
        }

        return (
            <>
                <option key={-1} value={""}>Velg kreditor</option>
                {crState.kreditorliste.map((item, i) => (
                    <option key={i} value={`${item.ind.split(';')[1]}`}>{item.disp}</option>
                ))}
            </>
        )
    }

    const handleFilteredKreditorChange = kreditorId => {
        //console.log('handleKreditorChange')                
        dispatch(getDebitorList(kreditorId, true, localeMessages))
        setKreditor(kreditorId)
        setSokVerdi('')
        setFilterValue('')
    }

    const setNewDebitor = () => {
        setFilterValue('')
        //dispatchNewDebitor(gncDispatch, gncActions, false)
        dispatch(newDebitor(false, localeMessages))
    }

    const showHeaderSelector = () => {
        if (!auth.isAuthenticated) {
            return false
        }
        if (location.pathname !== '/CaseNew' && location.pathname !== '/creditRating') {
            return false
        }
        if (auth.isSupervisor()) {
            return false
        }
        return true
    }

    if (!showHeaderSelector()) {
        return ""
    }
    
    return (
        <H2SelSpan>
            
                <Row>
                    {CONFIG.SHOW_OLD_KREDITORDROPDOWN && 
                    <Col xs="auto">
                        <Form.Group as={Col} controlId="kreditor">
                            <H2Caption>Kreditor</H2Caption>
                            <BfSelect
                                name="kreditor"
                                value={gncState.kreditorId}
                                onChange={handleKreditorChange}
                                disabled={gncState.loading}
                            >
                                {getKreditorOptions()}
                            </BfSelect>
                        </Form.Group>
                    </Col>
                    }

                    {CONFIG.SHOW_NEW_KREDITORDROPDOWN &&
                    <Col xs="auto">
                        <Form.Group as={Col} controlId="kreditor2">
                            <H2Caption>Kreditor</H2Caption>
                            <SearcableSelect
                                name="kreditor"
                                value={gncState.kreditorId}
                                onChange={handleFilteredKreditorChange}
                                disabled={gncState.loading}
                                kreditorListe={crState.kreditorliste}
                                defaultCaption={"Velg kreditor"}
                                hideAlle={true}
                                />
                        </Form.Group>
                    </Col>
                    }

                    <Col xs="auto">
                        <Form.Group as={Col} controlId="searchDebitor">
                            <H2Caption>Søkefelt</H2Caption>
                            <Form.Control
                                as="input"
                                name="searchDebitor"
                                autoComplete="off"
                                placeholder="søk etter"
                                value={filterValue}                                
                                onChange={handlefilterValueChange}
                                disabled={gncState.loading || gncState.debitorList.length < 2}
                                style={{ fontSize: "20px", borderRadius: ".5em", border: "none"}}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    {location.pathname === '/CaseNew'  && <Col xs="auto">
                    <OrangeButton
                            onClick={setNewDebitor}
                            disabled={gncState.loading || !gncState.kreditorId || gncState.displayForm !== 'DEBITORFILTER'}
                            >
                                Ny debitor
                        </OrangeButton>
                    </Col>}
                    
                </Row>
            
        </H2SelSpan>
    );
};

export default Header2NySakSelector;