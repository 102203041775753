import React, { useState } from 'react';
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { BfRowForm, BfButtonBlue, BfYellowRowFormScore, RatingContainer, RatingBox, RatingForm } from '../../../Styling/styledForm/styledBlueForm'
import { StyledKfTable, StyledArea } from './../../../Styling/styledTable/StyledTable';
import styled from 'styled-components';
import { BfColInput, BfColRating } from '../../../Styling/FormComponents/FormInput'
import CaseListToolTip from '../../caselist_redux/caseListTable/CaseListTooltip';
import { useAuth } from '../../../hooks/useAuth'
import { useSelector, useDispatch } from 'react-redux'
import { getCompanyRating, getPersonRating, disableRating, getBislabCurrentTerms } from '../../../reduxStore/creditRating/creditRatingActions';
import { getCreditRatingUpdateList } from "../../../reduxStore/newCase/newCaseActions"
import colorConfig from '../../../colorConfig';
import useDebitorForm from '../../caseNew/debitorForm/useDebitorForm';
import { locale } from 'moment';
import { BislabTermsModal } from '../../../components/bislabTermsModal/BislabTermsModal';
import { ConfirmActionModal } from '../modals/ConfirmActionModal';
import { CreditRatingExcel } from '../components/CreditRatingExcel';
import { OverlaySpinner } from '../../../components/spinner/OverlaySpinner';

const Circle = styled.button`    
    background: ${props => props.primary || "white"};
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 1px solid black;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;


const Heading = styled.h3`
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: "Fellix", "sans-serif";    
  `;

const SubText = styled.p`
  margin-bottom: 20px;
  color: ${colorConfig.tabellrad_tekst} !important;
  font-weight: 400;
  font-family: "Fellix", "sans-serif";    
  `;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FieldLabel = styled.label`
  display: block;
  font-weight: 400;
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 10px 10px 0;
`;

const LeftSection = styled.div`
  flex: 1;
  margin-right: 20px;
  font-weight: 400;
`;

const RightSection = styled.div`
  flex: 1;
  margin-left: 20px;
  font-weight: 400;
`;

const Divider = styled.div`
  border-left: 1px solid #888888;
  height: auto;
  min-height: 100px;
  margin-top: 16px;
  padding-right: 20px;
  align-self: stretch; 
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  flex: 1;
  margin-right: 10px;
`;

const FormRow = styled.div`
  display: flex;
`;

const TextButton = styled.div`
  background: none;
  border: none;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 5px;
`;

const CreditRatingList = (props) => {

    const {
        handleChange, handleBlur, formState, errorMessages, extraErrors,
    } = useDebitorForm(props.state, props.dispatch, props.showToasts, props.localeMessages)

    const localeMessages = props.localeMessages
    const dispatch = useDispatch()
    const showToasts = true
    const auth = useAuth()
    const [ratingLoading, setRatingLoading] = useState(false);
    const state = props.state;
    let selectedKreditor = state.kreditorId;
    const headerState = useSelector(state => state.sakslisteHeader)

    const [companyRating, setCompanyRating] = useState('');
    const [personRating, setPersonRating] = useState('');
    const [showBislabTermsModal, setShowBislabTerms] = useState(false);
    const [showConfirmActionModal, setShowConfirmActionModal] = useState(false);
    const [showConfirmActionRefreshModal, setShowConfirmActionRefreshModal] = useState(false);
    const [pdfBase64, setPdfBase64] = useState('');
    const [loading, setLoading] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = dd + '.' + mm + '.' + yyyy;
    // Setter dagens dato om acceptedTermsDate er tom pga det betyr at de har godkjent i dag.
    const acceptedTermsDate = props.acceptedTermsDate === '' ? today : props.acceptedTermsDate;

    const [debtorId, setDebtorId] = useState(null);

    const printHeader = (t1IndicaterIcon, t1ChangeSortField) => {
        return (
            <thead>
                <tr>
                    <th onClick={() => t1ChangeSortField('debtorName', 'string')}>{localeMessages['GH_name']}{t1IndicaterIcon('debtorName')}</th>
                    <th onClick={() => t1ChangeSortField('debtorIdentificationNumber', 'string')}>{localeMessages['GH_caseNumber']}{t1IndicaterIcon('debtorIdentificationNumber')}</th>
                    <th onClick={() => t1ChangeSortField('debtorNo', 'string')}>{localeMessages['GH_debtorNo']}{t1IndicaterIcon('debtorNo')}</th>
                    <th onClick={() => t1ChangeSortField('debtorType', 'string')}>{localeMessages['GH_type']}{t1IndicaterIcon('debtorType')}</th>
                    {/* <th onClick={() => t1ChangeSortField('creditMark', 'string')}>{localeMessages['GH_creditMark']}{t1IndicaterIcon('creditMark')}</th> */}
                    <th onClick={() => t1ChangeSortField('debtorRating', 'num')}>{localeMessages['GH_creditScore']}{t1IndicaterIcon('debtorRating')}</th>
                    <th onClick={() => t1ChangeSortField('debtorRating', 'num')}>{localeMessages['GH_creditRating']}{t1IndicaterIcon('debtorRating')}</th>
                    <th onClick={() => t1ChangeSortField('ratingDate', 'datetime')}>{localeMessages['GH_date']}{t1IndicaterIcon('ratingDate')}</th>
                    {/* <th onClick={() => t1ChangeSortField('recommended', 'string')}>{localeMessages['GH_recommended']}{t1IndicaterIcon('recommended')}</th> */}
                    <th onClick={() => t1ChangeSortField('button', 'string')}>{localeMessages['GH_performNewCheck']}{t1IndicaterIcon('button')}</th>
                </tr>
            </thead>
        )
    }

    const getFormField = (colspan, fieldname, inputType = 'cleaveOrgPersonNr', selectOptions = '', disabled = false, maxLength = 11) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
                onBlur={handleBlur}
            />
        )
    }

    const getMethodToUse = (debtorIdentificationNumber) => {
        if (formState.orgOrPersonNr !== undefined) {
            if (formState.orgOrPersonNr.length === 9 && formState.orgOrPersonNr && formState.orgOrPersonNr.trim() !== '') {
                return 'getCompanyRating';
            }
            if (formState.orgOrPersonNr.length === 11 && formState.orgOrPersonNr && formState.orgOrPersonNr.trim() !== '') {
                return 'getPersonRating';
            }
        } else if (debtorIdentificationNumber !== undefined) {
            if (debtorIdentificationNumber.length === 9 && debtorIdentificationNumber && debtorIdentificationNumber.trim() !== '') {
                return 'getCompanyRating';
            }
            if (debtorIdentificationNumber.length === 11 && debtorIdentificationNumber && debtorIdentificationNumber.trim() !== '') {
                return 'getPersonRating';
            }
        } else { 
            return null; 
        }
        return null;
    };


    const canDoCreditCheck = () => {
        return getMethodToUse() !== null;
    };

    const getChosenRating = async (row) => {
        let method = '';
        let refreshDebtorId = null;
        let debtorIdentificationNumber = null;
        let debtorNo = null;
        if (!row) {
            debtorIdentificationNumber = formState.orgOrPersonNr;
            method = getMethodToUse(debtorIdentificationNumber);
        } else {
            refreshDebtorId = row.debtorIdentificationNumber;
            debtorNo = row.debtorNo;
            method = getMethodToUse(refreshDebtorId);
        }
        if (!method) return;

        setCompanyRating(null);
        setPersonRating(null);

        refreshDebtorId ? setRefreshLoading(true) : setRatingLoading(true);
        const nr = refreshDebtorId ?? formState.orgOrPersonNr.replace(/\s/g, "");
        try {
            const result = method === 'getCompanyRating'
                ? await dispatch(getCompanyRating(selectedKreditor, nr, auth.user.email, debtorNo, showToasts, localeMessages))
                : await dispatch(getPersonRating(selectedKreditor, nr, auth.user.email, debtorNo, showToasts, localeMessages));

            if (result !== null) {
                method === 'getCompanyRating' ? setCompanyRating(result) : setPersonRating(result);
                currentRow.isSelected = true;
                currentRow.newScore = result.score;
                currentRow.scoreDate = today;
            } else {
                console.log('No valid data received');
            }
        } catch (error) {
            console.error(error);
        } finally {
            refreshDebtorId ? setRefreshLoading(false) : setRatingLoading(false);
        }
    };

    const disableCreditRating = async () => {

        const disableFormData = new FormData();

        disableFormData.append('customerNo', selectedKreditor);
        disableFormData.append('emailUser', auth.user.email)

        try {
            const result = await dispatch(disableRating(disableFormData, selectedKreditor, showToasts, localeMessages));

            if (result.enabled === false || result === 'disabled') {
                window.location.reload();

            } else {
                console.log('No valid data received');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setRatingLoading(false);
        }
    };

    const fetchBislabTerms = async () => {
        setLoading(true);
        try {
            const result = await dispatch(getBislabCurrentTerms(showToasts, localeMessages));
            if (result && result.base64 !== null) {
                setPdfBase64(`data:application/pdf;base64,${result.base64}`);
            } else {
                console.log('No valid data received');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const showBislabTerms = () => {
        fetchBislabTerms();
        setShowBislabTerms(true);
    };

    const handleCloseBislabTermsModal = () => {
        setShowBislabTerms(false);
        setPdfBase64('');
    };

    const showConfirmAction = () => {
        setShowConfirmActionModal(true);
    };

    const handleCloseConfirmActionModal = () => {
        setShowConfirmActionModal(false);
    };


    const handleOkConfirmActionModal = () => {
        getChosenRating();
        setShowConfirmActionModal(false);
    };

    const showConfirmActionRefresh = (row) => {
        setDebtorId(row.debtorIdentificationNumber);
        setCurrentRow(row);
        setShowConfirmActionRefreshModal(true);
    };

    const handleCloseRefreshConfirmActionModal = () => {
        setShowConfirmActionRefreshModal(false);
        setDebtorId(null);
        setCurrentRow(null);
    };

    const handleOkRefreshConfirmActionModal = async () => {
        if (!currentRow) return;
        getChosenRating(currentRow);
        setShowConfirmActionRefreshModal(false);
    };

    const ratingData = companyRating || personRating || {};
    const ratingName = ratingData.foretaksnavn ? ratingData.foretaksnavn : ratingData.navn;

    const LeftContentSection = () => (

        <LeftSection>
            <Heading>{localeMessages.checkTitle}</Heading>
            <SubText>{localeMessages.checkParagraph1}</SubText>

            <FormContainer>
                {getFormField(3, 'orgOrPersonNr', 'cleaveOrgPersonNr', null, false)}
                {ratingLoading ? (
                    <ButtonContainer style={{ padding: '0 0 8px 70px' }}>
                        <Spinner animation="border" />
                    </ButtonContainer>
                ) : (
                    <ButtonContainer style={{ paddingLeft: '20px' }}>
                        <BfButtonBlue variant="primary" type="button" onClick={() => showConfirmAction()} disabled={!canDoCreditCheck()}>
                            {localeMessages.newCheckButton}
                        </BfButtonBlue>
                    </ButtonContainer>
                )}
            </FormContainer>

            {/* <p style={{ fontSize: '24px' }}> {ratingName ?? 'Navn'} </p> */}
            <div style={{ marginTop: '-20px', marginBottom: '-10px' }}> {getFormFieldScore('Navn', ratingName, false)}</div>

            <FormRow>
                <div style={{ flexDirection: 'column', marginRight: '40px', marginBottom: '-10px', marginTop: '-10px' }}>
                    {getFormFieldScore(localeMessages.credit_colorLabel, ratingData.scoreForklaring, false)}
                    <div style={{ marginTop: '-20px' }}> {getFormFieldScore(localeMessages.recommended_limitLabel, ratingData.kredittgrense, false)}</div>
                </div>
                <div style={{ display: 'flex', flex: '1', paddingRight: '24px', gap: '24px' }}>
                    {getFormFieldScore(localeMessages.credit_ratingLabel, ratingData.scoreKarakter, '124px', true)}
                    {getFormFieldScore(localeMessages.credit_scoreLabel, ratingData.score, '124px', true)}
                </div>
            </FormRow>

            {(Object.keys(ratingData).length === 0) ?
                <BfButtonBlue disabled={true}>Last ned</BfButtonBlue> :
                <CreditRatingExcel
                    filename={'Kredittvurdering'}
                    headerState={state}
                    data={ratingData}
                    localeMessages={localeMessages}
                />}
        </LeftSection>
    );

    const RightContentSection = () => (
        <RightSection>
            <Heading>{localeMessages.howToCheck_title}</Heading>
            <SubText>{localeMessages.howToCheck_text}</SubText>

            {/* <div className="info-text"> */}
            <SubText style={{ display: 'flex' }}>
                {localeMessages.openTerms_text} <TextButton onClick={showBislabTerms}>her</TextButton>
            </SubText>
            <SubText>{localeMessages.termsAgreedDate_text}: <b>{acceptedTermsDate}</b></SubText>
            {/* </div> */}

            <Heading style={{ marginTop: '60px' }}>{localeMessages.deactivate_title}</Heading>
            <SubText>{localeMessages.deactivate_text}</SubText>
            <ButtonContainer>
                <BfButtonBlue style={{ backgroundColor: '#dc143c', color: 'white' }} variant="primary" type="button" onClick={disableCreditRating}>
                    {localeMessages.deactivateButton}
                </BfButtonBlue>
            </ButtonContainer>
        </RightSection>
    );

    const getFormFieldScore = (fieldname, value, height, square = false) => {
        return (
            <RatingContainer>
                {/* Litt hacky måte å få label til å ligge fint over boks når ikke square */}
                <FieldLabel style={{ position: square ? 'auto' : 'relative', top: square ? 'auto' : '30%' }}>{fieldname}</FieldLabel>
                <BfColRating
                    value={value}
                    height={height}
                    square={square}
                />
            </RatingContainer>
        );
    };

    const BfColRating = (props) => {
        const { height, square, value } = props;
        // Bruker box for karakter, score og trafikklys-vurdering, form for tekst
        if (square) {
            return (
                <RatingBox height={height}>
                    {value}
                </RatingBox>
            );
        } else {
            return (
                <RatingForm>
                    {value}
                </RatingForm>
            );
        }
    };

    const selectDebitor = model => {
        console.log('model selected', model)
        props.selectDebitor(model)
    }

    const printRow = (model) => {

        const formatDate = (val) => {

            let splittedDate = val.replace(' ', '-').split('-');
            let splittedDay = splittedDate[2].replace(' ', 'T').split('T');
            let formattedDate = splittedDay[0] + '.' + splittedDate[1] + '.' + splittedDate[0];
            return formattedDate;

        }

        const asDate = (val) => {
            if (val !== null && val !== undefined) {
                let formattedDate = formatDate(val);
                return <span>{formattedDate}</span>;
            } else {
                return ''
            }
        }

        const checkTimeFromDate = (dateString) => {
            if (!dateString) {
                return '';
            }
            // Fjern [UTC] fra slutten av dateString ellers er det helt lotto om Javascript gjør den om til Date eller ikke.
            const dateStringWithoutUTC = dateString.replace('[UTC]', '');
            const date = new Date(dateStringWithoutUTC);
            const currentDate = new Date();

            const difference = currentDate.getTime() - date.getTime();
            const differenceInMonths = difference / (1000 * 60 * 60 * 24 * 30);

            if (differenceInMonths >= 6) {
                return 'NB! Over 6 mnd gammel';
            } else {
                return '';
            }
        };


        const printRating = () => {

            function isNumeric(num) {
                return !isNaN(num)
            }

            let ratingstring = model.newScore ? model.newScore : model.debtorRating ? model.debtorRating.trim() : ''
            let outputString = ''
            let rcolor = 'white'
            let showBall = false


            if (!ratingstring) {
                outputString = 'Ikke ratet'
            } else {
                if (isNumeric(ratingstring)) {
                    let rating = parseInt(ratingstring)
                    showBall = true

                    if (rating <= 30) {
                        rcolor = 'red'
                    } else if (rating <= 65) {
                        rcolor = 'yellow'
                    } else {
                        rcolor = 'green'
                    }
                }
            }

            /*
          
            Rød "prikk" hvis ratingstallet er <30
            Gul "prikk" hvis ratingstallet er mellom 30 og 60
            Grønn "prikk" hvis ratingstallet >60
            
            */

            return (

                <td>
                    {showBall &&
                        <Circle primary={rcolor}></Circle>
                    }
                    {outputString}
                </td>

            )
        }

        return (
            <tr key={model.i}>

                <td>{model.debtorName}</td>
                <td>{model.debtorIdentificationNumber}</td>
                <td>{model.debtorNo}</td>
                <td>{model.debtorType}</td>
                {/* <td>{model.creditMark}</td> */}
                <td>{model.newScore ? model.newScore : model.debtorRating !== undefined && model.debtorRating !== 'null' ? model.debtorRating : ''}</td>
                <td>{printRating()}</td>
                <td>
                    <>
                        {model.scoreDate ? model.scoreDate : asDate(model.debtorRating !== undefined && model.debtorRating !== 'null' && model.debtorRating !== null ? model.ratingDate : null)}
                        {model.ratingDate && (
                            <span style={{ marginLeft: '8px', fontWeight: 'bold' }}>
                                {checkTimeFromDate(model.ratingDate)}
                            </span>
                        )}
                    </>
                </td>
                {/* <td>{model.recommended}</td> */}
                <td>
                    <CaseListToolTip
                        id={'t' + model.name}
                        caption={
                            model.isSelected ? localeMessages.refreshRow :
                                model.debtorIdentificationNumber
                                    ? localeMessages.updateCheckButton
                                    : !model.debtorIdentificationNumber && model.debtorType === 'Næring'
                                        ? localeMessages.cannotUpdateCompanyButton
                                        : localeMessages.cannotUpdatePersonButton
                        }
                    >
                        <span
                            className={`fa fa-refresh ${!model.debtorIdentificationNumber || model.isSelected ? 'inactive' : ''}`}
                            onClick={
                                model.debtorIdentificationNumber && !model.isSelected ? () => showConfirmActionRefresh(model) : null
                            }
                            style={{
                                cursor: model.debtorIdentificationNumber && !model.isSelected ? 'pointer' : 'not-allowed',
                                opacity: model.debtorIdentificationNumber && !model.isSelected ? 1 : 0.5,
                            }}
                        />
                    </CaseListToolTip>
                    &nbsp;&nbsp;
                    {model.isSelected && <span>(Oppdatert)</span>}
                </td>

            </tr>

        )
    }

    return (
        <>

            {showBislabTermsModal && (
                <BislabTermsModal
                    show={showBislabTermsModal}
                    // okHandler={handleAcceptBislabTerms}
                    cancelHandler={handleCloseBislabTermsModal}
                    message={localeMessages.terms_caption}
                    checkboxText={localeMessages.checkbox_text}
                    header={localeMessages.accept_terms}
                    // okCaption={localeMessages.accept}
                    cancelCaption={localeMessages.cancel}
                    pdfBase64={pdfBase64}
                    loading={loading}
                    viewOnlyMode={true}
                />)}

            {showConfirmActionModal && (
                <ConfirmActionModal
                    show={showConfirmActionModal}
                    okHandler={handleOkConfirmActionModal}
                    cancelHandler={handleCloseConfirmActionModal}
                    orgOrPersonNr={formState.orgOrPersonNr.replace(/\s/g, "")}
                    messagePerson={localeMessages.confirm_action_person}
                    messageOrg={localeMessages.confirm_action_org}
                    checkboxText={localeMessages.checkbox_text}
                    header={localeMessages.confirm_action_title}
                    okCaption={localeMessages.confirm_action_ok}
                    cancelCaption={localeMessages.confirm_action_cancel}
                />)}

            {showConfirmActionRefreshModal && (
                <ConfirmActionModal
                    show={showConfirmActionRefreshModal}
                    okHandler={handleOkRefreshConfirmActionModal}
                    cancelHandler={handleCloseRefreshConfirmActionModal}
                    orgOrPersonNr={debtorId}
                    messagePerson={localeMessages.confirm_action_person}
                    messageOrg={localeMessages.confirm_action_org}
                    checkboxText={localeMessages.checkbox_text}
                    header={localeMessages.confirm_action_title}
                    okCaption={localeMessages.confirm_action_ok}
                    cancelCaption={localeMessages.confirm_action_cancel}
                />)}

            {(refreshLoading) && <OverlaySpinner></OverlaySpinner>}



            <Container fluid="true">

                <BfRowForm>
                    <SectionContainer>
                        <LeftContentSection />
                        <Divider />
                        <RightContentSection />
                    </SectionContainer>

                    &nbsp;&nbsp;

                </BfRowForm>

                &nbsp;&nbsp;
                <BfRowForm>
                    <Col xs={12} md={12}>

                        <props.PaginationDropdown />
                        <StyledKfTable>
                            {printHeader(props.t1IndicaterIcon, props.t1ChangeSortField)}

                            <tbody>
                                <props.Paginate>
                                    {/* paginate forventer en funksjon */}
                                    {row => printRow(row)}
                                </props.Paginate>
                            </tbody>
                        </StyledKfTable>
                        <br />
                        <props.PaginationIndex />
                        <br /><br />
                        <props.PaginationNav />

                    </Col>

                </BfRowForm>

            </Container>

        </>
    )
}

export default React.memo(CreditRatingList)



