import styled from 'styled-components'
import { Form, Row, Button, Container } from 'react-bootstrap'
import colorConfig from '../../colorConfig'

const YellowRoundedContainer = styled(Container)`
    background-color: ${colorConfig.lys_gul};  
    color: black;
    border-bottom: 0px;  
    padding: 20px 0px 20px 0px;      
    border: 0px solid black;
    border-radius: 0.5em;
    font-family: "Fellix", "sans-serif";
    font-size: 20px !important; 

    input {   
        padding: 20;
        font-family: "Fellix", "sans-serif";
        font-size: 20px !important; 
        border-radius: 0.5em;
        border: 0px solid black;  

        *:focus {
           outline: none; !important; 
        }
    }

    .form-control {
        border-radius: 0.5em; !important; 
    }

    .form-control:focus {
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0.5em;
    }

    .alert-danger {
        color: ${colorConfig.tabell_lysGul}; !important; 
        background-color: ${colorConfig.danger_bg};
        border-color: none;
        font-family: "Fellix", "sans-serif";
        font-size: 20px !important; 
        border-radius: 0.5em;
        font-weight: 400;
    }
}
`


const BlueRow = styled(Row)`    
    //background-color: rgba(195, 215, 238, 0.4);
    background-color: ${colorConfig.tabell_lysGul};  
    color: black;
    border-bottom: 0px;  
    padding: 28px 10px 28px 10px;      
    border: 0px solid black;
    font-family: "Fellix", "sans-serif";
    font-size: 20px !important; 
    min-width: 1800px;
`

const BfRowForm = styled(Row)`
    background-color: ${colorConfig.tabell_lysGul};  
    color: black;
    border-bottom: 0px;  
    padding: 10px 30px 0px 30px;      
    border: 0px solid black;
    font-family: "Fellix", "sans-serif";
    font-size: 20px !important; 
`

const BfYellowRowForm = styled(Row)`
    background-color: ${colorConfig.lys_gul};  
    color: black;
    border-bottom: 0px;  
    padding: 10px 30px 0px 30px;      
    border: 0px solid black;
    font-family: "Fellix", "sans-serif";
    font-size: 20px !important; 
`

const BfTightRowForm = styled(Row)`        
    background-color: ${colorConfig.tabell_lysGul};  
    padding: 15px 30px 0px 30px;    
    color: black;
    border-bottom: 0px;     
    border: 0px solid black;
    font-family: "Fellix", "sans-serif";
    font-size: 20px !important; 
`

const BfTightYellowRowForm = styled(Row)`        
    background-color: ${colorConfig.lys_gul};  
    padding: 15px 30px 0px 30px;    
    color: black;
    border-bottom: 0px;     
    border: 0px solid black;
    font-family: "Fellix", "sans-serif";
    font-size: 20px !important; 
`

const BfRowSpacer = styled(Row)`    
    background-color: white;
    height: 40px;
`    

const BfFormLabel = styled(Form.Label)`
        font-family: "Fellix", "sans-serif";
        font-weight: 400;
        font-size: 20px;
        color: black;
        margin-bottom: 0px;       
    `
const BfFormLabelSm = styled.span`
        font-family: "Fellix", "sans-serif";
        font-size: 14px;
        font-weight: 400;
        color: black;
        margin-top: 4px;    
    `
const BfFormErrorSm = styled.span`
        font-family: "Fellix", "sans-serif";
        font-size: 14px;
        font-weight: 700;
        color: ${colorConfig.danger_bg};
        margin-top: 4px;    
    `

const BfFormControl = styled(Form.Control)`              
`


const BfButtonBlueNarrow = styled(Button)`
        border-radius: 0.5em;
        font-family: "Fellix", "sans-serif";
        font-size: 20px;
        border: 0px;
        background-color: ${colorConfig.button_bg};
        color: black;
        padding: 4px 20px 4px 20px;
        font-weight: 700;         

        :hover {
            background-color: ${colorConfig.button_focused};
            -webkit-transition: background-color 1.5s ease-out;
            -moz-transition: background-color 1.5s ease-out;
            -o-transition: background-color 1.5s ease-out;
            transition: background-color 1.5s ease-out;
            transition-delay:0.1s;
        }
        :focus {
            background-color: ${colorConfig.button_focused};
        }
        :disabled {
            background-color: gray;
        }
`

const BfButtonBlue = styled(BfButtonBlueNarrow)`        
        min-width: 150px;
`
const BfButtonLink = styled(Button)`        
        font-family: "Fellix", "sans-serif";
        font-size: 20px; 
        border-radius: 0.5em;           
`

const BfSelect = styled.select`        
    font-family: "Fellix", "sans-serif";
    font-size: 20px;    
        
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-radius: 0.5em;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; 
    :focus { 
        outline: none !important;
        border-color: #B7D2FB;
        border: 1px solid #B7D2FB;
        border-radius: 0.5em;;
        //box-shadow: 0 0 10px #719ECE;
    }   
`

const BfButtonBlueToggle = styled(BfButtonBlueNarrow)`
        background-color: ${props => props.isActive ? colorConfig.button_focused : colorConfig.button_bg};
        color: ${props => props.isActive ? 'white' : 'black'};

        :hover {
            background-color: ${colorConfig.button_focused};
            color: white;
            -webkit-transition: background-color 1.5s ease-out;
            -moz-transition: background-color 1.5s ease-out;
            -o-transition: background-color 1.5s ease-out;
            transition: background-color 1.5s ease-out;
            transition-delay:0.1s;
        }
        :focus {
            background-color: ${colorConfig.button_focused};
            color: white;
        }
        :disabled {
            background-color: gray;
            color: black;
        }
`

const BfCheckboxContainer = styled.label`
    // display: flex;
    align-items: center;
    cursor: pointer;
`;

const BfCheckboxContainer2 = styled.label`
    display: inline-block;
    vertical-align: middle:
    cursor: pointer;
`;

const BfCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    appearance: none;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    outline: none;

    &:checked {
        background-color: #044F67;
        border-color: #044F67;
    }
    
    &:checked::after {
        content: '\\2713'; /* Unicode checkmark */
        font-size: 16px;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const BfCheckboxInput2 = styled.input.attrs({ type: 'checkbox' })`
    width: 20px;
    height: 20px;
    appearance: none;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    outline: none;
    margin-right: 8px;
    margin-bottom: 6px;
    vertical-align: middle;

    &:checked {
        background-color: #044F67;
        border-color: #044F67;
    }

    &:checked::after {
        content: '\\2713'; /* Unicode checkmark */
        font-size: 14px;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const BfCheckboxText = styled.span`
    font-size: 1.25rem;
    font-family: "Fellix", "sans-serif";
`;


const BfCheckboxLabel = styled.span`
    font-size: 1.5em;
    margin-left: 10px;
`

const BfYellowRowFormScore = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px; 
  margin-bottom: 10px;  
  color: black;
  padding: 10px 30px 0px 0;   
  font-family: "Fellix", "sans-serif";
  font-size: 20px !important; 
  margin: 0 30px 10px 30px;
  border-radius: 10px;
`

const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 30px; 
`

const BfYellowRowTerms = styled.div`
  margin: 0 30px 10px 30px;
  color: black;
  font-family: "Fellix", "sans-serif";
  font-size: 20px !important; 
  border-radius: 10px;
  border: 1px solid #000000;
  padding-bottom: 20px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 35px;
`

const RatingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  font-size: 30px;
  height: ${({ height }) => height};
  width: ${({ height }) => height};
`;

const RatingForm = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 10px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #FFFFFF;
  position: relative;
  top: 30%;
  font-size: 20px;
  height: 45px;
  width: 300px;
`;

export { BfYellowRowForm, BfRowForm, BfTightRowForm, BfTightYellowRowForm, BfRowSpacer, BfFormLabel, BfFormLabelSm, BfFormErrorSm, BfFormControl, BfButtonBlue, BfButtonBlueNarrow, BfButtonBlueToggle, BfButtonLink, BfSelect, BlueRow, YellowRoundedContainer, BfCheckboxContainer, BfCheckboxContainer2, BfCheckboxInput, BfCheckboxInput2, BfCheckboxLabel, BfCheckboxText, BfYellowRowFormScore, RatingContainer, BfYellowRowTerms, ButtonContainer, RatingBox, RatingForm }