import React from "react";
import { Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,

} from "../../../Styling/styledModal/styledModals";


const TrekkSak01Modal = (props) => {

    const localeMessages = props.tsFuncs.trekkSakMessages    

    return (

        <StyledModal centered show={true} onHide={props.tsFuncs.cancelTrekkSak}>

            <StyledModalBody>
                <h4>{localeMessages["TS1M_Overskrift"]}</h4>
            </StyledModalBody>

            <StyledModalFooter>
                <Button variant="secondary" onClick={props.tsFuncs.showTrekkSak1OK}>
                    {localeMessages["TS1M_Ja"]}
                </Button>
                <Button variant="secondary" onClick={props.tsFuncs.cancelTrekkSak}>
                    {localeMessages["TS1M_Nei"]}
                </Button>
            </StyledModalFooter>

        </StyledModal>
    );
};

export default TrekkSak01Modal;