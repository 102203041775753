import React, { useState, useContext } from 'react';
import CaseDetailsContext from '../../CaseDetailsContext'
import { StyledKfTable, StyledKfTextTable } from '../../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../../components/useTable/UseTable'
import usePaginator from '../../../../components/useTable/UsePaginator'
import LocaleContext from '../../../../state/contexts/LocaleContext';
import { FormattedDate, FormattedNumber } from 'react-intl'
import fileDownload from 'js-file-download'
import axiosTokenInstance from '../../../../Instances/axiosTokenInstance'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { fixMissingTimeFromDate } from '../../../../util/date/dateRoutines';
import { useSelector } from 'react-redux';

const BfButtonBlue = styled(Button)`    
    border: none;
    font-family: "Fira Sans", "sans-serif";
    font-size: 20px;
    background-color: white;
    color: rgba(0, 79, 113, 1);
    padding-top: 1px;
    padding-bottom: 1px;       
    :disabled {        
        opacity: 0.6;
        background-color: white;
        color: gray;        
    }
    :hover {
        background-color: rgb(0, 62, 96);
        border:1px rgb(0, 62, 96);  
    }
    @media print
    {    
        .no-print, .no-print *
        {
            display: none !important;
        }
    }
`

const CaDetAktiviteterComponent = (props) => {

    const { localeMessages } = React.useContext(CaseDetailsContext);
    const state = useSelector(state => state.saksdetaljer)

    // Felter
    const f1 = 'activityDate'
    const f2 = 'activityText'
    const f3 = 'channel'
    const f4 = 'activityPaidTotal'
    const f5 = 'fee'

    const f6 = 'fees'
    const f7 = 'legalFees'
    const f8 = 'claim'
    const f9 = 'interest'
    const f10 = ''
    
    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down'); // default
    const [currentField, setCurrentField] = useState(f1);    // feltnavn
    const [currentType, setCurrentType] = useState('datetime'); // num

    let pageSize = 1000
    if (!props.isReport) {
        pageSize = 10
    }

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(pageSize)
    const [activePage, setActivePage] = useState(1);

    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    const gridData = state.data.activities

    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        gridData.sort(t1GetSortFunc().fn), // <==== fix henting av data
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const getPageHeader = (fld) => localeMessages['aktLst_' + fld]

    const asDate = (val) => {
        // Kludge fordi FireFox ikke godtar dato uten tid uten videre
        let valok = fixMissingTimeFromDate(val)
        return (
            <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
        )
    }

    const asDecimal = (val) => {
        if (val) {

            return (
                <FormattedNumber
                value={val}
                style='decimal'
                minimumFractionDigits={2}
            />
            )
        }
        else {
            return ''
        }
    }

    if (props.isReport && (state.data.activities.length === 0)) {
        return (
            <></>
        )
    }

    const handleDownload = (row) => {
        axiosTokenInstance.get(
            `api/sak/GetDocument/${props.crNo}/${state.data.fetchedData.caseNo}/${row.documentId}/${row.fileName}`,
            { responseType: 'blob' }
        )
            .then((res) => {
                fileDownload(res.data, `${row.fileName}`)
            })
    }

    const getDownloadLink = (row) => {

        if (!props.isReport) {

            if (row.documentId) {
                return (
                    <BfButtonBlue onClick={() => handleDownload(row)}>
                        <span className="fa fa-cloud-download" /> &nbsp;&nbsp; {localeMessages['aktLst_download']}
                    </BfButtonBlue>
                )
            }
        
        }
        return null
        
    }


    return (
        <>
            <StyledKfTable>
                <thead>
                    <tr>
                        <th onClick={() => t1ChangeSortField(f1, 'datetime')}>{getPageHeader('f1')}{!props.isReport && t1IndicaterIcon(f1)}</th>
                        <th></th>
                        <th onClick={() => t1ChangeSortField(f2, 'string')}>{getPageHeader('f2')}{!props.isReport && t1IndicaterIcon(f2)}</th>
                        <th onClick={() => t1ChangeSortField(f3, 'string')}>{getPageHeader('f3')}{!props.isReport && t1IndicaterIcon(f3)}</th>                        
                        <th onClick={() => t1ChangeSortField(f4, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f4')}{!props.isReport && t1IndicaterIcon(f4)}</th>
                        <th onClick={() => t1ChangeSortField(f5, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f5')}{!props.isReport && t1IndicaterIcon(f5)}</th>                        
                        <th onClick={() => t1ChangeSortField(f6, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f6')}{!props.isReport && t1IndicaterIcon(f6)}</th>
                        <th onClick={() => t1ChangeSortField(f7, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f7')}{!props.isReport && t1IndicaterIcon(f7)}</th>
                        <th onClick={() => t1ChangeSortField(f8, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f8')}{!props.isReport && t1IndicaterIcon(f8)}</th>
                        <th onClick={() => t1ChangeSortField(f9, 'num')} style={{ textAlign: 'right' }}>{getPageHeader('f9')}{!props.isReport && t1IndicaterIcon(f9)}</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>

                    <Paginate>
                        {row =>
                            <tr key={row.i}>
                                <td>{asDate(row[f1])}</td>                                
                                <td>{getDownloadLink(row)}</td>
                                <td>{row[f2]}</td>
                                <td>{row[f3]}</td>                                
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f4])}</td>
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f5])}</td>
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f6])}</td>
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f7])}</td>
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f8])}</td>
                                <td style={{ textAlign: 'right' }}>{asDecimal(row[f9])}</td>
                                <td></td>
                            </tr>
                        }
                    </Paginate>

                </tbody>

            </StyledKfTable>
            <br />
            {(!props.isReport) && <><PaginationNav /><PaginationIndex /></>}
        </>
    )
}

export default CaDetAktiviteterComponent
