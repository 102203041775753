import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LocaleContext from '../../../state/contexts/LocaleContext'
import { messages } from './pbiReportListTab.i18n'
import { OverlaySpinner } from '../../../components/spinner/OverlaySpinner'
import CONFIG from '../../../config'
import { getReportsList } from '../../../reduxStore/ReportMenu/reportMenuActions'
import { ArticleArea } from '../../../Styling/Layout/ContentArea'
import ReportListComponent from './ReportListComponent'
import { NavLink } from 'react-router-dom'
import { object } from 'prop-types'

export const usePbiReportListTab = (props) => {

    const IS_POWERBI_ENABLED = CONFIG.POWERBI_ENABLED

    // Localisation
    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const _dispatch = useDispatch()

    const reportMenuState = useSelector(state => state.reportsMenu)
    const state = useSelector(state => state.powerBi)
    const crState = useSelector(state => state.sakslisteHeader)

    const showTheSpinner = () => (state.loading || reportMenuState.loading || crState.loading)

    useEffect(() => {
        console.log("skal vi hente rapportene")

        // Ikke last i prod miljø
        if (IS_POWERBI_ENABLED) {
            if (!reportMenuState.reportItems && !reportMenuState.loading && reportMenuState.error === null) {
                _dispatch(getReportsList())
            }
        }
    }, [])

    const [groupedReports, setGoupedReports] = useState()
    const [groupNames, setGroupNames] = useState([])
    useEffect(() => {
        let rappHash = new Object();
        let rappGrps = [];
        if (reportMenuState && reportMenuState.reportItems) {
            reportMenuState.reportItems.forEach(e => {
                if (rappHash[e.reportGroup] === undefined) {
                    rappHash[e.reportGroup] = []
                }
                rappHash[e.reportGroup].push(e)
            });
        }

        for (var key in rappHash) {
            if (rappHash.hasOwnProperty(key)) {
                rappGrps.push(key)
            }
        }

        setGoupedReports(rappHash)
        setGroupNames(rappGrps)

    }, [reportMenuState])

    return { reportMenuState, groupedReports, groupNames, showTheSpinner, localeMessages }

}

export const PbiReportListTab = (props) => {

    const { reportMenuState, groupedReports, groupNames, showTheSpinner, localeMessages } = usePbiReportListTab(props)

    const pbiReportGroup = (groupname) => {
        return (
            <>
                {groupedReports &&
                    <ReportListComponent
                        reportItems={groupedReports[groupname]}
                        groupname={groupname}
                    />
                }

            </>
        )
    }

    return (
        <div style={{ padding: "30px" }}>
            {showTheSpinner() && <OverlaySpinner />}

            <ArticleArea>
                <h1>{localeMessages.HEADER_T1}</h1>
                <p>{localeMessages.PARAGRAPH_P1}</p>
            </ArticleArea>

            <div>
                {groupNames && groupNames.map((grname, i) => pbiReportGroup(grname, i))}
            </div>

        </div>
    )
}
