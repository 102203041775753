import React, { useState, useEffect } from 'react'
import { useUcForm } from '../../hooks/useUcForm'
import { getInterestCalculatorValidators } from './interestCalculatorValidators'

const stringToDate = function (dateString) {
    const [dd, mm, yyyy] = dateString.split(".");
    return new Date(`${yyyy}-${mm}-${dd}`);
};

function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

const cleaveAmountAsDecimal = d => parseFloat(d.toString().replace(/ /g, '').replace(/,/, '.'))

export const useInterestCalculator = (localemessages) => {

    // Sjekk på state for å inialisere formverdier
    let startValues = {

        startDate: '01.01.2020',
        endDate: '01.01.2021',
        interest: 8.0,
        amount: 100.0,
        message: '',
    }

    const [extraErrors, setExtraErrors] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    
    let formValidators = getInterestCalculatorValidators(localemessages)
  

    const {
        handleChange, handleCheckboxChange, setField, handleMultiSelectChange,
        formState, validateField, validateAll, errorMessages,
        formIsValid, validatorsState
    } = useUcForm(startValues, formValidators)

    const hasErrors = Object.values(errorMessages).some(x => (x !== null && x !== '')) || Object.values(extraErrors).some(x => (x !== null && x !== ''));

    const submitForm = () => {
        // Valider alle
        let formDontValidate = validateAll();

        // Sjekk felter som ikke valideres på cursor
        setExtraErrors({})

        // Test på om det finnes error
        if (!formDontValidate) {
            let days = datediff(stringToDate(formState.startDate), stringToDate(formState.endDate))
            let drentefot = cleaveAmountAsDecimal(formState.interest)
            let damount = cleaveAmountAsDecimal(formState.amount)
            let interestAmount = (((damount * drentefot / 100) / 365) * days)
            setField('message', `${damount.toLocaleString('nb-NO', { style: 'currency', currency: 'NOK' })}  
            i ${days} dag(er) 
            med ${(drentefot / 100).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })} rente, 
            blir ${interestAmount.toLocaleString('nb-NO', { style: 'currency', currency: 'NOK' })}.`)
        }
    }

    const clearForm = () => {
    }

    // -----------------------------------------------------------------
    // Returnerer verdier/funcs som skal benyttes i formen
    // -----------------------------------------------------------------

    return {
        formValues: startValues, formValidators, handleChange, formState, validateAll,
        hasErrors, errorMessages, extraErrors, setExtraErrors,
        submitForm, clearForm, errorMessage
    }


} 