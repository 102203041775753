import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

/*
To use:
   import { useSelector, useDispatch } from 'react-redux'
   import { ..exported dispatchers... } from '../../reduxStore/...';

   // Angi ønsket redux store du vil hente state fra
   const state = useSelector(state => state.oversikt)

   // Få tak i redux dispatheren som du evt kan gi eget navn
   const _dispatch = useDispatch()
    ..

   // Kjør kall for å endre state
   _dispatch(setSomeShit(shitparam1))

*/

export const actions = {
    FILBEHANDLING_LOADING: 'FILBEHANDLING_LOADING',
    FILBEHANDLING_ERROR: 'FILBEHANDLING_ERROR',
    FILBEHANDLING_DATA_FETCHED: 'FILBEHANDLING_DATA_FETCHED',
    FILBEHANDLING_MARK_DOWNLOADED: 'FILBEHANDLING_MARK_DOWNLOADED',
}


const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}

const mapDataToLists = ((taskList) => {

    let mottatteFiler = []
    let sendteFiler = []

    taskList.map((e, i) => {
        const item = {
            ...e,
            i: i,
        }

        if (e.isSentFile) {
            sendteFiler.push(item)
        } else {
            mottatteFiler.push(item)
        }
    })

    return { sendteFiler, mottatteFiler }

})

export const markDownloaded = (fileId, userName) => {

    return dispatch => {
        dispatch({ type: actions.FILBEHANDLING_MARK_DOWNLOADED, payload: {fileId: fileId, userName: userName} })
    }
}

export const uploadFiles = (formData, clearFileList, selectedKreditor, showToasts, messages) => {

    return dispatch => {

        const errorMessage = messages['toastFilesUploadedFailed']
        const okMessage = messages['toastFilesUploadedOk']

        dispatch({ type: actions.FILBEHANDLING_LOADING, data: null })

        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/files/PostFiles`,
                data: formData
            })
            .then((result) => {

                (showToasts && toast.warning(result.data))

                const formData2 = new FormData();
                formData2.append('SelectedKreditor', selectedKreditor)
                
                //dispatchGetFiles(formData2, showToasts, messages, dispatch, actions)
                getFilesInner(dispatch, formData, showToasts, messages)

                clearFileList()

            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                dispatch({ type: actions.FILBEHANDLING_ERROR, data: lErrMess })
            })

    }

}

export const getFiles = (formData, showToasts, messages) => {

    return dispatch => {

        getFilesInner(dispatch, formData, showToasts, messages)
    }
}
// NOTE Getfiles is called after upload also, so exportet to separate file
const getFilesInner = (dispatch, formData, showToasts, messages) => {

    const errorMessage = messages['toastGetFilesFailed']
    const okMessage = messages['toastGetFilesOk']

    dispatch({ type: actions.FILBEHANDLING_LOADING, data: null })

    axiosTokenInstance
        ({
            method: 'POST',
            url: `/api/files/getFiles`,
            data: formData
        })
        .then((result) => {
            //console.log(result)

            if (result.status !== 200) {

                (showToasts && toast.error(errorMessage, {autoClose: false}))
                dispatch({ type: actions.FILBEHANDLING_ERROR, data: errorMessage })

            } else {

                const data = result.data
                if (data.errorCode === "000") {

                    (showToasts && toast.info(okMessage))

                    // Map om                   
                    const { sendteFiler, mottatteFiler } = mapDataToLists(data.fileHandlerList)

                    dispatch({ type: actions.FILBEHANDLING_DATA_FETCHED, payload: { sendteFiler, mottatteFiler } })
                } else {
                    (showToasts && toast.warning(errorMessage + ', ' + result.data.errorMessage))
                    dispatch({ type: actions.FILBEHANDLING_ERROR, data: errorMessage + ', ' + result.data.errorMessage })
                }
            }
        })
        .catch((err) => {
            let lErrMess = errorMessage + ' ' + getErrorMessage(err)
            toast.error(lErrMess, {autoClose: false})
            dispatch({ type: actions.FILBEHANDLING_ERROR, data: lErrMess })
        })
}
