import React, {useState} from 'react';
import { BfButtonBlue } from '../../Styling/styledForm/styledBlueForm'
import axios from 'axios'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';
import _ from 'lodash'


// Denne er nok ikke i bruk, kun for å debugge getUri
const useDnnContainer = (props) => {

    const [errorMessage, setErrorMessage] = useState('')
    
    const dnnLogin = () => {


        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/DNN/GetURI`,                
            })
            .then((result) => {                
                console.log(result)
                window.location.assign(result.data)                
            })
            .catch((err) => {  
                if (_.has(err, 'response.data')) {
                    setErrorMessage(err.response.data)
                    console.log(err)
                } else {
                    setErrorMessage(err.message)
                    console.log(err)
                }
                console.log(errorMessage)

                console.log(err)
            })

    }

    return (
        <>   
            <BfButtonBlue variant="primary" type="button" onClick={dnnLogin}>
                test overgang til gammelt nettkontor
            </BfButtonBlue>
        </>
    )
}

export default useDnnContainer
