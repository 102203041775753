export const messages = {
    nb: {
        header: `Planlegging av rapport`,
        beskrivelse: `Dersom du ønkser rapport tilsendt, fyller du ut info her.`,
        Lagre: `Oppdater informasjon`,

        TrekkFra: 'Fjern',
        LeggTil: 'Bruk',

        reportNameLabel: `Navn`,
        reportNamePlaceholder: ``,
        reportNameSmall: `Navnet angis i emnefeltet i eposten som sendes ut.`,
        reportNameRequired: `Feltet er påkrevd.`,

        scheduleintervalLabel: `Hvor ofte vil du rapporten skal kjøres`,
        scheduleintervalPlaceholder: ``,
        scheduleintervalSmall: `Du vil motta rapporten i din epost på intevallet du velger.`,
        scheduleintervalRequired: `Feltet er påkrevd.`,

        dataintervalLabel: `Velg datointervall for rapportdata`,
        dataintervalPlaceholder: ``,
        dataintervalSmall: `Angi hvilket datagrunnlag som skal være med i rapporten`,
        dataintervalRequired: `Feltet er påkrevd.`,

        listactorLabel: `Ikke valgte kreditorer i datagrunnlaget til rapporten`,
        listactorPlaceholder: ``,
        listactorSmall: `Du har valgt n kreditorer. `,
        listactorRequired: `Feltet er påkrevd.`,

        unSelectedActorsLabel: `Valgte kreditorer i datagrunnlaget til rapporten`,
        unSelectedActorsPlaceholder: ``,
        unSelectedActorsSmall: `Du har valgt n kreditorer. `,
        unSelectedActorsRequired: `Du må velge minst en kreditor.`,

        RSC_T2: 'Planlagte rapporter',

        RSC_GRH1: 'Navn',
        RSC_GRH2: 'Intervall',
        RSC_GRH3: 'Sist varslet',
        RSC_GRH4: 'Siste status',
        RSC_GRH5: 'Neste varsling',
        RSC_GRH6: 'Varsler',

        BE_Header: "Bekreft sletting?",
        BE_message: 'Klikk "Ja" for å slette rapporten. Handlingen kan ikke angres.',
        BE_Ja: 'Ja',
        BE_Nei: 'Avbryt',

    }
}