import React from 'react'
import { Container, Col, Row } from "react-bootstrap";
import styled from 'styled-components'
import { FormattedNumber } from 'react-intl'
import colorConfig from '../../colorConfig';

const StyledCol = styled(Col)`
    border-width: 0 0 0 1px;
    border-color:  rgb(0, 79, 113);
    border-style: solid;
    margin-bottom: 20px;
`

const StyledColR = styled(Col)`
    border-width: 0 1px 0 1px;
    border-color:  rgb(0, 79, 113);
    border-style: solid;
    margin-bottom: 20px;
`
const StyledBox = styled.div`
    margin-right: 50px;
    height: 68px;
    background-color: white;
    margin-bottom: 0px;

    font-size: 20px;
    font-weight: 500;
    color: ${colorConfig.tabellrad_tekst}; 
`

const StyledBoxHeader = styled.p`
    font-size: 17px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
    margin-left: 30px;
`

const StyledBoxNumber = styled.p`
    font-size: 30px;
    line-height: 45px;
    font-weight: 500;
    margin: 0px 0px 0px 30px;    
    padding: 0px 0px 8px 0px;
    color: ${colorConfig.header_tekst}; 
`

const CaseListTotals = (props) => {

    const getSakerHeader = (messages) => {
        let result = props.messages['viserAlle']
        if (props.aktive === 'ACTIVE') {
            result = props.messages['viserAktive']
        } else  if (props.aktive === 'CLOSED') {
            result = props.messages['viserAvsluttede']
        }

        // Triks som gjør samme som &nbsp; uten å måtte ta setDangerouslyHtml
        return result.replace(/ /g, "\u00a0")
    }

    let numCases = props.state.numberOfItems
    let sumOrgClaims = props.state.sumOriginalClaim
    let sumRemBalance = props.state.sumRemainBalance
    let sumInnbetHs = props.state.sumInnbetHs
    let sumRestHs = props.state.sumRestHs

    if (props.state.filtered) {
        numCases = props.state.filtredNumberOfItems
        sumOrgClaims = props.state.filtredSumOriginalClaim
        sumRemBalance = props.state.filtredSumRemainBalance
        sumInnbetHs = props.state.filteredSumInnbetHs
        sumRestHs = props.state.filteredSumRestHs
    }

    return (
        <Container fluid="true">
            <Row>

                <StyledCol xs lg="2">
                    <StyledBox>
                        <StyledBoxNumber>
                            {getSakerHeader()}
                        </StyledBoxNumber>
                        <StyledBoxHeader>{props.messages['viserSaker']}</StyledBoxHeader>
                    </StyledBox>
                </StyledCol>

                <StyledCol xs lg="2">
                    <StyledBox>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={numCases}
                                style='decimal'
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />}
                        </StyledBoxNumber>
                        <StyledBoxHeader>{props.messages['antSaker']}</StyledBoxHeader>
                    </StyledBox>
                </StyledCol>



                <StyledCol xs lg="2">
                    <StyledBox>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={sumOrgClaims}
                                style='decimal'
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />}
                        </StyledBoxNumber>
                        <StyledBoxHeader>{props.messages['totHS']}</StyledBoxHeader>
                    </StyledBox>
                </StyledCol>

                <StyledCol xs lg="2">
                    <StyledBox>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={sumInnbetHs}
                                style='decimal'
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />}
                        </StyledBoxNumber>
                        <StyledBoxHeader>{props.messages['sumInnbetHs']}</StyledBoxHeader>
                    </StyledBox>
                </StyledCol>

                <StyledCol xs lg="2">
                    <StyledBox>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={sumRestHs}
                                style='decimal'
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />}
                        </StyledBoxNumber>
                        <StyledBoxHeader>{props.messages['sumRestHs']}</StyledBoxHeader>
                    </StyledBox>
                </StyledCol>

                <StyledColR xs lg="2">
                    <StyledBox>
                        <StyledBoxNumber>
                            {<FormattedNumber
                                value={sumRemBalance}
                                style='decimal'
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />}
                        </StyledBoxNumber>
                        <StyledBoxHeader>{props.messages['saldoTot']}</StyledBoxHeader>
                    </StyledBox>
                </StyledColR>
            </Row>
        </Container>
    )
}

export default CaseListTotals

