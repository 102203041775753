import { actions } from "./saksdetaljerActions";

const initialState = {
    data: {
        //mottatteFiler: [],
        //sendteFiler: [],
        reskontro: [],
        saksOversikt: [],
        debtor: [],
        invoices: [],
        activities: [],
        caseCollectionList: [],
        caseNo: '',
        creditorNo: '   '
    },
    fetched: false,
    loading: false,
    error: ''
}

export default function saksdetaljerReducer(state = initialState, action) {

    switch (action.type) {

        case actions.SAKSDETALJER_LOADING:
            return {
                ...state,
                fetched: false,
                loading: true,
                error: null
            };

        case actions.SAKSDETALJER_DATA_FETCHED:
            return {
                ...state,
                data: { ...action.payload },
                fetched: true,
                loading: false,
                error: null
            };

        case actions.SAKSDETALJER_ERROR:
            return {
                ...state,
                data: { ...initialState },
                loading: false,
                fetched: false,
                error: action.data
            }

        default:
            return state;
    }
}
