import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useAuth } from "../../hooks/useAuth";
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { getFromStorage, setStorage } from '../../state/storageUtil'
import LogoutWarningModal from './LogoutWarningModal'
import { useDispatch } from "react-redux";
import { resetStore } from "../../reduxStore";
import CONFIG from "../../config";

let gBaseUrl = window.location.protocol + '//' + window.location.hostname + ":" + window.location.port + '/'
if (process.env.NODE_ENV === 'development') {
    //gBaseUrl = 'http://localhost:5000/'
    gBaseUrl = CONFIG.DEBUG_SERVER
}


const AUTOLOGOUTMODALTHRESHOLD = 5

export const useRefreshTokenAutoLogout = () => {

    const auth = useAuth()
    const history = useHistory();

    const globalDispatch = useDispatch()

    const [showLogoutWarning, setShowLogoutWarning] = useState(false)


// ┌──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────┐
// │     TIMER I USEEFFECT                                                                                                │
// │     Sett opp en timer som kjører med jevne mellomrom og sjekker at du er pålogget                                    │
// │     Merk at denne kjøres hver gang siden oppfriskes, (dvs ikke noe "[]" parameter)                                   │
// │     Det ble forsøkt, men da fungerte ikke auth skikkelig, den rapporterte at man                                     │
// │     ikke var pålogget selv om man faktisk var det.                                                                   │
// │                                                                                                                      │
// │     Merk at denne på 'UnMount'  fjerner timer (clearinterval),                                                       │
// │     dermed ligger det ikke noen andre timere dangling....                                                            │
// └──────────────────────────────────────────────────────────────────────────────────────────────────────────────────────┘

    useEffect(() => {
      
        //console.log('useRefreshTokenAutoLogout. useffect setting up, authenticated: ', auth.isAuthenticated)

        let logoutTimer = setInterval(() => {

            // sjekk om du er faktisk innlogget
            if (auth.isAuthenticated) {

                let minutesLeft = auth.minutesLeftOfToken()

                // Alter to 0 e.g. 29 for verify proper logout
                if (minutesLeft <= 0) {

                    setShowLogoutWarning(false)
                    auth.logOut()                    
                    history.push('/Login')
                    globalDispatch(resetStore())

                } else if (minutesLeft <= AUTOLOGOUTMODALTHRESHOLD) {

                    // Vis bilde, du blir logget ut om n minutter                    
                    setShowLogoutWarning(true)

                } else {
                    setShowLogoutWarning(false)
                }
        
            } else {
                setShowLogoutWarning(false)
            }

        }, 30000);
    
        return () => clearInterval(logoutTimer);
    });

    const closeLogoutWarning = () => setShowLogoutWarning(false)
    const refreshToken = async () => await refreshTokenAsync()
  
    const displayLogoutWarning = () => {
        if (showLogoutWarning && auth.isAuthenticated) {
            return (<LogoutWarningModal
                closeLogoutWarning={closeLogoutWarning}
                refreshToken={refreshToken}
            />)
        }
        return null
    }

    /* 
    Funksjon som henter nytt token, og legger det på localstorage
    */
    const refreshTokenAsync = async () => {
           
        let token = getFromStorage('kf.token')

        let options = {
            headers: {

                'pragma': 'no-cache',
                'cache-control': 'no-cache',
                'User-Agent': 'Console app',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        try {
            let res = await axios.post(
                `${gBaseUrl}api/ApiSecurity/RefreshToken`,
                '',
                options)
    
            console.log('Token refreshed, no logout') //, JSON.stringify(res.data.token))
            let decodedToken = jwt_decode(res.data.token)
            let expirationDate = new Date(decodedToken.exp * 1000);

            setStorage('kf.token', res.data.token)
            setStorage('kf.expirationDate', expirationDate)

            setShowLogoutWarning(false)

            return res.data.token;
    
        } catch (e) {
            console.log('refreshTokenAsync failed:', e.message)
            return token
        }
    }

    return {

        displayLogoutWarning

    }
}