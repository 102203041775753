import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './caseListReduxContainer.i18n'
import UseTableSorter from '../../components/useTable/UseTable'
import usePaginator from '../../components/useTable/UsePaginator'
import { useGridProperties } from './caseListTable/useGridProperties';
//import * as R from 'rambda'

export const useCaselistReduxContainer = (props) => {

  const locale = React.useContext(LocaleContext);
  const localeMessages = messages[locale]
  
  // Aktiver REDUX 
  const state = useSelector(state => state.saksliste)
  const headerState = useSelector(state => state.sakslisteHeader)
  const gridState = useSelector(state => state.saksListeGridState)
  const dispatch = useDispatch()
  
  const showToasts = true
  const aktive = headerState.aktive
  const [fetchMessage, setFetchMessage] = useState('')
  useEffect(() => {
    if (headerState.aktive === 'ALL') {
      setFetchMessage(localeMessages['fetchMessAll'])       
    } else if (headerState.aktive === 'ACTIVE') {
      setFetchMessage(localeMessages['fetchMessActive'])       
    } else if (headerState.aktive === 'CLOSED') {
      setFetchMessage(localeMessages['fetchMessClosed'])       
    }  
  }, [headerState.aktive])
  
  
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(state.loading || headerState.loading)
  }, [state.loading, headerState.loading])
  
  const {
    currentSeq, setCurrentSeq, currentField, setCurrentField,
    currentType, setCurrentType, sortClicked, setSortClicked,
    numberPrPage, setNumberPrPAge, activePage, setActivePage } = useGridProperties()
  
    
  // -----------------Memoized --------------------------
  // Nødvendig pga hengin av memoised felt
  const t1ChangeSortField = (fieldName, fieldType) => {
  
    masterT1ChangeSortField(fieldName, fieldType)
  
    // Sett trigger for å tvinge memoized function til rerender
    setSortClicked(Date.now())
  }
  
  const [masterT1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
    currentSeq, setCurrentSeq,
    currentField, setCurrentField,
    currentType, setCurrentType
  )
  
  /* 
  ┌──────────────────────────────────────────────────────────────────────────────┐
  │     NBNB TODO                                                                │
  │     Forsøk på å få vist spinner mens sortering pågår har ikke fungert.       │
  │     Det blir ingen rerendring av containeren slik at spinner vises.          │
  │     For å få dette til må antageligvis sortering flyttes til reducerem       │
  │     Men da må man antageligvis duplisere innholdet der for å beholde         │
  │     den opprinnelige sorteringen                                             │
  │                                                                              │
  │     Rambda syntaks:                                                          │
  │     return R.sort(t1GetSortFunc().fn, state.caseList)                        │
  │                                                                              │
  └──────────────────────────────────────────────────────────────────────────────┘    
*/
  const memoizedCasesForTable = useMemo(
    () => {
      //console.log('memoizedCasesForTable', sortClicked)
  
      if ((headerState.filter) || (headerState.kreditorId)) {
  
        //setActivePage(1)
        if (state.caseFiltered.length > 0) {
          return [...state.caseFiltered].sort(t1GetSortFunc().fn)
          //return R.sort(t1GetSortFunc().fn, state.caseFiltered)
        }
        else {
          return []
        }
      } else {
        //setActivePage(1)
        return [...state.caseList].sort(t1GetSortFunc().fn)
        // Rambda varianten var ca 50% tregere 5sek mot 3sek med ordinær sort
        //return R.sort(t1GetSortFunc().fn, state.caseList)

      }

    },
    [state.caseFiltered, state.caseList, sortClicked]
  );
  
  const getCasesForTable = () => {
    // Gir bedre ytelse ettersom denne kan bli kalt for mange ganger
    return memoizedCasesForTable
  }
  
  const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
    getCasesForTable(),
    numberPrPage, setNumberPrPAge,
    activePage, setActivePage
  )
  
  // -----------------Memoized -------------------------- 
  
  return {
    isLoading, localeMessages, fetchMessage,
    state, aktive, memoizedCasesForTable,
    PaginationDropdown, t1IndicaterIcon, t1ChangeSortField,
    Paginate, PaginationIndex, PaginationNav
  }
}