import React, { useEffect, useState } from "react";
import Workbook from 'react-excel-workbook'
import { BfButtonBlue } from "../../../Styling/styledForm/styledBlueForm";

export const CreditRatingExcel = (props) => {

    const asDateForExcel = (val) => {
        if (val !== null && val !== undefined) {
            let splittedDate = val.replace(' ', '-').split('-');
            let splittedDay = splittedDate[2].replace(' ', 'T').split('T');
            let ExcelDate = splittedDay[0] + '.' + splittedDate[1] + '.' + splittedDate[0];
            return ExcelDate
        } else {
            return ''
        }
    }

    const asExcelNum = (val) => {
        return val
        return val.toString().replace(',', '.')
    }

    const getFileName = () => {
        const nameValue = props.data.foretaksnavn && props.data.foretaksnavn.trim() !== '' 
            ? props.data.foretaksnavn 
            : props.data.navn || '';
        
        return `Kredittsjekk ${nameValue}.xlsx`;
    };
    
    const canDownload = (data) => {
        if (!data) return false; 
    
        if (Array.isArray(data)) {
            return data.length > 0;
        } else if (typeof data === 'object') {
            return Object.keys(data).length > 0;
        }
    
        return false;
    };    

    // Gjør objekt om til array slik at Excel blir happy
    const data = Array.isArray(props.data) ? props.data : [props.data];
    return (
        <Workbook
            filename={getFileName()}
            element={<BfButtonBlue disabled={!canDownload(props.data) }>Last ned</BfButtonBlue>}>

            <Workbook.Sheet data={data} name={props.filename || 'Sheet1'}>

                <Workbook.Column
                    label={props.localeMessages['GH_name']}
                    value={(row) => row.foretaksnavn && row.foretaksnavn.trim() !== '' ? row.foretaksnavn : row.navn}
                /> 
                <Workbook.Column label={props.localeMessages['GH_creditRating']} value="scoreForklaring" />
                <Workbook.Column label={props.localeMessages['GH_recommended']} value="kredittgrense" />
                <Workbook.Column label={props.localeMessages['GH_creditMark']} value="scoreKarakter" />
                <Workbook.Column label={props.localeMessages['GH_creditScore']} value="score" />

            </Workbook.Sheet>

        </Workbook>

    )
}