import React from 'react';
import { Container, Col, Form, Alert } from "react-bootstrap";
import { BfColInput } from '../../../Styling/FormComponents/FormInput'
import { BfYellowRowForm, BfTightYellowRowForm, BfButtonBlue, YellowRoundedContainer } from '../../../Styling/styledForm/styledBlueForm'

export const KontaktForm = (props) => {

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}

                onChange={props.handleChange}
                errorMessages={props.errorMessages}
                extraErrors={props.extraErrors}
                messages={props.localeMessages}
                required
                formState={props.formState}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
            />
        )
    }

    const getEmne = () => {

        /*
        <select className="form-control" name="faktura" value={props.funcs.fakturaId} onChange={props.funcs.onFakturaIdChange}>
                    <option value="">{velgFakt}</option>
                    {props.funcs.fakturaListe.map((fakt, i) =>
                        <option key={fakt.invoiceNumber} value={fakt.invoiceNumber}>{fakt.invoiceNumber} - {fakt.description}</option>
                    )}
                </select>
        */
        return null
    }


    const getEmneOptions = () => {
        return (
            <>
                <option value="">Velg</option>
                <option value={props.localeMessages.dropDown1}>{props.localeMessages.dropDown1}</option>
                <option value={props.localeMessages.dropDown2}>{props.localeMessages.dropDown2}</option>
                <option value={props.localeMessages.dropDown3}>{props.localeMessages.dropDown3}</option>
                <option value={props.localeMessages.dropDown4}>{props.localeMessages.dropDown4}</option>
            </>
        )
    }
    const emneOptions = getEmneOptions()

    return (

        <YellowRoundedContainer fluid style={{padding: '15px'}}>
            <Form autocomplete="chrome-off">
                
                {(props.hasErrors) && <Alert variant='danger'>{props.localeMessages.Skjemafeil}</Alert>}

                <BfYellowRowForm>
                    {getFormField(12, 'navn', null, null, props.disabled, 63)}
                </BfYellowRowForm>

                <BfYellowRowForm>
                    {getFormField(12, 'epost', null, null, props.disabled, 63)}
                </BfYellowRowForm>

                <BfYellowRowForm>
                    {getFormField(12, 'emne', 'select', getEmneOptions, props.disabled, 63)}
                </BfYellowRowForm>

                <BfYellowRowForm>
                    {getFormField(12, 'melding', 'textarea')}
                </BfYellowRowForm>

                <BfTightYellowRowForm>
                    <Col xs={12} md={12}>
                        <BfButtonBlue variant="primary" type="button" onClick={props.submitForm}>
                            {props.localeMessages.Send}
                        </BfButtonBlue>

                        &nbsp;


                    </Col>
                </BfTightYellowRowForm>

                <BfTightYellowRowForm />

            </Form>
        </YellowRoundedContainer>
    )
}