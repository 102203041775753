import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

export const headlessCmsActions = {
    CMS_LOADING: 'HDLS_CMS_LOADING',
    CMS_ERROR: 'HDLS_CMS_ERROR',
    CMS_DATA_FETCHED: 'HDLS_CMS_DATA_FETCHED',
}

const fetchBegin = () => ({
    type: headlessCmsActions.CMS_LOADING
});

const fetchSuccess = data => ({
    type: headlessCmsActions.CMS_DATA_FETCHED,
    payload: { data: data }
});

const fetchFailure = error => ({
    type: headlessCmsActions.CMS_ERROR,
    payload: { error }
});

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}

export const getCMSbyIndex = (id, showToasts, messages) => {

    return dispatch => {

        const errorMessage = messages['toastFailed'] || 'Henting av artikkel feilet: ' + id
        const okMessage = messages['toastOk'] || 'Artikkel hentet fra CMS'

        dispatch(fetchBegin())

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/HeadlessCMS/GetCMSArticle/${id}`,
            })
            .then((result) => {

                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(errorMessage, {autoClose: false}))
                    dispatch(fetchFailure(errorMessage))
                } else {

                    (showToasts && toast.info(okMessage))
                    //console.log(result.data)
                    let dict = { id: id, htmlCode: result.data }
                    dispatch(fetchSuccess(dict))
                }
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                toast.error(lErrMess, {autoClose: false})
                dispatch(fetchFailure(lErrMess))
            })

    }
}

export const getAhlinCMSbyId = (id, showToasts, messages) => {
    return async dispatch => { 
        const errorMessage = messages['toastFailed'] || 'Henting av artikkel feilet: ' + id;
        const okMessage = messages['toastOk'] || 'Artikkel hentet fra CMS';

        dispatch(fetchBegin());

        try {
            const response = await axiosTokenInstance({
                method: 'GET',
                url: `/api/HeadlessCMS/GetCMSArticle2/${id}`,
            });

            if (response.status !== 200) {
                (showToasts && toast.error(errorMessage, { autoClose: false }));
                dispatch(fetchFailure(errorMessage));
            } else {
                (showToasts && toast.info(okMessage));
                const dict = { id: id, htmlCode: response.data };
                dispatch(fetchSuccess(dict));
                return response.data;
            }
        } catch (err) {
            const errorMessageWithDetails = errorMessage + ' ' + getErrorMessage(err);
            toast.error(errorMessageWithDetails, { autoClose: false });
            dispatch(fetchFailure(errorMessageWithDetails));
            throw err; 
        }
    };
};
