import React, { useState, useMemo, useEffect, useRef } from 'react';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './CreditRatingContainer.i18n'
import { Alert } from 'react-bootstrap';
import UseTableSorter from '../../components/useTable/UseTable';
import usePaginator from '../../components/useTable/UsePaginator';
import { BfButtonBlue, BfRowForm } from '../../Styling/styledForm/styledBlueForm'
import _ from 'lodash'
import { BislabTermsModal } from '../../components/bislabTermsModal/BislabTermsModal';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux'
import { setDebitor, displayForms } from '../../reduxStore/newCase/newCaseActions';
import CreditRatingList from './creditRatingList/CreditRatingList';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';
import { getRatingInfo, getBislabCurrentTerms, acceptCreditRating, getCurrentUser } from '../../reduxStore/creditRating/creditRatingActions';
import { useAuth } from '../../hooks/useAuth'
import KredittsjekkCMS from './components/KredittsjekkCMS';
import { ArticleArea } from '../../Styling/Layout/ContentArea';

const FieldLabel = styled.label`
  display: block;
//   margin-bottom: 5px;
  font-weight: 400;
`;

const CreditRatingContainer = () => {

    const showToasts = true
    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]
    const [localState, setState] = useState({ id: localStorage.getItem('prevStateId') || null });
    const [kreditorCanDoCheckValue, setKreditorCanDoCreditCheck] = useState(
        JSON.parse(localStorage.getItem('kreditorCanDoCheckValue')) || false
    );
    const [userCanDoCheckValue, setUserCanDoCreditCheck] = useState(
        JSON.parse(localStorage.getItem('userCanDoCheckValue')) || false
    );
    const [acceptedTermsDate, setAcceptedTermsDate] = useState("");
    const auth = useAuth()

    const state = useSelector(state => state.newCase)
    state.displayForm = displayForms.DEBITORFILTER;
    // nb: Felles kreditorliste med sakslisteheader
    const crState = useSelector(state => state.sakslisteHeader)
    const _dispatch = useDispatch()
    let selectedKreditor = state.kreditorId;
    const [loadingCanDoCreditCheck, setLoadingCanDoCreditCheck] = useState(false);
    const [loadingAcceptTerms, setloadingAcceptTerms] = useState(false);
    const [pdfBase64, setPdfBase64] = useState('');
    const [showBislabTermsModal, setShowBislabTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const prevIdRef = useRef();

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('up'); // default
    const [currentField, setCurrentField] = useState('debtorName');    // feltnavn
    const [currentType, setCurrentType] = useState('string'); // num
    const [sortClicked, setSortClicked] = useState(0)

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10)
    const [activePage, setActivePage] = useState(1);

    const [masterT1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    // Nødvendig pga henting av memoised felt
    const t1ChangeSortField = (fieldName, fieldType) => {
        masterT1ChangeSortField(fieldName, fieldType)

        // Sett trigger for å tvinge memoized function til rerender
        setSortClicked(Date.now())
    }

    const [lastFetchedEmail, setLastFetchedEmail] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        if (!userInfo && !userCanDoCheckValue) {
            const fetchUserInfo = async () => {
                try {
                    const result = await _dispatch(getCurrentUser(auth.user.email, showToasts, localeMessages));
    
                    if (result) {
                        setUserInfo(result);
                        if (result.bisLabDate) {
                            setUserCanDoCreditCheck(true);
                            setAcceptedTermsDate(formatDate(result.bisLabDate));
                        } else {
                            setUserCanDoCreditCheck(false);
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            };
    
            fetchUserInfo();
            setLastFetchedEmail(auth.user.email);
        }
    }, [auth.user.email, lastFetchedEmail, _dispatch, showToasts, localeMessages]);
    

    const fetchBislabTerms = async () => {
        setLoading(true);
        try {
            const result = await _dispatch(getBislabCurrentTerms(showToasts, localeMessages));
            if (result && result.base64 !== null) {
                setPdfBase64(`data:application/pdf;base64,${result.base64}`);
            } else {
                console.log('No valid data received');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const acceptBislabTerms = async () => {
        const acceptFormData = new FormData()
        acceptFormData.append('creditorNo', selectedKreditor)
        acceptFormData.append('email', auth.user.email)
        acceptFormData.append('phone', auth.user.phonenumber)
        acceptFormData.append('termsId', '1')
        setloadingAcceptTerms(true);
        try {
            const result = await _dispatch(acceptCreditRating(acceptFormData, selectedKreditor, showToasts, localeMessages));
            if (result !== null) {
                setKreditorCanDoCreditCheck(true);
                setUserCanDoCreditCheck(true);
            } else {
                setKreditorCanDoCreditCheck(false);
                setUserCanDoCreditCheck(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setloadingAcceptTerms(false);
        }
    };

    const showBislabTerms = () => {
        fetchBislabTerms();
        setShowBislabTerms(true);
    };

    const handleCloseBislabTermsModal = () => {
        setShowBislabTerms(false);
        setPdfBase64('');
    };

    const handleAcceptBislabTerms = () => {
        acceptBislabTerms();
        handleCloseBislabTermsModal();
    };

    const handleCancelBislabTerms = () => {
        handleCloseBislabTermsModal();
    };

    const formatDate = d => {
        if ((d) && (d.length >= 20)) {
            return `${d.substring(8, 10)}.${d.substring(5, 7)}.${d.substring(0, 4)}`
        }
        return ''
    }

    useEffect(() => {
        // Sjekker om kall har blitt gjort for denne kreditoren
        if ((!state.kreditorId) && (crState.kreditorliste.length > 1)) return;
        if (localStorage.getItem('prevStateId') === state.kreditorId) return;
        prevIdRef.current = state.kreditorId;

        const fetchRatingInfo = async () => {
            setLoadingCanDoCreditCheck(true);
            try {
                const result = await _dispatch(getRatingInfo(selectedKreditor, showToasts, localeMessages));
                if (result.enabled || result === true) {
                    setKreditorCanDoCreditCheck(true);
                    setLoadingCanDoCreditCheck(false);
                    localStorage.setItem('kreditorCanDoCheckValue', true);
                } else {
                    setKreditorCanDoCreditCheck(false);
                    localStorage.setItem('kreditorCanDoCheckValue', false);
                    setLoadingCanDoCreditCheck(false);
                }
            } catch (error) {
                console.error(error);
            } finally {
                
                localStorage.setItem('prevStateId', state.kreditorId);
            }
        };
        fetchRatingInfo();
        // prevIdRef.current = state.kreditorId;
        state.displayForm = displayForms.DEBITORFILTER;
    }, [state.kreditorId]);

    const memoizedCasesForTable = useMemo(
        () => {

            if (state.debitorListFiltered.length > 0) {
                return [...state.debitorListFiltered].sort(t1GetSortFunc().fn)
            }
            else {
                return []
            }

        },
        [state.debitorListFiltered, sortClicked]
    );

    const getCasesForTable = () => {
        // Gir bedre ytelse ettersom denne kan bli kalt for mange ganger
        return memoizedCasesForTable
    }

    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        getCasesForTable(),
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    const selectDebitor = (debitor) => {
        console.log("setDebitor")
        console.log(debitor)
        _dispatch(setDebitor(debitor))
    }

    const showDebitorTable = () => {

        if (_.isEmpty(crState.kreditorliste)) {
            return (
                <>
                    <Alert variant='danger'>{localeMessages['kreditorNoSel']}</Alert>
                    <ArticleArea>
                        <KredittsjekkCMS articleId={'kredittvurdering'} localeMessages={localeMessages} />
                        &nbsp;   &nbsp;   &nbsp;
                    </ArticleArea>
                </>
            )
        }

        else if ((!state.kreditorId) && (crState.kreditorliste.length > 1)) {
            return (
                <>
                    <Alert variant='danger'>{localeMessages['kreditorNoSel']}</Alert>
                    <ArticleArea>
                        <KredittsjekkCMS articleId={'kredittvurdering'} localeMessages={localeMessages} />
                        &nbsp;   &nbsp;   &nbsp;
                    </ArticleArea>
                </>
            )
        }
        else if (state.debitorListFiltered.length === 0) {
            return (
                <>
                    <Alert variant='danger'>{localeMessages['filterNone']}</Alert>
                    <ArticleArea>
                        <KredittsjekkCMS articleId={'kredittvurdering'} localeMessages={localeMessages} />
                        &nbsp;   &nbsp;   &nbsp;
                    </ArticleArea>
                </>
            )
        }

        else if (!kreditorCanDoCheckValue || !userCanDoCheckValue && !loadingAcceptTerms && !loadingCanDoCreditCheck && state.kreditorId && !state.loading) {
            return (
                <ArticleArea>
                    <KredittsjekkCMS articleId={'kredittvurdering'} localeMessages={localeMessages} />
                    &nbsp;   &nbsp;   &nbsp;
                    <BfButtonBlue style={{ width: '150px' }} variant="primary" type="button" onClick={showBislabTerms} disabled={!auth.user.phonenumber || auth.user.phonenumber.trim() === ''}>
                        {localeMessages.showTermsButton}
                    </BfButtonBlue>
                    &nbsp;   &nbsp;
                </ArticleArea>
            );
        }
        else if (kreditorCanDoCheckValue && userCanDoCheckValue && !loadingCanDoCreditCheck && state.kreditorId && !state.loading) {
            return (
                <CreditRatingList
                    state={state}
                    localeMessages={localeMessages}
                    PaginationDropdown={PaginationDropdown}
                    t1IndicaterIcon={t1IndicaterIcon}
                    t1ChangeSortField={t1ChangeSortField}
                    Paginate={Paginate}
                    PaginationIndex={PaginationIndex}
                    PaginationNav={PaginationNav}
                    selectDebitor={selectDebitor}
                    acceptedTermsDate={acceptedTermsDate}
                />  
            )
        }
    }

    if (showBislabTermsModal) {
        return ((
            <BislabTermsModal
                show={showBislabTermsModal}
                okHandler={handleAcceptBislabTerms}
                cancelHandler={handleCancelBislabTerms}
                message={localeMessages.terms_caption}
                checkboxText={localeMessages.checkbox_text}
                header={localeMessages.accept_terms}
                okCaption={localeMessages.accept}
                cancelCaption={localeMessages.cancel}
                pdfBase64={pdfBase64}
                loading={loading}
                viewOnlyMode={false}
            />
        ))
    }


    if (state.error) {
        return (<Alert variant='danger'>{localeMessages.creditCheck_failed}</Alert>)
    }

    return (
        <>
            {(state.loading || crState.loading || loadingAcceptTerms) && !loadingCanDoCreditCheck && <OverlaySpinner></OverlaySpinner>}

            {!state.loading && (state.displayForm === displayForms.DEBITORFILTER) && showDebitorTable()}

        </>
    )
}

export default CreditRatingContainer
