import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

export const bisnodeActions = {
    BISNODE_LOADING: 'BISNODE_LOADING',
    BISNODE_ERROR: 'BISNODE_ERROR',
    BISNODE_FETCHED: 'BISNODE_FETCHED',
}

const fetchBegin = () => ({
    type: bisnodeActions.BISNODE_LOADING
});

const fetchSuccess = data => ({
    type: bisnodeActions.BISNODE_FETCHED,
    payload: { data: data }
});

const fetchFailure = error => ({
    type: bisnodeActions.BISNODE_ERROR,
    payload: {data: error }
});

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    }    
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText    
    } else {
        return err.message.toString()
    }
}

export const getBisnodeUri = (id, onOk, onError, showToasts, messages) => {

    return dispatch => {

        const errorMessage = messages['toastFailed'] || 'Du ble ikke pålogget bisnode på grunn av: ' + id
        const okMessage = messages['toastOk'] || 'Du er pålogget bisnode'

        dispatch(fetchBegin())

        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/useradmin/GetBisNodeUri`,
            })
            .then((result) => {

                if (result.status === 200) {
                    let obj = JSON.parse(result.data);

                    // Sjekk at du logget på med en ok bruker
                    if (obj.errorCode === "000") {                       

                        let redirectUri = obj.url;
                        
                        if (redirectUri) {   
                            (showToasts && toast.info(okMessage))
                            dispatch(fetchSuccess(redirectUri))
                            onOk(redirectUri)
                        } else {
                            (showToasts && toast.error(errorMessage, {autoClose: false}))        
                            dispatch(fetchFailure(errorMessage)) 
                            onError()
                        }
                    }
                    else {
                        (showToasts && toast.error(errorMessage, {autoClose: false}))                    
                        dispatch(fetchFailure(errorMessage))
                        onError()
                    }
                } else {
                    (showToasts && toast.error(errorMessage + ', ' + result.data.errorMessage, {autoClose: false}))                
                    dispatch(fetchFailure(errorMessage + ', ' + result.data.errorMessage))
                    onError()
                }
                
            })
            .catch((err) => {
                let lErrMess = errorMessage + ' ' + getErrorMessage(err)                
                toast.error(lErrMess, {autoClose: false})                
                dispatch(fetchFailure(lErrMess))                
                onError()
            })
    }
}