import React, { useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
} from "../../../Styling/styledModal/styledModals";
import _ from 'lodash'

const SvarMeldingModal = (props) => {

    const localeMessages = props.localeMessages
    const model = props.model

    const [noteText, setNoteText] = useState('')
    const [selectedUploadFile, setselectedUploadFile] = useState(null)

    const onUploadFileChange = event => {
        console.log(event.target.files[0])
        setselectedUploadFile(event.target.files[0])
    };

    const validates = () => (noteText) ? '' : 'disabled'

    const handleOk = () => {

        const resultModel = {
            ...model,
            noteText: noteText,
            selectedUploadFile: selectedUploadFile
        }
        
        props.ok(resultModel)
    }

    const handleCancel = () => {
        props.close()
    }

    const messageLfCorrected = () => {
        if (model.messageText) {
            return model.messageText.replaceAll('\\r\\n', '\n')
        }
        else return ''
    }

    return (

        <StyledModal centered show={true} onHide={handleCancel} size="xl">

            <StyledModalBody>
                <h4>{localeMessages["MS1M_Overskrift"]}</h4>
                <Form.Group>

                    <Form.Row>
                        <Form.Label column="sm" lg={2}>
                            {localeMessages["MS1M_sak"]}
                        </Form.Label>
                        <Col>
                            {model.caseNo} {model.debtorName}
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={2}>
                            {localeMessages["MS1M_melding"]}
                        </Form.Label>
                        <Col>
                            
                            <Form.Control id="Oppgave" name="meldingstekst" as="textarea" rows={8} readOnly style={{ backgroundColor: '#ffd7ba', whiteSpace: 'pre-line'}}
                                value={messageLfCorrected()}
                            />
                        </Col>
                    </Form.Row>

                    <div>&nbsp;</div>


                    <Form.Row>
                        <Form.Label column="sm" lg={2}>
                            {localeMessages["MS1M_svar"]}
                        </Form.Label>
                        <Col>
                            <Form.Control id="Oppgave" name="Oppgave" as="textarea" rows={8} 
                                value={noteText}
                                onChange={(e) => setNoteText(e.target.value)}
                            />
                        </Col>
                    </Form.Row>

                    <div>&nbsp;</div>                 

                    <Form.Row>
                        <Form.Label column="sm" lg={2}>

                        </Form.Label>
                        <Col>
                            <input type="file" onChange={onUploadFileChange} />
                        </Col>
                    </Form.Row>

                </Form.Group>

            </StyledModalBody>

            <StyledModalFooter>
                <Button
                    variant="secondary"
                    disabled={validates()}
                    onClick={handleOk}
                >
                    {localeMessages["MS1M_Ok"]}
                </Button>
                <Button variant="secondary"
                    onClick={handleCancel}
                >
                    {localeMessages["MS1M_Avbryt"]}
                </Button>

            </StyledModalFooter>

        </StyledModal>
    );
};

export default SvarMeldingModal;

