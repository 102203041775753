export const actions = {
    GRIDSTATE_CURRENTSEQ: 'GRIDSTATE_CURRENTSEQ',
    GRIDSTATE_CURRENTFIELD: 'GRIDSTATE_CURRENTFIELD',
    GRIDSTATE_CURRENTTYPE: 'GRIDSTATE_CURRENTTYPE',
    GRIDSTATE_SORTCLICKED: 'GRIDSTATE_SORTCLICKED',
    GRIDSTATE_NUMBERPRPAGE: 'GRIDSTATE_NUMBERPRPAGE',
    GRIDSTATE_ACTIVEPAGE: 'GRIDSTATE_ACTIVEPAGE', 
}

/*
const [currentSeq, setCurrentSeq] = useState('down'); // default
const [currentField, setCurrentField] = useState('registerDate');    // feltnavn
const [currentType, setCurrentType] = useState('datetime'); // num
const [sortClicked, setSortClicked] = useState(0)

// state for grid paginator
const [numberPrPage, setNumberPrPAge] = useState(10)
const [activePage, setActivePage] = useState(1);
*/

/*===============================================================================

===============================================================================*/
export const dispSetCurrentSeq = (value) => {
    return dispatch => {
        dispatch({
            type: actions.GRIDSTATE_CURRENTSEQ,
            payload: { value }
        })
    }
}

export const dispSetCurrentField = (value) => {
    return dispatch => {
        dispatch({
            type: actions.GRIDSTATE_CURRENTFIELD,
            payload: { value }
        })
    }
}

export const dispSetcurrentType = (value) => {
    return dispatch => {
        dispatch({
            type: actions.GRIDSTATE_CURRENTTYPE,
            payload: { value }
        })
    }
}

export const dispSetSortClicked = (value) => {
    return dispatch => {
        dispatch({
            type: actions.GRIDSTATE_SORTCLICKED,
            payload: { value }
        })
    }
}

export const dispSetNumberPrPage = (value) => {
    return dispatch => {
        dispatch({
            type: actions.GRIDSTATE_NUMBERPRPAGE,
            payload: { value }
        })
    }
}

export const dispSetActivePage = (value) => {
    return dispatch => {
        dispatch({
            type: actions.GRIDSTATE_ACTIVEPAGE,
            payload: { value }
        })
    }
}