import {
    gncActions, displayForms
} from "./newCaseActions"

const initialState = {
    initialState: true,
    kreditorId: '',
    debitorList: [],
    debitorListFiltered: [],
    preCaseId: '',

    countryList: [],
    currencyList: [],
    postalList: [],
    basicDataFetched: false,
    filterString: '',
    selectedDebitor: {},
    existingDebitor: false,
    fakturaHeader: '',
    lineNo: 0,
    editLineNo: null,
    invoices: [],
    displayForm: displayForms.DEBITORFILTER,
    fetched: false,
    loading: false,
    error: ''
}

const newCaseReducer = (state = initialState, action) => {

    switch (action.type) {

        case gncActions.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };

        case gncActions.ERROR:
            return {
                ...state,
                loading: false,
                error: action.data.error
            };

        case gncActions.DEBITORSFETCHED:
            return {
                ...state,
                displayForm: displayForms.DEBITORFILTER,
                kreditorId: action.data.kreditorId,
                debitorList: [...action.data.debitorList],
                debitorListFiltered: [...action.data.debitorList],
                defaultProduct: action.data.defaultProduct,
                filterString: '',
                selectedDebitor: {},
                preCaseId: '',
                caseId: '',
                fetched: true,
                loading: false,
                error: null
            };

        case gncActions.DEBITORSFILTERED:
            return {
                ...state,
                displayForm: displayForms.DEBITORFILTER,
                debitorListFiltered: [...action.data.debitorListFiltered],
                filterString: action.data.filterString,
                loading: false,
                error: null
            };

        case gncActions.SELECTDEBITOR:
            return {
                ...state,
                initialState: false,
                displayForm: displayForms.EDITDEBITOR,
                selectedDebitor: { ...action.data.selectedDebitor },
                invoices: [],
                fakturaHeader: '',
                preCaseId: '',                
                caseId: '',
                existingDebitor: true,
                loading: false,
                error: null
            };

        case gncActions.NEWDEBITOR:
            return {
                ...state,
                displayForm: displayForms.EDITDEBITOR,
                selectedDebitor: {},
                invoices: [],
                preCaseId: '',
                caseId: '',
                fakturaHeader: '',
                existingDebitor: false,
                loading: false,
                error: null
            };

        case gncActions.SETFAKTURAHEADER:
            return {
                ...state,
                fakturaHeader: action.data,
                loading: false,
                error: null
            }

        case gncActions.SETBASICDATA:
            return {
                ...state,
                countryList: [...action.data.countryList],
                currencyList: [...action.data.currencyList],
                postalList: [...action.data.postalList],
                basicDataFetched: true,
                loading: false,
                error: null
            };

        case gncActions.VISUPLOADFAKTURA:
            return {
                ...state,
                displayForm: displayForms.FAKTURAUPLOAD,
                preCaseId: action.data,
                caseId: '',
                invoices: [],
                editLineNo: null,
                loading: false,
                error: null
            };

        case gncActions.FAKTURAUPLOADED:
            return {
                ...state,
                displayForm: displayForms.FAKTURAUPLOAD,
                invoices: [...action.data.invoiceList],
                lineNo: action.data.lineNo,
                editLineNo: null,
                loading: false,
                error: null
            };

        case gncActions.EDITFAKTURA:
            return {
                ...state,
                displayForm: displayForms.FAKTURAUPLOADEDIT,
                editLineNo: action.data.lineNo,
                loading: false,
                error: null
            };

        case gncActions.FAKTURADELETED:
            return {
                ...state,
                displayForm: displayForms.FAKTURAUPLOAD,
                invoices: [...action.data],
                editLineNo: null,
                loading: false,
                error: null
            }

        case gncActions.EMPTYFAKTURA:
            return {
                ...state,
                displayForm: displayForms.FAKTURAUPLOADEDIT,
                editLineNo: null,
                loading: false,
                error: null
            };

        case gncActions.FAKTURAFINALIZED:
            return {
                ...state,
                displayForm: displayForms.RECEIPT,
                preCaseId: '',
                caseId: action.data,
                invoices: [],
                loading: false,
                error: null
            }

        case gncActions.NO_DATA:
            return {
                ...initialState,
                loading: false,
                error: action.data
            };

        case gncActions.RESETCONTAINER:
            return {
                ...state,
                initialState: true,
                //kreditorId: '',
                filterString: '',
                selectedDebitor: {},
                fakturaHeader: '',
                debitorListFiltered: state.debitorList,
                existingDebitor: false,
                invoices: [],
                preCaseId: '',
                caseId: '',
                displayForm: displayForms.DEBITORFILTER,
                loading: false,
                error: ''
            }

        default:
            return state;    
    }
}

export default newCaseReducer