import React from 'react';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner'
import { PowerBIEmbed } from 'powerbi-client-react';
import { Alert } from "react-bootstrap";
import { ArticleArea } from '../../Styling/Layout/ContentArea';


const PbiReportComponent = (props) => {

    let state = props.state
    let crState = props.crState
    let reportMenuState = props.reportMenuState

    let reportId = props.reportId
    let currentReportName = ''
    if (reportMenuState.scheduleItems) {
        let currentReport = reportMenuState.scheduleItems.find(x => reportId === x.reportId)
        if (currentReport) {
            currentReportName = currentReport.reportName
        }
    }

    const PBiReport = () => {
        return (

            <>
                <ArticleArea>
                            <h1>{currentReportName}</h1>
                </ArticleArea>

                <PowerBIEmbed
                    embedConfig={state.data}

                    eventHandlers={
                        new Map([
                            ['loaded', function () { console.log('Report loaded'); }],
                            ['rendered', function () { console.log('Report rendered'); }],
                            ['error', function (event) { console.log(event.detail); }]
                        ])
                    }

                    cssClassName={"Embed-container"}

                    getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;
                    }}
                />
            </>

           
        )
    }

    return (
        <>
            {(state.error) &&
                <Alert variant='danger'>
                    {state.error}
                </Alert>}

            {state.loading && <OverlaySpinner />}

            {state.fetched && <PBiReport />}
        </>
    )
}

export default PbiReportComponent