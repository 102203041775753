import { actions } from "./saksListeGridStateActions";

const initialState = {
    currentSeq: 'down',
    currentField: 'regCloseDate',
    currentType: 'datetime',
    sortClicked: 0,
    numberPrPage: 10,
    activePage: 1
}

export default function saksListeGridState(state = initialState, action) {

    switch (action.type) {

        case actions.GRIDSTATE_CURRENTSEQ:
            return {
                ...state,
                currentSeq: action.payload.value,
                //activePage: 1
            };
        
        case actions.GRIDSTATE_CURRENTFIELD:
            return {
                ...state,
                currentField: action.payload.value
                //activePage: 1
            };
    
        case actions.GRIDSTATE_CURRENTTYPE:
            return {
                ...state,
                currentType: action.payload.value
            };
        
        case actions.GRIDSTATE_SORTCLICKED:
            return {
                ...state,
                sortClicked: action.payload.value,
                activePage: 1
            };
        
        case actions.GRIDSTATE_NUMBERPRPAGE:
            return {
                ...state,
                numberPrPage: action.payload.value,
                activePage: 1
            };
        
        case actions.GRIDSTATE_ACTIVEPAGE:
            return {
                ...state,
                activePage: action.payload.value
            };
        
        default:
            return state;
    }
}
