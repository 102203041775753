import axiosTokenInstance from '../../Instances/axiosTokenInstance';
import { toast } from 'react-toastify';
import _ from 'lodash'

export const creditRatingActions = {
    LOADING: 'CREDIT_RATING_LOADING',
    ERROR: 'CREDIT_RATING_ERROR',
    COMPANY_RATING_FETCHED: 'COMPANY_RATING_FETCHED',
    PERSON_RATING_FETCHED: 'PERSON_RATING_FETCHED',
    RATING_INFO_FETCHED: 'RATING_INFO_FETCHED',
    CREDIT_RATING_TERMS_FETCHED: 'CREDIT_RATING_TERMS_FETCHED',
    UPDATE_DEBTOR_RATING: 'UPDATE_DEBTOR_RATING',
    CREDIT_RATING_FILTERED: 'CREDIT_RATING_FILTERED',
    SELECTDEBITOR: 'SELECT_DEBITOR',
    SETFAKTURAHEADER: 'SET_FAKTURA_HEADER',
    NEWDEBITOR: 'NEW_DEBITOR',
    NO_DATA: 'NO_DATA',
    RESETCONTAINER: 'RESET_CONTAINER',
    SETBASICDATA: 'SET_BASIC_DATA'
}

export const displayForms = {
    DEBITORFILTER: 'DEBITORFILTER',
    EDITDEBITOR: 'EDITDEBITOR',
    CONFIRM: 'CONFIRM',
    RECEIPT: 'RECEIPT'
}

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}

const mapCompanyRating = (companyRatingObject) => {
    if (!companyRatingObject) {
        return null;
    }
    let result = Object.keys(companyRatingObject).map((key, i) => {
        let e = companyRatingObject[key];
        let _filter = Object.values(e).join(";").toUpperCase();
        return {
            ...e,
            filter: _filter,
            key: key,
            i: i
        };
    });

    return result;
};

const mapPersonRating = (personRatingObject) => {
    if (!personRatingObject) {
        return null;
    }
    let result = Object.keys(personRatingObject).map((key, i) => {
        let e = personRatingObject[key];
        let _filter = Object.values(e).join(";").toUpperCase();
        return {
            ...e,
            filter: _filter,
            key: key,
            i: i
        };
    });

    return result;
};

const mapRatingInfo = (ratingInfoObject) => {
    if (!ratingInfoObject) {
        return null;
    }

    const uCase = (e, fieldName) => {
        if (e.hasOwnProperty(fieldName)) {
            let val = e[fieldName]
            if (val) {
                return val.toUpperCase()
            }
        }
        return ''
    }
    let result = Object.keys(ratingInfoObject).map((key, i) => {
        let e = ratingInfoObject[key];
        let _filter = Object.values(e).join(";").toUpperCase();
        return {
            ...e,
            key: key,
            i: i,
            filter: _filter,
            billingEmail: e.billingEmail,
            bislabCustomerId: e.bislabCustomerId,
            contactEmail: e.contactEmail,
            contactPhone: e.contactPhone,
            createdDate: e.createdDate,
            enabled: e.enabled,
            kfCustomerNo: e.kfCustomerNo,
            orgno: e.orgno,
            partnerCustomerId: e.partnerCustomerId,
            termsId: Object.keys(e.termsId || {}).map((key, b) => {
                return {
                    key: key,
                    b: b,
                    termsId: key.termsId,
                    fromDate: key.fromDate,
                };
            }),
            bislabLogCollection: Object.keys(e.bislabLogCollection || {}).map((key, c) => {
                return {
                    key: key,
                    c: c,
                    action: key.action,
                    bislabLogId: key.bislabLogId,
                    email: key.email,
                    regDate: key.regDate,
                }
            }),
            bislabTransactionsCollection: Object.keys(e.bislabTransactionsCollection || {}).map((key, d) => {
                return {
                    key: key,
                    d: d,
                    bislabTransactionId: key.bislabTransactionId,
                    noSearch: key.noSearch,
                    price: key.price,
                    transactionDate: key.transactionDate,
                    bislabLogList: Object.keys(key.bislabLogList || {}).map((key, f) => {
                        return {
                            key: key,
                            f: f,
                            action: key.action,
                            bislabLogId: key.bislabLogId,
                            email: key.email,
                            regDate: key.regDate,
                        }
                    })
                }
            })

        }
    })
    return result
}

const creditRatingLoading = () => ({
    type: creditRatingActions.LOADING
});

const companyRatingFailure = error => ({
    type: creditRatingActions.ERROR,
    data: { error }
});


const companyRatingFetched = data => ({
    type: creditRatingActions.COMPANY_RATING_FETCHED,
    data: data
})

const personRatingFailure = error => ({
    type: creditRatingActions.ERROR,
    data: { error }
});


const personRatingFetched = data => ({
    type: creditRatingActions.PERSON_RATING_FETCHED,
    data: data
})

const ratingInfoFailure = error => ({
    type: creditRatingActions.ERROR,
    data: { error }
});


const ratingInfoFetched = data => ({
    type: creditRatingActions.RATING_INFO_FETCHED,
    data: data
})

const creditRatingTermsFailure = error => ({
    type: creditRatingActions.ERROR,
    data: { error }
});

const creditRatingTermsFetched = data => ({
    type: creditRatingActions.CREDIT_RATING_TERMS_FETCHED,
    data: data
})

const updateDebtorRatingFailure = error => ({
    type: creditRatingActions.ERROR,
    data: { error }
});

const setFakturaHeader = data => ({
    type: creditRatingActions.SETFAKTURAHEADER,
    data: data
})


export const getCompanyRating = (creditorNo, orgNo, emailUser, debtorNo, showToasts, messages) => {

    return dispatch => {

        if (creditorNo === '') {
            return
        }

        dispatch(creditRatingLoading())
        return axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/CreditRating/getCreditRatingCompany/${creditorNo}/${orgNo}/${emailUser}/${debtorNo}`,
            })
            .then((result) => {
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastGetCompanyRatingFailed'], { autoClose: false }))
                    dispatch(companyRatingFailure(messages['toastGetCompanyRatingFailed']))
                    return null;
                } else {
                    const data = result.data
                    if (data.status.errorCode === 0) {
                        (showToasts && toast.warning(messages['toastGetCompanyRatingOk'] + orgNo))
                        let companyRating = mapCompanyRating(data)
                        dispatch(companyRatingFetched({ kreditorId: creditorNo, companyRating: companyRating }))
                        return data.companyRating;
                    } else {
                        (showToasts && toast.error(messages['toastGetCompanyRatingFailed'] + orgNo + ' er korrekt.', { autoClose: true }))
                        return null;
                    }
                }
            })
            .catch((err) => {
                let errormessage = `Noe gikk galt ved henting av kredittvurdering av ${orgNo}.` + getErrorMessage(err)
                toast.error(errormessage, { autoClose: false })
                dispatch(companyRatingFailure(errormessage))
                return null;
            })
    }
}

export const getPersonRating = (creditorNo, personalIdentificationNumber, emailUser, debtorNo, showToasts, messages) => {

    return dispatch => {

        if (creditorNo === '') {
            return
        }

        dispatch(creditRatingLoading())

        return axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/CreditRating/getCreditRatingPerson/${creditorNo}/${personalIdentificationNumber}/${emailUser}/${debtorNo}`,
            })
            .then((result) => {
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastGetPersonRatingFailed'], { autoClose: false }))
                    dispatch(personRatingFailure(messages['toastGetPersonRatingFailed']))
                    return null;
                } else {
                    const data = result.data

                    if (data.status.errorCode === 0) {
                        (showToasts && toast.warning(messages['toastGetPersonRatingOk'] + personalIdentificationNumber))
                        let personRating = mapPersonRating(data)
                        dispatch(personRatingFetched({ kreditorId: creditorNo, personRating: personRating }))
                        return data.personRating;
                    } else {
                        (showToasts && toast.error(messages['toastGetPersonRatingFailed']  + personalIdentificationNumber + ' er korrekt.', { autoClose: true }))
                        return null;
                    }
                }
            })
            .catch((err) => {
                console.log(err)
                let errormessage = `Noe gikk galt ved henting av kredittvurdering av ${personalIdentificationNumber}.` + getErrorMessage(err)
                toast.error(errormessage, { autoClose: false })
                dispatch(personRatingFailure(errormessage))
                return null;
            })
    }
}

export const getRatingInfo = (creditorNo, showToasts, messages) => {

    return dispatch => {

        if (creditorNo === '') {
            return
        }

        dispatch(creditRatingLoading())
       return axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/CreditRating/doGetRatingInfo/${creditorNo}`,
            })
            .then((result) => {
                console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastGetRatingInfoFailed'], { autoClose: false }))
                    dispatch(ratingInfoFailure(messages['toastGetRatingInfoFailed']))
                } else {
                    const data = result.data
                    if (data.errorCode === 0) {
                        if (!data.customer.enabled) {
                            (showToasts && toast.warning(messages['toastGetRatingInfoNoGo'], { autoClose: true }))
                            // dispatch(ratingInfoFailure(messages['toastGetRatingInfoNoGo']))
                            return data.customer.enabled;
                        }
                        (showToasts && toast.warning(messages['toastGetRatingInfoOk']))

                        let ratingInfo = mapRatingInfo(result)
                        dispatch(ratingInfoFetched({ kreditorId: creditorNo, ratingInfo: ratingInfo }))
                        return data.customer;
                    } else {
                        (showToasts && toast.warning(data.status))
                        return false;
                        //dispatch({ type: actions.HIDE_MODAL, data: null })
                    }
                }
            })
            .catch((err) => {
                let errormessage = getErrorMessage(err) + ` (/api/CreditRating/doGetRatingInfo/${creditorNo})`
                toast.error(errormessage, { autoClose: false })
                //dispatch({ type: actions.ERROR, data: errormessage })
                dispatch(ratingInfoFailure(errormessage))
            })
    }
}

export const getBislabCurrentTerms = (showToasts, messages) => {
    return dispatch => {
        dispatch(creditRatingLoading());

        return axiosTokenInstance({
            method: 'GET',
            url: `/api/CreditRating/getBislabCurrentTerms`,
        })
            .then((result) => {
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastGetBislabTermsFailed'], { autoClose: false }));
                    dispatch(creditRatingTermsFailure(messages['toastGetBislabTermsFailed']));
                    return null;
                } else {
                    const data = result.data;
                    if (data.base64 !== null) {
                        (showToasts && toast.warning(messages['toastGetBislabTermsOk']));
                        dispatch(creditRatingTermsFetched({ bislabCurrentTerms: data }));
                        return data;
                    } else {
                        (showToasts && toast.warning(messages['toastGetBislabTermsFailed'] + ', ' + result.data.errorMessage));
                        return null;
                    }
                }
            })
            .catch((err) => {
                let errormessage = getErrorMessage(err) + ` (/api/CreditRating/getBislabCurrentTerms)`;
                toast.error(errormessage, { autoClose: false });
                dispatch(creditRatingTermsFailure(errormessage));
                return null;
            });
    }
}


export const updateDebtorRating = (formData, debtorNo, showToasts, messages) => {

    const okMessage = messages['toastUpdateDebtorRatingOk']

    return dispatch => {

        axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/CreditRating/updateRating`,
                data: formData
            })
            .then((result) => {

                if (result.status !== 200) {

                    (showToasts && toast.error(messages['toastUpdateDebtorRatingFailed'], { autoClose: false }))

                } else {

                    if (result.data) {

                        (showToasts && toast.info(okMessage + debtorNo))

                    } else {
                        (showToasts && toast.warning(messages['toastUpdateDebtorRatingFailed'] + ', ' + + ', ' + result.data.errorMessage))
                        //dispatch({ type: actions.ERROR, data: messages['toastNySakPostDebitorFailed'] + + ', ' + result.data.errorMessage })
                    }
                }
            })
            .catch((err) => {

                let errormessage = getErrorMessage(err)
                toast.error(errormessage, { autoClose: false })
                //dispatch({ type: actions.ERROR, data: errormessage })
                dispatch(updateDebtorRatingFailure(messages['toastUpdateDebtorRatingFailed']))
                console.log(err)
            })
    }
}

export const acceptCreditRating = (formData, creditorNo, showToasts, messages) => {

    const okMessage = messages['toastAcceptRatingOk']

    return dispatch => {

        return axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/CreditRating/doAcceptCreditRating`,
                data: formData
            })
            .then((result) => {
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastAcceptRatingFailed'] + formData.get("email"), { autoClose: false }))
                    return null;
                } else {
                    if (result.data.errorCode === 110) {
                        (showToasts && toast.warning(result.data.status + ', avtale signert for bruker ' + formData.get("email"), { autoClose: true }))
                        return true;
                    }

                    if (result.data.errorCode === 100) {
                        (showToasts && toast.warning(result.data.status, { autoClose: true }))
                        return false;
                    }

                    if (result.data.errorCode === 0) {
                        (showToasts && toast.warning(messages['toastAcceptRatingOk'] + formData.get("email") + ' og for kreditor ' + creditorNo))
                        return result.data;
                    } else {
                        (showToasts && toast.warning(messages['toastAcceptRatingFailed'] + formData.get("email") + + ', ' + result.data.errorMessage))
                        return null;
                    }
                }
            })
            .catch((err) => {
                let errorMessage = err.message;
                // if (err.response === '(code: 110)') {
                // console.log(err.response.data === '(code: 110')
                toast.error(errorMessage, { autoClose: false })
                // }
                // let errormessage = 'err.request.staus' + ' ' + getErrorMessage(err)
                // toast.error(errormessage, { autoClose: false })
                //dispatch({ type: actions.ERROR, data: errormessage })
                // dispatch(newCaseFailure(errormessage))
            })
    }
}

export const disableRating = (formData, creditorNo, showToasts, messages) => {

    const okMessage = messages['toastDisableDebtorRatingOk']

    return dispatch => {

       return axiosTokenInstance
            ({
                method: 'POST',
                url: `/api/CreditRating/disableRating`,
                data: formData
            })
            .then((result) => {

                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastDisableDebtorRatingFailed'], { autoClose: false }))
                    return null;

                } else {

                    if (result.data.errorCode === 0) {
                        var data = result.data;
                        (showToasts && toast.info(okMessage + creditorNo))
                        return data.customer;

                    } else if (result.data === 'OK') {
                        var data = result.data;
                        (showToasts && toast.info(okMessage + creditorNo))
                        return 'disabled';
                    } else {
                        (showToasts && toast.warning(messages['toastDisableDebtorRatingFailed'] + ', ' + + ', ' + result.data.errorMessage))
                        return null;
                    }
                }
            })
            .catch((err) => {

                let errormessage = getErrorMessage(err)
                toast.error(errormessage, { autoClose: false })
                dispatch(updateDebtorRatingFailure(messages['toastDisableDebtorRatingFailed']))
                console.log(err)
            })
    }


}

export const getCurrentUser = (userName, showToasts, messages) => {
    console.log("Henter bruker: ", userName)

    return dispatch => {
        return axiosTokenInstance
            ({
                method: 'GET',
                url: `api/UserAdmin/GetUser?email=${userName}`
            })
            .then((result) => {
                //console.log(result)
                if (result.status !== 200) {
                    (showToasts && toast.error(messages['toastNoUserError'], { autoClose: false }))
                    return null;
                }
                else if (result.data) {
                    // (showToasts && toast.info(messages['toastFoundUser']))
                    var result = result.data;
                    // if (result.bisLabDate) {
                    //     (showToasts && toast.info(messages['toastUserHasBislab']))
                    // } else {
                    //     (showToasts && toast.info(messages['toastUserNoBislab']))
                    // }
                    return result;
                } else {
                    // (showToasts && toast.warning(messages['toastNoUserFound']))
                    return null;
                }
            })
            .catch((err) => {
                // (showToasts && toast.error(messages['toastNoUserError'], { autoClose: false }))
                console.log(err)
                // dispatch({ type: actions.ERROR, data: err.message })
            })
    }
}



