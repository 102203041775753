export const getFromStorage = (name) => localStorage.getItem(name)
export const removeFromStorage = (name) => localStorage.removeItem(name)
export const setStorage = (name, valAsString) => localStorage.setItem(name, valAsString)

export const getFromSession = (name) => sessionStorage.getItem(name)    
export const removeFromSession = (name) => sessionStorage.removeItem(name)    
export const setSessionStorage = (name, valAsString) => sessionStorage.setItem(name, valAsString)

export const restoreFormFromStorage = (name, setField) => {

    try {    
        let savedFormItems = getFromSession(name);
        if (savedFormItems) {
            let _formValues = JSON.parse(savedFormItems)
            Object.keys(_formValues).forEach(e => {
                if (_formValues[e]) {
                    setField(e, _formValues[e])
                }
            });
            setField()
        }   
    } catch (e) {
        console.log('Error in storageToFormFields: ', e)
    }
}

/*
export const setStorageX = (name, valAsString) => {
    try {    
        console.log('saving to storage')
        //sessionStorage.removeItem(...name)
        sessionStorage.setItem(name, valAsString)
  
    } catch (e) {
        console.log('saving to storage error ', e)
    }
}*/

//import { getFromStorage, removeFromStorage, setStorage } from '../../state/storageUtil'