import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';
//import { useDispatch, useSelector } from 'react-redux';
import LocaleContext from '../../state/contexts/LocaleContext'
import { ArticleArea } from '../../Styling/Layout/ContentArea';
import { KontaktForm } from './kontaktForm/KontaktForm';
import { messages } from './KontaktSideContainer.i18n'
import { useKontaktSideContainer } from './useKontaktSideContainer';
import { IntlProvider } from 'react-intl';

const KontaktSideContainer = () => {

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const {
        formValues, formValidators, handleChange,
        formState, validateAll, hasErrors, savedOk,
        errorMessages, extraErrors, setExtraErrors,
        submitForm, isLoading, pageHasError
    } = useKontaktSideContainer(localeMessages)

    return (

        <Container>
            {(isLoading) && <OverlaySpinner/>}
            
            <ArticleArea>
                <h1>{localeMessages.header}</h1>
                <p>{localeMessages.beskrivelse}</p>                    
            </ArticleArea>

            <KontaktForm
                disabled={false}
                submitForm={submitForm}
                localeMessages={localeMessages}
                handleChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                formState={formState}  
                hasErrors={hasErrors}
            />
                
        </Container>
        
    )
}

export default KontaktSideContainer