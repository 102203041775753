import { check_personalnumber_no, orgNummer } from '../../../util/validering/valideringsRutiner'

const getValidatePnr = (errorMessage) => {

    const isValidPnr = (val, name, formState) => {

        if (!val) {
            return ''
        }

        if (formState.debtorType.toLowerCase() === 'privat')
            return ''

        if (check_personalnumber_no(val.replace(" ", ""))) {
            return ''
        }
        return errorMessage
    }
    return isValidPnr
}

const getValidateOrgNr = (errorMessage) => {

    const isValidOrgNr = (val, name, formState) => {

        if (!val) {
            return ''
        }

        if (formState.debtorType.toLowerCase() === 'privat')
            return ''
        
        if (orgNummer(val.replace(" ", ""))) {
            return ''
        }
        return errorMessage
    }
    return isValidOrgNr
}

const stringToDate = function (dateString) {
    const [dd, mm, yyyy] = dateString.split(".");
    return new Date(`${yyyy}-${mm}-${dd}`);
};

const getIsBeyondToday = (errorMessage, length) => {

    const isBeyondToday = (val, name, formState) => {

        if (!val) {
            return ''
        }

        let CurrentDate = new Date()
        let GivenDate = stringToDate(val)

        if (GivenDate > CurrentDate) {
            return errorMessage
        } else {
            return ''
        }
    }

    return isBeyondToday
}

function deltaDate(input, days, months, years) {
    return new Date(
        input.getFullYear() + years,
        input.getMonth() + months,
        Math.min(
            input.getDate() + days,
            new Date(input.getFullYear() + years, input.getMonth() + months + 1, 0).getDate()
        )
    );
}

const getIsNotToOld = (errorMessage, length) => {

    const isBeyondToday = (val, name, formState) => {

        if (!val) {
            return ''
        }

        let minDate = deltaDate(new Date(), 0, 0, -105);
        let GivenDate = stringToDate(val)

        if (GivenDate < minDate) {
            return errorMessage
        } else {
            return ''
        }
    }

    return isBeyondToday
}



const getValidateEmail = (errorMessage) => {

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // const re2 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    // https://www.w3resource.com/javascript/form/email-validation.php
    const validateEmail = (val, name, formState) => {

        //if (formState.debtorType.toLowerCase() !== 'privat')
        //    return ''
        if (val === '') {
            return ''
        } else{
            if (re.test(val)) {
                return ''
            }
        }

        return errorMessage
    }

    return validateEmail
}

const getIsRequired = (errorMessage) => {

    const isRequired = (val, name, formState) => {
        //console.log ('is not blank', val)
        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getIsRequiredIfDebtorType = (errorMessage, debtorType) => {

    const isRequired = (val, name, formState) => {

        // Annen type enn næreing/privat ?
        if (formState.debtorType.toLowerCase() !== debtorType.toLowerCase())
            return ''
        
        //console.log ('is not blank', val)
        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getMinLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {

        if (!val) {
            return ''
        }
        else if (val.length >= length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

// if (formState.debtorType === 'privat')
const getMaxLenghtName = (errorMessage) => {

    const isRequired = (val, name, formState) => {

        let len = 40
        if (formState.debtorType.toLowerCase() === 'privat') {
            len = 80
        }

        if (!val) {
            return ''
        }
        else if (val.length <= len) {
            return ''
        }
        else {
            return errorMessage + len
        }
    }

    return isRequired
}



const getMaxLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {

        if (!val) {
            return ''
        }
        else if (val.length <= length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}


// Validators ved eksisterende debitor
export const GetFormValidators = (messages) => {
    const produktTypeIsRequired = getIsRequired(messages['produktTypeisRequired'])
    const referanseNummerIsRequired = getIsRequired(messages['referanseNummerIsRequired'])

    const formValidatorsExist = {
        produktType: [
            produktTypeIsRequired,
        ],
        referanseNummer: [
            // Ikke lenger påkrevd
            //referanseNummerIsRequired,
        ],
    }

    return formValidatorsExist
}

// Validators ved ny debitor
export const GetFormValidatorsNew = (messages) => {

    const referanseNummerIsRequired = getIsRequired(messages['referanseNummerIsRequired'])
    const produktTypeIsRequired = getIsRequired(messages['produktTypeisRequired'])

    const debtorTypeIsRequired = getIsRequired(messages['debtorTypeisRequired'])
    
    const postalAddressIsRequired = getIsRequired(messages['postalAddressisRequired'])
    const postalCodeIsRequired = getIsRequired(messages['postalCodeisRequired'])
    const postalPlaceIsRequired = getIsRequired(messages['postalPlaceisRequired'])

    const debtorNameisRequired = getIsRequiredIfDebtorType(messages['debtorNameisRequired'], 'næring')
    const firstNameisRequired = getIsRequiredIfDebtorType(messages['firstNameisRequired'], 'privat')
    const lastNameisRequired = getIsRequiredIfDebtorType(messages['lastNameisRequired'], 'privat')

    const minLength3 = getMinLenght(messages['minLength3'], 3)
    const minLength9 = getMinLenght(messages['notValidOrgnr'], 11)
    const minLength10 = getMinLenght(messages['minLength10'], 10)
    const maxLenghtName = getMaxLenghtName(messages['maxLength'])

    const maxLength80 = getMaxLenght(messages['maxLength'] + '80', 80)
    const maxLength40 = getMaxLenght(messages['maxLength'] + '40', 40)
    const maxLength63 = getMaxLenght(messages['maxLength'] + '63', 63)

    const validEmail = getValidateEmail(messages['notValidEmail'])

    const validOrgNr = getValidateOrgNr(messages['notValidOrgnr'])
    const isBeyondToday = getIsBeyondToday(messages['isBeyondToday'], 10)
    const isNotToOld = getIsNotToOld(messages['isTooOld'], 10)

    const validPnr = getValidatePnr('nope');

    const formValidators = {
        referanseNummer: [
            // Ikke lenger påkrevd
            //referanseNummerIsRequired,
        ],
        produktType: [
            produktTypeIsRequired,
        ],
        debtorType: [
            debtorTypeIsRequired,
        ],
        birthDate: [
            isBeyondToday,
            minLength10,
            isNotToOld,
        ],
        birthNumber: [
            validPnr
        ],
        streetAddress: [
            maxLength63
        ],
        postalAddress: [
            postalAddressIsRequired,
            maxLength63
        ],
        postalCode: [
            postalCodeIsRequired
        ],
        postalPlace: [
            postalPlaceIsRequired
        ],
        debtorName: [
            debtorNameisRequired,
            minLength3,
            maxLenghtName,
        ],
        firstName: [
            firstNameisRequired,
            minLength3,
        ],
        lastName: [
            lastNameisRequired,
            minLength3,
        ],
        debtorIdentificationNumber: [
            minLength9,
        ],
        email: [
            validEmail,
        ]

    }

    return formValidators
}


