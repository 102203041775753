export const digits_only = string => [...string].every(c => '0123456789'.includes(c));


export const check_personalnumber_no = (pno) => {
    // Check length
    if (pno.length != 11)
        return false;

    // Split
    let day = pno.substr(0, 2);
    let month = pno.substr(2, 2);
    let year = pno.substr(4, 2);
    let ind = pno.substr(6, 3);
    let c1 = pno.substr(9, 1);
    let c2 = pno.substr(10, 1);
    let yearno = parseInt(year);

    if (ind > 0 && ind < 500) {
        yearno += 1900;
    } else if (ind > 499 && ind < 750 && year > 55 && year < 100) {
        yearno += 1800;
    } else if (ind > 499 && ind < 999 && year >= 0 && year < 40) {
        yearno += 2000;
    } else if (ind > 899 && ind < 999 && year > 39 && year < 100) {
        yearno += 1900;
    } else {
        return false;
    }

    let d1 = parseInt(day.substr(0, 1));
    let d2 = parseInt(day.substr(1, 1));
    let m1 = parseInt(month.substr(0, 1));
    let m2 = parseInt(month.substr(1, 1));
    let a1 = parseInt(year.substr(0, 1));
    let a2 = parseInt(year.substr(1, 1));
    let i1 = parseInt(ind.substr(0, 1));
    let i2 = parseInt(ind.substr(1, 1));
    let i3 = parseInt(ind.substr(2, 1));

    // Calculate control check c1
    let c1calc = 11 - (((3 * d1) + (7 * d2) + (6 * m1) + m2 + (8 * a1) + (9 * a2) + (4 * i1) + (5 * i2) + (2 * i3)) % 11);
    if (c1calc === 11)
        c1calc = 0;
    if (c1calc === 10)
        return false;
    if (c1 !== c1calc.toString())
        return false;

    // Calculate control check c2
    let c2calc = 11 - (((5 * d1) + (4 * d2) + (3 * m1) + (2 * m2) + (7 * a1) + (6 * a2) + (5 * i1) + (4 * i2) + (3 * i3) + (2 * c1calc)) % 11);
    if (c2calc === 11)
        c2calc = 0;
    if (c2calc === 10)
        return false;
    if (c2 !== c2calc.toString())
        return false;

    return true;
}



// https://github.com/tompi/novalidation/blob/master/src/novalidation.js

const mod11 = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

export const sjekkMod11 = (nummer, vekter) => {
    let tempk = 0;
    for (let i = 0; i < nummer.length - 1; i++) {
        tempk += nummer[i] * vekter[i];
    }
    tempk = 11 - (tempk % 11);
    if (tempk === 11) tempk = 0;
    if (tempk !== parseInt(nummer[nummer.length - 1], 10)) return false;
    return true;
}

export const bankKontoNummer = function (kontoNummer) {
    let fnrOgBankRegEx = /^[0-9]{11}$/;
    if (!fnrOgBankRegEx.test(kontoNummer)) return false;
    return sjekkMod11(kontoNummer, mod11);
};

export const orgNummer = function (orgNummer) {
    let org = [3, 2, 7, 6, 5, 4, 3, 2];
    let orgNrRegEx = /^[0-9]{9}$/;
    if (!orgNrRegEx.test(orgNummer)) return false;
    return sjekkMod11(orgNummer, org);
};


// Svensk og dansk telefonnr validering i profil og brukeradmin
export const getValidatePhoneIntl = (errorMessage) => {

    const onlyContainsNumbers = (str) => /^\d+$/.test(str);

    const validatePhoneIntl = (val, name, formState) => {
        let _val = val
            .replace(/\s/g, '') 
            .replace('-', '') 

        // Space tom streng
        if (!_val) {
            return ''
        }

        // Check swedish regex
        if (_val.match(/^([+]460)(7[0236])(\d{4})(\d{3})$/)) {
                //alert("match!");
                return ''
        
            //^\+46 *7[0236] *\d{4} *\d{3}$
            //+46 076-790 0078
        }

        // Må ha korrekt antall siffer
        if (_val.length === 8) {
            if (onlyContainsNumbers(_val))
                return ''
        }
        if (_val.length === 10) {
            if (onlyContainsNumbers(_val))
                return ''
        }
        if (_val.length === 11) {
             let plus = _val.substring(0, 1)
            let digts = _val.substring(1, 11)
            if (((plus) === '+') && (onlyContainsNumbers(digts)))
                return ''
        }
        if (_val.length === 12) {
            let plus = _val.substring(0, 1)
           let digts = _val.substring(1, 11)
           if (((plus) === '+') && (onlyContainsNumbers(digts)))
               return ''
               
       }

        return errorMessage

    }

    return validatePhoneIntl
}

export const isValidphone = val => {

    if (val.replace(' ', '').length < 8)
        return false

    if ([...val].every(c => {
        //console.log(c)
        return ' 0123456789'.includes(c)
    })) {
        return true
    }

    return false
}

export const isValidCellPhone = val => {

    if (!isValidphone(val)) {
        return false
    }

    let x = val.replace(/ /g, '').length
    if (val.replace(/ /g, '').length !== 8) {
        return false
    }
        
    let firstChar = val.substring(0, 1)
    if ((firstChar === '9') || (firstChar === '4'))
        return true

    return false
}

export const emailIsValid = (email) => {
    //return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    return isValidEmail(email)
}

export const isValidEmail = (val) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(val)) {
        return true
    }

    return false
}
