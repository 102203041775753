/*

How to:

1. Denne modulen skal kjøres i kontaineren.
2. Den bruker lokal state for å angi hvilket vindu som evt skal vises, må eksporteres til containeren
3. Containeren skal importere komponent for modale 
4. Den må eksportere alle metoder som skal benyttes av de modale, 
   disse sendes inn som properties i fra containeren
5. Ideelt bør en trukket sak fjernes fra sakslisten/endre status?

*/

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosTokenInstance from "../../../Instances/axiosTokenInstance";
import LocaleContext from "../../../state/contexts/LocaleContext";
import { messages } from './fakturaUpload.i18n'
import _ from 'lodash'
import { useSelector } from 'react-redux';

export const useFakturaUploadModal = (setErrorMessage, setMessage, setShowSpinner) => {
    
    const locale = useContext(LocaleContext);
    const localeMessages = messages[locale]

    const headerState = useSelector(state => state.sakslisteHeader)
        
    const showToasts = true;

    // Styring av modale og spinner
    const [dialogVisible, setDialogVisible] = useState(false)
      
    // Parametre til rest kallene
    const [creditorNo, setCreditorNo] = useState('')
    const [caseNo, setCaseNo] = useState('')

    const [creditorName, setCreditorName] = useState('vent litt')
    const [debitorNo, setDebitorNo] = useState('')
    const [debitorName, setDebitorName] = useState('')
    const [selectedUploadFile, setselectedUploadFile] = useState([])
    const [fakturaId, setFakturaId] = useState('')
    const [fakturaListe, setFakturaListe] = useState([])       
     
    // RESETS MODAL BOXES AND STATE
    const resetModal = () => {
        
        setErrorMessage('')
        setMessage('')

        setDialogVisible(false)
        setShowSpinner(false)
    }
    
    useEffect(() => {
        // TODO Reset
        resetModal()
        
    }, [creditorNo, caseNo])

    // TRIGGER FOR MODAL SEQUENCE
    const showError = (errorMessage) => {
        (showToasts && toast.error(errorMessage, {autoClose: false}))
        setErrorMessage(errorMessage)
        console.error('TrekkSakModal: ', errorMessage)
    }

    const showMessage = (message) => {
        (showToasts && toast.info(message))
        setMessage(message)
        console.log('TrekkSakModal: ', message)
    }

    const getCreditorNameFromState = (crNo) => {
        setCreditorName('Name not available...')
        let tt = _.find(headerState.kreditorliste, (d) => {
            let indKred = d.ind.split(';')[1]
            if (indKred === crNo) {
                setCreditorName(d.disp)
                return true
            }           
        })       
    }

    const showDialogHandler = (creditorNo, caseNo, debitorNo, debitorNavn) => {
        setErrorMessage('')
        setMessage('')

        setCreditorNo(creditorNo)
        setCaseNo(caseNo)
        setDebitorNo(debitorNo)
        setDebitorName(debitorNavn)
        getCreditorNameFromState(creditorNo)               
        setFakturaId('-1')
        hentFakturaer(caseNo, creditorNo)
        
        setselectedUploadFile([])
        setDialogVisible(true)
    }

    const cancelHandler = e => {        
        setDialogVisible(false)
        setShowSpinner(false)
    } 

    const getErrorMessage = (err) => {
        if (_.has(err, 'response.data.title')) {
            return err.response.data.title
        }
        else if (_.has(err, 'response.data'))
        {
            return err.response.data
        }
        else if (_.has(err, 'response.statusText')) {
            return err.response.statusText
        }
        else
        {
            return err.message.toString()
        }
    }

    const onUploadFileChange = event => {
        setselectedUploadFile(event.target.files[0])
        //console.log(event.target.files[0])
    };

    const onFakturaIdChange = event => {
        setFakturaId(event.target.value)
        //console.log('onFakturaIdChange', event.target.value)
    };
    
    const validates = () => {        
        return (
            (selectedUploadFile)
            && (selectedUploadFile.name)
            && fakturaId) ?
            '' : 'disabled'            
        }
 
        
    const hentFakturaer = (pCaseNo, pCreditorNo) => {

        const getFakturalisteError = localeMessages['getFakturalisteError']
        const getFakturalisteOk = localeMessages['getFakturalisteOk']

        setShowSpinner(true)
        
        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/sak/FakturalisteForSak?caseNumber=${pCaseNo}&creditorNo=${pCreditorNo}`,
            })
            .then((result) => {
                console.log(`/api/sak/FakturalisteForSak?caseNumber=${pCaseNo}&creditorNo=${pCreditorNo}`, result)

                setShowSpinner(false)
                
                if (result.status !== 200) {
                    showError(getFakturalisteError)   
                } else {

                    toast.info(getFakturalisteOk)
                    
                    //"[{\"balanceCapital\":6336.0,\"balanceInterest\":11.09,\"caseNumber\":\"3S56S3\",\"curreny\":\"NOK\",\"description\":\"Utgående faktura\",\"dueDate\":\"2020-12-28Z\",\"interestRate\":7.0,\"invoiceDate\":\"2020-12-18Z\",\"invoiceNumber\":\"1020161\",\"lineNumber\":1,\"originalCapital\":6336.0,\"paidCapital\":0.0}]"
                    let resObj = JSON.parse(result.data)
                    let invoiceList = resObj.map(
                        item => {
                            const container = {};
                            container.invoiceNumber = item.invoiceNumber
                            container.description = item.description
                            return container;
                        }       
                    )

                    setFakturaListe(invoiceList)
                    setDialogVisible(true)
                    
                }
            })

            .catch((err) => {
                setShowSpinner(false)
                if (_.has(err, 'response.data')) {
                    showError(getFakturalisteError  + ' ' + err.response.data)                    
                } else {
                    showError(getFakturalisteError  + ' ' + err.message)       
                }
            })
        
    }

    const okHandler = () => {

        const postFakturaUploadOk = localeMessages['postFakturaUploadOk']
        const postFakturaUploadError = localeMessages['postFakturaUploadError']

        const formData = new FormData();

        // Update the formData object
        formData.append(
            "files",
            selectedUploadFile,
            selectedUploadFile.name
        );
        formData.append('fakturanr', fakturaId)
        formData.append('caseNumber', caseNo)
        formData.append('crNo', creditorNo)

        console.log(JSON.stringify(formData))

        setShowSpinner(true)
        
        axiosTokenInstance
            ({
                method: 'POST',
                url: `api/sak/UploadFakturaer`,
                data: formData
            })
            .then((result) => {
                console.log(`/api/sak/UploadFakturaer/${creditorNo}/${caseNo} returnerte:`, result)
            
                setDialogVisible(false)
                setShowSpinner(false)
            
                if (result.status !== 200) {
                    showError(postFakturaUploadOk)         
                    
                } else {
                    if (result.data.errorCode === "000") {
                        showMessage(`${postFakturaUploadOk}  (saksnr: ${caseNo})`)
                    } else {
                        showError(postFakturaUploadOk + ' ' + result.data.errorMessage)                        
                    }                                      
                }
            })
            .catch((err) => {
                setShowSpinner(false)
                setDialogVisible(false)
                showError(postFakturaUploadError + ' ' + getErrorMessage(err))
            })
    }


    return {
        okHandler, cancelHandler, showDialogHandler, dialogVisible, onUploadFileChange, onFakturaIdChange,
        fakturaId, creditorName, creditorNo, debitorName, debitorNo, validates,
        fakturaListe, localeMessages
    }

}
