import React, { useEffect, useState } from "react";
import { FormattedDate, FormattedNumber } from 'react-intl'
import Workbook from 'react-excel-workbook'
import { fixMissingTimeFromDate } from "../../../util/date/dateRoutines";
import { BfButtonBlue } from "../../../Styling/styledForm/styledBlueForm";

export const SelectedExcelSheet = (props) => {

    const asDateForExcel = (val) => {
        if (val !== null && val !== undefined) {
            let splittedDate = val.replace(' ', '-').split('-');
            let splittedDay = splittedDate[2].replace(' ', 'T').split('T');
            let ExcelDate = splittedDay[0] + '.' + splittedDate[1] + '.' + splittedDate[0];
            return ExcelDate
        } else {
            return ''
        }
    }

    const getCurrentDate = () => {
        const currentDate = new Date();
        // Format the date as needed (e.g., "dd.mm.yyyy")
        const formattedDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`;
        return formattedDate;
    };


    const asExcelNum = (val) => {
        return val
        return val.toString().replace(',', '.')
    }

    const getFileName = () => `${props.headerState.kreditorName}.xlsx`

    return (

        <Workbook
            filename={getFileName()}
            element={<BfButtonBlue>Excel valgte</BfButtonBlue>}>

            <Workbook.Sheet data={props.data} name={props.filename}>


                <Workbook.Column label={props.localeMessages['GHS_customer_ref']} value="customerNo" />
                <Workbook.Column label={props.localeMessages['GHS_measuring_ID']} value="meeterPointId" />
                <Workbook.Column label={props.localeMessages['GHS_customer_name']} value="debtorName" />
                <Workbook.Column label={props.localeMessages['GHS_utility_address']} value="utilityAddress" />
                <Workbook.Column label={props.localeMessages['GHS_closing_amount']} value="remainingTotal" />
                <Workbook.Column label={props.localeMessages['GHS_date_closingList']} value={row => asDateForExcel(row.regDate)} />
                <Workbook.Column label={props.localeMessages['GHS_selected_date']} value={row => getCurrentDate()} />


            </Workbook.Sheet>

        </Workbook>

    )
}