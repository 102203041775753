import styled from 'styled-components'
import colorConfig from '../../colorConfig'

const Header2Area = styled.div`    
    padding: 37px 50px 15px 50px;    
    background-color: ${colorConfig.header_bg};
    @media print { 
      display: none      
    }
 `

const Header2Left = styled.div`  
  float: left;  
`

const Header2Right = styled.div`  
 float: right;  
`
const Header2Clear = styled.div`  
  clear: both; 
`



export {Header2Area, Header2Left, Header2Right, Header2Clear}