import React, {useState, useEffect, useRef } from 'react'
import { Tabs, Tab, Container, Col, Alert } from "react-bootstrap";
import LocaleContext from '../../state/contexts/LocaleContext'

import { BlueRow } from '../../Styling/styledForm/styledBlueForm';
import UploadComponent from './components/UploadComponent';
import { useFilbehandlingsContainer } from './useFilbehandlingsContainer'
import FilListe from './components/FillisteComponent';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';
import { TabWrapper } from '../../components/tabs/TabWrapper';




const FilebehandlingContainer = (props) => {

    const locale = React.useContext(LocaleContext);
    
    const { localeMessages, key, setKey, selectedKreditor, files, clearAll, clearSingle, onDrop, upload, state } = useFilbehandlingsContainer(locale)
    
    // Tre tabs mottatte sendte og upload
    return (

        <>        
            
            {(state.error) &&
                <Alert variant='danger'>
                    {state.error}
                </Alert>}

            {state.loading && <OverlaySpinner /> }

            {!state.loading &&
                <Container fluid="true">

            
                        <Col xs="12">
                            <TabWrapper>

                                <Tabs id="filbehandlingsTab" activeKey={key} onSelect={(k) => setKey(k)} style={{ backgroundColor: "white", fontWeight: "400", "color": "rgb(0, 62, 96)" }} >
                                    <Tab eventKey="sendteFilerTab" title={localeMessages['TaC_T2']}>
                                        <FilListe
                                            retning={'sendt'}
                                            localeMessages={localeMessages}
                                            tableData={state.data.sendteFiler}
                                            selectedKreditor={selectedKreditor}
                                        />
                                    </Tab>
                                    <Tab eventKey="mottatteFilerTab" title={localeMessages['TaC_T1']}>
                                        <FilListe
                                            retning={'motatt'}
                                            localeMessages={localeMessages}
                                            tableData={state.data.mottatteFiler}
                                            selectedKreditor={selectedKreditor}
                                        />
                                    </Tab>


                                <Tab eventKey="UploadTab" title={localeMessages['TaC_T3']}>
                                    
                                    <UploadComponent                                            
                                            localeMessages={localeMessages}
                                            selectedKreditor={selectedKreditor}
                                            files={files}
                                            upload={upload}
                                            clearSingle={clearSingle}
                                            clearAll={clearAll}
                                            onDrop={onDrop}
                                        />
                                
                                    </Tab>

                                </Tabs>
                            </TabWrapper>
                        </Col>
            
              

                    

                </Container>}
    </>

         

    )
}

export default FilebehandlingContainer
