import * as accessLevels from '../../../Utility/Types/roleTypes'
import { getValidatePhoneIntl } from '../../../util/validering/valideringsRutiner'

const getIsRequired = (errorMessage) => {

    const isRequired = (val, name, formState) => {
        //console.log ('is not blank', val)
        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getIsBisNodeRequired = (errorMessage) => {

    const isBisNodeRequired = (val, name, formState) => {
        
        if (!formState.bisNodeEnabled) {
            return ''
        }

        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isBisNodeRequired
}

const getMinLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {
        
        if (val.length >= length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getNewPassWordRequired = (errorMessage) => {
    
    const newPassWordRequired = (val, name, formState) => {        
        
        if ((formState.newUser) && (!val)) {
            return errorMessage
        }

        if ((formState.oldPassword) && (!val)) {
            return errorMessage
        }
        return ''
    }
    
    return newPassWordRequired
}

const  getCheckEqualPasswords = (errorMessage) => {

    const checkequalPasswords = (val, name, formState) => {
        //console.log('Password1', val)
        //console.log('Password2', formState.newPassword1)

        if (val !== formState.newPassword1) {
            return errorMessage
        }
        return ''
    }

    return checkequalPasswords
}

const getValidatePassword = (errorMessage) => {

    const validatePassword = (val, name, formState) => {

        // (?=.*?[#?!@$%^&*-])

        let errors = false;
        
        // 8 Tegn
        if (val.length < 8) {            
            errors = true;
        }

        // minst en bokstav
        if (val.search(/[a-z]/) < 0) {            
            errors = true
        }

        // minst en stor bokstav
        if (val.search(/[A-Z]/) < 0) {            
            errors = true
        }

        // Minst ett tall
        if (val.search(/[0-9]/) < 0) {            
            errors = true
        }

        // Minst ett spesialtegn
        if (val.search(/[#?!@$%^&*-]/) < 0) {
            errors = true
        }

        if (errors) {            
            return errorMessage            
        }
        return '';
    }

    return validatePassword
}

const getValidateEmail = (errorMessage) => {

    //const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const re2 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
     //   return re.test(email.toLowerCase());
    //}

    // https://www.w3resource.com/javascript/form/email-validation.php
    const validateEmail = (val, name, formState) => {
        if (re.test(val)) {
            return ''
        }
        
        return errorMessage
    }

    return validateEmail
}

export const foreningIsRequired = (formState, messages) => {

    let errorMessage = messages['foreningIsRequired']
    if (formState.rolle !== accessLevels.SUPERVISOR) {
        //console.log ('is not blank', val)
        if (formState.forening) {
            return ''
        }
        else {
            return errorMessage
        }
    }
    return ''
}

export const kreditorIsRequired = (formState, messages) => {

    // Legg inn sjekk på bare en
    let errorMessage = messages['kreditorsIsRequired']

    if (formState.rolle === '') {
        return ''
    }    
    const harKreditors = [accessLevels.CUSTOMERADMIN, accessLevels.USER]

    if (harKreditors.some(e => e === formState.rolle)) {
        if (formState.kreditorListe.length === 0) {
            return errorMessage
        }
        else {
            return ''
        }
    }
    else {
        if (formState.kreditorListe.length !== 0) {
            return errorMessage
        }
        else {
            return ''
        }
    }
    
    return ''
}


export const GetFormValidators = (messages) => {
    const fullNameisRequired = getIsRequired(messages['fullNameisRequired'])
    const checkEqualPasswords = getCheckEqualPasswords(messages['checkEqualPasswords'])
    const newPassWordRequired = getNewPassWordRequired(messages['newPassWordRequired'])
    const validatePassword = getValidatePassword(messages['validatePassword'])
    const validateEmail = getValidateEmail(messages['validateEmail'])
    const minLength5 = getMinLenght(messages['minLength5'], 5)
    const bisNodeNameIsRequired = getIsBisNodeRequired(messages['bisNodeNameIsRequired'])
    const bisNodePassIsRequired = getIsBisNodeRequired(messages['bisNodePassIsRequired'])
    const rolleIsRequired = getIsRequired(messages['rolleIsRequired'])
    const validatePhone = getValidatePhoneIntl(messages['GyldigMobil'])

    const formValidators = {
        username: [
            validateEmail
        ],
        fullName: [
            fullNameisRequired,
            minLength5
        ],
        phoneNumber: [
            validatePhone,
        ],
        newPassword1: [
            newPassWordRequired,
            validatePassword
        ],
        newPassword2: [
            newPassWordRequired,
            checkEqualPasswords
        ],
        bisNodeUser: [
            bisNodeNameIsRequired,
        ],
        bisNodePassword: [
            bisNodePassIsRequired
        ],
        rolle: [
            rolleIsRequired,
        ], 
        forening: [
            //foreningIsRequired,
        ]

    }  
    
    return formValidators
}


