import { useState, useEffect } from 'react';
import Axios from 'axios'
import { useUcForm } from '../../hooks/useUcForm'
import { onBoardingFormValidators } from './onBoardingFormValidators'
import  axiosTokenInstance from '../../Instances/axiosTokenInstance'
import { toast } from 'react-toastify';
import { getFromSession, setSessionStorage, removeFromSession, restoreFormFromStorage } from '../../state/storageUtil'
import _ from 'lodash'

export const useOnBoarding = (localeMessages) => {

    const strToInt = v => parseInt(v)
    const strToBool = v => v === '1'

    const showToasts = true

    const [isLoading, setIsLoading] = useState(false)
    const [pageHasError, setPageHasError] = useState('')
    const [savedOk, setSavedOk] = useState('')
    const [financialSystems, setfinancialSystems] = useState([])

    let formValues = {
        orgnr: '',
        firmanavn: '',
        adresse: '',
        postnr: '',
        poststed: '',
        firmaepost: '',
        kontonr: '',
        brukernavn: '',
        brukerid: '',
        telefonnr: '',
        passord: '',
        gjentapassord: '',
        okonomisystem: '',
        momsPliktig: '',
        prodType: '',
        kommentar: ''        
    }

    const ClearFormValues = () => {
        formValues = {
            orgnr: '',
            firmanavn: '',
            adresse: '',
            postnr: '',
            poststed: '',
            firmaepost: '',
            kontonr: '',
            brukernavn: '',
            brukerid: '',
            telefonnr: '',
            passord: '',
            gjentapassord: '',
            okonomisystem: '',
            momsPliktig: '',
            prodType: '',
            kommentar: ''
    
        }
    
    }


    const getValuesFromStorage = () => {

        let savedFormItems = getFromSession('newCreditor');
        if (savedFormItems) {
            let _formValues = JSON.parse(savedFormItems)
            Object.keys(_formValues).forEach(e => {

                //console.log(`key=${e}  value=${obj[e]}`)
                if (_formValues[e]) {
                    setField(e, _formValues[e])
                }
            });
            setField()
        }
    }

    const formValidators = onBoardingFormValidators(localeMessages)

    const {
        handleChange, handleCheckboxChange, setField, handleMultiSelectChange, formState, validateField,
        validateAll, errorMessages, formIsValid,
        validatorsState } = useUcForm(formValues, formValidators)

    const [extraErrors, setExtraErrors] = useState({})
    const hasErrors = Object.values(errorMessages).some(x => (x !== null && x !== '')) || Object.values(extraErrors).some(x => (x !== null && x !== ''));

    useEffect(() => {
        setfinancialSystems(getFinancialSystems())
        restoreFormFromStorage('newCreditor', setField)
        //getValuesFromStorage()
    }, []); 

    const getFinancialSystems = () => {
     
        setIsLoading(true)
        setPageHasError('')

        const setComboVals = (finsysArr) => {
            let result = []                        
            for (let i = 0; i < finsysArr.length; i++) {
                const element = finsysArr[i];
                result.push({ id: element.systemId, value: element.systemDescr})
            }            
            setfinancialSystems(result)
        }

        axiosTokenInstance
            ({
                method: 'GET',
                url: `/api/Onboarding/getFinancialSystem`
            })
            .then((result) => {

                setIsLoading(false)
                setPageHasError('')

                if (result.status !== 200) {
                    (showToasts && toast.error(localeMessages['toastFinancialError'], {autoClose: false}))
                    setPageHasError(localeMessages['toastFinancialError'])
                }
                else if (result.data.length > 0) {
                    (showToasts && toast.info(localeMessages['toastFinancialSystems']))                                        
                    setComboVals(JSON.parse(result.data)) 
                    removeFromSession('newCreditor')
                } else {
                    (showToasts && toast.warning(localeMessages['toastFinancialError']))
                    setPageHasError(localeMessages['toastFinancialError'])                    
                }
            })
            .catch((err) => {
                (showToasts && toast.error(localeMessages['toastFinancialError'] + ' ' + err.message, {autoClose: false}))
                console.log(err)
                setIsLoading(false)
                setPageHasError(localeMessages['toastFinancialError'] + ' ' + err.message)                
            })        
               
    }

    /*
    https://data.brreg.no/enhetsregisteret/api/docs/index.html#enheter-oppslag
    */
    const getFirmaInfo = () => {

        const orgnr = formState.orgnr
        setIsLoading(true)
        setPageHasError('')
        //dispatch({ type: actions.LOADING, data: null })

        //console.log("updateUser", JSON.stringify(userData))

        Axios
            ({
                method: 'GET',
                url: `https://data.brreg.no/enhetsregisteret/api/enheter/${orgnr}`
            })
            .then((result) => {
                setIsLoading(false)
                setPageHasError('')
                setSavedOk('')
                console.log(result)

                if (result.status !== 200) {
                    (showToasts && toast.error(localeMessages['toastFirmainfoError'], {autoClose: false}))                    
                    //setPageHasError(localeMessages['toastFirmainfoError'], + ', statuscode: ' + result.status)                    
                } else {
                    (showToasts && toast.warning(localeMessages['toastFirmaIntoHentet']))
                    console.log(JSON.stringify(result.data))
                    setField('firmanavn', result.data.navn)
                    if ( result.data.forretningsadresse.adresse.length > 0) {
                        setField('adresse', result.data.forretningsadresse.adresse[0])
                    }
                    setField('postnr', result.data.forretningsadresse.postnummer)
                    setField('poststed', result.data.forretningsadresse.poststed)    
                    if (result.data.registrertIMvaregisteret === true) {
                        setField ('momsPliktig', '1')
                    } else {
                        setField('momsPliktig', '0')
                    }
                        
                    setPageHasError('')                            
                }                
            })
            .catch((err) => {
                setIsLoading(false)                
                setPageHasError(localeMessages['toastFirmainfoError'], + ', statuscode: ' + err.message)                
                console.log(err)                
            })

    }

    const submitForm = () => {

        let formDontValidate = validateAll();

        // Sjekk felter som ikke valideres på cursor
        setExtraErrors({})
        
        // Test på om det finnes error
        if (formDontValidate) {
            console.log('cannot be submitted')
        } else {
            console.log('Canbesubmitted')

            setIsLoading(true)
            setPageHasError('')
            setSavedOk('')

            //console.log("updateUser", JSON.stringify(userData))
            
            let submitData = {
                "KosCreditor": {
                    "AccountNo": formState.kontonr,
                    "ActorId": 0,
                    "ClientSystem": formState.okonomisystem,
                    "Comment": formState.kommentar,
                    "CorporateGroup": null,
                    "CountryCode": "NO",
                    "CreateWsUser": false,
                    "CreatePortalUser": true,
                    "CreditorNo": "",
                    "DefaultProductNo": parseInt(formState.prodType),
                    //"DepartmentId": parseInt(formState.forening),
                    "Email": formState.firmaepost, // firmaepost
                    "ErroCode": "",
                    "ErrorMessage": "",
                    "LanguageCode": "NB",
                    "Name": formState.firmanavn,
                    "OrgNo": formState.orgnr,
                    "PostalAddress": formState.adresse,
                    "PostalNumber": formState.postnr,
                    "PostalPlace":  formState.poststed,
                    "VatRequired": strToBool(formState.momsPliktig),
                    "WebDisplayName": formState.brukernavn, //EPOST
                    "WebPassword": formState.passord,
                    "WebUserName": formState.brukerid,  // FULLT Navn
                },
                "sendEmail": true
            }

            console.log (JSON.stringify(submitData))


            axiosTokenInstance
                ({
                    method: 'POST',
                    url: `/api/Onboarding/NewCreditor`,
                    data: submitData 
                })
                .then((result) => {
                    setIsLoading(false)
                    setPageHasError('')
                    setSavedOk('')
                    console.log(result)

                    if (result.status !== 200) {
                        (showToasts && toast.error(localeMessages['toastSaveError'], {autoClose: false}))                        
                        setPageHasError(localeMessages['toastSaveError'])                    
                    } else {
                        (showToasts && toast.warning(localeMessages['toastSaveOk']))
                        console.log(JSON.stringify(result.data))
                        setSavedOk(localeMessages['toastSaveOk'])
                        ClearFormValues()
                        setPageHasError('')
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    //loadash is better than if (err.response.status === 400) {
                    if (_.has(err, 'response.data')) {   
                        setPageHasError(localeMessages['toastSaveError'] + ', ' + err.response.data)
                        console.log(err)
                    } else {
                        setPageHasError(localeMessages['toastSaveError'] + ', statuscode: ' + err.message)
                        console.log(err)
                    }                    
                })            
        }
       
    }

    const handleChangeProxy = e => {      
        setSessionStorage('newCreditor', JSON.stringify(formState).toString())
        handleChange(e)
    }

    return { formValues, formValidators, handleChangeProxy, formState, validateAll, hasErrors, savedOk, errorMessages, extraErrors, setExtraErrors, getFirmaInfo, submitForm, financialSystems,  isLoading, pageHasError }
}

/*
Eksempel på brønnøysund data

{
    "organisasjonsnummer": "924816937",
    "navn": "ULRIKEN CONSULTING AS",
    "organisasjonsform": {
        "kode": "AS",
        "beskrivelse": "Aksjeselskap",
        "_links": { "self": { "href": "https://data.brreg.no/enhetsregisteret/api/organisasjonsformer/AS" } }
    },
    "registreringsdatoEnhetsregisteret": "2020-03-23",
    "registrertIMvaregisteret": true,
    "naeringskode1": {
        "beskrivelse": "Konsulentvirksomhet tilknyttet informasjonsteknologi",
        "kode": "62.020"
    },
    "antallAnsatte": 36,
    "forretningsadresse": {
        "land": "Norge",
        "landkode": "NO",
        "postnummer": "5008",
        "poststed": "BERGEN",
        "adresse": ["Nygårdsgaten 114"],
        "kommune": "BERGEN",
        "kommunenummer": "4601"
    },
    "stiftelsesdato": "2020-03-01",
    "institusjonellSektorkode": {
        "kode": "2100",
        "beskrivelse": "Private aksjeselskaper mv."
    },
    "registrertIForetaksregisteret": true,
    "registrertIStiftelsesregisteret": false,
    "registrertIFrivillighetsregisteret": false,
    "konkurs": false,
    "underAvvikling": false,
    "underTvangsavviklingEllerTvangsopplosning": false,
    "maalform": "Bokmål",
    "_links": { "self": { "href": "https://data.brreg.no/enhetsregisteret/api/enheter/924816937" } }
}
*/



