import { actions } from "./kredSelActions"

const initialState = {
    creditors: [],
    selectedKreditor: '-1',
    forening: -1,
    fetched: false,
    loading: false,
    error: ''
}

const kredSelReducer = (state = initialState, action) => {

    switch (action.type) {

        case actions.KREDSEL_LOADING:
            return {
                ...state,
                fetched: false,
                loading: true,
                error: null
            };

        case actions.KREDSEL_DATA_FETCHED:
            return {
                ...state,
                creditors: [...action.payload.data],
                forening: action.payload.forening,
                fetched: true,
                loading: false,
                error: null
            };
    
        case actions.KREDSEL_SET_SELECTED:
            return {
                ...state,
                selectedKreditor: action.payload.data,
                fetched: true,
                loading: false,
                error: null
            };

        case actions.KREDSEL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.data
            }

        default:
            return state;
    }
}

export default kredSelReducer