import {
    FETCH_SBURLLISTE_BEGIN,
    FETCH_SBURLLISTE_FAILURE,
    FETCH_SBURLLISTE_SUCCESS
} from "./sbUrlListeActions"

const initialState = {
    items: null,
    fetched: false,
    loading: false,
    error: null
}

const sbUrlListeReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_SBURLLISTE_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        
        case FETCH_SBURLLISTE_SUCCESS:
            
            return {
                ...state,
                loading: false,
                fetched: true,
                items: action.payload.menuItems
            }
        
        case FETCH_SBURLLISTE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };
            
        default:
            return state
    }
}

export default sbUrlListeReducer