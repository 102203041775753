import React from "react";
import { Button } from "react-bootstrap";
import LocaleContext from "../../state/contexts/LocaleContext";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,

} from "../../Styling/styledModal/styledModals";
import { messages } from './LogoutWarningModal.i18n'


const LogoutWarningModal = (props) => {

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]   

    return (

        <StyledModal centered show={true}>

            <StyledModalBody>
                <h4>{localeMessages["TS1M_Overskrift"]}</h4>
                <p>{localeMessages["TS1M_message"]}</p>
            </StyledModalBody>

            <StyledModalFooter>
                <Button variant="secondary" onClick={props.refreshToken}>
                    {localeMessages["TS1M_Avbryt"]}
                </Button>
                {/* 
                <Button variant="secondary" onClick={props.closeLogoutWarning}>
                    {localeMessages["TS1M_Lukk"]}
                </Button>
                */}
            </StyledModalFooter>

        </StyledModal>
    );
};

export default LogoutWarningModal;