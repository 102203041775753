import React, {useState} from 'react'
import { toast } from 'react-toastify';
import { GetFormValidators, foreningIsRequired, kreditorIsRequired } from './editProfileValidators'

const useEditProfile = (auth, props, formState, forceRepaint, localeMessages, errorMessages, validateAll, clearField, deleteUser) => {

/* */
    
    const { VelgRolle, Systemadministrator, Foreningsadministrator, Konsernbruker, Bruker } = localeMessages
    

    const getRolleOption = () => {

        if (auth.isAdminKreditor()) {
            return (
                <>
                    <option value={auth.accessLevels.USER}>{Bruker}</option>
                </>
            )
        }

        if (auth.isForeningSupervisor()) {
            return (
                <>
                    <option value="">Velg</option>
                    <option value={auth.accessLevels.USER}>{Bruker}</option>
                    <option value={auth.accessLevels.CUSTOMERADMIN}>{Konsernbruker}</option>
                    <option value={auth.accessLevels.USERADMIN}>{Foreningsadministrator}</option>
                </>
            )
        }

        if (auth.isSupervisor()) {
            return (
                <>
                    <option value="">{VelgRolle}</option>
                    <option value={auth.accessLevels.USER}>{Bruker}</option>
                    <option value={auth.accessLevels.CUSTOMERADMIN}>{Konsernbruker}</option>
                    <option value={auth.accessLevels.USERADMIN}>{Foreningsadministrator}</option>
                    <option value={auth.accessLevels.SUPERVISOR}>{Systemadministrator}</option>
                </>
            )
        }
        
        return (

            <>
            <option value="">Har ikke tilgang til å opprette bruker</option>                               
            </>   
        )
    

    }

    const getForeningsOption = () => {

        if (auth.isSupervisor()) {
            return (
                <>
                    <option value="">Velg forening</option>                    
                    <option value="20">Kreditorforeningen SA</option>
                </>
            )
        } else {

            if (auth.forening == 20) {
                return (
                    <>                        
                        <option value="20">Kreditorforeningen SA</option>
                    </>
                )
            }
            
        }
    }

    // ------------------------------------------------------------------------------------
    // start Rutiner for håndtering av valgte kreditorer til og fra 
    // ------------------------------------------------------------------------------------ 

    const hasNode = (toSelect, chkOption) => {
        for (var i = 0; i < toSelect.options.length; i++) {
            var option = toSelect.options[i];
            if (option.value === chkOption.value) {
                return true
            }
        }
        return false
    }

    const addNode = (fromSelect, toSelect) => {

        // Note downward count, as we are removing items from the select
        for (var i = fromSelect.options.length - 1; i >= 0; i--) {

            var option = fromSelect.options[i];

            // check if selected
            if ((option.selected) && (option.value)) {

                // If already in receiver, just remove from origin
                if (hasNode(toSelect, option)) {
                    toast.error(`Har allerede tilgang til ${option.text}`, {autoClose: false})
                } else {
                    // unselect and transfer to list
                    option.selected = false;
                    formState.kreditorListe.unshift(
                        { kreditorId: option.value, kreditorNavn: option.text }
                    )
                    toast.info(`${option.text} lagt til`)
                    forceRepaint(Math.random())
                    //toSelect.focus()
                }
            }
        }
    }

    const removeNode = (fromSelect) => {

        // Note downward count, as we are removing items from the select
        for (var i = fromSelect.options.length - 1; i >= 0; i--) {

            var option = fromSelect.options[i];

            // check if selected
            if (option.selected) {
                // finn item i formState kreditorListe
                for (var j = formState.kreditorListe.length - 1; j >= 0; j--) {
                    if (formState.kreditorListe[j].kreditorId === option.value) {
                        formState.kreditorListe.splice(j, 1)
                        toast.info(`${option.text} fjernet`)
                        forceRepaint(Math.random())
                    }
                }
            }
        }
    }

    const FilteredForeningKreditorListe = () => {

        if (!props.foreningkreditorListe)
            return null

        if (formState.sokKreditor) {
            try {
                return (
                    <>
                        {props.foreningkreditorListe
                            .filter((item) => {
                                return item.disp.toLowerCase().includes(formState.sokKreditor.toLowerCase())
                            })
                            .map((item, i) => (
                                <option key={i} value={`${item.ind}`}>{item.disp}</option>
                            ))}
                    </>
                )
            } catch (e) {
                return (<option>Ingen kreditorer funnet</option>)
            }

        }

        return (
            <>
                {props.foreningkreditorListe.map((item, i) => (
                    <option key={i} value={`${item.ind}`}>{item.disp}</option>
                ))}
            </>
        )
    }

    const FilteredKreditorListe = () => {

        if (!formState.kreditorListe)
            return (
                <>
                    <option key={0}>(Ingen kreditorer)</option>
                </>
            )

        if (formState.filterKreditor) {
            return (
                <>
                    {formState.kreditorListe
                        .filter((item) => {
                            return item.kreditorNavn.toLowerCase().includes(formState.filterKreditor.toLowerCase())
                        })
                        .map((item, i) => (
                            <option key={i} value={`${item.kreditorId}`}>{item.kreditorNavn}</option>
                        ))}
                </>
            )
        }

        return (
            <>
                {formState.kreditorListe.map((item, i) => (
                    <option key={i} value={`${item.kreditorId}`}>{item.kreditorNavn}</option>
                ))}
            </>
        )
    }

    // ------------------------------------------------------------------------------------
    // slutt Rutiner for håndtering av valgte kreditorer til og fra 
    // ------------------------------------------------------------------------------------


    const [extraErrors, setExtraErrors] = useState({})
    const hasErrors = Object.values(errorMessages).some(x => (x !== null && x !== '')) || Object.values(extraErrors).some(x => (x !== null && x !== ''));

    const submitForm = () => {
        /* Note to future devs
        Feil settes i state, og form rerendres.
        Man må derfor vente på at alle states er oppdatert.
        Dette kunne man før gjøre i callback til setstate, men den er
        nå tatt vekk.  Derfor må valideringsrutinene levere tilbake info
        om evt feil, ellers kjøres post uten at formen validerer.
        */

        let formDontValidate = validateAll();

        // Sjekk felter som ikke valideres på cursor
        setExtraErrors({})
        let foreningError = foreningIsRequired(formState, localeMessages)
        if (foreningError) {
            formDontValidate = true
            setExtraErrors((prevState => ({ ...prevState, ['forening']: foreningError })))
        }

        let kreditorListeError = kreditorIsRequired(formState, localeMessages)
        if (kreditorListeError) {
            formDontValidate = true
            setExtraErrors((prevState => ({ ...prevState, ['kreditorListe']: kreditorListeError })))
        }

        // Form Input
        //setExtraErrors((prevState => ({ ...prevState, ...errorMessages })))

        // Test på om det finnes error
        if (formDontValidate) {
            console.log('cannot be submitted')
        } else {
            console.log('Canbesubmitted')
            postUser()
        }
    }
    
    const clearForm = () => {
        clearField("orgUsername", "")
        clearField("username", "")
        clearField("fullName", "")
        clearField("phoneNumber", "")
        clearField("newPassword1", "")
        clearField("bisNodeEnabled", false)
        clearField("webserviceBruker", false)
        clearField("twoFactorEnabled", false)        
        clearField("disabled", false)
        clearField("bisNodeUser", "")
        clearField("bisNodePassword", "")
        clearField("concentUserName", "")
        clearField("emailConfirmed", false)
        clearField("kreditorListe", [])
    }

    const postUser = () => {

        let kreditorList = [...formState.kreditorListe]

        const userData = {
            "OrgUsername": formState.orgUsername,
            "Username": formState.username,
            "FullName": formState.fullName,
            "PhoneNumber": formState.phoneNumber,
            "Password": formState.newPassword1,
            "concentUserName": "",
            "extraInfo1": "",
            "extraInfo2": "",
            "BisNodeEnabled": formState.bisNodeEnabled,
            "webserviceBruker": formState.webserviceBruker,
            "twoFactorEnabled": formState.twoFactorEnabled,   
            "Disabled": formState.disabled,
            "BisNodeUser": formState.bisNodeUser,
            "BisNodePassword": formState.bisNodePassword,
            "forening": formState.forening,
            "foreningsNavn": "",
            "rolle": formState.rolle,
            "kreditorListe": kreditorList
        }

        //  ************************************
        // Kaller Uptdate funksjonen på REST api
        // *************************************
        
        //clearForm ()
        
        props.updateUser(userData, clearForm)
    }


    const debugInfo = () => {
        if (process.env.NODE_ENV == 'development') {
            return (
                <>
                    errors: {JSON.stringify(errorMessages)}<br />
                    ekstraErrors: {JSON.stringify(extraErrors)}<br />
                    savedMessage: {props.state.savedMessage}
                    { FilteredKreditorListe(formState.valgteKreditorer)}<br /><br />
                    { JSON.stringify(formState, null, "\n")}
                </>
            )
        }
    }

    // Slette rutiner
    const [visBekreftSlett, SetVisBekreftSlett] = useState(false)

    const visBekreftModal = () => SetVisBekreftSlett(true)

    const slettCancelHandler = () => SetVisBekreftSlett(false)

    const slettOkHandler = () => {
        //console.log(`Sletter ${formState.username}`)
        SetVisBekreftSlett(false)

        // Kall metode på reducer, Reducer vil fjerne bruker fra listen og gå til søkebildet
        deleteUser(formState.orgUsername)
    }
    
    
    return {
        getRolleOption, getForeningsOption, FilteredForeningKreditorListe, FilteredKreditorListe, addNode, removeNode, debugInfo, hasErrors, extraErrors, submitForm,
        visBekreftSlett, slettCancelHandler, slettOkHandler, visBekreftModal
    }
}

export default useEditProfile