import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

/*
To use:
   import { useSelector, useDispatch } from 'react-redux'
   import { ..exported dispatchers... } from '../../reduxStore/...';

   // Angi ønsket redux store du vil hente state fra
   const state = useSelector(state => state.oversikt)

   // Få tak i redux dispatheren som du evt kan gi eget navn
   const _dispatch = useDispatch()
    ..

   // Kjør kall for å endre state
   _dispatch(setSomeShit(shitparam1))

*/

export const actions = {
    SAKSLISTEHEADER_LOADING: 'SAKSLISTEHEADER_LOADING',
    SAKSLISTEHEADER_ERROR: 'SAKSLISTEHEADER_ERROR',
    SAKSLISTEHEADER_KREDITORSLISTE_FETCHED: 'SAKSLISTEHEADER_KREDITORSLISTE_FETCHED',
 
    SAKSLISTEHEADER_FILTER_CHANGED: 'SAKSLISTEHEADER_FILTER_CHANGED',
    SAKSLISTEHEADER_ACTIVE_CHANGED: 'SAKSLISTEHEADER_ACTIVE_CHANGED',
    SAKSLISTEHEADER_KREDITOR_SELECTED: 'SAKSLISTEHEADER_KREDITOR_SELECTED',
}

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}


const fetchkredSelSuccess = (data) => ({
    type: actions.SAKSLISTEHEADER_DATA_FETCHED,
    payload: {
        data: data,
    }
});

const fetchkredSelFailure = error => ({
    type: actions.SAKSLISTEHEADER_ERROR,
    payload: { error }
});

/*===============================================================================
Valgt kreditor er endret i dropdown
===============================================================================*/
export const setSelectedKreditor = (id, name) => {
    return dispatch => {
        dispatch({
            type: actions.SAKSLISTEHEADER_KREDITOR_SELECTED,
            payload: { kreditorId: id, kreditorName: name }
        })
    }
}

/*===============================================================================
Filterstring er skrevet i
===============================================================================*/
export const setFilterString = (filter) => {
    return dispatch => {
        dispatch({
            type: actions.SAKSLISTEHEADER_FILTER_CHANGED,
            payload: { filter: filter }
        })
    }
}


/*===============================================================================
Dropdown aktive/Inaktive er skrevet i
===============================================================================*/
export const setAktiveInactiveSelected = (aktive) => {
    return dispatch => {
        dispatch({
            type: actions.SAKSLISTEHEADER_ACTIVE_CHANGED,
            payload: { aktive: aktive }
        })
    }
}


/*===============================================================================

Henter data for kreditorlisten fra server og populerer denne.
Denne må kjøres ved pålogging for å få opp kreditorere for aktuell bruker.    
    
===============================================================================*/
export const getKreditorsForDropdown = (foreningsId, showToasts, messages) => {

    return dispatch => {

        if (foreningsId) {

            const errorMessage = 'Kunne ikke hente kreditorer til bruk i nedtrekkslister. (redux:kredSel)' // messages['toastGetFilesFailed']
            const okMessage = 'Hentet kreditorer til nedtrekkslister.'    //messages['toastGetFilesOk']      

            dispatch({ type: actions.SAKSLISTEHEADER_LOADING })

            axiosTokenInstance
                ({
                    method: 'GET',
                    url: `api/Kreditor/Kreditors4Dept/${foreningsId}`
                })
                .then((result) => {

                    //console.log('getKreditorsForDropdown', result)
                    if (result.status !== 200) {
                        (showToasts && toast.error(errorMessage, {autoClose: false}))

                        dispatch(fetchkredSelFailure(errorMessage))
                    } else {
                        (showToasts && toast.info(okMessage))

                        let fakturaAdm = result.data.find(e => e.fakturaAdm) !== undefined
                        let stengeliste = result.data.find(e => e.stengeliste) !== undefined

                        dispatch({
                            type: actions.SAKSLISTEHEADER_KREDITORSLISTE_FETCHED,
                            payload: { data: result.data, fakturaAdm: fakturaAdm, stengeliste: stengeliste }
                        })

                        // Set default dersom bare en kreditor
                        if (result.data.length === 1) {
                            let firstKred = result.data[0]
                            let val = result.data[0].ind.split(';')[1]
                            
                            dispatch({
                                type: actions.SAKSLISTEHEADER_KREDITOR_SELECTED,
                                payload: { kreditorId: val }
                            })                            
                        }

                    }
                })
                .catch((err) => {
                    let lErrMess = errorMessage + ' ' + getErrorMessage(err)

                    toast.error(lErrMess, {autoClose: false})

                    dispatch(fetchkredSelFailure(lErrMess))
                })
        }
    }
}