import { bisnodeActions } from "./bisNodeActions";

const initialState = {
    uri: '',
    fetched: false,
    loading: false,
    error: ''
}

const bisnodeReducer = (state = initialState, action) => {

    switch (action.type) {

        case bisnodeActions.BISNODE_LOADING:
            return {
                ...state,
                fetched: false,
                loading: true,
                error: ''
            };

        case bisnodeActions.BISNODE_FETCHED:          
            return {
                ...state,
                uri: action.payload.data,
                fetched: true,
                loading: false,
                error: ''
            };

        case bisnodeActions.BISNODE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.data
            }

        default:
            return state;
    }
}

export default bisnodeReducer