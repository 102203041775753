import React from 'react';
import { Alert } from 'react-bootstrap';
import StyledDropzone from '../../../components/dropZone/StyledDropzone';
import { BfButtonBlue } from '../../../Styling/styledForm/styledBlueForm';

const UploadComponent = (props) => {
    
    const localeMessages = props.localeMessages

    const legalFileExtensions = ".mpf, .xlsx, .xls, .csv, .sdv, .xml, .lis, .aq, .doc, .docx, .ppt, .pptx, .txt, .pdf, .jpg, .dat, image/*"

    const filesSelected = () => ((props.files) && (props.files.length > 0))

    //color: #842029;
    //background - color: #f8d7da;
    //border - color: #f5c2c7;
    const fileList = (files) => {
        if ((!files) || (files.length === 0)) {
            return null
        }
        return (
            <>                
                <h4>Files</h4>
                {files.map(f =>
                    <div><button onClick={() => props.clearSingle(f.name)}>x</button>&nbsp;{f.name}&nbsp;</div>
                )}
            </>
        )
    }

    const getFilbehandlingError = () => {
        let lErrorMessage = ''
        if (props.selectedKreditor === '') {
            lErrorMessage += localeMessages.KreditorNotSelected 
        } else {
            // Anne Sofie vil ikke se denne feilmeldingen før kreditor er valgt
            if ((!props.files) || (props.files.length === 0)) {
                lErrorMessage += localeMessages.NoFilesInList
            }
        }

        if (lErrorMessage) {
            return (
                <div style={{
                    color: "#842029",
                    fontWeight: "400",
                    backgroundColor: "#f8d7da",
                    borderColor: "#f5c2c7",
                    padding: "15px 15px 15px 15px",
                    borderRadius: ".25rem",
                    marginTop: "10px"

                }}>
                    {lErrorMessage}
                </div>
            )
        }
        return null
    }

    return (
        <>           
            {getFilbehandlingError()}

            <StyledDropzone onDrop={props.onDrop} accept={legalFileExtensions} legalFileTypes={legalFileExtensions}/>
           
            <br />

            {fileList(props.files)}
            
            <br/>


            <BfButtonBlue variant="primary" type="button" onClick={() => props.upload(props.selectedKreditor)} disabled={!filesSelected() || (props.selectedKreditor === '')}>
                {localeMessages['LASTOPP']}
            </BfButtonBlue>
           &nbsp;&nbsp;

            <BfButtonBlue variant="primary" type="button" onClick={props.clearAll} disabled={!filesSelected()}>
                {localeMessages['CLEAR']}
            </BfButtonBlue>
            <br />
        </>
    )
}

export default UploadComponent

