import React, { useContext, useState, useRef } from "react";
import { Form, Col, Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
} from "../../../Styling/styledModal/styledModals";

// Eksempel på file upload i react
// https://www.geeksforgeeks.org/file-uploading-in-react-js/

// Det finnes eksempler på hvordan man kan kommer rundt styling av knapp for upload

const FakturaUploadModal = (props) => {
            
    const localeMessages =  props.funcs.localeMessages 
    const { velgFakt, filVelg } = localeMessages

    let btnRef = useRef();
    let faktRef = useRef();

    // State flyttet inn hit fra hooken, pga ellers skiftet ikke alltid faktura id ved valg (react bug??)
    //const [fakturaId, setFakturaId] = useState('')
    //const onFakturaIdChange = (e) => props.funcs.onFakturaIdChange(e)
  

    //const okHandler = () => props.funcs.okHandler(fakturaId)
         
    const fakturaSelect = () => {

        //console.log('Fakturaselect', props.funcs.fakturaListe)

        return (
            <>
                <select className="form-control" name="faktura" value={props.funcs.fakturaId} onChange={props.funcs.onFakturaIdChange}>
                    <option value="">{velgFakt}</option>
                    {props.funcs.fakturaListe.map((fakt, i) =>
                        <option key={fakt.invoiceNumber} value={fakt.invoiceNumber}>{fakt.invoiceNumber} - {fakt.description}</option>
                    )}
                </select>
            </>
        )
    }

    
    return (
        <StyledModal centered show={true} onHide={props.funcs.cancelHandler}>

            <StyledModalBody>
                <h4>{localeMessages["Overskrift"]}</h4>
                <Form.Group>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["sak"]}
                        </Form.Label>
                        <Col>
                            {props.funcs.debitorNo} - {props.funcs.debitorName}
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["kreditor"]}
                        </Form.Label>
                        <Col>
                            {props.funcs.creditorNo} - {props.funcs.creditorName}
                        </Col>
                    </Form.Row>
                    
                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["velgFakt"]}
                        </Form.Label>
                        <Col>
                            {fakturaSelect()}
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                               
                        </Form.Label>
                        <Col>
                            <input type="file" onChange={props.funcs.onUploadFileChange} />
                        </Col>
                    </Form.Row>

                </Form.Group>

            </StyledModalBody>

            <StyledModalFooter>
                <Button
                    ref={btnRef}
                    variant="secondary"
                    disabled={props.funcs.validates()}
                    onClick={props.funcs.okHandler}>
                    {localeMessages["Ok"]}
                </Button>
                <Button variant="secondary" onClick={props.funcs.cancelHandler}>
                    {localeMessages["Avbryt"]}
                </Button>
                    
            </StyledModalFooter>

        </StyledModal>
    );
}

export default FakturaUploadModal;