import React, {useRef} from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const CaseListToolTip = props => {

    let ref = React.useRef(null);

    return (

        <OverlayTrigger
            animation={false}
            id={'ot' + props.id}
            key={'kt' + props.id}
            ref={r => (ref = r)}
            
            placement='top'
            overlay={<Tooltip key={'tkt' + props.id} id={props.id}>{props.caption}</Tooltip>}
        >
            {props.children}
        </OverlayTrigger>

    )
}

export default CaseListToolTip