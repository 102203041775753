import styled from 'styled-components'
import { Modal, Form } from 'react-bootstrap'
import colorConfig from '../../colorConfig'

//const _orange = '#f19568'
const bg_color = colorConfig.modal_background;
const font_color = colorConfig.lys_gul;

// Bakgrunnen blir slør over resten av siden
const StyledModal = styled(Modal)`
    // Definerer siden rundt comboen
    color: ${colorConfig.lys_gul} !important;            
`
const StyledModalHeader = styled(Modal.Header)`  
    border-bottom: 0px;
    background-color: ${bg_color};
    color: #fff;      
    border-top-right-radius: 1.50em;
    border-top-left-radius: 1.50em; 
`
const StyledModalBody = styled(Modal.Body)`        
    background-color: ${bg_color};
    color: ${colorConfig.lys_gul};    
    border: 30px solid ${bg_color};
    padding-bottom: 0px;
    margin-bottom: 0px;    
    font-family: "Fellix", "sans-serif";
    font-size: 20px !important;
    
    Input {
      border-radius: 0.5em;
      font-family: "Fellix", "sans-serif";
      font-size: 20px !important;
      border: none ;
      color: black;
    };
    input[type=file] {
        color: ${colorConfig.lys_gul};   
    };
    Textarea {
      border-radius: 0.5em;
      font-family: "Fellix", "sans-serif";
      font-size: 20px !important;
      border: none ;
      color: black;
    };
    Form.Row {
        padding-top: 32px !important;            
    };     
    Form.Group {
        padding-bottom: 10px !important;            
    };     
    Label {
        padding-top: 20px;
        font-size: 20px;
        color: ${colorConfig.lys_gul};              
    }; 
    Div & Div & Label {
        font-family: "Fellix", "sans-serif";
        font-size: 20px !important;
        color: ${colorConfig.lys_gul};             
    };
    H4 {
        color: ${colorConfig.lys_gul};
        font-size: 30px;
        fontWeight: 900;         
    }
    P {
        color: ${colorConfig.lys_gul};
        fontWeight: 500;         
    }
`
const StyledModalFooter = styled(Modal.Footer)`    
    background-color: ${bg_color};
    color: ${colorConfig.lys_gul};  
    border-top: 0px;
    border-bottom: 20px solid ${bg_color};
    border-left: 30px solid ${bg_color};
    border-right: 30px solid ${bg_color};
    padding-top: 0px;
    justify-content: flex-start;
    >Button {
        border-radius: 0.5em;
        background-color: ${colorConfig.button_bg};
        font-weight: 500;
        :hover {
            background-color: ${colorConfig.button_focused};
            color: black;
        }
        :focus {
            background-color: ${colorConfig.button_focused};
        }
        :disabled {
            background-color: gray;
            color: lightGray;
        }
      
    }
`

const SquareInput = styled(Form.Control)`    
    border: none;
    border-radius: 0.5em;
`

const ModalP = styled.span`
    font-size: 24 !important;
`

export { StyledModal, StyledModalHeader, StyledModalBody, StyledModalFooter, SquareInput, ModalP }