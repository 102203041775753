import React, { useState } from "react";
import { createIntl, createIntlCache } from "react-intl";
import { Form, Col, Button } from "react-bootstrap";
import _ from 'lodash'
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
    SquareInput,
} from "../../../Styling/styledModal/styledModals";


const AvdragsordModal = (props) => {
    
    const localeMessages =  props.funcs.localeMessages 
    
    return (
        

        <StyledModal centered show={true} onHide={props.funcs.cancelHandler} size="lg">

            <StyledModalBody>
                <h4>{localeMessages["AO1M_Overskrift"]} {props.funcs.caseInfo}</h4>
                <Form.Group>
                    
                    <Form.Row>
                        <Form.Label column="sm" lg={12}>
                            {localeMessages["AO1M_forsRt"]}
                        </Form.Label>                        
                    </Form.Row>
                    
                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["AO1M_andAvdr"]}
                        </Form.Label>
                        <Col>
                            <div onChange={e => props.funcs.antAvdragChanged(e.target.value)}>

                                <input type="radio" name="AO1M_antAvdrag" value="2" defaultChecked={props.funcs.antAvdrag === '2'} />&nbsp;&nbsp;
                                {localeMessages["AO1M_andAvdr2"]}&nbsp;&nbsp;&nbsp;&nbsp;

                                <input type="radio" name="AO1M_antAvdrag" value="3" defaultChecked={props.funcs.antAvdrag === '3'} />&nbsp;&nbsp;
                                {localeMessages["AO1M_andAvdr3"]}&nbsp;&nbsp;&nbsp;&nbsp;

                                <input type="radio" name="AO1M_antAvdrag" value="4" defaultChecked={props.funcs.antAvdrag === '4'} />&nbsp;&nbsp;
                                {localeMessages["AO1M_andAvdr4"]}

                            </div>
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={8}>
                            {localeMessages["AO1M_terminLengde"]}
                        </Form.Label>
                        <Col>
                            <div onChange={e => props.funcs.setTerminLengde(e.target.value)}>

                                <input type="radio" name="AO1M_terminLengde" value="15" defaultChecked={props.funcs.terminLengde === '15'}/>&nbsp;&nbsp;
                                {localeMessages["AO1M_alt1"]}&nbsp;&nbsp;&nbsp;&nbsp;

                                <input type="radio" name="AO1M_terminLengde" value="30" defaultChecked={props.funcs.terminLengde === '30'}/>&nbsp;&nbsp;
                                {localeMessages["AO1M_alt2"]}

                            </div>
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["AO1M_terminbelop"]}
                        </Form.Label>
                        <Col>
                            <SquareInput size="sm" type="text"
                                value={props.funcs.terminBelop} onChange={(e) => props.funcs.setTerminBelop(e.target.value)} />
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["AO1M_balanceTotal"]}
                        </Form.Label>
                        <Col>
                            <SquareInput size="sm" type="text" value={props.funcs.totalBalance} readOnly />
                        
                        </Col>
                    </Form.Row>


                    <Form.Row>
                        <Form.Label column="sm" lg={4}>
                            {localeMessages["AO1M_skyldner"]}
                        </Form.Label>

                        <Col>                           
                            <div onChange={e => props.funcs.setMobEpostChanged(e.target.value)}>

                                <input type="radio" name="AO1M_mob_epost" value="mobil" defaultChecked={props.funcs.mobEpost === 'mobil'} />&nbsp;&nbsp;
                                {localeMessages["AO1M_alt_mob"]}&nbsp;&nbsp;&nbsp;&nbsp;

                                <input type="radio" name="AO1M_mob_epost" value="epost" defaultChecked={props.funcs.mobEpost === 'epost'} />&nbsp;&nbsp;
                                {localeMessages["AO1M_alt_epost"]}

                            </div>
                        </Col>
                        <Col>
                            <SquareInput size="sm" type="text"
                                value={props.funcs.epost} onChange={(e) => props.funcs.setEpost(e.target.value)}
                            />
                        </Col>
                    </Form.Row>

                </Form.Group>

                <div>{props.funcs.AVOErrorMessage}</div>

            </StyledModalBody>

            <StyledModalFooter>
                <Button
                    variant="secondary"
                    disabled={props.funcs.allFieldsHasValues()}
                    onClick={props.funcs.okHandler}>
                    {localeMessages["AO1M_Ok"]}
                </Button>
                <Button variant="secondary" onClick={props.funcs.cancelHandler}>
                    {localeMessages["AO1M_Avbryt"]}
                </Button>
            </StyledModalFooter>

        </StyledModal>
 
    );
};

export default AvdragsordModal