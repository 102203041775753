import React, { useContext, useRef, useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import { IntlProvider} from "react-intl";
import RootContainer from "./RootContainer";

import {
  BlueHeader1Area,
  BlueHeader1Right,
  BlueHeader1Clear,
} from "./BlueHeader1Area";

import {
  Header2Area,
  Header2Left,
  Header2Right,
  Header2Clear,
} from "./Header2Area";
import SvgLogo from "./Logo";
import { ContentArea } from "./ContentArea";
import FooterArea from "./FooterArea";
import LocaleContext from "../../state/contexts/LocaleContext";
import KfRoutes from "../../menu/KfRoutes";
import Header1Logout from "../../menu/Header1Logout"
import { messages } from './MainLayout.i18n'
import { useAuth } from "../../hooks/useAuth";
import FooterComponent from "../../menu/FooterComponent"
import BlueHeader1Menu from "../../menu/BlueHeader1Menu";
import Header2NySakSelector from "../../components/header2Selector/Header2NySakSelector";
import Header2SelectorRedux from "../../components/header2Selector/Header2SakslisteSelectorRedux";

const MainLayout = (props) => {
  const auth = useAuth()
  const locale = useContext(LocaleContext);

  const authRef = useRef(auth);
  useEffect(() => {
    // Sjekker om man er pålogget og laster data på nytt
    authRef.current.authCheckState()  
  }, [])



  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Router>
        <RootContainer>
          <Header2Area>
            <Header2Left>
              <SvgLogo />
            </Header2Left>
            <Header2Right>
               <Header2NySakSelector /> 
               <Header2SelectorRedux />
            </Header2Right>
            <Header2Clear></Header2Clear>
          </Header2Area>

          <BlueHeader1Area>
            <BlueHeader1Menu />
            <BlueHeader1Right><Header1Logout /></BlueHeader1Right>
            <BlueHeader1Clear></BlueHeader1Clear>
          </BlueHeader1Area>          

          <ContentArea>
            <KfRoutes />
          </ContentArea>

          <FooterArea><FooterComponent /></FooterArea>
        </RootContainer>
      </Router>
    </IntlProvider>
  );
};

export default MainLayout;