import React from 'react';
import styled from 'styled-components'
import CaDetDebtorComponent from './components/infogrids/CaDetDebtorComponent';
import CaDetFakturaerComponent from './components/tabs/CaDetFakturaerComponent';
import CaDetMeldingListComponent from './components/tabs/CaDetMeldingListComponent'
import CaDetAktiviteterComponent from './components/tabs/CaDetAktiviteterComponent';
import CaDetNotaterComponent from './components/tabs/CaDetNotaterComponentDeprecated';
import CaDetReskontroComponent from './components/infogrids/CaDetReskontroComponent';
import CaDetHeaderComponent from './components/infogrids/CaDetHeaderComponent';
import CaDetSamleSakComponent from './components/tabs/CaDetSamleSakComponent';
import CaDetSaksOversiktComponent from './components/infogrids/CaDetSaksOversiktComponent';

const ReportWrapper = styled.div`   
    display: none;
    
    @media print {
        display: contents;
        size: A4 landscape
        //background-color: white !important;
        //color: black !important;
        //font-size: 14px;
        font-family: 'Roboto', sans-serif !Important;
        

        .PrintSection {
             padding: 40px !important;
        }
    }
`

const ReportSpacer = styled.div`
    min-height: 20px;
`

export class CaseDetailsReportComponentToPrint extends React.Component {
    render() {
        return (
            <ReportWrapper>

                <CaDetHeaderComponent />
                <ReportSpacer />

                <CaDetReskontroComponent />
                <ReportSpacer />

                <CaDetDebtorComponent />
                <ReportSpacer />

                <CaDetSaksOversiktComponent />
                <ReportSpacer />

                <CaDetFakturaerComponent isReport={true} />
                <ReportSpacer />

                <CaDetMeldingListComponent isReport={true} /> 
                <ReportSpacer />
        
                {/* 
                <CaDetNotaterComponent isReport={true} />
                <ReportSpacer />
                */}
                
                <CaDetAktiviteterComponent isReport={true} />
                <ReportSpacer />

                <CaDetSamleSakComponent isReport={true} />

            </ReportWrapper>
        );
    }
}

// Create Document Component
export const CaseDetailsReport = () => {

    return (
        
        <ReportWrapper>
            
            <CaDetHeaderComponent />

            <CaDetReskontroComponent />

            <CaDetDebtorComponent />

            <CaDetFakturaerComponent isReport={true}/>

            <CaDetAktiviteterComponent isReport={true}/>

            <CaDetNotaterComponent isReport={true}/>

        </ReportWrapper>

    )
}