import React, { useState } from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl'
import { fixMissingTimeFromDate } from '../../../util/date/dateRoutines';
import styled from 'styled-components';
import { HoverBox } from '../../../components/fullTextOnHover/HoverBox';
import CaseListToolTip from '../../caselist_redux/caseListTable/CaseListTooltip';
import { BfCheckboxContainer, BfCheckboxInput } from '../../../Styling/styledForm/styledBlueForm'
import StengelisteExpandedRowComponent from './StengelisteExpandedRowComponent';


const Circle = styled.button`    
    background: ${props => props.primary || "white"};
    width: 23px;
    height: 23px;
    border-radius: 50%;
    border: 1px solid black;
`;

export const StengelisteRowComponent = (props) => {
    const [checked, setChecked] = React.useState(props.row.isSelected);
    const [isShown, setIsShown] = useState(false);

    const handleChange = () => {
        const model = props.row;
        model.status === 'SLETTET' ? props.toggleMovedRow(model.i, !model.isSelected) :
        props.toggleRow(model.i, !model.isSelected)
    };

    const asDate = (val) => {
        // Tomt felt om det er noe krøll med val, ellers blir dagens dato satt inn som default
        if (val === null || val === '' || val === undefined) {return (<>{''}</>)};
        // Kludge fordi FireFox ikke godtar dato uten tid uten videre
        let valok = fixMissingTimeFromDate(val)
        return (
            <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
        )
    }

    // Logikk for å vente litt før man viser stengeliste,
    // unngår flickering ved flytting av markøren
    let timeoutId;
    const mouseEnter = () => {
        if (!timeoutId) {
            timeoutId = window.setTimeout(function () {
                timeoutId = null; // EDIT: added this line
                setIsShown(true)
            }, 200);
        }
    }
    const mouseLeave = () => {
        if (timeoutId) {
            window.clearTimeout(timeoutId);
            timeoutId = null;
        }
        setIsShown(false)
    }

    const [expanded, setExpanded] = useState(false);

    const handleRowClick = () => {
        setExpanded(!expanded);
    };

    const printRating = () => {


        function isNumeric(num) {
            return !isNaN(num)
        }

        let ratingstring = model.debtorRating ? model.debtorRating.trim() : ''
        let outputString = ''
        let rcolor = 'white'
        let showBall = false


        if (!ratingstring) {
            outputString = 'Ikke ratet'
        } else {
            if (isNumeric(ratingstring)) {
                let rating = parseInt(ratingstring)
                showBall = true

                if (rating <= 30) {
                    rcolor = 'red'
                } else if (rating <= 65) {
                    rcolor = 'yellow'
                } else {
                    rcolor = 'green'
                }
            }
        }

        /*

        Rød "prikk" hvis ratingstallet er <30
        Gul "prikk" hvis ratingstallet er mellom 30 og 60
        Grønn "prikk" hvis ratingstallet >60
        
        */

        return (

            <td>
                {showBall &&
                    <Circle primary={rcolor}></Circle>
                }
                {outputString}
            </td>

        )

    }

    const model = props.row
    const showPaymentDate = props.showPaymentDate
    const showCheckbox = props.showCheckbox
    const showSelectedDate = props.showSelectedDate

    return (
        <>
            <tr key={model.i.toString()} onClick={handleRowClick} style={{ cursor: 'pointer' }} >
                <td onClick={handleRowClick} >
                    {expanded ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M7 10l5 5 5-5z" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M12 7l5 5-5 5" />
                        </svg>
                    )}
                    <>&nbsp;({model.closingListCase.length})</>
                </td>
                {showCheckbox && (
                    <td>
                        <BfCheckboxContainer>
                            <BfCheckboxInput
                                checked={checked}
                                onChange={handleChange}
                                disabled={model.disabled || model.onHold || model.moved}
                            />
                            {model.disabled && <>&nbsp;(Innsendt)</>}
                            {model.onHold && <>&nbsp;(På hold)</>}
                            {model.moved && <>&nbsp;(Flyttet)</>}
                        </BfCheckboxContainer>
                    </td>
                )
                }
                <td>{model.customerNo}</td>
                <td>{model.meeterPointId}</td>
                <td>{printRating(model.debtorRating)}</td>
                <td>
                    <HoverBox minWidth={200}>
                        {model.debtorName}
                    </HoverBox>
                </td>
                <td>{model.utilityAddress}</td>
                <td align='right' style={{ paddingRight: 50 }}>
                    {<FormattedNumber
                        value={model.remainingTotal}
                        style='decimal'
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                    />}
                </td>
                <td>{asDate(model.regDate)}</td>
                {showSelectedDate && (<td>{asDate(model.closingDate)}</td>)}
                {showPaymentDate && (<td>{asDate(model.paymentDate)}</td>)}
                {model.status === 'NY' && (<td>
                    {model.onHold || model.disabled ? <span className="fa fa-pause" style={{ color: 'LightGray' }} /> :
                        <CaseListToolTip id={'t' + model.closingListId} caption={'Sett på hold'}>
                            <span className="fa fa-pause"
                                onClick={
                                    () => {
                                        props.stFuncs.showPutOnHoldDialog(model.closingListId, model.i)
                                        props.toggleOnHoldRow(model.i, !model.isSelected)
                                    }
                                } />
                        </CaseListToolTip>}
                    &nbsp;
                    &nbsp;
                </td>)}

            </tr>
            {expanded &&
                <>
                    <tr key={model.i + 1000} style={{ backgroundColor: 'white', hover: { opacity: 1 } }} >
                        <td></td>
                        <td colspan="8" className={expanded ? 'expand-animation' : 'minimize-animation'}>
                            <StengelisteExpandedRowComponent tableData={model.closingListCase} localeMessages={props.localeMessages} />
                        </td>
                    </tr>
                    <tr style={{ backgroundColor: 'white' }}>
                        <td colspan="8"></td>
                    </tr>
                </>}

        </>
    )
}
