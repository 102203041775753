import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getBisnodeUri } from '../../reduxStore/bisnode/bisNodeActions';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './BisNodeContainer.i18n'

export const useBisnode = () => {

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const showToasts = true

    let history = useHistory();

    // Aktiver REDUX 
    const bisnodeState = useSelector(state => state.bisnode)
    const dispatch = useDispatch()


    /*
    Denne funskjonen skal brukes i fra menyen

    Dersom du er pålogget, kan du åpne bisnode i nytt vindu
    Dersom du ikke er pålogget må du hente uri (logg på fra KOS)
    
    Dersom det er en feil, kan du sendes til en side som viser hva problemet er

    */
    
    const openBisnodeInNewTab = (uri) => window.open(uri, "_blank")

    const showBisnodePage = () => history.push('/KredittInformasjon')
    
    const onBisnodeClick = () => {

        if (bisnodeState.error) {

            // Send til en feilside med history push
            showBisnodePage()

        } else if (bisnodeState.fetched) {

            // åpne bisnode i nytt vindu
            openBisnodeInNewTab(bisnodeState.uri)
           

        } else {

            //showBisnodePage()

            // Hent bisnode og åpne bisnode i nytt vindu 
            dispatch(getBisnodeUri('', openBisnodeInNewTab, showBisnodePage, showToasts, messages))
        }

    }


    return { onBisnodeClick, bisnodeState }
}



