import React, { useState, useEffect } from 'react'
import { IntlProvider, FormattedMessage } from 'react-intl'
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './InterestCalculatorContainer.i18n'

import { ArticleArea } from '../../Styling/Layout/ContentArea'
import {useInterestCalculator} from './useInterestCalculator'

import { BfColInput } from '../../Styling/FormComponents/FormInput';
import { BfButtonBlue, BfRowForm, BfTightRowForm, BfTightYellowRowForm, BfYellowRowForm, YellowRoundedContainer } from '../../Styling/styledForm/styledBlueForm'
import { Container, Col, Form, Alert } from 'react-bootstrap'

const InterestCalculatorContainer = (props) => {

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const { formValues, formValidators, handleChange, formState, validateAll,
        hasErrors, errorMessages, extraErrors, setExtraErrors,
        submitForm, clearForm, errorMessage } = useInterestCalculator (localeMessages)

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '', disabled = false, maxLength = 524288) => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                //state={state}
                selectOptions={selectOptions}
                disabled={disabled}
                maxLength={maxLength}
            />
        )
    }

    return (
        <YellowRoundedContainer fluid="md">

            <Form autocomplete="off">
                <Container >

                    {hasErrors && (
                        <Alert variant='danger'>
                            {localeMessages.youHaveErrors}
                        </Alert>
                    )}

                    <ArticleArea>
                        <h1>{localeMessages.header}</h1>
                        <p>{localeMessages.beskrivelse}</p>
                    </ArticleArea>

                    <BfYellowRowForm>
                        {getFormField(6, 'startDate', 'cleaveDate')}
                        {getFormField(6, 'endDate', 'cleaveDate')}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        {getFormField(6, 'interest', 'cleaveAmount')}
                        {getFormField(6, 'amount', 'cleaveAmount')}
                    </BfYellowRowForm>

                    <BfYellowRowForm>
                        <span style={{fontSize: "40px"}}><b>
                            {formState.message}
                        </b></span>
                        
                    </BfYellowRowForm>
                    

                    <BfTightYellowRowForm>
                        <Col xs={12} md={12}>
                            <BfButtonBlue variant="primary" type="button" onClick={submitForm}>
                                {localeMessages.Calculate}
                            </BfButtonBlue>
                        </Col>
                    </BfTightYellowRowForm>

                    <BfTightYellowRowForm />

                </Container>
            </Form>
        </YellowRoundedContainer>
    )
}

export default InterestCalculatorContainer

/*
export const messages = {
    nb: {
      Ex: `text`,
    }
  } 

|123456789|123456789|123456789|123456789|123456789|123456789|12
*/