import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosTokenInstance from "../../../Instances/axiosTokenInstance";
import LocaleContext from "../../../state/contexts/LocaleContext";
import { messages } from './meldingModal.i18n'
import _ from 'lodash'
import { useDispatch } from "react-redux";
import { dispatchGetCaseDetails } from '../../../reduxStore/saksdetaljer/saksdetaljerActions'

export const useMeldingModal = (setErrorMessage, setMessage, setShowSpinner) => {
    
    const locale = useContext(LocaleContext);
    const localeMessages = messages[locale]
    
    const showToasts = true;

    // Styring av modale
    const [dialogVisible, setDialogVisible] = useState(false)
    
    // Parametre til rest kallene
    const [creditorNo, setCreditorNo] = useState('')
    const [caseNo, setCaseNo] = useState('')
    const [debtorName, setDebtorName] = useState('')

    const [taskId, setTaskId] = useState()
    const [selectedUploadFile, setselectedUploadFile] = useState(null)    
    const [oppgave, setOppgave] = useState('')
    const [orgMessage, setOrgMessage] = useState('')

    const dispatch = useDispatch()
    

    // RESETS MODAL BOXES AND STATE
    const resetModal = () => {
        
        setErrorMessage('')
        setMessage('')
                                
        setShowSpinner(false)
    }
    
    useEffect(() => {        
        resetModal()
        
    }, [creditorNo, caseNo])

    // TRIGGER FOR MODAL SEQUENCE
    const showError = (errorMessage) => {
        (showToasts && toast.error(errorMessage, {autoClose: false}))
        setErrorMessage(errorMessage)
        console.error('TrekkSakModal: ', errorMessage)
    }

    const showMessage = (message) => {
        (showToasts && toast.info(message))
        setMessage(message)
        console.log('TrekkSakModal: ', message)
    }

    const onUploadFileChange = event => {
        //console.log(event.target.files[0])
        setselectedUploadFile(event.target.files[0])
    };    

    const validates = () => oppgave ? '' : 'disabled'

    const getM1Tekst = () => {
        /*if (!taskId === '') {
            return 'Ny melding'
        } else {
            return 'svar på melding ' + taskId
        }*/
        return `${caseNo} ${debtorName}`
    }

    const messageLfCorrected = (messageText) => {
        if (messageText) {
            return messageText.replaceAll('\\r\\n', '\n')
        }
        else return ''
    }


    const showDialogHandler = (_creditorNo, _caseNo, _taskid, messageToReplyTo, _debtorName) => {
        setErrorMessage('')
        setMessage('')

        setCreditorNo(_creditorNo)
        setCaseNo(_caseNo)
        setDebtorName(_debtorName)

        setTaskId(_taskid)
        setOrgMessage(messageLfCorrected(messageToReplyTo))
        setselectedUploadFile(null)
        setOppgave('')
               
        setDialogVisible(true)
    }

    const cancelHandler = e => {
        setDialogVisible(false)       
        setShowSpinner(false)
    }

    const getErrorMessage = (err) => {
        if (_.has(err, 'response.data.title')) {
            return err.response.data.title
        }
        else if ((_.has(err, 'response.data')) && (err.response.data)) {
            return err.response.data
        }
        else if (_.has(err, 'response.statusText')) {
            return err.response.statusText
        }
        else {
            return err.message.toString()
        }
    }
    
    const okHandler = () => {

        const formData = new FormData();

        // Update the formData object
        if (selectedUploadFile) {
            formData.append(
                "file",
                selectedUploadFile,
                selectedUploadFile.name
            )
        }

        formData.append('CaseNumber', caseNo)
        formData.append('crNo', creditorNo)
        formData.append('NoteText', oppgave)

        if (taskId) {
            formData.append('taskId', taskId)
            formData.append('newTask', false)
        } else {
            formData.append('taskId', 0)
            formData.append('newTask', true)
        }

        console.log(`/api/sak/PostUpdateCreateTask, ${creditorNo}/${caseNo} formData: `, JSON.stringify(formData))

        setShowSpinner(true)       

        let toastMeldingUploadOk = localeMessages['toastMeldingUploadOk']
        let toastSendMeldingFailed = localeMessages['toastSendMeldingFailed']

        axiosTokenInstance
            ({
                method: 'POST',
                url: `api/sak/PostUpdateCreateTask`,
                data: formData
            })
            .then((result) => {

                setDialogVisible(false)
                setShowSpinner(false)

                console.log(`/api/sak/PostUpdateCreateTask, ${creditorNo}/${caseNo} returnerte:`, result)
                
                if (result.status !== 200) {
                    showError(toastSendMeldingFailed)             
                } else {
                    if (result.data.errorCode === "000") {
                        showMessage(`${toastMeldingUploadOk}  (saksnr: ${caseNo})`)
                        
                        // Last saken på nytt
                        dispatch(dispatchGetCaseDetails(creditorNo, caseNo, true, localeMessages, null))
                    } else {
                        showError(toastSendMeldingFailed + ', ' + result.data.errorMessage)                        
                    }
                }                
            })
            .catch((err) => {

                setDialogVisible(false)
                setShowSpinner(false)

                showError(toastSendMeldingFailed + ' ' + getErrorMessage(err))
            })
       
    }

    return {
        okHandler, cancelHandler, showDialogHandler, dialogVisible, onUploadFileChange,
        selectedUploadFile, setselectedUploadFile, oppgave, setOppgave, getM1Tekst, orgMessage, taskId,
        validates, localeMessages
    }
   
}
