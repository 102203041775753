import { toast } from 'react-toastify';
import _ from 'lodash'
import axiosTokenInstance from '../../Instances/axiosTokenInstance';

/*
To use:
   import { useSelector, useDispatch } from 'react-redux'
   import { ..exported dispatchers... } from '../../reduxStore/...';

   // Angi ønsket redux store du vil hente state fra
   const state = useSelector(state => state.newCase)

   // Få tak i redux dispatheren som du evt kan gi eget navn
   const _dispatch = useDispatch()
    ..
    
   // Kjør kall for å endre state 
   _dispatch(setSomeShit(shitparam1))

*/

export const actions = {
    KREDSEL_LOADING: 'LOADING',
    KREDSEL_ERROR: 'ERROR',
    KREDSEL_DATA_FETCHED: 'KREDSEL_DATA_FETCHED',
    KREDSEL_SET_SELECTED: 'KREDSEL_SET_SELECTED',
}

const fetchkredSelLoading = () => ({type: actions.KREDSEL_LOADING});

const fetchkredSelSuccess = (data, forening) => ({
    type: actions.KREDSEL_DATA_FETCHED,
    payload: {
        data: data,
        forening: forening,
    }
});

const fetchkredSelFailure = error => ({
    type: actions.KREDSEL_ERROR,
    payload: { error }
});

const kredSetSelected = id => ({
    type: actions.KREDSEL_SET_SELECTED,
    payload: { data: id }
});

const getErrorMessage = (err) => {
    if (_.has(err, 'response.data.title')) {
        return err.response.data.title
    }
    else if (_.has(err, 'response.statusText')) {
        return err.response.statusText
    }
    else if (_.has(err, 'response.data')) {
        return err.response.data
    } else {
        return err.message.toString()
    }
}

export const setSelectedKreditor = (id) => {
    return dispatch => {
        dispatch(kredSetSelected(id))
    }
}

export const getKreditorsForDropdown = (foreningsId, showToasts, messages) => {

    return dispatch => {

        if (foreningsId) {

            const errorMessage = 'Kunne ikke hente kreditorer til bruk i nedtrekkslister. (redux:kredSel)' // messages['toastGetFilesFailed']
            const okMessage = 'Hentet kreditorer til nedtrekkslister.'    //messages['toastGetFilesOk']      
    
            dispatch(() => ({ type: actions.KREDSEL_LOADING }))
    
            axiosTokenInstance
                ({
                    method: 'GET',
                    url: `api/Kreditor/Kreditors4Dept/${foreningsId}`
                })
                .then((result) => {
    
                    //console.log(result)
                    if (result.status !== 200) {
                        (showToasts && toast.error(errorMessage, {autoClose: false}))

                        dispatch(fetchkredSelFailure(errorMessage))
                    } else {
    
                        (showToasts && toast.info(okMessage))
                        //console.log(result.data)
    
                        dispatch(fetchkredSelSuccess(result.data, foreningsId))
                    }
                })
                .catch((err) => {
                    let lErrMess = errorMessage + ' ' + getErrorMessage(err)
                    
                    toast.error(lErrMess, {autoClose: false})
                    
                    dispatch(fetchkredSelFailure(lErrMess))
                })
        }
    }
}