import React from 'react';
import { IntlProvider } from 'react-intl'
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './onBoardingContainer.i18n'
import { ArticleArea } from '../../Styling/Layout/ContentArea'
import { OnBoardingForm } from './OnboardingForm'
import { useOnBoarding } from './useOnboarding'
import { Spinner } from '../../components/spinner/Spinner'
import { Alert, Container } from "react-bootstrap";


/*

  Todo i denne formen:
  [ ] - Hent økonomisystemer
  [ ] - Post til server
  [ ] - Bruk toast ved henting
  [ ] - Vis feil dersom noe går galt
  [ ] - Knapp nederst for submit
  [ ] - Litt bedre tekst over form
  [ ] - Spinner under henting
  [ ] - Alert på toppen dersom henting går galt?
  [ ] - Mangler felt for Momspliktig
  [ ] - Mangler Vis avtalevilkår

  logikk på server:
    Epostadressen må ikke være i bruk
    Orgnr må ikke være registrert før
    Opprett bruker lokalt
    Opprett kunde i KOS

*/

const OnBoardingContainer = () => {

    console.log('ONBOARDING')
    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]

    const {        
        handleChangeProxy, formState,        
        hasErrors, savedOk, errorMessages, extraErrors,        
        getFirmaInfo, submitForm, financialSystems, isLoading, pageHasError
    } = useOnBoarding(localeMessages)


    const getForeningsOption = () => {
        return (
            <>
                <option value="">Velg forening</option>                
                <option value="20">Kreditorforeningen SA</option>
            </>
        )
    }

    const okonomiSys = () => {
        
        if (!financialSystems)
            return (null)
        
        return (
            <>
                <option value="">{localeMessages.oksysDefault}</option>
                
                {financialSystems.map(function (object, i) {
                    return <option value={object.id} key={i}>{object.value}</option>
                })}
    
            </>
        )
    }

    const momsPlikt = () => {
        return (
            <>
                <option value="">{localeMessages.mvapliktDefault}</option>
                <option value="1">{localeMessages.mvaplikt1}</option>
                <option value="0">{localeMessages.mvaplikt0}</option>
            </>
        )
    }

    const produktType = () => {
        return (
            <>
                <option value="">{localeMessages.prodTypeDefault}</option>
                <option value="2">{localeMessages.prodtypePurre}</option>
                <option value="1">{localeMessages.prodtypeInkasso}</option>
            </>
        )
    }
    
    if (isLoading) {
        return (
            <Spinner></Spinner>
        )
    } 

    return (
        
        <IntlProvider locale={locale} messages={messages[locale]}>            

            <Container>

                {(pageHasError !== '') && <Alert variant='danger'>
                    {pageHasError}
                </Alert>}

                <ArticleArea>
                    <h1>{localeMessages.header}</h1>
                    <p>{localeMessages.beskrivelse}</p>
                </ArticleArea> 
            </Container>
            
            <OnBoardingForm                
                handleChange={handleChangeProxy}
                errorMessages={errorMessages}
                savedOk={savedOk}
                extraErrors={extraErrors}
                localeMessages={localeMessages}
                formState={formState}
                getFirmaInfo={getFirmaInfo}
                hasErrors={hasErrors}
                okonomiSys={okonomiSys}
                produktType={produktType}
                momsPlikt={momsPlikt}
                foreninger={getForeningsOption}
                submitForm={submitForm}
            />
        </IntlProvider>
    )
}

export default OnBoardingContainer 