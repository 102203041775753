import { useCallback, useState, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import { messages } from './FakturaAdminContainer.i18n'
import { useSelector, useDispatch } from 'react-redux'
import { clearData, getFakturaAdmin, sendInnValgteFakturaer, toggleAll, toggleSelected } from '../../reduxStore/fakturaAdmin/fakturaAdminActions'
import { useBekreftInnsending } from './modals/bekreftInnsending/useBekreftInnsending'
import { CurrencyExchange } from 'react-bootstrap-icons'
//import { getFiles, uploadFiles } from '../../reduxStore/filbehandling/filbehandlingActions'

export const useFakturaAdminContainer = (locale) => {

    const showToasts = true;
    
    const localeMessages = messages[locale]   

    const fakturaMonthSelectorValues = [
        { value: 1, caption: '1 Måned' },
        { value: 2, caption: '2 Måneder' },
        { value: 3, caption: '3 Måneder' },
        { value: 6, caption: '6 Måneder' },
        { value: 12, caption: '1 år' },
        { value: 24, caption: '2 år' },
        { value: 36, caption: '3 år' },
    ]
    const [invMonths, setInvMonths] = useState(fakturaMonthSelectorValues[0])
    
    // Activate global redux reducer
    const state = useSelector(state => state.fakturaAdmin)
    const headerState = useSelector(state => state.sakslisteHeader)

    const dispatch = useDispatch()
    let selectedKreditor = headerState.kreditorId


    let getNumDisabled = () => {

        let numDisabled = 0
        
        state.invoiceList.forEach(e => {           
            if (e.disabled) {
                numDisabled++
            }
        })
            
        return numDisabled
    }

    let getSumDisabled = () => {
        
        let totalDisabled = 0.0
        
        state.invoiceList.forEach(e => {
            
            if (e.disabled) {
                totalDisabled += e.invoiceAmount
            }
        })
            
        return totalDisabled 
    }
    

    // Hent data hver gang kreditor blir endret i dropdown
    useEffect(() => {        
        if (!headerState.kreditorId) {
            // TODO Vise feilmeldingsside 
        } else {
            let currentKreditor = headerState.kreditorliste.find(e => e.customerNo === headerState.kreditorId)
            // Dispatch hening av data
            // NB DepartementID trengs ikke settes, det finner man ut i C# Api
            dispatch(clearData())
            if ((currentKreditor) && currentKreditor.fakturaAdm) {
                dispatch(getFakturaAdmin(headerState.kreditorId, invMonths.value, showToasts, localeMessages))
            }
        }
    }, [headerState.kreditorId, invMonths])

    const toggleRow = (i, toggleValue) => {        
        dispatch (toggleSelected(i, toggleValue))
    }

    const toggleAllRows = toggleValue => dispatch(toggleAll(toggleValue))
    
    const sendInn = () => {
        console.log('OK')

        let fakturaList = state.invoiceList.filter(e => e.isSelected).map(e => e.invoiceNumber)
        
        const formData = new FormData()       
        formData.append('CreditorNo', headerState.kreditorId)
        //formData.append('InvoiceList',fakturaList)
        for (var i = 0; i < fakturaList.length; i++) {
            formData.append('InvoiceList[]', fakturaList[i]);
        }

        dispatch(sendInnValgteFakturaer(formData, showToasts, localeMessages))
        
    }

        
    const confirmMessage = () => `Du har valgt ${state.antall_valgte} fakturaer, 
    totalt beløp er ${new Intl.NumberFormat('nb-NO').format(state.sum_valgte, {minimumFractionDigits: 2,
        maximumFractionDigits: 2,})}.\n
    Trykk "Fortsett" for å sende inn`
     
    const bsFuncs = useBekreftInnsending(localeMessages, sendInn, confirmMessage)

    return {
        localeMessages, selectedKreditor, state, headerState, getNumDisabled, getSumDisabled,
        toggleRow, toggleAllRows,
        invMonths, setInvMonths, fakturaMonthSelectorValues,
        bsFuncs
    }
    


}