const getIsRequired = (errorMessage) => {

    const isRequired = (val, name, formState) => {
        //console.log ('is not blank', val)
        if (val) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}

const getMinLenght = (errorMessage, length) => {

    const isRequired = (val, name, formState) => {

        if (val.length >= length) {
            return ''
        }
        else {
            return errorMessage
        }
    }

    return isRequired
}



const stringToDate = function (dateString) {
    const [dd, mm, yyyy] = dateString.split(".");
    return new Date(`${yyyy}-${mm}-${dd}`);
};

const getIsBeyondToday = (errorMessage, length) => {

    const isBeyondToday = (val, name, formState) => {

        if (!val) {
            return ''
        }

        let CurrentDate = new Date()
        let GivenDate = stringToDate(val)

        if (GivenDate > CurrentDate) {
            return errorMessage
        } else {
            return ''
        }
    }

    return isBeyondToday
}

const getForfallEtterFaktDate = (errorMessage, length) => {

    const validatorFunc = (val, name, formState) => {

        if (!val) {
            return ''
        }

        if (!formState.startDate) {
            return ''
        }

        let fakturaDate = stringToDate(formState.startDate)
        let forfall = stringToDate(val)

        if (forfall <= fakturaDate) {
            return errorMessage
        } else {
            return ''
        }
    }

    return validatorFunc
}


export const getInterestCalculatorValidators = (messages) => {
    
    const minLength10 = getMinLenght(messages.minLenght10, 10)
    const isForfallOk = getForfallEtterFaktDate(messages['isForfallOk'], 10)
    const startDateRequired = getIsRequired(messages['startDateIsRequired'])
    const endDateRequired = getIsRequired(messages['endDateIsRequired'])
    const interestRequired = getIsRequired(messages['interestIsRequired'])
    const amountRequired = getIsRequired(messages['amountIsRequired'])

    const formValidators = {

        startDate: [
           startDateRequired,
            minLength10,
        ],
        endDate: [
            endDateRequired,
            minLength10,
            isForfallOk,
        ],
        interest: [
            interestRequired,            
        ],
        amount: [
            amountRequired           
        ]
    }

    return formValidators
}
