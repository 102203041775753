import React from 'react';
import { Container, Col, Form, Alert } from "react-bootstrap";
import { BfColInput } from '../../Styling/FormComponents/FormInput';
import { BfRowForm, BfTightRowForm, BfButtonBlue } from '../../Styling/styledForm/styledBlueForm'

export const ResetPasswordForm = (props) => {

    const handleChange = props.handleChange
    const errorMessages = props.errorMessages
    const extraErrors = props.extraErrors
    const localeMessages = props.localeMessages
    const formState = props.formState
    const hasErrors = props.hasErrors
    const submitForm = props.submitForm

    const getFormField = (colspan, fieldname, inputType = 'text', selectOptions = '') => {
        return (
            <BfColInput
                colspan={colspan}
                name={fieldname}
                inputType={inputType}
                onChange={handleChange}
                errorMessages={errorMessages}
                extraErrors={extraErrors}
                messages={localeMessages}
                required
                formState={formState}
                selectOptions={selectOptions}
            />
        )
    }


    return (
        
            
        <Form autoComplete="chrome-off" style={{ maxWidth: "1000px" }}>

            {hasErrors && (
                <Alert variant='danger'>
                    {localeMessages.youHaveErrors}
                </Alert>
            )}                    

            <BfRowForm>
                {getFormField(12, 'Password1', 'password')}
            </BfRowForm>

            <BfRowForm>
                {getFormField(12, 'Password2', 'password')}
            </BfRowForm>

            <BfTightRowForm>
                <Col xs={12} md={12}>
                    <BfButtonBlue variant="primary" type="button" onClick={submitForm}>
                        {localeMessages.Lagre}
                    </BfButtonBlue>
                </Col>
            </BfTightRowForm>

            <BfTightRowForm />

        </Form>
           
        
    )
}