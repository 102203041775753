import React, { useState } from "react";
import { Form, Col, Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
} from "../../../Styling/styledModal/styledModals";


const UtsettSakModal = (props) => {
    
    const localeMessages =  props.funcs.utsettSakMessages 
    
    const [chosen, setChosen] = useState('')
    const [cause, setCause] = useState('')
    
    const allFieldsHasValues = () => {
        return (!chosen || !cause)
    }
          
    const eventHide = props.funcs.cancelHandler
    
    const onOk = () => props.funcs.OkHandler(chosen, cause)

    return (
        

        <StyledModal centered show={true} onHide={eventHide}>

            <StyledModalBody>
                <h4>{localeMessages["US1M_Overskrift"]}</h4>
                <Form.Group>

                    <Form.Row>
                        <Col> 
                            <div onChange={e => setChosen(e.target.value)}>

                                <input type="radio" name="gender" value="7d" />&nbsp;&nbsp;
                                {localeMessages["US1M_alt1"]}<br />

                                <input type="radio" name="gender" value="14d" />&nbsp;&nbsp;
                                {localeMessages["US1M_alt2"]}<br />

                                {/*
                                <input type="radio" name="gender" value="30d" />&nbsp;&nbsp;
                                {localeMessages["US1M_alt3"]}<br />
                                 */}

                            </div>
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Form.Label column="sm">
                            {localeMessages["US1M_melding"]}
                        </Form.Label>
                        <Col>                            
                            <Form.Control 
                                id="cause" 
                                name="cause" 
                                as="textarea" rows={2} style={{ whiteSpace: 'pre-line'}}
                                value={cause} onChange={(e) => setCause(e.target.value)}                                
                            />
                        </Col>
                    </Form.Row>

                </Form.Group>

            </StyledModalBody>

            <StyledModalFooter>
                <Button variant="secondary" onClick={onOk} disabled={allFieldsHasValues()}>
                    {localeMessages["US1M_Ok"]}
                </Button>
                <Button variant="secondary" onClick={eventHide}>
                    {localeMessages["US1M_Avbryt"]}
                </Button>
            </StyledModalFooter>

        </StyledModal>
    );
};

export default UtsettSakModal;