import React, { useState } from 'react';
import { FormattedDate } from 'react-intl'
import { StyledKfTextTable, StyleInlineFkTable } from '../../../Styling/styledTable/StyledTable';
import UseTableSorter from '../../../components/useTable/UseTable'
import usePaginator from '../../../components/useTable/UsePaginator'
import { fixMissingTimeFromDate } from '../../../util/date/dateRoutines';
import CaseListToolTip from '../../caselist_redux/caseListTable/CaseListTooltip';
import fileDownload from 'js-file-download'
import axiosTokenInstance from '../../../Instances/axiosTokenInstance';
import { useDispatch } from 'react-redux'
import { markDownloaded } from '../../../reduxStore/filbehandling/filbehandlingActions';
import { useAuth } from '../../../hooks/useAuth';


const FilListe = (props) => {

    const auth = useAuth()

    //const localMessages = props.localeMessages
    const localeMessages = props.localeMessages

    const retning = props.retning
    const dispatch = useDispatch()

    // State for grid sorting
    const [currentSeq, setCurrentSeq] = useState('down') // default
    const [currentField, setCurrentField] = useState('fileDate')    // feltnavn
    const [currentType, setCurrentType] = useState('datetime') // num

    // state for grid paginator
    const [numberPrPage, setNumberPrPAge] = useState(10)
    const [activePage, setActivePage] = useState(1);


    const [t1ChangeSortField, t1GetSortFunc, t1IndicaterIcon] = UseTableSorter(
        currentSeq, setCurrentSeq,
        currentField, setCurrentField,
        currentType, setCurrentType
    )

    // Point to data 
    const tableData = props.tableData

    // Setupt paginator
    const [Paginate, PaginationNav, PaginationDropdown, PaginationIndex, resetPagination] = usePaginator(
        tableData.sort(t1GetSortFunc().fn), // <==== sorting inputdata =========
        numberPrPage, setNumberPrPAge,
        activePage, setActivePage
    )

    /*
     "fileId": 0,
            "fileDate": "2021-05-19T14:19:32.726Z",
            "fileName": "string",
            "documentType": "string",
            "fileGroup": 0,
            "userName": "string",
            "fileStatus": "string",
            "isSentFile": true,
            "isDownloaded": true
    */

    const printHeader = () => {
        return (
            <thead>
                <tr>
                    <th onClick={() => t1ChangeSortField('fileDate', 'datetime')}>{localeMessages['GHF_fileDate']}{t1IndicaterIcon('fileDate')}</th>
                    <th onClick={() => t1ChangeSortField('fileName', 'string')}>{localeMessages['GHF_fileName']}{t1IndicaterIcon('fileName')}</th>
                    <th onClick={() => t1ChangeSortField('documentType', 'string')}>{localeMessages['GHF_documentType']}{t1IndicaterIcon('documentType')}</th>
                    <th onClick={() => t1ChangeSortField('downloadUserName', 'string')}>{localeMessages['GHF_lastetNed']}{t1IndicaterIcon('lastetNed')}</th>
                    <th onClick={() => t1ChangeSortField('uploadUserName', 'string')}>{localeMessages['GHF_lastetOpp']}{t1IndicaterIcon('lastetOpp')}</th>
                    <th onClick={() => t1ChangeSortField('fileStatus', 'string')}>{localeMessages['GHF_fileStatus']}{t1IndicaterIcon('fileStatus')}</th>
                    <th ></th>
                                        
                </tr>
            </thead>
        )
    }


    const handleDownload = (row) => {

        //[FromForm] string SelectedKreditor, [FromForm] int FileId, [FromForm] string FileName
        
        axiosTokenInstance.get(
            `api/files/DownloadFile/${row.customerNo}/${row.fileId}/${row.originSeqNo}/${row.fileName}`,
            { responseType: 'blob' }
        )
            .then((res) => {
                fileDownload(res.data, `${row.fileName}`)

                dispatch(markDownloaded(row.fileId,  auth.user.email))
            })
        
        
            
    }

    const getDownloadLink = (row) => {
        if (row.fileId) {
            return (

                <CaseListToolTip key={'f' + row.id} id={'f' + row.id} caption={'Last ned fil ' + row.fileName}>
                    <span key={'fr' + row.id}  className="fa fa-cloud-download"
                        onClick={() => {
                            handleDownload(row)

                        }}
                    />
                </CaseListToolTip>

            )
        }

        return null

    }

    const printBoldIfMottatt = (model) => {
        if (retning === 'motatt' && !model.isDownloaded) {
            return (
                <b>{model.fileName}</b>
            )
        } else {
            return (
                <>{model.fileName}</>
            )
        }
    }

    const printRow = (model, i) => {

        const asDate = (val) => {
            // Kludge fordi FireFox ikke godtar dato uten tid uten videre
            let valok = fixMissingTimeFromDate(val)
            return (
                <FormattedDate value={valok} day="2-digit" month="2-digit" year="numeric" />
            )
        }        

        // ISDOWNDLOAD

        return (
            <>
                <tr key={model.i.toString()}>
                    <td>{asDate(model.fileDate)}</td>
                    <td>{printBoldIfMottatt(model)}</td>                   
                    <td>{model.documentType}</td>
                    <td>{model.downloadUserName}</td>
                    <td>{model.uploadUserName}</td>
                    <td>{model.fileStatus}</td>
                    <td>{getDownloadLink(model)}</td>
                </tr>

            </>
        )
    }    

    return (
        <>
            {props.tableData.length !== 0 && <PaginationDropdown />}
            <StyledKfTextTable>
                {printHeader()}

                <tbody>
                    <Paginate>
                        {/* paginate forventer en funksjon */}
                        {(row, i) =>
                            printRow(row, i)}
                    </Paginate>
                </tbody>
            </StyledKfTextTable>
            {props.tableData.length !== 0 &&
                <>
                    <br />
                    <PaginationIndex />
                    <br /><br />
                    <PaginationNav />
                </>
            }

        </>
    )
}

export default FilListe
