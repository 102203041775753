import {
    FETCH_TOOLSMENU_BEGIN,
    FETCH_TOOLSMENU_FAILURE,
    FETCH_TOOLSMENU_SUCCESS
} from "./toolsMenuActions"

const initialState = {
    menuItems: null,
    loading: false,
    error: null
}

const toolsMenuReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_TOOLSMENU_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };
        
        case FETCH_TOOLSMENU_SUCCESS:
            
            return {
                ...state,
                loading: false,
                items: action.payload.menuItems
            }
        
        case FETCH_TOOLSMENU_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };
            
        default:
            return state
    }
}

export default toolsMenuReducer