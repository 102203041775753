export const messages = {
    nb: {
        Header1: 'Dine siste saker',
        Paragraph1: 'Merk at saldo i listen under er saldo fra dato på siste besøk av saken, og kan fravike fra dagens saldo.  Klikk på lenke for å gå til saken for å se oppdatert informasjon.',
        'visitedDate': 'Besøkt',
        'debitorNavn': 'Debitor',
        'refNr': 'Referansenr',
        'saksNr': 'Saksnr',
        'saldo': 'Saldo',
        'status': 'Status'
    }
  }
