import React, { useEffect } from 'react';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './casesMRUContainer.i18n'
import CasesMRUTable from './components/CasesMRUTable';
import { Alert } from 'react-bootstrap';
import { OverlaySpinner } from '../../components/spinner/OverlaySpinner';
import { ArticleArea } from '../../Styling/Layout/ContentArea';
import { useSelector, useDispatch } from 'react-redux'
import { dispatchGetCasesMRUList } from '../../reduxStore/mru/mruActions';

const CasesMRUContainer = (props) => {

    const locale = React.useContext(LocaleContext);
    const localeMessages = messages[locale]
    const showToasts = true;

    //const { state, dispatch, actions } = userCasesMRUReducer(localeMessages)
    const state = useSelector(state => state.mru)
   
    const dispatch = useDispatch()
    useEffect(() => {
        //dispatchGetCasesMRUList(showToasts, localeMessages, dispatch, actions)   
        dispatch(dispatchGetCasesMRUList(showToasts, localeMessages))
    }, [])
      
    return (
        <>
            {(state.error) &&
                <Alert variant='danger'>
                    {state.error}
                </Alert>}

            {state.loading && <OverlaySpinner />}

            {(state.fetched && !state.loading) &&
                <>
                    <ArticleArea>
                        <h1>{localeMessages.Header1}</h1>
                        <p>{localeMessages.Paragraph1}</p>                    
                    </ArticleArea>

                    
                    <CasesMRUTable
                    data={state.data}
                    messages={messages}
                    />
                
                </>
            }
        </>
    )
}

export default CasesMRUContainer