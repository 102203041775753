import { useCallback, useState, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import { messages } from './FilbehandlingContainer.i18n'
import { useSelector, useDispatch } from 'react-redux'
import { getFiles, uploadFiles } from '../../reduxStore/filbehandling/filbehandlingActions'

export const useFilbehandlingsContainer = (locale) => {

    const showToasts = true;
    
    const localeMessages = messages[locale]   

    // Activate global redux reducer
    const state = useSelector(state => state.filbehandling)
    const headerState = useSelector(state => state.sakslisteHeader)

    const dispatch = useDispatch()
    let selectedKreditor = headerState.kreditorId 

    // Hent data ved oppstart
    useEffect(() => {
        // Dispatch hening av data
        const formData = new FormData();
        if (!headerState.kreditorId) {
            //MERK kludge pga sjekk på C# server
            formData.append('SelectedKreditor', '-1')        
        } else {
            formData.append('SelectedKreditor', headerState.kreditorId)        
        }
        dispatch(getFiles(formData, showToasts, localeMessages))
    }, [headerState.kreditorId])

    const [key, setKey] = useState('mottatteFilerTab');

    const [files, setFiles] = useState([])

    const clearAll = () => setFiles([])
    const clearSingle = (name) => {
        setFiles([...files].filter(x => x.name !== name))
    }
    
    // onDrop function  
    const onDrop = useCallback(acceptedFiles => {
        // this callback will be called after files get dropped      
        const MAXBYTES = 2000000000 // På server satt til 2147483647                        
        
        acceptedFiles.map(f => {
            let duplicate = files.some(e => e.name === f.name)
            if (!duplicate) {
                if (f.size > MAXBYTES) {
                    (showToasts && toast.error(localeMessages['toastFileExceedsMax'] + f.name, {autoClose: false}))
                } else {
                    setFiles(files => [...files, f])
                }
            }
        })
    }, [files]);

    const upload = (SelectedKreditor) => {

        const formData = new FormData();

        files.map((f) => {
            formData.append(
                "files",
                f,
                f.name)
        })

        formData.append('SelectedKreditor', SelectedKreditor)

        console.log("FormData")
        for (var pair of formData.entries()) {
            console.log(pair[0] + ': "' + pair[1] + '"');
        }
        
        dispatch(uploadFiles(formData, clearAll, selectedKreditor, showToasts, localeMessages))
    }

    return { localeMessages, selectedKreditor, key, setKey, files, clearAll, clearSingle, onDrop, upload, state }
}