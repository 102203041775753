import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import {
    StyledModal,
    StyledModalBody,
    StyledModalFooter,
} from "../../../Styling/styledModal/styledModals";
import _ from 'lodash'


const LesMeldingModal = (props) => {

    const localeMessages = props.localeMessages

    const model = props.model

    const handleOk = () => {
        const resultModel = {...model}
        props.ok(resultModel)
    }

    const handleCancel = () => {
        props.close()
    }

    const messageLfCorrected = () => {
        if (model.messageText) {
            return model.messageText.replaceAll('\\r\\n', '\n')
        }
        else return ''
    } 

    return (

        <StyledModal centered show={true} onHide={handleCancel} size="xl">

            <StyledModalBody>
                <h4>{localeMessages["ML1M_Overskrift"]}</h4>
                <Form.Group>

                    <Form.Row>
                        <Form.Label column="sm" lg={2}>
                            {localeMessages["ML1M_sak"]}
                        </Form.Label>
                        <Col>
                            {model.caseNo} {model.debtorName}
                        </Col>
                    </Form.Row>
                    

                    <Form.Row>
                        <Form.Label column="sm" lg={2}>
                            {localeMessages["ML1M_oppgave"]}
                        </Form.Label>
                        <Col>
                            <Form.Control id="Oppgave" name="Oppgave" as="textarea" rows={8} ReadOnly style={{ backgroundColor: '#ffd7ba', whiteSpace: 'pre-line' }}
                                value={messageLfCorrected()}
                            />
                        </Col>
                    </Form.Row>

                </Form.Group>

            </StyledModalBody>

            <StyledModalFooter>
                {!model.isHistorical &&
                    <>
                        <Button

                            variant="secondary"                    
                            onClick={handleOk}
                        >
                        {localeMessages["ML1M_Ok"]}
                                
                        </Button>
                        <Button variant="secondary"                    
                            onClick={handleCancel}
                        >
                            {localeMessages["ML1M_Avbryt"]}
                        </Button>
                    </>
                }
                {model.isHistorical &&
                    <>                       
                        <Button variant="secondary"
                            onClick={handleCancel}
                        >
                            {localeMessages["ML1M_Lukk"]}
                        </Button>
                    </>
                }
                

            </StyledModalFooter>

        </StyledModal>
    );
};

export default LesMeldingModal;

