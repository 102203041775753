import React, { useEffect, useState } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import styled from 'styled-components';

const StyledDropdownToggle = styled(Dropdown.Toggle)`
    font-family: "Fellix","sans-serif";
    font-size: 20px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-radius: 0.5em;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`

export const SearcableSelect = (props) => {

    // const getKreditorOptions = props.options

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </a>
    ));

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Filtrer poster..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(   
                            (child) => !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
                        )}
                    </ul>
                </div>
            );
        },
    );

    const getCaption = () => {
        if (props.kreditorListe && props.value) {
            let selitem = props.kreditorListe.find(o => o.customerNo === props.value)
            if (selitem) 
                return selitem.disp
        }            
        return props.defaultCaption

    }

    const handleSelected = (e) => {
        //console.log(eventKey)
        props.onChange(e)
    }

    return (
       <>
            <Dropdown>

                <StyledDropdownToggle variant="input" id="dropdown-basic" style={{ backgroundColor: "white" }}>
                   {getCaption()}
                </StyledDropdownToggle>


                <Dropdown.Menu as={CustomMenu} onChange={(e) => console.log(e)}>

                    {(!props.kreditorListe || props.kreditorListe.length === 0) &&
                        <Dropdown.Item key={-1}>Ingen kreditorer</Dropdown.Item>
                    }

                    {(props.kreditorListe && props.kreditorListe.length !== 0 && props.hideAlle !== true) &&
                        <Dropdown.Item key={0} onSelect={e => handleSelected("")} eventkey={"alle"}>Alle kreditorer</Dropdown.Item>
                    }

                    {props.kreditorListe && props.kreditorListe.map((item, i) => (
                        <Dropdown.Item key={i} onSelect={e => handleSelected(`${item.ind.split(';')[1]}`)} eventkey={`${item.ind.split(';')[1]}`}>{item.disp}</Dropdown.Item>
                        ))}
                                         
                </Dropdown.Menu>
            </Dropdown>

        </>
    )

}