import React, { useEffect, useState } from 'react';
import LocaleContext from '../../state/contexts/LocaleContext'
import { messages } from './messages.i18n'
import _ from 'lodash'
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import axiosTokenInstance from '../../Instances/axiosTokenInstance';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../reduxStore/profil/profilActions';

export const useMfaContainer = () => {

    const locale = React.useContext(LocaleContext)
    const localeMessages = messages[locale]

    const auth = useAuth()
    const twoFactorEnabled = auth.user.twoFactorEnabled

    const history = useHistory()
    
    const showToasts = true

    const [isLoading, setIsLoading] = useState(false)
    const [pageHasError, setPageHasError] = useState('')
    const [savedOk, setSavedOk] = useState('')

    const [displayWelcome, setDisplayWelcome] = useState(true)
    const [displayQrCode, setDisplayQrCode] = useState(false)
    const [displaySMSValidator, setDisplaySMSValidator] = useState(false)
    const [displayMfaOk, setDisplayMfaOk] = useState(false)
    const [removeMfa, setDisplayRemoveMfa] = useState(false)

    const [qrCodeUri, setQrCodeUri] = useState(true)

    const dispatch = useDispatch()
    const profilState = useSelector(state => state.profil)
        
    // Hent data ved oppstart
    useEffect(() => {                   
        dispatch(getProfile(auth.user.email))
    }, [])
     
    const getQrCodeUrl = () => {

        setQrCodeUri("")

        const formData = new FormData()                              
        formData.append('Epost', auth.user.email)            

        if (auth.isAuthenticated) {

            axiosTokenInstance({
                method: 'POST',
                url: `/api/apisecurity/GetAuthenticatorUri`,
            }).then((result) => {
                setIsLoading(false)
                setPageHasError('')
                setSavedOk('')
                
                console.log(result);

                if (result.status !== 200) {
                    toast.error('Registrering av tofaktor pålogging feilet.', {autoClose: false})
                } else {
                    console.log(JSON.stringify(result.data))
                    setQrCodeUri(result.data.qrCodeUrl)
                    setDisplayQrCode(true)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                toast.error('Registrering av tofaktor pålogging feilet.', {autoClose: false})                                  
            })            
        }                 
    }

    const postValidationCode = (code) => {
               
        const formData = new FormData()                              
        formData.append('code', code)  
        
        if (auth.isAuthenticated) {

            axiosTokenInstance({
                method: 'POST',
                url: `/api/apisecurity/VerifyAuthenticator`,
                data: formData
            }).then((result) => {
                setIsLoading(false)
                setPageHasError('')
                setSavedOk('')
                auth.setMfa()
                console.log(result); 
                history.push(`/Overview`);
                
                (showToasts && toast.info('Tofaktor kode er godkjent..'))                    
            })
            .catch((err) => {
                setIsLoading(false)                
                console.log(err)   
                toast.error('Registrering av tofaktor pålogging feilet, koden som ble oppgitt er ikke gyldig.', {autoClose: false})
            })            
        }                 
    }

    const postUnRegisterMfa = (code) => {
              
        if (auth.isAuthenticated) {

            axiosTokenInstance({
                method: 'POST',
                url: `/api/apisecurity/UnRegisterMfa`,
            }).then((result) => {
                setIsLoading(false);
                setPageHasError('');
                setSavedOk('');
                auth.removeMfa();
                console.log(result);
                history.push(`/profil`);
                (showToasts && toast.info('Tofaktor pålogging er avregistrert.'))                   
            })
            .catch((err) => {
                setIsLoading(false)                
                console.log(err)    
                toast.error('Tofaktor pålogging er registrering feilet.', {autoClose: false})
            })            
        }                 
    }

    const showQrCode = () => {
        console.log("Clicked get getqrcode url")
        getQrCodeUrl();
        setDisplayWelcome(false)        
    }

    const handleSMSClick = () =>  {
        
        console.log("Clicked SendSMS")

        setDisplaySMSValidator(true)
        setDisplayWelcome(false)
        setDisplayQrCode(false)

        // NB tom formdata, kunne vært Get?
        const formData = new FormData()                              
        
        if (auth.isAuthenticated) {

            axiosTokenInstance({
                method: 'POST',
                url: `/api/apisecurity/SendRegisterMFASMS`,
                data: formData
            }).then((result) => {
                setIsLoading(false)
                setPageHasError('')
                setDisplaySMSValidator(true)

                console.log(result); 
                
                (showToasts && toast.info('SMS for registrering av SMS Tofaktor sendes.'))                    
            })
            .catch((err) => {
                setIsLoading(false)                
                console.log(err)   
                toast.error('Registrering av tofaktor pålogging feilet.', {autoClose: false})
            })            
        }
    }

    const postValidateSMSCode = (code) => {
               
        const formData = new FormData()                              
        formData.append('code', code)  
        
        if (auth.isAuthenticated) {

            axiosTokenInstance({
                method: 'POST',
                url: `/api/apisecurity/VerifyMFASMS`,
                data: formData
            }).then((result) => {
                setIsLoading(false)
                setPageHasError('')
                setSavedOk('')
                auth.setMfa()
                console.log(result); 
                history.push(`/Overview`);
                
                (showToasts && toast.info('Tofaktor kode er godkjent.'))                    
            })
            .catch((err) => {
                setIsLoading(false)                
                console.log(err)   
                toast.error('Registrering av tofaktor pålogging feilet. (er koden korrekt?)', {autoClose: false})
            })            
        }                 
    }

    return { isLoading, displayWelcome, displayQrCode, displayMfaOk, removeMfa, showQrCode, qrCodeUri, localeMessages, postValidationCode, postUnRegisterMfa, twoFactorEnabled, handleSMSClick, displaySMSValidator, postValidateSMSCode, profilState}
}
