import React, { useContext, useEffect, useState } from 'react';
import LocaleContext from '../state/contexts/LocaleContext';
import { messages } from './Header1Menu.i18n'
import { BlueHeader1Left } from '../Styling/Layout/BlueHeader1Area';
import { NavLink, Link  } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useBisnode } from '../containers/bisNode/useBisnode';
//import { Header1ReportDropDown } from './Header1ReportDropDown'
import CONFIG, { POWERBI_ENABLED } from '../config';

/*
    variant av header1 menu som plasseres under blått felt for å emulere gammelt design
*/ 
const BlueHeader1Menu = (props) => {


    const IS_POWERBI_ENABLED = CONFIG.POWERBI_ENABLED
    const IS_KONTAKTSKJEMA_ENABLED = CONFIG.KONTAKTSKJEMA_ENABLED
    
    const locale = useContext(LocaleContext);
    const { IndexCaption, UserAdminCaption, ProfilCaption, LoginCaption, LogoutCaption,
        SakCaption, NySakCaption, OnBoardingRegisterCaption, OnBoardingCaption,
        KontaktSkjemaCaption, mRapporter
    } = messages[locale]
    const localeMessages = messages[locale]

    const headerState = useSelector(state => state.sakslisteHeader)

    const auth = useAuth()

    const location = useLocation();
    const path = location.pathname

    const isAdmin = () => {
        if (auth.isSupervisor())
            return true
        if (auth.isForeningSupervisor())
            return true
        return false
    }

    const {onBisnodeClick} = useBisnode()

    return (
        <>            

            {(auth.isAuthenticated && !isAdmin()  && (auth.creditors.length > 0)) &&
                <BlueHeader1Left border={path === "/Overview"}>
                    <NavLink to="/Overview" >{localeMessages['mOversikt']}</NavLink>
                </BlueHeader1Left>
            }

            {(auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0)) &&
                <BlueHeader1Left border={path === "/Filbehandling"}>
                <NavLink to="/Filbehandling" >{localeMessages['mFilbehandling']}</NavLink>
                </BlueHeader1Left>
            }
        
            {(!IS_POWERBI_ENABLED  && auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0)) &&
                <BlueHeader1Left border={false}>
                    <NavLink to="/BanqsoftPortal">{localeMessages['mRapporter']}</NavLink>               
                </BlueHeader1Left>
            }

            
            {(IS_POWERBI_ENABLED && auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0)) &&
                <BlueHeader1Left border={path.lastIndexOf("/Reports", 0) === 0}>
                    <NavLink to="/Reports/Main">{mRapporter}</NavLink>
                </BlueHeader1Left>
            }

                      
            {(auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0)) &&
                <BlueHeader1Left border={path === "/Caselist"}>
                    <NavLink to="/Caselist">{SakCaption}</NavLink>
                </BlueHeader1Left>
            }

            {(auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0)) &&
                <BlueHeader1Left border={path === "/CasesMRU"}>
                    <NavLink to="/CasesMRU" >{localeMessages['mSisteSaker']}</NavLink>
                </BlueHeader1Left>
            }

            {(auth.isAuthenticated && headerState.stengeliste && !isAdmin()) && (auth.creditors.length > 0) &&
                <BlueHeader1Left border={path === "/stengeliste"}>
                    <NavLink to="/stengeliste" >{localeMessages['mStengeliste']}</NavLink>
                </BlueHeader1Left>
            }

            {(auth.isAuthenticated && headerState.fakturaAdm && !isAdmin()) && (auth.creditors.length > 0) &&
                <BlueHeader1Left border={path === "/fakturaadmin"}>
                    <NavLink to="/fakturaadmin" >{localeMessages['mFakturaAdmin']}</NavLink>
                </BlueHeader1Left>
            }

            {(auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0)) &&
                <BlueHeader1Left border={path === "/CaseNew"}>
                    <NavLink to="/CaseNew/init">{NySakCaption}</NavLink>
                </BlueHeader1Left>
            }
            
            {(auth.isAuthenticated && !auth.isUser()) &&
                <BlueHeader1Left border={path === "/Brukere"}>
                    <Link to="/Brukere" replace>{UserAdminCaption}</Link>
                </BlueHeader1Left>
            }
            {auth.isAuthenticated &&
                <BlueHeader1Left border={path === "/Profil"}>
                    <NavLink to="/Profil">{ProfilCaption}</NavLink>
                </BlueHeader1Left>
            }

            {(auth.isAuthenticated && !isAdmin() && (auth.creditors.length > 0)) &&
                <BlueHeader1Left border={path === "/creditRating"}>
                    <NavLink to="/creditRating">{localeMessages['mKredittvurdering']}</NavLink>
                </BlueHeader1Left>
            }

            {(auth.isAuthenticated && auth.hasBisnode()) &&
                <BlueHeader1Left border={false}>
                    <a onClick={onBisnodeClick} >{localeMessages['mKredittInformasjon']}</a>
                </BlueHeader1Left>
            }

            {!auth.isAuthenticated &&
                <BlueHeader1Left border={path === "/OnBoarding"}>
                    <NavLink to="/OnBoarding">{OnBoardingCaption}</NavLink>
                </BlueHeader1Left>
            }

            {IS_KONTAKTSKJEMA_ENABLED && (!auth.isAuthenticated) &&
                <BlueHeader1Left border={path === "/KontaktSkjema"}>
                    <NavLink to="/KontaktSkjema">{KontaktSkjemaCaption}</NavLink>
                </BlueHeader1Left>
            }
            
            {!auth.isAuthenticated &&
                <BlueHeader1Left border={path === "/Login"}>
                    <NavLink to="/Login">{LoginCaption}</NavLink>
                </BlueHeader1Left>
            }
                        
        </>
    )
}

export default BlueHeader1Menu
